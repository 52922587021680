const STATUSLIST = {
    Todos: "Todos",
    NoLeido: "No Leido",
    Activo: "Activo",
    Activa: "Activa",
    Advertencia: "Advertencia",
    Urgente: "Urgente",
    Cerrado: "Cerrado",
    Aprobado: "Aprobado",
    Aceptado: "Aceptado",
    EnRevision: "En Revision",
    Rechazado: "Rechazado",
    Desactivado: "Desactivado",
    NoPublico: "No Público",
    Inactivo: "No Activa",
    Pendiente: "Pendiente",

}

module.exports = {
    STATUSLIST
}