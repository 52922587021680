import React from "react";
import { makeStyles } from "@material-ui/core";
import "./variables.css";
import Person from "./Persons";

const styles = makeStyles((theme) => ({
  wrapperList: {
    backgroundColor: "var(--list-section-color)",
    minWidth: "320px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    height: "64px",
  },
  link: {
    width: "fit-content",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignSelf: "center",
  },
  archiveButton: {
    cursor: "pointer",
    color: "#2475B9",
    border: "none",
    backgroundColor: "inherit",
    outline: "none",
    fontSize: "14px",
  },
}));

const List = ({ personInfo, id, handleArchive, isArchive }) => {
  const classes = styles();
  return (
    <div className={classes.wrapperList}>
      <div className={classes.header}>
        {isArchive ? "Mensajes archivados" : "Recientes"}
      </div>
      <Person personInfo={personInfo} idPerson={id} isArchive={isArchive} />
      <div className={classes.link}>
        <button
          className={classes.archiveButton}
          onClick={() => handleArchive(true)}
        >
          {isArchive ? "Volver a Recientes" : "Conversaciones archivadas"}
        </button>
      </div>
    </div>
  );
};
export default List;
