import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneradorDeGraficos from "../Reportes/GeneradorDeGraficos";
import * as reportesActions from "../../redux/actions/reportesActions";
import * as reportesSelectors from "../../redux/selectors/reportesSelector";
import juntasVecinales from "../../consts/juntasVecinales";
import {
  arrayDeDias,
  fechaStringBasicoAFechaJS,
  mesNumericoAMesNombre,
} from "../../utils/ComponentUtils";
import "./Tablero.css"

import {
  Table,
  Row,
  Container,
  Col,
} from "react-bootstrap";


const fechaHoy = new Date(Date.now());
const fechaSemanaPasada = new Date(Date.now() - 518400000);
const arrayDeDiasMilisegundos = arrayDeDias(fechaSemanaPasada, fechaHoy);
const stringBusquedaFecha = `${fechaSemanaPasada.getFullYear()}-${
  fechaSemanaPasada.getMonth() + 1 < 10
    ? "0" + (fechaSemanaPasada.getMonth() + 1)
    : fechaSemanaPasada.getMonth() + 1
}-${
  fechaSemanaPasada.getDate() < 10
    ? "0" + fechaSemanaPasada.getDate()
    : fechaSemanaPasada.getDate()
}-${fechaHoy.getFullYear()}-${
  fechaHoy.getMonth() + 1 < 10
    ? "0" + (fechaHoy.getMonth() + 1)
    : fechaHoy.getMonth() + 1
}-${fechaHoy.getDate() < 10 ? "0" + fechaHoy.getDate() : fechaHoy.getDate()}`;

const Inicio = () => {
  const dispatch = useDispatch();
  const mapa = useSelector((state) =>
    reportesSelectors.mapaTableroResult(state)
  );
  const temas = useSelector((state) =>
    reportesSelectors.temasTableroResult(state)
  );
  const propuestas = useSelector((state) =>
    reportesSelectors.propuestasTableroResult(state)
  );
  useEffect(() => {
    dispatch(reportesActions.getReportesTablero(stringBusquedaFecha));
  }, []);

  return (
    <Container title={"Inicio"} style={{ marginTop: "72px", minWidth: "100%" }}>
      {mapa && temas && propuestas && (
        <Table>
          <Row>
            <Col>
              <h3 className="titulo-table-actions" style={{ margin: "24px 0 0 24px" }}>
                Información de los últimos 7 días
              </h3>
            </Col>
          </Row>
          <Row className="row-tablero-temas-propuestas">
            <Col>
              <GeneradorDeGraficos
                tipoGrafico={"Pastel"}
                valores={temas.map((tema) => tema.info.length)}
                etiquetas={temas.map((tema) => tema.nombre)}
                titulo={"Temas Calientes"}
                IndicadorRender={true}
                jsonComunasJuntas={juntasVecinales}
              />
            </Col>
            <Col>
              <GeneradorDeGraficos
                tipoGrafico={"Barras"}
                valores={arrayDeDiasMilisegundos.map(
                  (dia) =>
                    propuestas.filter(
                      (propuesta) =>
                        Date.parse(
                          fechaStringBasicoAFechaJS(propuesta.fecha_publicacion)
                        ) > new Date(dia) &&
                        Date.parse(
                          fechaStringBasicoAFechaJS(propuesta.fecha_publicacion)
                        ) < new Date(dia + 86400000)
                    ).length
                )}
                etiquetas={arrayDeDiasMilisegundos.map(
                  (dia) =>
                    `${mesNumericoAMesNombre(new Date(dia).getMonth())
                      .slice(0, 3)
                      .toUpperCase()} - ${new Date(dia).getDate()}`
                )}
                titulo={"Propuestas"}
                IndicadorRender={true}
                jsonComunasJuntas={juntasVecinales}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <GeneradorDeGraficos
                tipoGrafico={"Mapa de calor"}
                valores={mapa}
                etiquetas={[]}
                titulo={"Mapa de calor"}
                IndicadorRender={true}
                jsonComunasJuntas={juntasVecinales}
                alturaGrafico={"70vh"}
                anchoGrafico={"100%"}
              />
            </Col>
          </Row>
        </Table>
      )}
    </Container>
  );
};

export default Inicio;
