import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import GeneralUrl from "../../consts/GeneralUrlStatic"
import {
  Form,
  Row,
  Col,
  FormControl,
  InputGroup,
  Button,
  Dropdown,
  Alert,
} from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  ChevronLeft,
  ChevronDown,
  Upload,
  ExternalLink,
  Trash,
  MessageSquare,
  Clock,
  MoreHorizontal,
  AlertCircle,
  ThumbsUp,
  ThumbsDown,
  X,
} from "react-feather";
import Swal from "sweetalert2";
import {shorten} from "../../utils/ComponentUtils"

// Styles
import "./DetalleAccion.css";

//Componentes
import CalendarioInline from "../../components/reusable/CalendarioInline/CalendarioInline";
import TableWithActions from "../../components/ActionTable/index";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import BorrarAccion from "./BorrarAccion";

//Actions
import {
  getAccionADetalle,
  postAccion,
  putAccion,
  postComentarioAccion,
  putComentarioAccion,
  postRespuestaAccion,
  postVotoComentarioAccion,
  deleteComentarioAccion,
  closeAlert,
} from "../../redux/actions/accionesActions";
import { getProyectos } from "../../redux/actions/proyectosActions";

//Selectors
import {
  accionADetalleResult,
  updateComentariosAccionResult,
  accionPostSuccess,
} from "../../redux/selectors/accionesSelectors";
import { proyectosResult } from "../../redux/selectors/proyectosSelectors";
import PreviewAcciones from "./PreviewAcciones";

import avatar from "./AvatarTest.svg";

const DetalleAccion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, idProyecto, nombreProyecto, idAccion } = useParams();
  const proyectos = useSelector((state) => proyectosResult(state));
  const accionADetalle = useSelector((state) => accionADetalleResult(state));
  const actualizaComentarios = useSelector((state) =>
    updateComentariosAccionResult(state)
  );
  const postSuccess = useSelector((state) => accionPostSuccess(state));

  const [modalDelete, setModalDelete] = useState(false);

  const [tab, setTab] = useState("comentarios");
  const [estatus, setEstatus] = useState();
  const [titulo, setTitulo] = useState();
  const [sector, setSector] = useState();
  const [fecha, setFecha] = useState();
  const [contenido, setContenido] = useState();
  const [etiquetas, setEtiquetas] = useState([]);
  const [imagen, setImagen] = useState();
  const [imagenNueva, setImagenNueva] = useState(false);
  const [comentarios, setComentarios] = useState([]);
  const [actividad, setActividad] = useState([]);
  const [proyecto, setProyecto] = useState();
  const [bannerInicial, setBannerInicial] = useState(true);
  const [modalPreview, setModalPreview] = useState(false);
  const [esRespuesta, setEsRespuesta] = useState(false);
  const [comentarioAResponder, setComentarioAResponder] = useState({
    id: null,
    comentario: null,
    usuario: "",
  });
  const [show, setShow] = useState(false);

  const parserDeFecha = (string) => {
    if (string) {
      let fecha = new Date(string.slice(0, 19));
      const nombreDeMes = (num) => {
        switch (num) {
          case 0:
            return "enero";
          case 1:
            return "febrero";
          case 2:
            return "marzo";
          case 3:
            return "abril";
          case 4:
            return "mayo";
          case 5:
            return "junio";
          case 6:
            return "julio";
          case 7:
            return "agosto";
          case 8:
            return "septiembre";
          case 9:
            return "octubre";
          case 10:
            return "noviembre";
          case 11:
            return "diciembre";
          default:
        }
      };
      return `${fecha.getDate()} de ${nombreDeMes(
        fecha.getMonth()
      )} de ${fecha.getFullYear()} a las ${
        fecha.getHours() > 9 ? fecha.getHours() : "0" + fecha.getHours()
      }:${
        fecha.getMinutes() > 9 ? fecha.getMinutes() : "0" + fecha.getMinutes()
      }`;
    }
  };

  const renderEstatus = (estatus) => {
    const elemento = (color, texto) => {
      return (
        <div className="item-dropdown-estatus-propuesta">
          <div className={`bolita-estatus bolita-estatus-${color}`}></div>
          {texto}
        </div>
      );
    };
    switch (estatus) {
      case "B":
        return elemento("azul", "Borrador");
      case "A":
        return elemento("verde", "Aprobado");
      case "T":
        return elemento("negro", "Terminado");
      default:
    }
  };

  const parserActividades = (actividades) => {
    return actividades.map((actividad) => {
      return {
        actividad: actividad.actividad,
        hora: parserDeFecha(actividad.fecha),
        estatus: renderEstatus(actividad.estado),
      };
    });
  };

  const handleTags = (string, key) => {
    if (key === "Enter") {
      let arr = [...etiquetas];
      string.trimEnd();
      arr.push({ nombre: string });
      setEtiquetas(arr);
      document.getElementById("input-etiquetas-crear-proyecto").value = "";
    } else if (string === "" && key === "Backspace" && etiquetas.length > 0) {
      let arr = [...etiquetas];
      arr.pop();
      setEtiquetas(arr);
    }
  };

  const handleComentar = () => {
    if (esRespuesta) {
      dispatch(
        postRespuestaAccion({
          comunicado: idAccion,
          comentario: document.getElementById("input-comentario-accion").value,
          padre: comentarioAResponder.id,
        })
      );
      setEsRespuesta(false);
    } else {
      dispatch(
        postComentarioAccion({
          comunicado: idAccion,
          comentario: document.getElementById("input-comentario-accion").value,
        })
      );
    }
    document.getElementById("input-comentario-accion").value = "";
  };

  const handleReponder = (idComentario, comentario, usuario) => {
    document.getElementById("input-comentario-accion").focus();
    document.getElementById("input-comentario-accion").click();
    setEsRespuesta(true);
    setComentarioAResponder({
      id: idComentario,
      comentario: comentario,
      usuario: usuario,
    });
  };

  const handleModerar = (comentario, idComentario, estatusComentario) => {
    let body = {
      comentario: comentario,
      comunicado: idAccion,
      moderated: estatusComentario === "N" ? "M" : "N",
    };
    dispatch(putComentarioAccion({ id: idComentario, body: body }));
  };

  const handleVotar = (idComentario, tipo) => {
    dispatch(
      postVotoComentarioAccion({ voto: tipo, comentario: idComentario })
    );
  };

  const handleBorrarComentario = (id) => {
    dispatch(deleteComentarioAccion(id));
  };

  const handleEnviar = () => {
    let body = new FormData();
    if ((titulo, contenido)) {
      body.append("titulo", titulo);
      body.append("estado", estatus);
      body.append("quill_html", contenido);
      body.append("etiquetas", JSON.stringify(etiquetas));
      body.append("campaign", proyecto.id);
      if (document.getElementById("subir-imagen-accion").files[0]) {
        body.append(
          "img",
          document.getElementById("subir-imagen-accion").files[0]
        );
      }
      if (idAccion) {
        dispatch(putAccion({ id: idAccion, body: body }));
      } else {
        dispatch(postAccion(body));
      }
    } else {
      Swal.fire(
        "Datos incompletos",
        "Asegurese que su acción tenga titulo y contenidlo",
        "warning"
      );
    }
  };

  useEffect(() => {
    if (idAccion) {
      dispatch(getAccionADetalle(idAccion));
    } else {
      dispatch(getProyectos());
      setShow(postSuccess ? postSuccess : false);
    }
  }, []);

  useEffect(() => {
    if (accionADetalle && idAccion) {
      setEstatus(accionADetalle.estado);
      setTitulo(accionADetalle.titulo);
      setFecha(accionADetalle.fecha);
      setContenido(accionADetalle.quill_html);
      setEtiquetas(accionADetalle.etiquetas);
      setImagen(GeneralUrl+accionADetalle.img);
      setProyecto(accionADetalle.campaign);
      setComentarios(accionADetalle.comentarios.reverse());
      setActividad(accionADetalle.actividad);
      dispatch(getProyectos());
    }
  }, [accionADetalle]);

  useEffect(() => {
    if (actualizaComentarios) {
      dispatch(getAccionADetalle(idAccion));
    }
  }, [actualizaComentarios]);

  useEffect(() => {
    if (idProyecto) {
      setProyecto({ id: idProyecto, nombre: nombreProyecto });
    }
  }, [idProyecto]);

  return (
    <div class="section">
      <section id="header-propuesta">
        <Link
          id="regresar-propuesta"
          to={idProyecto ? `/proyectos/detalles/${idProyecto}` : "/acciones"}
        >
          <ChevronLeft />
          {idProyecto ? (
            <p>Regresar a Proyecto: {proyecto ? proyecto.nombre : ""}</p>
          ) : (
            <p>Regresar a Acciones</p>
          )}
        </Link>
        <div id="div-header-titulo-botones-propuesta">
          <p id="titulo-propuesta">
            {idAccion ? `Acción: ${titulo}` : "Crear Acción"}
          </p>
          <div id="div-header-botones-propuesta">
            {(id || idAccion) && <Trash onClick={() => setModalDelete(true)} />}

            <Button
              variant="outline-secondary"
              size="sm"
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={() => setModalPreview(true)}
            >
              Preview
            </Button>

            {(id || idAccion) && (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-estatus-propuesta"
                  className="botones-header-propuesta"
                >
                  <div>{renderEstatus(estatus)}</div>
                  <ChevronDown />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {["B", "A", "T"].map((elemento) => (
                    <Dropdown.Item onClick={() => setEstatus(elemento)}>
                      {renderEstatus(elemento)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Button
              className="boton-enviar-accion-detalle"
              onClick={handleEnviar}
            >
              <ExternalLink />
              <p>Enviar</p>
            </Button>
          </div>
        </div>
      </section>

      <section id="datos-propuesta">
        <Form id="form-propuesta">
          {!id && !idAccion && bannerInicial && (
            <div className="banner-azul-mensajes-cambios">
              <AlertCircle />
              <p>
                El estatus inicial de está Acción será "Borrador", por lo que no
                será visible para los ciudadanos.
              </p>
              <X
                className="banner-x-cerrar"
                onClick={() => setBannerInicial(false)}
              />
            </div>
          )}
          <Row>
            <Col sm={12}>
              <Alert
                variant="success"
                show={postSuccess}
                dismissible
                onClose={() => dispatch(closeAlert())}
              >
                <p>
                  {idAccion
                    ? "¡Acción actualizada con éxito!"
                    : "¡Acción creada con éxito!"}
                </p>
              </Alert>
            </Col>
          </Row>
          <Row className="secciones-propuesta">
            <Col sm={9}>
              <label className="labels-propuesta">Titulo de la Acción</label>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder="Ingrese el titulo de la acción"
                  className="inputs-propuesta"
                  defaultValue={titulo}
                  onChange={(event) => setTitulo(event.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <label
                htmlFor="subir-imagen-proyecto"
                className="labels-propuesta"
              >
                Subir Imagen
              </label>
              <input
                id="subir-imagen-accion"
                type="file"
                onChange={(event) => {
                  setImagenNueva(true);
                  let file = new FileReader();
                  file.readAsDataURL(event.target.files[0]);
                  file.onload = function (e) {
                    setImagen(this.result);
                  };
                }}
              />
              <Button
                id="boton-subir-imagen-proyecto"
                onClick={() => {
                  if (document.getElementById("subir-imagen-accion")) {
                    document.getElementById("subir-imagen-accion").click();
                  }
                }}
              >
                <p>
                  {imagenNueva && document.getElementById("subir-imagen-accion")
                    ? document.getElementById("subir-imagen-accion").files[0]
                        .name
                    : "JPEG, JPG o PNG"}
                </p>
                <Upload />
              </Button>
              {idAccion && imagen && !imagenNueva && (
                <a
                  href={imagen}
                  target="_blank"
                >
                  Ver imagen actual
                </a>
              )}
            </Col>
          </Row>
          <Row className="secciones-propuesta">
            <Col sm={12}>
              <label className="labels-propuesta">Contenido de la Acción</label>
              <ReactQuill
                theme="snow"
                value={contenido ? contenido.toString() : ""}
                onChange={(content) => setContenido(content)}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                  "image",
                ]}
              ></ReactQuill>
            </Col>
          </Row>
          <Row className="secciones-propuesta">
            <Col sm={6}>
              <label
                htmlFor="input-nombre-de-encuesta"
                className="labels-propuesta"
              >
                Tags
              </label>
              <div
                id="div-etiquetas-crear-proyecto"
                className="input-crear-proyecto"
              >
                <div id="div-solo-etiquetas-crear-proyecto">
                  {etiquetas &&
                    etiquetas.map((etiqueta) => (
                      <p className="etiquetas-proyecto">{etiqueta.nombre}</p>
                    ))}
                </div>
                <InputGroup>
                  <FormControl
                    type="text"
                    name="input-nombre-de-encuesta"
                    id="input-etiquetas-crear-proyecto"
                    onKeyDown={(event) =>
                      handleTags(event.target.value, event.key)
                    }
                  />
                </InputGroup>
              </div>
            </Col>
            <Col sm={3}>
              <label className="labels-propuesta">Proyecto</label>
              <select className="dropdown-proyecto-detalle-encuesta" onChange={(e)=>{
                console.log({e:e.target.value});
                setProyecto({id:e.target.value})}}> 
              <option>Selecciona un proyecto...</option>
                {proyectos && accionADetalle &&
                  proyectos.map((proyecto) => (
                    <option
                    selected={proyecto.id===accionADetalle.campaign.id}
                      onClick={() => {
                        console.log({proyecto});
                        setProyecto(proyecto);
                      }}
                    value={proyecto.id}>
                      {proyecto.nombre}
                    </option>
                  ))}
              </select>
            </Col>
            {(id || idAccion) && (
              <Col sm={3}>
                <label className="labels-propuesta">Votación Ligada</label>
                <div id="div-votacion-ligada-accion">
                  {accionADetalle && accionADetalle.encuesta ? (
                    <Link to={"/preguntas/" + accionADetalle.encuesta.id}>
                      {shorten(accionADetalle.encuesta.nombre, 20)}
                      <ExternalLink />
                    </Link>
                  ) : (
                    "Sin votación ligada"
                  )}
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </section>
      {idAccion && (
        <section id="comentarios-actividad-propuesta">
          <div id="div-tabs-propuesta">
            <div
              id="tab-comentarios-propuesta"
              className={
                tab === "comentarios"
                  ? "tab-activo-propuesta"
                  : "tabs-inactivos-propuesta"
              }
              onClick={() => setTab("comentarios")}
            >
              <MessageSquare />
              <p>Comentarios</p>
            </div>
            <div
              id="tab-actividad-propuestas"
              className={
                tab === "actividad"
                  ? "tab-activo-propuesta"
                  : "tabs-inactivos-propuesta"
              }
              onClick={() => setTab("actividad")}
            >
              <Clock />
              <p>Actividad</p>
            </div>
          </div>
          {tab === "comentarios" && (
            <div id="contenido-comentarios-actividad-propuesta">
              <Form id="form-comentarios-propuesta">
                <InputGroup>
                  <Form.Control
                    id="input-comentario-accion"
                    as="textarea"
                    rows="1"
                    placeholder="Ingresa tu comentario"
                  />
                </InputGroup>
                <Button id="boton-comentar-propuesta" onClick={handleComentar}>
                  <p>Comentar</p>
                  <MessageSquare />
                </Button>
              </Form>
              {esRespuesta && (
                <div className="mensaje-respondiendo-comentario">
                  <p>Respondiendo a {comentarioAResponder.usuario}</p>
                  <X
                    onClick={() => {
                      setEsRespuesta(false);
                      setComentarioAResponder({ id: null, comentario: null });
                    }}
                  />
                </div>
              )}{" "}
              <div id="div-render-comentarios-propuesta">
                {accionADetalle ? (
                  accionADetalle.comentarios.length === 0 ? (
                    <div className="div-comentarios-o-actividad-vacio">
                      <p>Sin comentarios</p>
                    </div>
                  ) : (
                    accionADetalle.comentarios.map((comentario, index) => {
                      if (comentario.padre === null) {
                        return (
                          <div
                            className="comentario-contenedor-primario-propuesta"
                            key={index}
                          >
                            <img
                              src={
                                "https://api.participacion.management-r.com/display-avatar/" +
                                comentario.usuario.avatar
                              }
                              className="img-avatar-comentarios-desktop"
                            />
                            <div className="comentario-contenedor-secundario-propuesta">
                              <div className="comentario-contenedor-terciario-propuesta">
                                <img
                                  src={
                                    "https://api.participacion.management-r.com/display-avatar/" +
                                    comentario.usuario.avatar
                                  }
                                  className="img-avatar-comentarios-movil"
                                />
                                <div className="comentario-contenedor-cuaternario-propuesta">
                                  <p className="nombres-comentarios-propuesta">
                                    {comentario.usuario.first_name +
                                      " " +
                                      comentario.usuario.last_name}
                                  </p>
                                  <p className="fecha-comentarios-propuesta">
                                    {parserDeFecha(comentario.fecha)}
                                  </p>
                                </div>
                                {comentario.disponibilidad === "1" && (
                                  <Dropdown>
                                    <Dropdown.Toggle className="dropdown-comentario-propuesta">
                                      <MoreHorizontal />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleReponder(
                                            comentario.id,
                                            comentario.comentario,
                                            comentario.usuario.first_name +
                                              " " +
                                              comentario.usuario.last_name
                                          );
                                        }}
                                      >
                                        Responder
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleModerar(
                                            comentario.comentario,
                                            comentario.id,
                                            comentario.moderated
                                          )
                                        }
                                      >
                                        {comentario.moderated === "N"
                                          ? "Intervenir"
                                          : "Aprobar"}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleBorrarComentario(comentario.id)
                                        }
                                      >
                                        Archivar
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                )}
                              </div>
                              {comentario.moderated === "M" && (
                                <div className="div-banner-comentario-rojo">
                                  <AlertCircle />
                                  <p>
                                    Este comentario infringe las normas de la
                                    plataforma o contiene información sensible.
                                  </p>
                                </div>
                              )}
                              {comentario.disponibilidad === "2" && (
                                <div className="div-banner-comentario-rojo">
                                  <AlertCircle />
                                  <p>Este comentario ha sido archivado.</p>
                                </div>
                              )}
                              <p className="texto-comentarios-propuesta">
                                {comentario.comentario}
                              </p>
                              <div className="div-votos-comentarios-propuestas">
                                <ThumbsUp
                                  onClick={() =>
                                    handleVotar(comentario.id, "U")
                                  }
                                />
                                <p>{comentario.votos_up}</p>
                                <ThumbsDown
                                  onClick={() =>
                                    handleVotar(comentario.id, "D")
                                  }
                                />
                                <p>{comentario.votos_down}</p>
                                <div className="barra-gris-2X32"></div>
                                <p className="texto-negro-votos-comentarios-propuestas">
                                  {comentario.votos_up + comentario.votos_down}{" "}
                                  Votos
                                </p>
                              </div>
                              <p className="etiqueta-respuestas">
                                {comentario.respuestas.length > 0
                                  ? comentario.respuestas.length > 1
                                    ? "Respuestas"
                                    : "Respuesta"
                                  : null}
                              </p>
                              {comentario.respuestas.map((respuesta, index) => {
                                return (
                                  <div
                                    className="comentario-contenedor-primario-propuesta"
                                    key={index}
                                  >
                                    <img
                                      src={
                                        "https://api.participacion.management-r.com/display-avatar/" +
                                        respuesta.usuario.avatar
                                      }
                                      className="img-avatar-comentarios-desktop"
                                    />
                                    <div className="comentario-contenedor-secundario-propuesta">
                                      <div className="comentario-contenedor-terciario-propuesta">
                                        <img
                                          src={
                                            "https://api.participacion.management-r.com/display-avatar/" +
                                            respuesta.usuario.avatar
                                          }
                                          className="img-avatar-comentarios-movil"
                                        />
                                        <div className="comentario-contenedor-cuaternario-propuesta">
                                          <p className="nombres-comentarios-propuesta">
                                            {respuesta.usuario.first_name +
                                              " " +
                                              respuesta.usuario.last_name}
                                          </p>
                                          <p className="fecha-comentarios-propuesta">
                                            {parserDeFecha(respuesta.fecha)}
                                          </p>
                                        </div>
                                        <Dropdown>
                                          <Dropdown.Toggle className="dropdown-comentario-propuesta">
                                            <MoreHorizontal />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleModerar(
                                                  respuesta.comentario,
                                                  respuesta.id,
                                                  respuesta.moderated
                                                )
                                              }
                                            >
                                              {respuesta.moderated === "N"
                                                ? "Intervenir"
                                                : "Aprobar"}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleBorrarComentario(
                                                  respuesta.id
                                                )
                                              }
                                            >
                                              Archivar
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      {respuesta.moderated === "M" && (
                                        <div className="div-banner-comentario-rojo">
                                          <AlertCircle />
                                          <p>
                                            Este comentario infringe las normas
                                            de la plataforma o contiene
                                            información sensible.
                                          </p>
                                        </div>
                                      )}

                                      {respuesta.disponibilidad === "2" && (
                                        <div className="div-banner-comentario-rojo">
                                          <AlertCircle />
                                          <p>
                                            Este comentario ha sido archivado.
                                          </p>
                                        </div>
                                      )}
                                      <p className="texto-comentarios-propuesta">
                                        {respuesta.comentario}
                                      </p>
                                      <div className="div-votos-comentarios-propuestas">
                                        <ThumbsUp
                                          onClick={() =>
                                            handleVotar(respuesta.id, "U")
                                          }
                                        />
                                        <p>{respuesta.votos_up}</p>
                                        <ThumbsDown
                                          onClick={() =>
                                            handleVotar(respuesta.id, "D")
                                          }
                                        />
                                        <p>{respuesta.votos_down}</p>
                                        <div className="barra-gris-2X32"></div>
                                        <p className="texto-negro-votos-comentarios-propuestas">
                                          {respuesta.votos_up +
                                            respuesta.votos_down}{" "}
                                          Votos
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      }
                    })
                  )
                ) : (
                  <div className="div-comentarios-o-actividad-vacio">
                    <p>Sin comentarios</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {tab === "actividad" && (
            <div
              id="contenido-comentarios-actividad-propuesta"
              style={{ justifyContent: "flex-start" }}
            >
              {accionADetalle ? (
                accionADetalle.actividad.length === 0 ? (
                  <div className="div-comentarios-o-actividad-vacio">
                    <p>Sin actividad</p>
                  </div>
                ) : (
                  <TableWithActions
                    columns={["ACTIVIDAD", "HORA", "ESTATUS"]}
                    data={parserActividades(accionADetalle.actividad).reverse()}
                  />
                )
              ) : (
                <div className="div-comentarios-o-actividad-vacio">
                  <p>Sin actividad</p>
                </div>
              )}
            </div>
          )}
        </section>
      )}

      <ModalEstandar visibilidad={modalPreview} setModal={setModalPreview}>
        <PreviewAcciones
          titulo={titulo}
          fecha={fecha}
          contenido={contenido}
          etiquetas={etiquetas}
          imagen={imagen}
          imagenNueva={imagenNueva}
          setModal={setModalPreview}
        />
      </ModalEstandar>

      <ModalEstandar visibilidad={modalDelete} setModal={setModalDelete}>
        <BorrarAccion
          setModal={setModalDelete}
          nombreDeAccion={accionADetalle ? accionADetalle.titulo : ""}
          idDeAccion={accionADetalle ? idAccion : ""}
        />
      </ModalEstandar>
    </div>
  );
};

export default DetalleAccion;
