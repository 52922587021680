import React from "react";
import { Card, Row, Col, ProgressBar, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import BadgeComponent from "../../components/BadgeComponent";
import {
  Facebook,
  Linkedin,
  Twitter,
  Whatsapp,
  MessageCircle,
} from "react-feather";
import imagenDefault from "../../assets/PWA-imagen-default.jpg";
import ReactQuill from "react-quill";
import baseUrl from "../../consts/GeneralUrlStatic"

const PreviewAcciones = ({
  titulo,
  fecha,
  contenido,
  etiquetas,
  imagen,
  imagenNueva,
  setModal,
}) => {
  const localTime = moment(fecha);
  moment.locale("es");
  localTime.locale(false);

  console.log(imagen);

  const renderBadges = () => {
    return etiquetas.map((badge) => {
      return (
        <BadgeComponent text={badge.nombre} className="etiquetas-propuesta" />
      );
    });
  };

  const renderMedia = () => {
    return (
      <div className="social-media-div">
        <div className="social-media-icons">
          <p style={{ margin: 0 }}>Compartir</p>
          <div className="icon-social-media-container">
            <a>
              <Facebook />
            </a>
          </div>
          <div className="icon-social-media-container">
            <a>
              <Twitter />
            </a>
          </div>
          <div className="icon-social-media-container">
            <a>
              <Linkedin />
            </a>
          </div>
          <div className="icon-social-media-container">
            <a>
              <MessageCircle />
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card className="card-preview">
      <Card.Title className="card-title-preview">{titulo}</Card.Title>

      <Row className="card-tags-date-social">
        <Col md={3}>
          <Card.Text className="mb-2 text-muted card-preview-fecha">
            <svg
              style={{ marginRight: "0.5em" }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 0C3.73478 0 3.48043 0.105357 3.29289 0.292893C3.10536 0.48043 3 0.734784 3 1V2H2C1.46957 2 0.960859 2.21071 0.585786 2.58579C0.210714 2.96086 0 3.46957 0 4V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V4C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H13V1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0C11.7348 0 11.4804 0.105357 11.2929 0.292893C11.1054 0.48043 11 0.734784 11 1V2H5V1C5 0.734784 4.89464 0.48043 4.70711 0.292893C4.51957 0.105357 4.26522 0 4 0ZM4 5C3.73478 5 3.48043 5.10536 3.29289 5.29289C3.10536 5.48043 3 5.73478 3 6C3 6.26522 3.10536 6.51957 3.29289 6.70711C3.48043 6.89464 3.73478 7 4 7H12C12.2652 7 12.5196 6.89464 12.7071 6.70711C12.8946 6.51957 13 6.26522 13 6C13 5.73478 12.8946 5.48043 12.7071 5.29289C12.5196 5.10536 12.2652 5 12 5H4Z"
                fill="#0F69B4"
              />
            </svg>
            {localTime.format("LL")}
          </Card.Text>
        </Col>
        <Col md={5}>
          <Card.Text style={{ display: "flex" }}>{renderBadges()}</Card.Text>
        </Col>
        <Col md={4}>
          <Card.Text>{renderMedia()}</Card.Text>
        </Col>
      </Row>
      <ReactQuill
        className="quill-no-toolbar"
        readOnly={true}
        value={contenido ? contenido.toString() : ""}
      ></ReactQuill>
      {/* <Card.Text
        className="card-text-style"
        dangerouslySetInnerHTML={{ __html: contenido }}
      ></Card.Text> */}
      <div className="display-flex-proporty">
        <div
          className="card-info-image-novotes"
          style={{
            backgroundImage: imagenNueva
              ? imagen
              : imagen
              ? `url(${imagen})`
              : imagenDefault,
          }}
        >
          <div className="div-imagen-preview">
            <Card.Img
              variant="preview"
              src={
                imagenNueva
                  ? imagen
                  : imagen
                  ? imagen
                  : imagenDefault
              }
            />
          </div>
        </div>
        <div className="card-info-votes">
          <Row>
            <Col md={10}>
              <Card.Text className="progress-bar-container card-text">
                0/3,300 apoyos
              </Card.Text>
              <Card.Text>
                <ProgressBar now={(0 / 3300) * 100} max={50}></ProgressBar>
              </Card.Text>
            </Col>
            <Col md={2}>
              <Link className="">
                <Button className="button-vote" variant="primary">
                  Apoyar
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <button
          className="boton-cerrar-preview-accion"
          onClick={() => setModal(false)}
        >
          Cerrar
        </button>
      </div>
    </Card>
  );
};

export default PreviewAcciones;
