import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from '../services/authServices';

export function* requestNotificationsSaga({ payload }) {
    try {
        const results = yield call(
            apiCall,
            `notificacion/notificaciones/?${payload.url}`,
            null,
            {
                "Content-Type": "application/json",
                "Host": "localhost:3000",
                Authorization: `Bearer ${Services.getAccessToken()}`,
            },
            "GET"
        );
        console.log("Notifications request successfully done", results);
        yield put({ type: types.GET_NOTIFICATIONS_SUCCESS, results });
    } catch (e) {
        console.log("Error while requesting notifications :", e);
        yield put({ type: types.GET_NOTIFICATIONS_ERROR, payload });
    }
}

export function* requestTodayNotificationsSaga({ payload }) {
    try {
        const results = yield call(
            apiCall,
            `notificacion/notificaciones/?${payload.url}`,
            null,
            {
                "Content-Type": "application/json",
                "Host": "localhost:3000",
                Authorization: `Bearer ${Services.getAccessToken()}`,
            },
            "GET"
        );
        console.log("Today notifications request successfully done", payload);
        console.log(results.data);
        yield put({ type: types.GET_TODAY_NOTIFICATIONS_SUCCESS, results });
    } catch (e) {
        console.log("Error while requesting notifications :", e);
        yield put({ type: types.GET_TODAY_NOTIFICATIONS_ERROR, payload });
    }
}

export function* readNotificationSaga({ payload }) {
    try {
        const results = yield call(
            apiCall,
            "notificacion/detalle/",
            payload,
            {
                "Content-Type": "application/json",
                "Host": "localhost:3000",
                Authorization: `Bearer ${Services.getAccessToken()}`,
            },
            "POST"
        );
        console.log("Notification request successfully done", payload);
        yield put({ type: types.PUT_NOTIFICATIONS_SUCCESS, results });
    } catch (e) {
        console.log("Error while sending notification request :", e);
        yield put({ type: types.PUT_NOTIFICATIONS_ERROR, payload });
    }
}

export default function* notificationsSaga() {
    yield takeLatest(types.GET_NOTIFICATIONS, requestNotificationsSaga);
    yield takeLatest(types.GET_TODAY_NOTIFICATIONS, requestTodayNotificationsSaga);
    yield takeLatest(types.PUT_NOTIFICATIONS, readNotificationSaga);
}