import React from "react";
import Plot from "react-plotly.js";
import { call } from "redux-saga/effects";
import "./Reportes.css";

const GeneradorDeGraficos = ({
  tipoGrafico,
  valores,
  etiquetas,
  titulo,
  IndicadorRender,
  jsonComunasJuntas,
  alturaGrafico,
  anchoGrafico,
  tituloEjeX,
  tituloEjeY,
  callbackClick
}) => {
  const buildDataGraficos = (grafico, val, etiq) => {
      switch (grafico) {
        case "Pastel":
          return [
            {
              values: val,
              labels: etiq.map(
                (e) => e.trim()[0].toUpperCase() + e.trim().slice(1).toLowerCase()
              ),
              type: "pie",
              outsidetextfont: { size: 0, color: "white" },
            },
          ];
        case "Barras":
          return [
            {
              type: "bar",
              x: etiq,
              y: val,
            },
          ];
        case "Lineas":
          return [
            {
              type: "scatter",
              x: etiq,
              y: val,
            },
          ];
        case "Histograma":
          return [
            {
              type: "histogram",
              x: val,
            },
          ];
        case "Histograma":
          return [
            {
              type: "histogram",
              x: val,
            },
          ];
        case "Mapa de calor":
          let juntas = [];
          let coordenadas = [];
          Object.values(jsonComunasJuntas).forEach((comuna) => {
            juntas = juntas.concat(Object.keys(comuna));
            coordenadas = coordenadas.concat(Object.values(comuna));
          });
          let arrayDeJuntas = juntas
            .map((junta, index) => {
              return {
                junta: junta,
                coordenadas: coordenadas[index],
                numeroPropuestas: val.filter(
                  (registro) => registro.junta_vecinal_destino === junta
                ).length,
              };
            })
            .filter(
              (objeto) =>
                objeto.numeroPropuestas > 0 &&
                objeto.junta !== "lat" &&
                objeto.junta !== "lon"
            );
          let arrayDeComunas = Object.keys(jsonComunasJuntas)
            .map((comuna) => {
              return {
                comuna: comuna,
                coordenadas: {
                  lat: jsonComunasJuntas[comuna]["lat"],
                  lon: jsonComunasJuntas[comuna]["lon"],
                },
                numeroPropuestas: val.filter(
                  (registro) =>
                    registro.comuna_destino &&
                    registro.comuna_destino.toLowerCase() === comuna.toLowerCase()
                ).length,
              };
            })
            .filter((objeto) => objeto.numeroPropuestas > 0);
          return [
            {
              type: "densitymapbox",
              hoverinfo: "none",
              text: juntas,
              lon: arrayDeJuntas.map((objeto) => Number(objeto.coordenadas.lon)),
              lat: arrayDeJuntas.map((objeto) => Number(objeto.coordenadas.lat)),
              z: arrayDeJuntas.map((objeto) => objeto.numeroPropuestas),
              zmin: 0,
              zmax: Math.max(
                ...arrayDeJuntas.map((objeto) => objeto.numeroPropuestas)
              ),
              opacity: 0.7,
              showlegend: false,
              colorbar: {
                thickness: 10,
                x: 0,
                title: {
                  text: "Densidad de propuestas",
                  side: "right",
                  font: { size: 14 },
                },
              },
              // coloraxis: 'coloraxis',
            },
            {
              type: "scattermapbox",
              hoverinfo: "text+name",
              name: "Juntas Vecinales",
              lon: arrayDeJuntas.map((objeto) => Number(objeto.coordenadas.lon)),
              lat: arrayDeJuntas.map((objeto) => Number(objeto.coordenadas.lat)),
              text: arrayDeJuntas.map(
                (objeto) =>
                  objeto.junta +
                  " - " +
                  objeto.numeroPropuestas +
                  (objeto.numeroPropuestas > 1 ? " Propuestas" : " Propuesta")
              ),
              marker: { color: "orange" },
            },
            {
              type: "scattermapbox",
              hoverinfo: "text+name",
              name: "Distrito",
              lon: arrayDeComunas.map((objeto) => Number(objeto.coordenadas.lon)),
              lat: arrayDeComunas.map((objeto) => Number(objeto.coordenadas.lat)),
              text: arrayDeComunas.map(
                (objeto) =>
                  objeto.comuna +
                  " - " +
                  objeto.numeroPropuestas +
                  (objeto.numeroPropuestas > 1 ? " Propuestas" : " Propuesta")
              ),
              marker: { color: "blue" },
            },
          ];
        default:
          return null;
      }
  
  
  };
  return valores?(
    <Plot
      data={
        valores && etiquetas
          ? buildDataGraficos(tipoGrafico, valores, etiquetas)
          : null
      }
      layout={
        tipoGrafico === "Mapa de calor"
          ? {
              title: titulo.split("-").join(" ").toUpperCase(),
              datarevision: IndicadorRender,
              dragmode: "zoom",
              mapbox: {
                style: "open-street-map",
                center: { lat: 37.4000000, lon:  -4.6000000 },
                zoom: 6.8,
              },
              margin: { r: 0, t: 30, b: 0, l: 0 },
              legend: { orientation: "h", font: { size: 16 } },
              modebar: { orientation: "v" },
              // coloraxis: { colorscale: "Bluered" },
            }
          : {
              title: titulo.replace("-", " ").toUpperCase(),
              datarevision: IndicadorRender,
              modebar: { orientation: "v" },
              xaxis: { title: tituloEjeX },
              yaxis: { title: tituloEjeY },
              // legend: { x: -1, xanchor: "right" },
              // legend: { orientation: "h" },
              
            }
      }
      style={{
        width: anchoGrafico,
        height: alturaGrafico,
        backgroundColor: "#FFFFFF",
      }}
      config={{ responsive: true, displaylogo: false }}
      onClick={
        ((event) => event.target.on("plotly_click"),
        function (data) {
          if (callbackClick) {
            console.log({data});
            callbackClick.f()
            
          }
        })
      }
    />
  ):("")
};

export default GeneradorDeGraficos;
