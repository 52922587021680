import * as types from "../../consts/actionTypes";

const initialState = {
  updateComentariosAccion: false,
  updateAcciones: false,
  postSuccessAccion: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ACCIONES_SUCCESS:
      console.log("Acciones guardadas en el estado acciones");
      return {
        ...state,
        acciones: action.results.data,
        updateAcciones: false,
        postSuccessAccion: false,
      };
    case types.GET_ACCION_DETALLE_SUCCESS:
      console.log("Acciona a detalle guardada en el estado accionADetalle");
      return {
        ...state,
        accionADetalle: action.results.data,
        updateComentariosAccion: false,
        updateAcciones: false,
        postSuccessAccion: false,
      };
    case types.POST_ACCION_SUCCESS:
      console.log("Acciones guardadas en el estado acciones");
      return {
        ...state,
        updateAcciones: false,
        postSuccessAccion: true,
      };
    case types.POST_ACCION_ERROR:
      console.log("Acciones guardadas en el estado acciones");
      return {
        ...state,
        postSuccessAccion: false,
      };
    case types.PUT_ACCION_SUCCESS:
      return {
        ...state,
        updateAcciones: true,
        postSuccessAccion: true,
      };
    case types.DELETE_ACCION_SUCCESS:
      return {
        ...state,
        updateAcciones: true,
      };
    case types.POST_COMENTARIO_ACCION_SUCCESS:
      return {
        ...state,
        updateComentariosAccion: true,
      };
    case types.POST_RESPUESTA_ACCION_SUCCESS:
      return {
        ...state,
        updateComentariosAccion: true,
      };
    case types.PUT_COMENTARIO_ACCION_SUCCESS:
      return {
        ...state,
        updateComentariosAccion: true,
      };
    case types.POST_VOTO_COMENTARIO_ACCION_SUCCESS:
      return {
        ...state,
        updateComentariosAccion: true,
      };
    case types.DELETE_COMENTARIO_ACCION_SUCCESS:
      return {
        ...state,
        updateComentariosAccion: true,
      };
    case "ALERT_CLOSE_ACCION":
      return {
        ...state,
        postSuccessAccion: false,
      };
    default:
      return { ...state };
  }
}
