import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Settings, User, Mail, Info, LogOut } from "react-feather";
import { logOut } from "../../redux/actions/userActions";

//Utils
import { clicksInside } from "../../redux/services/uxServices";

const MiniPanelUsuario = ({ usuario, setAbrirMiniPanel }) => {
  const dispatch = useDispatch();
  const [fechaYHora, setFechaYHora] = useState(new Date(Date.now()));

  const fechaParser = (fecha) => {
    const nombreDeMes = (num) => {
      switch (num) {
        case 0:
          return "enero";
        case 1:
          return "febrero";
        case 2:
          return "marzo";
        case 3:
          return "abril";
        case 4:
          return "mayo";
        case 5:
          return "junio";
        case 6:
          return "julio";
        case 7:
          return "agosto";
        case 8:
          return "septiembre";
        case 9:
          return "octubre";
        case 10:
          return "noviembre";
        case 11:
          return "diciembre";
        default:
      }
    };
    return `${fecha.getDate()} de ${nombreDeMes(
      fecha.getMonth()
    )} de ${fecha.getFullYear()}`;
  };

  const horaParser = (fecha) => {
    return `${
      fecha.getHours() > 9 ? fecha.getHours() : "0" + fecha.getHours()
    }:${
      fecha.getMinutes() > 9 ? fecha.getMinutes() : "0" + fecha.getMinutes()
    } hrs`;
  };

  useEffect(() => {
    clicksInside(["mini-panel-usuario", "div-div-imagen-de-perfil"], () =>
      setAbrirMiniPanel(false)
    );
  }, []);

  return (
    <div id="mini-panel-usuario">
      <div className="mini-panel-div-date-hour">
        <p className="mini-panel-date">{fechaParser(fechaYHora)}</p>
        <p className="mini-panel-hour">{horaParser(fechaYHora)}</p>
      </div>
      <div className="mini-panel-div-picture-name-location">
        <img
          className="navbar-profile-picture"
          src={"https://api.participacion.management-r.com/display-avatar/" + usuario.avatar}
          alt={"profile"}
        />
        <div>
          <p className="mini-panel-name">
            {usuario && usuario.user.first_name + " " + usuario.user.last_name}
          </p>
          <p className="mini-panel-location">{usuario + "Ayuntamiento"}</p>
        </div>
      </div>
      <div className="minipanel-div-correo-run-id">
        <div>
          <Mail />
          <p>{usuario && usuario.user.email}</p>
        </div>
        <div>
          <User />
          <p>{usuario && usuario.user.username}</p>
        </div>
        <div>
          <Info />
          <p>{usuario && usuario.user.id}</p>
        </div>
      </div>
      <div className="minipanel-div-logout-settings">
        <div
          className="botones-minipanel-usuario"
          onClick={() => dispatch(logOut())}
        >
          <LogOut />
          <p>Cerrar sesión</p>
        </div>
        <div className="minipanel-linea-gris-logout-settings"></div>
        <Link
          to="/ajustes"
          className="botones-minipanel-usuario"
          onClick={() => setAbrirMiniPanel(false)}
        >
          <Settings />
          <p>Configuración</p>
        </Link>
      </div>
    </div>
  );
};

export default MiniPanelUsuario;
