import React from 'react';

import { Badge } from 'react-bootstrap';

const BadgeComponent = (props) => {
	return (
		<Badge className={props.className}  
		variant="primary">
			{props.text}
		</Badge>
	);
};

export default BadgeComponent;