import { put, call, takeLatest} from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from '../services/authServices'

function* getBusqueda(query) {
  try {
    const busquedaResult = yield call(
      apiCall,
      `search/tags/?search=nombre:${query.payload}`,
      {},
      {
        "Authorization": `Bearer ${Services.getAccessToken()}`
      },
      "GET"
    )

    console.log(busquedaResult.data)
    yield put({type: types.SET_RESULT, payload:busquedaResult.data})
  } catch (error) {
    console.log(error)
  }

}

export default function* busquedaSaga() {
  yield takeLatest(types.GET_BUSQUEDA, getBusqueda);
}
