import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
} from "react-bootstrap";
import "./conversaciones.css";
import { Inbox, Archive } from "react-feather";
import TableWithActions from "../../components/ActionTable/index";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import BorrarConversacion from "./componentes/BorrarConversacion";
// import { FormControl } from '@material-ui/core'

// Import actions
import * as Actions from "../../redux/actions/messages";

import { messages } from "../../redux/selectors/messagesSelectors";

const columns = ["NOMBRE DEL CIUDADANO", "ASUNTO", "OPERADOR", "FECHA Y HORA"];

const data = [
  {
    id: "9348157-3",
    asunto: "Trámite de cédula",
    operador: "José Cárdenas",
    datetime: "2020-07-21 02:31:13",
  },
  {
    id: "9326578-2",
    asunto: "Colonia sin agua",
    operador: "Pedro Perez",
    datetime: "2020-07-21 02:31:13",
  },
  {
    id: "9856745-1",
    asunto: "Pago de impuestos",
    operador: "Pablo Acosta",
    datetime: "2020-07-21 02:31:13",
  },
  {
    id: "9556985-5",
    asunto: "Recolección de basura",
    operador: "Juan Valadez",
    datetime: "2020-07-21 02:31:13",
  },
];

const Conversaciones = () => {
  // Import dispatch from react-redux
  const dispatch = useDispatch();

  //Fetch initial conversations
  useEffect(() => {
    dispatch(Actions.getConversations());
  }, []);

  // Get conversations state
  const conversationsState = useSelector((state) => state.MessagesReducer);
  const { conversations } = conversationsState;

  const [modalDelete, setModalDelete] = useState(false);
  const [conversacionIdABorrar, setConversacionIdABorrar] = useState();

  const deleteConversation = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      setModalDelete(true);
      setConversacionIdABorrar(arrayDeIds[0]);
    }
  };

  const parserDeFecha = (string) => {
    if (string) {
      let fecha = new Date(string.slice(0, 19));
      const nombreDeMes = (num) => {
        switch (num) {
          case 0:
            return "enero";
          case 1:
            return "febrero";
          case 2:
            return "marzo";
          case 3:
            return "abril";
          case 4:
            return "mayo";
          case 5:
            return "junio";
          case 6:
            return "julio";
          case 7:
            return "agosto";
          case 8:
            return "septiembre";
          case 9:
            return "octubre";
          case 10:
            return "noviembre";
          case 11:
            return "diciembre";
          default:
        }
      };
      return `${fecha.getDate()} de ${nombreDeMes(
        fecha.getMonth()
      )} de ${fecha.getFullYear()} a las ${
        fecha.getHours() > 9 ? fecha.getHours() : "0" + fecha.getHours()
      }:${
        fecha.getMinutes() > 9 ? fecha.getMinutes() : "0" + fecha.getMinutes()
      }`;
    }
  };

  const dataParser = (data) => {
    return data
      .sort(
        (a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      )
      .map((conversacion) => {
        return {
          id: conversacion.id || "-",
          idCiudadano: (
            <Link to={"/conversacion/" + conversacion.id}>
              {conversacion.owners[1]
                ? conversacion.owners[1].first_name +
                    " " +
                    conversacion.owners[1].last_name || "-"
                : conversacion.owners[0].first_name +
                  " " +
                  conversacion.owners[0].last_name}
            </Link>
          ),
          asunto: conversacion.message.content || "-",
          operador: conversacion.owners
            ? conversacion.owners[0].first_name +
                " " +
                conversacion.owners[0].last_name || "-"
            : "-",
          datetime: parserDeFecha(conversacion.created_at) || "-",
        };
      })
      .filter((element) => element !== undefined);
  };

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <BreadcrumbItem>Conversaciones</BreadcrumbItem>
            <h3 className="titulo-table-actions">Tablero de Conversaciones</h3>
          </div>
          <Tabs>
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <Inbox /> {"Activas"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      conversations
                        ? dataParser(
                            conversations.filter(
                              (conversacion) => conversacion.active
                            )
                          )
                        : []
                    }
                    selectable={false}
                    userStatus={false}
                    firstColumnLink={true}
                    linkType={"linkWithId"}
                    link={"/conversacion"}
                    archive={false}
                    archiveFunction={deleteConversation}
                    searchBar={true}
                    messageWhenEmpty={"No hay conversaciones para mostrar"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <Archive /> {"Archivadas"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      conversations
                        ? dataParser(
                            conversations.filter(
                              (conversacion) => !conversacion.active
                            )
                          )
                        : []
                    }
                    selectable={false}
                    userStatus={false}
                    firstColumnLink={true}
                    linkType={"linkWithId"}
                    link={"/conversacion"}
                    archive={false}
                    archiveFunction={deleteConversation}
                    searchBar={true}
                    messageWhenEmpty={"No hay conversaciones para mostrar"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modalDelete} setModal={setModalDelete}>
        <BorrarConversacion
          setModal={setModalDelete}
          idDeConversacion={conversacionIdABorrar ? conversacionIdABorrar : ""}
        />
      </ModalEstandar>
    </div>
  );
};

export default Conversaciones;
