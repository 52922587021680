import * as types from "../../consts/actionTypes";

// let user = JSON.parse(localStorage.getItem("user"));
const initialState = {
    busquedaResult:[]
}

export default function (state = initialState, action) {

    switch (action.type) {
  
      case types.SET_RESULT:
        return {
          ...state,
          busquedaResult: action.payload
        };
  
      default:
        return { ...state };
    }
  }