import React, { useState } from "react";
import { Form, InputGroup, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ChevronDown } from "react-feather";

import { escalarPropuesta } from "../../../redux/actions/propuestasActions";

const EscalarPropuesta = ({ setModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [comentarioEscalado, setComentarioEscalado] = useState()

  const handleClickDropdown = (event) => {
    event.preventDefault();
    let persona = document.getElementById("dropdown-escalar-propuesta-texto");
    persona.innerText = event.target.innerText;
  };

  const handleEscalar = () => {
    dispatch(escalarPropuesta({id: id, comentario_escalado: comentarioEscalado}));
    setModal(false);
    // history.push("/propuestas");
  };

  return (
    <div id="contenedor-modal-escalar-propuesta">
      <section id="seccion-datos-escalar-propuesta">
        <p id="titulo-escalar-propuesta">
          Escalar Propuesta a Administración General
        </p>
        <Form>
          {/* <Form.Label className="labels-escalar-propuesta">Escalar a:</Form.Label>
          <InputGroup
            name="persona-escalar-propuesta"
            id="persona-escalar-propuesta"
          >
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-escalar-propuesta"
              >
                <div>
                  <p id="dropdown-escalar-propuesta-texto">
                    Selecciona a quién se escala
                  </p>
                  <ChevronDown />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleClickDropdown}>
                  Dir. Pérez - Salud
                </Dropdown.Item>
                <Dropdown.Item onClick={handleClickDropdown}>
                  Coord. López - Vialidad
                </Dropdown.Item>
                <Dropdown.Item onClick={handleClickDropdown}>
                  Coord. Palacios - Agua
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup> */}
          <Form.Group>
            <Form.Label className="labels-escalar-propuesta">
              Comentarios
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="4"
              id="comentarios-escalar-propuesta"
              onChange={(event) => setComentarioEscalado(event.target.value)}
            />
          </Form.Group>
        </Form>
      </section>
      <section id="seccion-botones-escalar-propuesta">
        <button
          onClick={() => setModal(false)}
          id="boton-cancelar-escalar-propuesta"
        >
          Cancelar
        </button>
        <button
          onClick={() => handleEscalar()}
          id="boton-modal-escalar-propuesta"
        >
          Escalar
        </button>
      </section>
    </div>
  );
};

export default EscalarPropuesta;
