import * as types from '../../consts/actionTypes'

export const getPropuestas = (payload) => {
    console.log("solicitando propuestas...")
    return {
        type: types.GET_PROPUESTAS,
        payload
    }
}

export const getPropuestaADetalle = (payload) => {
    console.log("solicitando propuesta a detalle...")
    return {
        type: types.GET_PROPUESTA_DETALLES,
        payload
    }
}

export const putEstatusPropuesta = (payload) => {
    console.log("Actualizando estado de propuesta...")
    return {
        type: types.PUT_PROPUESTA_ESTATUS,
        payload
    }
}

export const getComentarios = (payload) => {
    console.log("Solicitando comentarios...")
    return {
        type: types.GET_COMENTARIOS,
        payload
    }
}

export const postComentario = (payload) => {
    console.log("Enviando comentario...")
    return {
        type: types.POST_COMENTARIO,
        payload
    }
}

export const updateComentarios = (payload) => {
    console.log("Actualizando comentarios...")
    return {
        type: types.UPDATE_COMENTARIOS,
        payload
    }
}

export const deleteComentario = (payload) => {
    console.log("Eliminando comentario...")
    return {
        type: types.DELETE_COMENTARIO,
        payload
    }
}

export const escalarPropuesta = (payload) => {
    console.log("Escalando propuesta...")
    return {
        type: types.PUT_ESCALAR_PROPUESTA,
        payload
    }
}

export const deletePropuesta = (payload) => {
    console.log("Eliminando propuesta...")
    return {
        type: types.DELETE_PROPUESTA,
        payload
    }
}

export const postRespuestaPropuesta = (payload) => {
    console.log("Creando respuesta...")
    return {
        type: types.POST_RESPUESTA_PROPUESTA,
        payload
    }
}

export const putComentarioPropuesta = (payload) => {
    console.log("Actualizando comentario...")
    return {
        type: types.PUT_COMENTARIO_PROPUESTA,
        payload
    }
}

export const postVotoComentarioPropuesta = (payload) => {
    console.log("Añadiendo un voto...")
    return {
        type: types.POST_VOTO_COMENTARIO_PROPUESTA,
        payload
    }
}

export const getEscalamientos = (payload) => {
    console.log("Solicitando escalamientos...")
    return {
        type: types.GET_ESCALAMIENTOS,
        payload
    }
}


