import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  infoBox: { display: "flex", width: "content-fit", height: "50px" },
  nameBox: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  nameBoxReverse: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
  },
  imgBox: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    color: "var(--chat-person-color)",
  },
  nameReverse: {
    color: "var(--chat-person-color)",
    paddingRight: "20px",
  },
  time: {
    color: "var(--time-color)",
    marginLeft: "20px",
  },
  timeReverse: {
    color: "var(--time-color)",
    marginRight: "15px",
  },
  infoBoxRevers: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

const PersonaInfoBlock = ({ name, time, avatar, reverse }) => {
  const classes = useStyles();

  return (
    <div className={clsx(reverse ? classes.infoBoxRevers : classes.infoBox)}>
      <div className={classes.imgBox}>
        <img src={avatar} alt={name} />
      </div>
      <div className={clsx(reverse ? classes.nameBoxReverse : classes.nameBox)}>
        <div className={clsx(reverse ? classes.nameReverse : classes.name)}>
          {name}
        </div>
        <div className={clsx(reverse ? classes.timeReverse : classes.time)}>
          {time}
        </div>
      </div>
    </div>
  );
};

export default PersonaInfoBlock;
