import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  FormControl,
  InputGroup,
  Dropdown,
  Button,
  Alert,
} from "react-bootstrap";
import Swal from "sweetalert2";
import CalendarioInline from "../../../components/reusable/CalendarioInline/CalendarioInline";
import { ChevronDown, Upload, RotateCw } from "react-feather";
import { useParams } from "react-router-dom";
import "./NuevaEncuesta.css";
import comunas from "../../../consts/comunas";

// acciones
import {
  crearNuevaEncuesta,
  putEncuesta,
} from "../../../redux/actions/encuestasActions";
import { getAcciones } from "../../../redux/actions/accionesActions";

// selectores
import {
  encuestasResult,
  encuestaCreadaResult,
} from "../../../redux/selectors/encuestasSelectors";
import { accionesResult } from "../../../redux/selectors/accionesSelectors";

//Existe una inestabilidad con input file y seState de React por lo que
//se recurrio a utilizar una variable de apoyo
// let fileName;

const CrearNuevaEncuesta = ({ setModal }) => {
  const history = useHistory();
  const { idEncuesta } = useParams();
  const resEncuestas = useSelector((state) => encuestasResult(state));
  const encuestaCreada = useSelector((state) => encuestaCreadaResult(state));
  const acciones = useSelector((state) => accionesResult(state));
  const [alertStatus, setAlertStatus] = useState(false);

  let encuestaAEditar;
  if (idEncuesta) {
    resEncuestas.forEach((encuesta) => {
      if (Number(encuesta.id) === Number(idEncuesta)) {
        encuestaAEditar = encuesta;
      }
    });
  }

  const dispatch = useDispatch();
  const [localizacion, setLocalizacion] = useState(
    idEncuesta && encuestaAEditar ? encuestaAEditar.area : "General"
  );
  const accionById = (id) => {
    let nombreDeAccion;
    if (acciones) {
      acciones.forEach((acc) => {
        if (Number(acc.id) === Number(id)) {
          nombreDeAccion = acc.titulo;
        }
      });
    }
    return nombreDeAccion;
  };
  const [accion, setAccion] = useState("Sin acción");
  const [estatus, setEstatus] = useState(
    idEncuesta && encuestaAEditar ? encuestaAEditar.estado : "Borrador"
  );
  const [nombre, setNombre] = useState(
    idEncuesta && encuestaAEditar ? encuestaAEditar.nombre : null
  );
  const [fecha, setFecha] = useState(
    idEncuesta && encuestaAEditar ? encuestaAEditar.fecha_finalizacion : null
  );
  const [imagen, setImagen] = useState();
  const [nombreDeImagen, setNombreDeImagen] = useState();
  const [nuevaImagen, setNuevaImagen] = useState(false);

  console.log(
    accion,
    estatus,
    nombre,
    fecha,
    localizacion,
    imagen,
    nombreDeImagen,
    encuestaAEditar
  );

  const handleDropdown = (objeto, setEstado) => {
    setEstado(objeto);
  };

  const handleGuardar = async function () {
    if (
      nombre === null ||
      estatus === "Seleccione estado" ||
      fecha === null ||
      imagen === null
    ) {
      setAlertStatus(true);
      return false;
    }
    let body = new FormData();
    body.append("nombre", nombre);
    body.append("fechaDeTerminacion", fecha);
    if (localizacion !== null) {
      body.append("area", localizacion);
    }
    if (imagen) {
      body.append("imagen", imagen);
    }
    body.append(
      "estado",
      estatus === "Activa"
        ? 1
        : estatus === "Inactiva"
        ? 2
        : estatus === "Borrador"
        ? 3
        : 3
    );
    if (accion !== null ) {
      if (accion === "Sin acción") {
        body.append("comunicado", "");
      } else {
        body.append("comunicado", Number(accion));
      }
    }
    if (idEncuesta) {
      // PUT encuesta
      body.append("actualizacion", "encuesta");
      dispatch(putEncuesta({ id: idEncuesta, body: body }));
      setModal(false);
    } else {
      // POST encuesta
      dispatch(crearNuevaEncuesta(body));
    }
  };

  useEffect(() => {
    if (acciones) {
      console.log(acciones.filter((action) => action.encuesta === null));
      setAccion(
        encuestaAEditar && encuestaAEditar.comunicado
          ? encuestaAEditar.comunicado
          : null
      );
    }
  }, [acciones]);

  useEffect(() => {
    dispatch(getAcciones());
  }, []);

  useEffect(() => {
    if (encuestaCreada) {
      history.push(`/preguntas/${encuestaCreada}`);
      setModal(false);
    } else if (encuestaCreada === false) {
      setModal(false);
    }
  }, [encuestaCreada]);

  useEffect(() => {
    if (estatus !== "Seleccione estado" && nombre && fecha && imagen) {
      setAlertStatus(false);
    }
  }, [estatus, nombre, fecha, imagen]);

  return (
    <div id="contenedor-modal-nueva-encuesta">
      {acciones ? (
        <section id="seccion-datos-nueva-encuesta">
          <div id="titulo-estatus-nueva-encuesta">
            <p id="titulo-nueva-encuesta">
              {idEncuesta ? "Editar encuesta: " + nombre : "Crear Votación"}
            </p>
            {idEncuesta && (
              <Dropdown>
                <Dropdown.Toggle className="dropdown-crear-encuesta">
                  <p className="dropdown-crear-encuesta-texto">{estatus}</p>
                  <ChevronDown />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(event) =>
                      handleDropdown(event.target.innerText, setEstatus)
                    }
                  >
                    Activa
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(event) =>
                      handleDropdown(event.target.innerText, setEstatus)
                    }
                  >
                    Borrador
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <Form>
            <Row>
              <Col>
                <label
                  htmlFor="input-nombre-de-encuesta"
                  className="labels-crear-nueva-encuesta"
                >
                  Nombre de Votación
                </label>
                <InputGroup>
                  <FormControl
                    type="text"
                    maxLength="60"
                    name="input-nombre-de-encuesta"
                    id="input-nombre-de-encuesta"
                    placeholder="Escribe un mensaje..."
                    onChange={(event) => setNombre(event.target.value)}
                    defaultValue={idEncuesta ? nombre : ""}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Proporcione titulo
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  htmlFor="fecha-de-terminacion-de-encuesta"
                  className="labels-crear-nueva-encuesta"
                >
                  Término de Votación
                </label>
                <InputGroup>
                  <FormControl
                    name="fecha-de-terminacion-crear-encuesta"
                    id="fecha-de-terminacion-crear-encuesta"
                    type="date"
                    className="dropdown-crear-encuesta"
                    defaultValue={fecha}
                    onChange={(event) => setFecha(event.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col>
                <label
                  htmlFor="estatus-crear-encuesta"
                  className="labels-crear-nueva-encuesta"
                >
                  Localización
                </label>
                <select
                  className="dropdown-crear-encuesta"
                  onChange={(event) => setLocalizacion(event.target.value)}
                  value={localizacion && localizacion}
                >
                  <option value={"General"}>General</option>
                  {comunas.map((comuna, key) => (
                    <option key={key} value={comuna}>
                      {comuna}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row>
              <Col>
                <label
                  htmlFor="boton-subir-imagen-encuesta"
                  className="labels-crear-nueva-encuesta"
                  onClick={() => {
                    let fileElem = document.getElementById(
                      "subir-imagen-nueva-encuesta"
                    );
                    if (fileElem) {
                      fileElem.click();
                    }
                  }}
                >
                  Subir Imagen
                </label>
                <Button
                  id="boton-subir-imagen-encuesta"
                  onClick={() => {
                    let inputFile = document.createElement("input");
                    inputFile.id = "subir-imagen-nueva-encuesta";
                    inputFile.type = "file";
                    inputFile.addEventListener("change", (event) => {
                      let img = event.target.files[0];
                      let file = new FileReader();
                      file.readAsDataURL(event.target.files[0]);
                      file.onload = function (e) {
                        setNuevaImagen(this.result);
                        setImagen(img);
                      };
                    });
                    inputFile.click();
                  }}
                >
                  <p>{imagen ? imagen.name : "JPEG, JPG o PNG"}</p>

                  <Upload />
                </Button>
                {encuestaAEditar && encuestaAEditar.imagen && (
                  <a
                    className="labels-crear-nueva-encuesta"
                    href={"https://" + encuestaAEditar.imagen}
                    target="_blank"
                  >
                    Ver imagen actual
                  </a>
                )}
              </Col>
              <Col>
                <label
                  htmlFor="estatus-crear-encuesta"
                  className="labels-crear-nueva-encuesta"
                >
                  Acción
                </label>
                <select
                  className="dropdown-crear-encuesta"
                  onChange={(event) => setAccion(event.target.value)}
                  value={accion}
                >
                  <option key={0} value={null}>
                    Sin acción
                  </option>
                  {acciones &&
                    acciones
                      .filter((action) => action.encuesta === null)
                      .map((action, key) => (
                        <option key={key + 1} value={action.id}>
                          {action.titulo}
                        </option>
                      ))}
                </select>
              </Col>
            </Row>
          </Form>
          <br></br>
          <Alert
            variant="danger"
            show={alertStatus}
            onClose={() => setAlertStatus(false)}
            dismissible
          >
            {estatus === "Seleccione estado" && (
              <p>El estatus no está definido</p>
            )}
            {!nombre && <p>El titulo no está definido</p>}
            {!fecha && <p>la fecha no está definida</p>}
            {!imagen && <p>No incluyó imagen</p>}
          </Alert>
        </section>
      ) : (
        <div className="cargando-editar-votacion">
          <RotateCw className="icono-rotate-modal-votacion" />
          <p>Cargando información</p>
        </div>
      )}

      <section id="seccion-botones-nueva-encuesta">
        <button
          onClick={() => setModal(false)}
          id="boton-cancelar-crear-encuesta"
        >
          Cancelar
        </button>
        {acciones && (
          <button onClick={handleGuardar} id="boton-guardar-crear-encuesta">
            Guardar
          </button>
        )}
      </section>
    </div>
  );
};

export default CrearNuevaEncuesta;
