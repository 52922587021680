import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Container, Col, BreadcrumbItem } from "react-bootstrap";
import TableWithActions from "../../components/ActionTable/index";
import ModalEstandar from "../../components/modalEstandar/ModalEstandar";
import Swal from "sweetalert2";
import { resetResultadosReportes } from "../../redux/actions/reportesActions";

const columns = ["TIPO DE REPORTE", "DESCRIPCIÓN", "ACCIÓN"];

const Reportes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [tipo, setTipo] = useState(null);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);

  const data = [
    {
      name: "Mapa de calor de zonificacion de propuestas",
      descripcion: "Mapa que representa la cantidad de propuestas por zona",
      accion: (
        <button
          className="botones-generar-reportes"
          onClick={() => {
            setTipo("mapa-de-calor");
            setModal(true);
          }}
        >
          Generar
        </button>
      ),
    },
    {
      name: "Temas calientes",
      descripcion:
        "Etiquetas mas utilizadas en las propuestas creadas por los usuarios",
      accion: (
        <button
          className="botones-generar-reportes"
          onClick={() => {
            setTipo("temas-calientes");
            setModal(true);
          }}
        >
          Generar
        </button>
      ),
    },
    {
      name: "Propuestas publicadas",
      descripcion:
        "Cantidad de propuestas publicadas organizadas por datos demográficos",
      accion: (
        <button
          className="botones-generar-reportes"
          onClick={() => {
            setTipo("propuestas");
            setModal(true);
          }}
        >
          Generar
        </button>
      ),
    },
    {
      name: "Propuestas escalables",
      descripcion:
        "Número de propuestas que superan los apoyos necesarios para ser escaladas",
      accion: (
        <button
          className="botones-generar-reportes"
          onClick={() => {
            setTipo("propuestas-escalables");
            setModal(true);
          }}
        >
          Generar
        </button>
      ),
    },
    // {
    //   name: "Solicitudes atendidas",
    //   descripcion:
    //     "Ratio de solicitudes SIAC atendidas por los administradores/operadores",
    //   accion: (
    //     <button
    //       className="botones-generar-reportes"
    //       onClick={() => {
    //         setTipo("solicitudes-atendidas");
    //         setModal(true);
    //       }}
    //     >
    //       Generar
    //     </button>
    //   ),
    // },
    {
      name: "Tiempos de revisión de propuestas",
      descripcion:
        "Tiempo que pasa entre cuando una propuesta es creada y esta es aprobada",
      accion: (
        <button
          className="botones-generar-reportes"
          onClick={() => {
            setTipo("tiempos-revision");
            setModal(true);
          }}
        >
          Generar
        </button>
      ),
    },
    {
      name: "Ratio de comentarios intervenidos",
      descripcion:
        "Cantidad de comentarios que son intervenidos por algun administrador/operador",
      accion: (
        <button
          className="botones-generar-reportes"
          onClick={() => {
            setTipo("comentarios");
            setModal(true);
          }}
        >
          Generar
        </button>
      ),
    },
    {
      name: "Interacciones de la plataforma",
      descripcion:
        "Cantidad de conversaciones, intervenciones, comentarios y acciones publicadas",
      accion: (
        <button
          className="botones-generar-reportes"
          onClick={() => {
            setTipo("interacciones");
            setModal(true);
          }}
        >
          Generar
        </button>
      ),
    },
  ];

  const handleGenerarReporte = () => {
    if (fechaInicio && fechaFin) {
      history.push(`/reportes/${tipo}/${fechaInicio + "-" + fechaFin}`);
    } else {
      setModal(false);
      Swal.fire(
        "Datos Incompletos",
        "Seleccione fecha de inicio y fecha fin para pder generar el reporte",
        "error"
      );
    }
  };

  useEffect(() => {
    dispatch(resetResultadosReportes());
  }, []);
  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <BreadcrumbItem>Reportes</BreadcrumbItem>
            <h3 className="titulo-table-actions">Tablero de Reportes</h3>
          </div>

          <Row>
            <Col sm={12}>
              <TableWithActions columns={columns} data={data} />
            </Col>
          </Row>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modal} setModal={setModal}>
        <div className="div-modal-generar-reporte">
          <label>Fecha de incio</label>
          <input
            type="date"
            onChange={(event) => setFechaInicio(event.target.value)}
          />
          <label>Fecha de finalización</label>
          <input
            type="date"
            onChange={(event) => setFechaFin(event.target.value)}
          />
          <div className="div-botones-modal-generar-reporte">
            <button
              style={{ backgroundColor: "#D7EBFB", color: "#0F69B4" }}
              onClick={() => setModal(false)}
            >
              Cancelar
            </button>
            <button
              style={{ backgroundColor: "#1387DA", color: "#FEFFFF" }}
              onClick={handleGenerarReporte}
            >
              Generar
            </button>
          </div>
        </div>
      </ModalEstandar>
    </div>
  );
};

export default Reportes;
