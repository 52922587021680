import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as Services from '../services/authServices'
import * as types from "../../consts/actionTypes";

export function* getTendenciasProyectoSaga({payload}){

    try {
        const tendenciasProyecto = yield call(
            apiCall,
            "tendencias/proyectos/",
            {},
            {
                Authorization: "Bearer " + Services.getAccessToken()
            },
            "GET"
        )
        yield put({ type: types.GET_TENDENCIAS_PROYECTOS_SUCCESS, tendenciasProyecto})
    } catch (error) {
        console.log(error)
    }
}

export function* getTendenciasPropuestas({ payload }) {
    try {
        const tendencias = yield call(
            apiCall,
            "tendencias/propuestas/",
            {},
            {
            Authorization: "Bearer " + Services.getAccessToken(),
            },
            "GET"
        );
        yield put({ type: types.GET_TENDENCIAS_PROPUESTAS_SUCCESS, tendencias });
    } catch (error) {
        console.log(error);
    }
}

export default function* proyectoSagas(){
    yield takeLatest(types.GET_TENDENCIAS_PROYECTOS, getTendenciasProyectoSaga);
    yield takeLatest(types.GET_TENDENCIAS_PROPUESTAS, getTendenciasPropuestas);
}
