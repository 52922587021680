import * as types from "../../consts/actionTypes";


export const getPoliticasPrivacidad = (payload) => {
    console.log("Solicitando politicas de privacidad...");
    return {
      type: types.GET_LIST_POLITICAS_PRIVACIDAD,
      payload,
    };
  };
  
  export const putPoliticasPrivacidad = (payload) => {
    console.log("Actualizando politicas de privacidad...");
    return {
      type: types.PUT_POLITICAS_PRIVACIDAD,
      payload,
    };
  };
  