import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from './redux/reducers/rootReducers'
import { Provider } from "react-redux";
import {createStore} from 'redux'
import { Container, Row, Col } from "react-bootstrap";
import LogoCore from "./assets/icons/Footer/logo CORE.jpg";
import LogoGoRe from "./assets/icons/Footer/logo Gobierno Regional.jpg";
import LogoTarapaca from "./assets/icons/Footer/Tarapaca Blanco@300x-8.png";
// store all the data for the App

import configureStore from "./redux/store";
import Footer from "./components/footer/Footer"

/**
 * Se crea el store de la aplicacion
 */
// const store = createStore(rootReducer, 
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const store = configureStore();

if (localStorage.getItem('access')) {

    store.dispatch({
      type: 'LOGIN_SUCCESS', tokens: {
        data:{
            access: localStorage.access,
            refresh: localStorage.refresh
        }
      }
    });
  }
if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.warn = () => {}
  }

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />

            {/* <Footer/> */}
   
        </Provider>
        
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
