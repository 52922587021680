import React, { useState, useCallback } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import PersonaInfoBlock from "./PersonaInfoBlock";
import DefaultAvatar from "./AvatarTest.svg";
import Questionnaire from "./Questionnaires/Questionnaire";
import QuestionnaireMultiply from "./Questionnaires/QuestionnaireMultiply";
import PreQuestionnaire from "./Questionnaires/PreQuestionnaire";
import MultilineTextFields from "./Questionnaires/MultilineTextFields";
import MessagesRender from "./MessagesRender";

const useStyles = makeStyles((theme) => ({
  /* reverseWrapper: {
    display: "flex",
    flexDirection: "column-reverse",
    overflowY: " auto",
  },
  chatWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px",
    overflowY: " auto",
  }, */
  chatWrapper: {
    // height:'calc(100vh - 72px)',
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px",
  },
  reverseWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column-reverse",
    overflowY: " auto",
  },
  messageBox: {
    border: "1px solid #ded5ff",
    width: "fit-content",
    padding: "20px",
    color: "var(--chat-person-color)",
    boxShadow: "0px 5px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    maxWidth: "300px",
  },

  responseBox: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "20px",
  },

  questionBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },

  questionnaireWrapper: {
    marginTop: "20px",
  },
  questionnaireTextWrapper: {
    display: "flex",
    alignItems: "center",
  },
  chatIconBox: {
    width: "40px",
    height: "40px",
    backgroundColor: "var(--chat-main-color)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: "20px",
    color: "white",
  },
  questionnaireText: {
    color: "var(--chat-main-color)",
    "& p": {
      margin: "10px  0",
    },
  },
}));

const QuestionnaireWrapper = ({
  message,
  children,
  questionnaire,
  questionnaireMultiply,
  multilineField,
}) => {
  const classes = useStyles();

  return (
    <>
      <PreQuestionnaire
        questionnaire={questionnaire}
        questionnaireMultiply={questionnaireMultiply}
        multilineField={multilineField}
      />
      <div className={classes.messageBox}>{message}</div>
      <div className={classes.questionnaireWrapper}>{children}</div>
    </>
  );
};

const Chat = ({ messages, personInfo, user }) => {
  const {
    message,
    name,
    time,
    avatar,
    questionnaire,
    questionnaireMultiply,
    multilineField,
  } = personInfo;

  const classes = useStyles();
  const isMultiquestion =
    questionnaire && questionnaireMultiply && multilineField;
  const [answersCount, setAnswersCount] = useState(0);
  const handleAnswer = useCallback(() => {
    if (answersCount < 2) {
      setAnswersCount(answersCount + 1);
    }
  }, [answersCount]);

  return (
    <div className={classes.reverseWrapper}>
      <div className={classes.chatWrapper}>
        {/* <div>
          {!questionnaire && !questionnaireMultiply && !multilineField && (
            <div className={classes.messageBox}>{message}</div>
          )}

          {isMultiquestion && (
            <>
              {answersCount === 0 && (
                <QuestionnaireWrapper
                  message={message}
                  questionnaire={questionnaire}
                >
                  <Questionnaire
                    personInfo={personInfo}
                    onAnswer={handleAnswer}
                  />
                </QuestionnaireWrapper>
              )}
              {answersCount === 1 && (
                <QuestionnaireWrapper
                  message={message}
                  questionnaireMultiply={questionnaireMultiply}
                >
                  <QuestionnaireMultiply
                    personInfo={personInfo}
                    onAnswer={handleAnswer}
                  />
                </QuestionnaireWrapper>
              )}
              {answersCount >= 2 && (
                <QuestionnaireWrapper
                  message={message}
                  multilineField={multilineField}
                >
                  <MultilineTextFields
                    personInfo={personInfo}
                    onAnswer={handleAnswer}
                  />
                </QuestionnaireWrapper>
              )}
            </>
          )}

          {questionnaire && !isMultiquestion && (
            <QuestionnaireWrapper message={message} questionnaire={questionnaire}>
              <Questionnaire personInfo={personInfo} />
            </QuestionnaireWrapper>
          )}
          {questionnaireMultiply && !isMultiquestion && (
            <QuestionnaireWrapper
              message={message}
              questionnaireMultiply={questionnaireMultiply}
            >
              <QuestionnaireMultiply personInfo={personInfo} />
            </QuestionnaireWrapper>
          )}
          {multilineField && !isMultiquestion && (
            <QuestionnaireWrapper
              message={message}
              multilineField={multilineField}
            >
              <MultilineTextFields personInfo={personInfo} />
            </QuestionnaireWrapper>
          )}
          <PersonaInfoBlock name={name} time={time} avatar={avatar} />
        </div> */}

        {messages.map((item, index) => {
          const has_image =
            item.file && item.file.file && item.file.file.includes("images");
          const has_pdf =
            item.file && item.file.file && item.file.file.includes("pdf");
          const is_user =
            item.user.id === user.user.id ||
            (item.user.first_name === user.user.first_name &&
              item.user.last_name === user.user.last_name);
          return (
            <div
              key={index}
              className={is_user ? classes.questionBox : classes.responseBox}
            >
              <MessagesRender
                item={item}
                message={item.content}
                type={item.type}
                first_name={item.user.first_name}
                last_name={item.user.last_name}
                avatar={item.user.avatar}
                time={item.time}
                user={is_user}
                has_pdf={has_pdf}
                has_image={has_image}
              />
            </div>
          );
        })}
        {/* PARA EL DEMO */}
        {/* {messages.length > 0 && (
          <PersonaInfoBlock
            reverse
            name="Victor"
            time={moment().format("HH:mm")}
            avatar={DefaultAvatar}
          />
        )} */}
      </div>
    </div>
  );
};

export default Chat;
