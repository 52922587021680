import React from "react";
import Modal from "@material-ui/core/Modal";
import './ModalEstandar.css'

export default function ModalEstandar({ children, visibilidad, setModal, forzado }) {
  
    return (
    <div>
      <Modal
        open={visibilidad}
        onClose={() => {if(!forzado){setModal(false)}}}
        className="background-modal" 
      >
        {children}
      </Modal>
    </div>
  );
}
