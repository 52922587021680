import React from "react";
import { useSelector, useDispatch } from "react-redux";

//Actions
import { deleteEncuesta } from "../../../redux/actions/encuestasActions";

//Selector

//Las clases de este componente se alojan en App.css

const BorrarEncuesta = ({ setModal, nombreDeEncuesta, idDeEncuesta }) => {
  const dispatch = useDispatch();
  const handleEliminar = () => {
    dispatch(deleteEncuesta(idDeEncuesta));
    setModal(false);
  };

  return (
    <div className="cuerpo-modal-borrado">
      <p className="titulo-modal-borrado">¿Archivar Encuesta?</p>
      <p className="detalle-modal-borrado">
        Procede para archivar permanentemente las encuesta “{nombreDeEncuesta}”
      </p>
      <div className="div-botones-modal-borrado">
        <button
          className="boton-cancelar-modal-borrado"
          onClick={() => setModal(false)}
        >
          Cancelar
        </button>
        <button
          className="boton-eliminar-modal-borrado"
          onClick={handleEliminar}
        >
          Eliminar
        </button>
      </div>
    </div>
  );
};

export default BorrarEncuesta;
