import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Estadisticas = ({ encuesta }) => {
  const sortF = (a, b) => {
    if (a.porcentaje < b.porcentaje) {
      return 1;
    }
    if (a.porcentaje > b.porcentaje) {
      return -1;
    }
    return 0;
  };
  return (
    <Container style={{ padding: "0" }}>
      <p className="leyenda-estadisticas">Se muestran como destacados los resultados con mayor puntaje</p>
      {encuesta.preguntas.map((pregunta, index) => {
        let respuestasSorted =
          pregunta.respuestas.length > 1 &&
          pregunta.respuestas.sort((a, b) =>
            a.porcentaje < b.porcentaje
              ? 1
              : b.porcentaje < a.porcentaje
              ? -1
              : 0
          );
        return (
          <div className="div-pregunta-encuesta" key={index}>
            <Row>
              <Col sm={12} className="div-texto-preguta-encuesta">
                <p className="texto-pregunta-encuestas">{pregunta.pregunta}</p>
              </Col>
            </Row>
            <Row>
              {pregunta.respuestas.length > 1 &&
                pregunta.respuestas.sort(sortF).map((respuesta, index) => {
                  return (
                    <Col
                      sm={pregunta.respuestas.length === 2 ? 6 : 4}
                      key={index}
                    >
                      <Row>
                        <Col
                          className={
                            index === 0
                              ? "div-etiqueta-azul-oscuro-respuesta-encuestas"
                              : "div-etiqueta-respuesta-encuestas"
                          }
                        >
                          <p className="etiqueta-respuesta-encuestas">
                            {respuesta.etiqueta.toUpperCase()}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          className={
                            index === 0
                              ? "div-etiqueta-azul-claro-respuesta-encuestas"
                              : "div-etiqueta-respuesta-encuestas"
                          }
                        >
                          <p className="valor-respuestas-encuestas">
                            {respuesta.total} ({respuesta.porcentaje})
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
            </Row>
          </div>
        );
      })}
    </Container>
  );
};

export default Estadisticas;
