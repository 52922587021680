import * as types from "../../consts/actionTypes";

const initialState = {
  propuestas: [],
  comentarios: [],
  actualizarComentarios: false,
  actualizarPropuestas: false,
  escalamientos: [],
  propuestaADetalle: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_PROPUESTAS:
      return {
        ...state,
        propuestaADetalle: null,
      };
    case types.GET_PROPUESTAS_SUCCESS:
      console.log("Las propuestas se guardaron en el estado propuestas");
      return {
        ...state,
        propuestas: action.results.data,
        actualizarPropuestas: false,
      };
    case types.GET_PROPUESTA_DETALLES_SUCCESS:
      console.log(
        "Los detalles de la propuesta en curso se guardaron en el estado propuestaADetalle"
      );
      return {
        ...state,
        propuestaADetalle: action.results.data,
        actualizarComentarios: false,
        actualizarPropuestas: false,
      };
    case types.PUT_PROPUESTA_ESTATUS_SUCCESS:
      return {
        ...state,
        actualizarPropuestas: true,
      };
    case types.GET_COMENTARIOS_SUCCESS:
      console.log("Los comentarios se guardaron en el estado");
      return {
        ...state,
        comentarios: action.results.data,
        actualizarComentarios: false,
      };
    case types.POST_COMENTARIO_SUCCESS:
      return {
        ...state,
        actualizarComentarios: true,
      };
    case types.UPDATE_COMENTARIOS:
      return {
        ...state,
        actualizarComentarios: true,
      };
    case types.DELETE_COMENTARIO_SUCCESS:
      return {
        ...state,
        actualizarComentarios: true,
      };
    case types.PUT_ESCALAR_PROPUESTA_SUCCESS:
      return {
        ...state,
        actualizarPropuestas: true,
      };
    case types.DELETE_PROPUESTA_SUCCESS:
      return {
        ...state,
        actualizarPropuestas: true,
      };
    case types.POST_RESPUESTA_PROPUESTA_SUCCESS:
      return {
        ...state,
        actualizarComentarios: true,
      };
    case types.PUT_COMENTARIO_PROPUESTA_SUCCESS:
      return {
        ...state,
        actualizarComentarios: true,
      };
    case types.POST_VOTO_COMENTARIO_PROPUESTA_SUCCESS:
      return {
        ...state,
        actualizarComentarios: true,
      };
    case types.GET_ESCALAMIENTOS_SUCCESS:
      console.log("Escalamientos guardadados en estados escalamientos");
      return {
        ...state,
        escalamientos: action.results.data,
      };
    default:
      return { ...state };
  }
}
