import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Home } from "react-feather";
import "./NotFound.css";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();

  return (
    <Container className="container-404">
      <Row className="row-404">
        <Col className="col-center">
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M78.3838 31.6162L63.3838 16.6163C63.1494 16.3818 62.8315 16.2501 62.5 16.25H32.5C32.1685 16.2501 31.8506 16.3818 31.6162 16.6163L16.6163 31.6162C16.3818 31.8506 16.2501 32.1685 16.25 32.5V62.5C16.2501 62.8315 16.3818 63.1494 16.6163 63.3838L31.6162 78.3838C31.8506 78.6182 32.1685 78.7499 32.5 78.75H62.5C62.8315 78.7499 63.1494 78.6182 63.3838 78.3838L78.3838 63.3838C78.6182 63.1494 78.7499 62.8315 78.75 62.5V32.5C78.7499 32.1685 78.6182 31.8506 78.3838 31.6162ZM66.0625 60.7575C66.1787 60.8736 66.2709 61.0115 66.3338 61.1632C66.3967 61.3149 66.4291 61.4776 66.4291 61.6419C66.4291 61.8061 66.3967 61.9688 66.3338 62.1206C66.2709 62.2723 66.1787 62.4102 66.0625 62.5262L62.5262 66.0625C62.4102 66.1787 62.2723 66.2709 62.1206 66.3338C61.9688 66.3967 61.8061 66.4291 61.6419 66.4291C61.4776 66.4291 61.3149 66.3967 61.1632 66.3338C61.0115 66.2709 60.8736 66.1787 60.7575 66.0625L47.5 52.8025L34.2425 66.0625C34.0033 66.2881 33.6869 66.4138 33.3581 66.4138C33.0293 66.4138 32.7129 66.2881 32.4738 66.0625L28.9375 62.5262C28.8213 62.4102 28.7291 62.2723 28.6662 62.1206C28.6033 61.9688 28.5709 61.8061 28.5709 61.6419C28.5709 61.4776 28.6033 61.3149 28.6662 61.1632C28.7291 61.0115 28.8213 60.8736 28.9375 60.7575L42.1975 47.5L28.9375 34.2425C28.8213 34.1264 28.7291 33.9885 28.6662 33.8368C28.6033 33.6851 28.5709 33.5224 28.5709 33.3581C28.5709 33.1939 28.6033 33.0312 28.6662 32.8794C28.7291 32.7277 28.8213 32.5898 28.9375 32.4738L32.4738 28.9375C32.7129 28.7119 33.0293 28.5862 33.3581 28.5862C33.6869 28.5862 34.0033 28.7119 34.2425 28.9375L47.5 42.1975L60.7575 28.9375C60.9967 28.7119 61.3131 28.5862 61.6419 28.5862C61.9707 28.5862 62.2871 28.7119 62.5262 28.9375L66.0625 32.4738C66.1787 32.5898 66.2709 32.7277 66.3338 32.8794C66.3967 33.0312 66.4291 33.1939 66.4291 33.3581C66.4291 33.5224 66.3967 33.6851 66.3338 33.8368C66.2709 33.9885 66.1787 34.1264 66.0625 34.2425L52.8025 47.5L66.0625 60.7575Z"
              fill="#D7EBFB"
            />
            <path
              d="M60 1.25H5C4.00544 1.25 3.05161 1.64509 2.34835 2.34835C1.64509 3.05161 1.25 4.00544 1.25 5V57.5C1.25 58.4946 1.64509 59.4484 2.34835 60.1516C3.05161 60.8549 4.00544 61.25 5 61.25H60C60.9946 61.25 61.9484 60.8549 62.6516 60.1516C63.3549 59.4484 63.75 58.4946 63.75 57.5V5C63.75 4.00544 63.3549 3.05161 62.6516 2.34835C61.9484 1.64509 60.9946 1.25 60 1.25ZM61.25 57.5C61.25 57.8315 61.1183 58.1495 60.8839 58.3839C60.6495 58.6183 60.3315 58.75 60 58.75H5C4.66848 58.75 4.35054 58.6183 4.11612 58.3839C3.8817 58.1495 3.75 57.8315 3.75 57.5V13.75H61.25V57.5ZM61.25 11.25H3.75V5C3.75 4.66848 3.8817 4.35054 4.11612 4.11612C4.35054 3.8817 4.66848 3.75 5 3.75H60C60.3315 3.75 60.6495 3.8817 60.8839 4.11612C61.1183 4.35054 61.25 4.66848 61.25 5V11.25Z"
              fill="#0F69B4"
            />
            <path
              d="M55 6.25H47.5C47.1685 6.25 46.8505 6.3817 46.6161 6.61612C46.3817 6.85054 46.25 7.16848 46.25 7.5C46.25 7.83152 46.3817 8.14946 46.6161 8.38388C46.8505 8.6183 47.1685 8.75 47.5 8.75H55C55.3315 8.75 55.6495 8.6183 55.8839 8.38388C56.1183 8.14946 56.25 7.83152 56.25 7.5C56.25 7.16848 56.1183 6.85054 55.8839 6.61612C55.6495 6.3817 55.3315 6.25 55 6.25Z"
              fill="#0F69B4"
            />
            <path
              d="M53.75 41.25H11.25C10.9185 41.25 10.6005 41.3817 10.3661 41.6161C10.1317 41.8505 10 42.1685 10 42.5V52.5C10 52.8315 10.1317 53.1495 10.3661 53.3839C10.6005 53.6183 10.9185 53.75 11.25 53.75H53.75C54.0815 53.75 54.3995 53.6183 54.6339 53.3839C54.8683 53.1495 55 52.8315 55 52.5V42.5C55 42.1685 54.8683 41.8505 54.6339 41.6161C54.3995 41.3817 54.0815 41.25 53.75 41.25ZM52.5 51.25H12.5V43.75H52.5V51.25Z"
              fill="#0F69B4"
            />
            <path
              d="M10 36.25H55C55.3315 36.25 55.6495 36.1183 55.8839 35.8839C56.1183 35.6495 56.25 35.3315 56.25 35C56.25 34.6685 56.1183 34.3505 55.8839 34.1161C55.6495 33.8817 55.3315 33.75 55 33.75H10C9.66848 33.75 9.35054 33.8817 9.11612 34.1161C8.8817 34.3505 8.75 34.6685 8.75 35C8.75 35.3315 8.8817 35.6495 9.11612 35.8839C9.35054 36.1183 9.66848 36.25 10 36.25Z"
              fill="#0F69B4"
            />
            <path
              d="M10 28.75H55C55.3315 28.75 55.6495 28.6183 55.8839 28.3839C56.1183 28.1495 56.25 27.8315 56.25 27.5C56.25 27.1685 56.1183 26.8505 55.8839 26.6161C55.6495 26.3817 55.3315 26.25 55 26.25H10C9.66848 26.25 9.35054 26.3817 9.11612 26.6161C8.8817 26.8505 8.75 27.1685 8.75 27.5C8.75 27.8315 8.8817 28.1495 9.11612 28.3839C9.35054 28.6183 9.66848 28.75 10 28.75Z"
              fill="#0F69B4"
            />
            <path
              d="M10 21.25H55C55.3315 21.25 55.6495 21.1183 55.8839 20.8839C56.1183 20.6495 56.25 20.3315 56.25 20C56.25 19.6685 56.1183 19.3505 55.8839 19.1161C55.6495 18.8817 55.3315 18.75 55 18.75H10C9.66848 18.75 9.35054 18.8817 9.11612 19.1161C8.8817 19.3505 8.75 19.6685 8.75 20C8.75 20.3315 8.8817 20.6495 9.11612 20.8839C9.35054 21.1183 9.66848 21.25 10 21.25Z"
              fill="#0F69B4"
            />
            <path
              d="M36.25 48.75H28.75C28.4185 48.75 28.1005 48.6183 27.8661 48.3839C27.6317 48.1495 27.5 47.8315 27.5 47.5C27.5 47.1685 27.6317 46.8505 27.8661 46.6161C28.1005 46.3817 28.4185 46.25 28.75 46.25H36.25C36.5815 46.25 36.8995 46.3817 37.1339 46.6161C37.3683 46.8505 37.5 47.1685 37.5 47.5C37.5 47.8315 37.3683 48.1495 37.1339 48.3839C36.8995 48.6183 36.5815 48.75 36.25 48.75Z"
              fill="#0F69B4"
            />
          </svg>
          <p>Página no encontrada</p>
          <Button
            size="small"
            className="boton-404"
            onClick={() => history.push("/")}
          >
            <p>Ir al Inicio</p>
            <Home />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
