import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as Services from "../services/authServices";
import * as types from "../../consts/actionTypes";

export function* enviarCorreoRecuperacionSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      "sso-client/password-link-reset/",
      payload,
      {
        // Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    yield put({ type: types.ENVIAR_CORREO_RECUPERACION_SUCCESS, result });
  } catch (error) {
    yield put({ type: types.ENVIAR_CORREO_RECUPERACION_ERROR, error });
    console.log(error);
  }
}

export function* restablecerPasswordSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      "sso-client/password-reset/",
      payload,
      {
        // Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );

    yield put({ type: types.RESTABLECER_PASSWORD_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.RESTABLECER_PASSWORD_ERROR, error });
  }
}

export default function* proyectoSagas() {
  yield takeLatest(types.ENVIAR_CORREO_RECUPERACION, enviarCorreoRecuperacionSaga);
  yield takeLatest(types.RESTABLECER_PASSWORD, restablecerPasswordSaga);
}
