import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall, token } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from "../services/authServices";
import Swal from "sweetalert2";

export function* postPreguntaSaga({ payload }) {
  console.log(payload);
  try {
    const results = yield call(
      apiCall,
      "encuesta/preguntas/",
      {
        encuesta: payload.encuesta,
        pregunta: payload.pregunta,
        tipo: payload.tipo,
        respuestas: payload.respuestas,
        indice: payload.indice,
      },
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Pregunta creada satisfactoraimente");
    Swal.fire({
      title: "Pregunta creada!",
      text: "Pregunta creada satisfactoraimente",
      icon: "success",
      confirmButtonText: "Cerrar",
    });
    yield put({ type: types.POST_PREGUNTA_SUCCESS, results });
  } catch (e) {
    console.log("Error al crear a la pregunta :", e);
    yield put({ type: types.POST_PREGUNTA_ERROR, payload });
  }
}

export function* getPreguntaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `encuesta/preguntas/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Pregunta solicictada satisfactoriamente");
    yield put({ type: types.GET_PREGUNTA_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar pregunta", e.message);
    yield put({ type: types.GET_PREGUNTA_ERROR, payload });
  }
}

export function* deletePreguntaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `encuesta/preguntas/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    console.log("Pregunta eliminada satisfactoriamente");
    yield put({ type: types.DELETE_PREGUNTA_SUCCESS, results });
  } catch (e) {
    console.log("Error al eliminar pregunta", e.message);
    yield put({ type: types.DELETE_PREGUNTA_ERROR, payload });
  }
}

export function* putRespuestasSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `encuesta/preguntas/${payload.id}/`,
      {
        encuesta: payload.encuesta,
        pregunta: payload.pregunta,
        id: payload.id,
        tipo: payload.tipo,
        respuestas: payload.respuestas,
        indice: payload.indice,
        actualizacion: "respuestas",
      },
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Respuestas actualizadas satisfactoriamente");
    yield put({ type: types.PUT_RESPUESTAS_SUCCESS, results });
  } catch (e) {
    console.log("Error al actualizar respuestas", e.message);
    yield put({ type: types.PUT_RESPUESTAS_SUCCESS, payload });
  }
}

export default function* preguntasSaga() {
  yield takeLatest(types.POST_PREGUNTA, postPreguntaSaga);
  yield takeLatest(types.GET_PREGUNTA, getPreguntaSaga);
  yield takeLatest(types.DELETE_PREGUNTA, deletePreguntaSaga);
  yield takeLatest(types.PUT_RESPUESTAS, putRespuestasSaga);
}
