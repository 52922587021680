import * as types from "../../consts/actionTypes";

const initialState = {
  updatePreguntas: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_PREGUNTA_SUCCESS:
      console.log("Pregunta guardada en el estado pregunta");
      return {
        ...state,
        pregunta: action.results.data,
        updatePreguntas: false,
      };
    case types.POST_PREGUNTA_SUCCESS:
      return {
        ...state,
        updatePreguntas: true,
      };
    case types.DELETE_PREGUNTA_SUCCESS:
      return {
        ...state,
        updatePreguntas: true,
      };
    case types.PUT_RESPUESTAS_SUCCESS:
      return {
        ...state,
      };
    case types.ACTUALIZA_ENCUESTAS:
      return {
        ...state,
        updatePreguntas: true,
      };
    case types.NO_ACTUALIZA_ENCUESTAS:
      return {
        ...state,
        updatePreguntas: false,
      };
    default:
      return { ...state };
  }
}
