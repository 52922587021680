import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core/";
import { useDispatch } from "react-redux";
import { setMessages } from "../../../../redux/actions/messages";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
      width: "333px",
    },
  },
  textMultiline: {
    marginBottom: "20px",
  },
  button: {
    textTransform: "none",
    width: "133px",
  },
  textArea: {
    color: "red",
    "& .MuiOutlinedInput-inputMultiline::placeholder": {
      textAlign: "center",
    },
  },
}));

const MultilineTextFields = ({ personInfo, onAnswer }) => {
  const { id } = personInfo;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState("Controlled");

  const handleSendAnswer = useCallback(() => {
    dispatch(setMessages({ message: value, id: String(id) }));
    if (onAnswer) {
      onAnswer();
    }
  }, [dispatch, id, onAnswer, value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div className={classes.textMultiline}>
        <TextField
          className={classes.textArea}
          id="outlined-multiline-static"
          multiline
          rows={4}
          placeholder="Escriba su propuesta aquí"
          variant="outlined"
          onChange={handleChange}
        />
      </div>
      <Button
        onClick={handleSendAnswer}
        className={classes.button}
        variant="contained"
        color="primary"
      >
        Continuar
      </Button>
    </form>
  );
};

export default MultilineTextFields;
