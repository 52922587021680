import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { shorten } from "../../utils/ComponentUtils"

//Actions
import { deleteProyecto } from "../../redux/actions/proyectosActions";

//Selector

//Las clases de este componente se alojan en App.css

const BorrarProyecto = ({ setModal, nombreDeProyecto, idDeProyecto }) => {
  const dispatch = useDispatch();
  const handleEliminar = () => {
    dispatch(deleteProyecto(idDeProyecto));
    setModal(false);
  };

  return (
    <div className="cuerpo-modal-borrado">
      <p className="titulo-modal-borrado">¿Eliminar proyecto?</p>
      <p className="detalle-modal-borrado">
        Procede para eliminar permanentemente las proyecto “{shorten(nombreDeProyecto, 180)}”
      </p>
      <div className="div-botones-modal-borrado">
        <button
          className="boton-cancelar-modal-borrado"
          onClick={() => setModal(false)}
        >
          Cancelar
        </button>
        <button
          className="boton-eliminar-modal-borrado"
          onClick={handleEliminar}
        >
          Eliminar
        </button>
      </div>
    </div>
  );
};

export default BorrarProyecto;
