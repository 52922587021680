import React, { useState } from "react";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Search, Plus, X } from "react-feather";
import TableWithActions from "../../components/ActionTable/index";
import "./Agrupacion.css";

const columns = ["NOMBRE", "TIPO", "FECHA DE CREACIÓN", "ACCIÓN"];

const data = [
  {
    id: 1,
    nombre: "Proyecto 01",
    tipo: "Proyecto",
    fecha: "Nombre Apellido",
  },
  {
    id: 2,
    nombre: "Proyecto 02",
    tipo: "Proyecto",
    fecha: "Nombre Apellido",
  },
  {
    id: 3,
    nombre: "Proyecto 03",
    tipo: "Proyecto",
    fecha: "Nombre Apellido",
  },
  {
    id: 4,
    nombre: "Proyecto 04",
    tipo: "Proyecto",
    fecha: "Nombre Apellido",
  },
  {
    id: 5,
    nombre: "Proyecto 05",
    tipo: "Proyecto",
    fecha: "Nombre Apellido",
  },
];

const Agrupacion = () => {
  const [etiquetas, setEtiquetas] = useState([]);
  const dataParser = (data) => {
    return data
      .map((elemento) => {
        return {
          id: elemento.id,
          nombre: elemento.nombre,
          tipo: elemento.tipo,
          fecha: elemento.fecha,
          accion: <Link>Acceso Directo</Link>,
        };
      })
      .filter((element) => element !== undefined);
  };
  const addEtiqueta = () => {
    let arr = [...etiquetas];
    arr.push(document.getElementById("input-searchbar-agrupacion").value);
    setEtiquetas(arr);
  };
  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <BreadcrumbItem>Agrupación</BreadcrumbItem>
            <h3 className="titulo-table-actions">Agrupación</h3>
          </div>

          <div>
            <InputGroup id="searchbar-agrupacion">
              <InputGroup.Prepend>
                <Search id="search-icon-agrupacion" />
              </InputGroup.Prepend>
              <FormControl
                id="input-searchbar-agrupacion"
                placeholder="Buscar"
              />
              <InputGroup.Append>
                <Plus id="plus-icon-agrupacion" onClick={addEtiqueta} />
              </InputGroup.Append>
            </InputGroup>
            {etiquetas.length > 0 && (
              <div id="div-busquedas-agrupacion">
                <h3 className="titulo-table-actions">Busquedas</h3>
                {etiquetas.map((etiqueta) => (
                  <div className="etiquetas-busqueda-agrupacion">
                    <p>
                      {etiqueta}
                      <X />
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <Row>
            <Col sm={12}>
              <TableWithActions
                columns={columns}
                data={dataParser(data)}
                paginate={true}
                paginateRows={10}
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default Agrupacion;
