import React, { useCallback, useState } from "react";
import { makeStyles, ClickAwayListener } from "@material-ui/core";
import "./variables.css";
import { useHistory } from "react-router-dom";
import { KeyboardArrowDown, Archive } from "@material-ui/icons";
import clsx from "clsx";

const styles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "var(--list-section-color)",
    height: "58px",
    position: "relative",
    paddingLeft: "3px",
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "var(--searchForm-section-color)",
      borderLeft: "3px solid #5750ec",
      marginLeft: "-3px",
      "& $proposition": {
        display: "block",
        position: "absolute",
        top: "16px",
        right: 0,
        height: "24px",
        width: "24px",
      },
    },

    "& $propositionIcon": {
      position: "absolute",
      top: "40px",
      right: "5px",
      zIndex: 2,
    },
  },
  wrapperActive: {
    backgroundColor: "var(--searchForm-section-color)",
    borderLeft: "3px solid  #1387DA",
    marginLeft: "-3px",
  },
  infoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  time: {
    color: "var(--time-color)",
    paddingRight: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-evenly",
  },
  proposition: {
    position: "absolute",
    height: "100px",
    width: "100px",
    display: "none",
    color: "var(--time-color)",
  },
  propositionIcon: {
    backgroundColor: "white",
  },
  customUl: {
    marginLeft: "10px",
    paddingLeft: 0,
    margin: 0,
  },
  customLi: {
    listStyleType: "none",
    fontSize: "12px",
    cursor: "pointer",
    padding: "14px 8px 8px 8px",
    "&:hover": {
      color: "var(--time-color)",
    },
  },
  personData: {
    color: "var(--chat-person-color)",
    paddingLeft: "10px",
  },
  online: {
    backgroundColor: "#3ad378",
    height: "8px",
    width: "8px",
    borderRadius: "50%",
  },
  names: {
    display: "flex",
    alignSelf: "center",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    marginBottom: "7px",
  },
  positions: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "15px",
  },
  archiveIcon: {
    width: "0.7em",
    height: "auto",
    paddingLeft: "10px",
  },
}));

const Person = ({ personInfo, idPerson, isArchive }) => {
  const classes = styles();
  const history = useHistory();

  const [isProposition, setIsProposition] = useState(null);
  const handleProposition = useCallback((id) => {
    setIsProposition(id);
  }, []);

  return (
    <div id="propositionParent" className={classes.wrapperPerson}>
      {personInfo.map(
        ({ name, avatar, time, id, position, online, archive }) => {
          return (
            <React.Fragment key={id}>
              {isArchive === archive && (
                <div
                  className={clsx(
                    id.toString() === idPerson.toString()
                      ? classes.wrapperActive
                      : "",
                    classes.wrapper
                  )}
                  onClick={() => {
                    history.push("/conversaciones/" + id);
                  }}
                >
                  <div className={classes.infoWrapper}>
                    <img src={avatar} alt={name} />
                    <div className={classes.personData}>
                      <div className={classes.names} key={name}>
                        {name}
                        {isArchive && (
                          <Archive className={classes.archiveIcon} />
                        )}
                      </div>
                      <div className={classes.positions}>{position}</div>
                    </div>
                  </div>
                  <div className={classes.time}>
                    {time}
                    {online && <div className={classes.online}></div>}
                  </div>
                  <div
                    role="button"
                    onClick={() => handleProposition(id)}
                    className={classes.proposition}
                  >
                    <KeyboardArrowDown />
                  </div>
                  {isProposition === id && (
                    <ClickAwayListener
                      onClickAway={() => setIsProposition(null)}
                    >
                      <div id="proposition" className={classes.propositionIcon}>
                        <ul className={classes.customUl}>
                          <li
                            onClick={() => handleProposition(null)}
                            className={classes.customLi}
                          >
                            Archivar conversación
                          </li>
                          <li
                            onClick={() => handleProposition(null)}
                            className={classes.customLi}
                          >
                            Enviar chat a mi correo
                          </li>
                        </ul>
                      </div>
                    </ClickAwayListener>
                  )}
                </div>
              )}
            </React.Fragment>
          );
        }
      )}
    </div>
  );
};
export default Person;
