import * as types from "../../consts/actionTypes";

// buscar y obtener la informacion

export const login = (payload) => {
    return {
        type: types.LOGIN,
        payload: payload
    };
};

export const loginSuccess = (payload) => {
    return {
        type: types.LOGIN_SUCCESS,
        payload
    };
};

export const loginError = (payload) => {
    return {
        type: types.LOGIN_ERROR,
        payload: payload
    };
};

export const verifyToken = (payload) => {
    return {
        type: types.VERIFY_TOKEN,
        payload
    };
};

export const validToken = (payload) => {
    return {
        type: types.VALID_TOKEN,
        payload
    };
};

export const refreshToken = (payload) => {
    return {
        type: types.REFRESH_TOKEN,
        payload
    };
};

export const newAccessToken = (payload) => {
    return {
        type: types.REFRESH_TOKEN_SUCCESS,
        payload
    };
};

export const logOut = () => {
    return { type: types.LOGOUT, payload: {} };
};

export const getPoliticasPrivacidad = () => {
    return {
      type: types.GET_POLITICAS_PRIVACIDAD,
    };
  };

  export const refreshUser = () => {
    console.log("Refrescando usuario")
    return { type: types.REFRESH_USER}
}