import * as types from "../../consts/actionTypes";

const initialState = {
  updateCatalogoUsuarios: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_CATALOGO_USUARIOS_SUCCESS:
      console.log(
        "Catalogo de usuarios guardado en el estado catalogoUsuarios"
      );
      return {
        ...state,
        catalogoUsuarios: action.results.data,
        updateCatalogoUsuarios: false,
      };
    case types.POST_VALIDACION_USUARIO_SUCCESS:
      return {
        ...state,
        updateCatalogoUsuarios: true,
      };
    default:
      return { ...state };
  }
}
