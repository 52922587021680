import * as types from "../../consts/actionTypes"

export const getTendenciasProyectos = (payload) =>{
    return{
        type: types.GET_TENDENCIAS_PROYECTOS,
        payload
    }
}

export const getTendenciasPropuestas = (payload) =>{
    return{
        type: types.GET_TENDENCIAS_PROPUESTAS,
        payload
    }
}