import * as types from '../../consts/actionTypes'

export const postNuevaPregunta = (payload) => {
    console.log("creando pregunta...")
    return {
        type: types.POST_PREGUNTA,
        payload
    }
}

export const getPregunta = (payload) => {
    console.log("solicitando pregunta...")
    return {
        type: types.GET_PREGUNTA,
        payload
    }
}

export const deletePregunta = (payload) => {
    console.log("Eliminando pregunta...")
    return {
        type: types.DELETE_PREGUNTA,
        payload
    }
}

export const actualizaEncuestas = (payload) => {
    console.log("actualizando encuestas...")
    return {
        type: types.ACTUALIZA_ENCUESTAS,
        payload
    }
}

export const noActualizaEncuestas = (payload) => {
    return {
        type: types.NO_ACTUALIZA_ENCUESTAS,
        payload
    }
}


export const putRespuestas = (payload) => {
    console.log("actualizando respuestas...")
    return {
        type: types.PUT_RESPUESTAS,
        payload
    }
}