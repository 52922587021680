const { promisify } = require("util");
const sleep = promisify(setTimeout);

//Data----------

const SIAC = { total: 11, atendidas: ["Cesar Cardenas", "David Mora", "Jose Carlos", "Valeria Diaz", "Rene Cossio"] };

const tiempos_revision = [
    { tiempo: 1, autor: "Cesar Cardenas" },
    { tiempo: 2, autor: "David Mora" },
    { tiempo: 15, autor: "Jose Carlos" },
    { tiempo: 1, autor: "Valeria Diaz" },
    { tiempo: 7, autor: "Rene Cossio" },
    { tiempo: 4, autor: "Rene Cossio" },
];

const comentarios_intervenidos = {
    intervenidos_y_censurados: [
        { comentario: "comentario ofensivo", actor: "Cesar Cardenas" },
        { comentario: "comentario ofensivo", actor: "Cesar Cardenas" },
        { comentario: "comentario ofensivo", actor: "Rene Cossio" },
        { comentario: "comentario ofensivo", actor: "Valeria Diaz" },
    ],
    total: 11,
};

const propuestas_escalables = {
    no_escalables: [{ comuna_destino: "Iquique", sector: "Seguridad", sexo: "masculino", edad: 30, titulo: "Nueva Propuesta3" }],
    escalables_sin_escalar: [
        { comuna_destino: "Iquique", sector: "Seguridad", sexo: "masculino", edad: 30, titulo: "Nueva Propuesta2" },
        { comuna_destino: "Iquique", sector: "Seguridad", sexo: "masculino", edad: 30, titulo: "Nueva Propuesta3" },
    ],
    escaladas: [{ comuna_destino: "Iquique", sector: "Seguridad", sexo: "femenino", edad: 30, actor: "UserSA1", titulo: "Nueva Propuesta1" },{ comuna_destino: "Iquique", sector: "Seguridad", sexo: "femenino", edad: 30, actor: "UserSA2", titulo: "Nueva Propuesta2" },{ comuna_destino: "Iquique", sector: "Seguridad", sexo: "femenino", edad: 30, actor: "UserSA3", titulo: "Nueva Propuesta3" },{ comuna_destino: "Iquique", sector: "Seguridad", sexo: "femenino", edad: 30, actor: "UserSA3", titulo: "Nueva Propuesta3" }],
};
//--------------

const data = {
    SIAC,
    tiempos_revision,
    comentarios_intervenidos,
    propuestas_escalables,
};
export const getData = (nameData) => {
    sleep(1000);
    console.log(data[nameData]);
    return { data: data[nameData] };
};
