import * as types from "../../consts/actionTypes";

const initialState = {
  respuestaSuccess: null,
  respuestaError: null,
  mapaTablero: null,
  temasTablero: null,
  propuestasTablero: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.RESET_RESULTADOS_REPORTES:
      return {
        ...state,
        respuestaSuccess: null,
        respuestaError: null,
      };
    case types.GET_REPORTES_TEMAS_CALIENTES_SUCCESS:
      return {
        ...state,
        respuestaSuccess: action.results.data,
      };
    case types.GET_REPORTES_TEMAS_CALIENTES_ERROR:
      return {
        ...state,
        respuestaSuccess: false,
        respuestaError: action.error.request,
      };

    case types.GET_REPORTES_PROPUESTAS_SUCCESS:
      return {
        ...state,
        respuestaSuccess: action.results.data,
      };
    case types.GET_REPORTES_PROPUESTAS_ERROR:
      return {
        ...state,
        respuestaSuccess: false,
        respuestaError: action.error.request,
      };
    case types.GET_REPORTES_SOLICITUDES_SUCCESS:
      return {
        ...state,
        respuestaSuccess: action.results.data,
      };
    case types.GET_REPORTES_SOLICITUDES_ERROR:
      return {
        ...state,
        respuestaSuccess: false,
        respuestaError: action.error.request,
      };
    case types.GET_REPORTES_COMENTARIOS_SUCCESS:
      return {
        ...state,
        respuestaSuccess: action.results.data,
      };
    case types.GET_REPORTES_COMENTARIOS_ERROR:
      return {
        ...state,
        respuestaSuccess: false,
        respuestaError: action.error.request,
      };
    case types.GET_REPORTES_PROPUESTAS_ESCALABLES_SUCCESS:
      return {
        ...state,
        respuestaSuccess: action.results.data,
      };
    case types.GET_REPORTES_PROPUESTAS_ESCALABLES_ERROR:
      return {
        ...state,
        respuestaSuccess: false,
        respuestaError: action.error.request,
      };
    case types.GET_REPORTES_TIEMPOS_REVISION_SUCCESS:
      return {
        ...state,
        respuestaSuccess: action.results.data,
      };
    case types.GET_REPORTES_TIEMPOS_REVISION_ERROR:
      return {
        ...state,
        respuestaSuccess: false,
        respuestaError: action.error.request,
      };
    case types.GET_REPORTES_MAPA_CALOR_SUCCESS:
      return {
        ...state,
        respuestaSuccess: action.results.data,
      };
    case types.GET_REPORTES_MAPA_CALOR_ERROR:
      return {
        ...state,
        respuestaSuccess: false,
        respuestaError: action.error.request,
      };
    case types.GET_REPORTES_INTERACCIONES_SUCCESS:
      return {
        ...state,
        respuestaSuccess: action.results.data,
      };
    case types.GET_REPORTES_INTERACCIONES_ERROR:
      return {
        ...state,
        respuestaSuccess: false,
        respuestaError: action.error.request,
      };
    case types.GET_REPORTES_TABLERO_MAPA_SUCCESS:
      return {
        ...state,
        mapaTablero: action.results.data,
      };
    case types.GET_REPORTES_TABLERO_TEMAS_SUCCESS:
      return {
        ...state,
        temasTablero: action.results.data,
      };
    case types.GET_REPORTES_TABLERO_PROPUESTAS_SUCCESS:
      return {
        ...state,
        propuestasTablero: action.results.data,
      };
    case types.GET_REPORTES_TABLERO_ERROR:
      return {
        ...state,
        respuestaSuccess: false,
        respuestaError: action.error.request,
      };
    default:
      return {
        ...state,
      };
  }
}
