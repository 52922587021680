import {
  faThLarge,
  faChartLine,
  faClone,
  faCommentAlt,
  faUserMd,
  faHandPaper,
  faInbox,
  faNewspaper,
  faBullhorn,
  faExchangeAlt,
  faCheckCircle,
  faUsers,
  faUniversity,
  faKey,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";

const routes = [
  {
    name: "",
    children: [
      {
        name: "Inicio",
        path: "/",
        icon: faChartLine,
      },
    ],
  },
  {
    name: "Reportes",
    children: [
      {
        name: "Tablero",
        path: "/tablero",
        icon: faThLarge,
      },
      {
        name: "Reportes",
        path: "/reportes",
        icon: faChartBar,
      },

      // {
      //     name: "Agrupación",
      //     path: "/agrupacion",
      //     icon:  faClone
      // },
    ],
  },
  {
    name: "Atención Ciudadana",
    children: [
      {
        name: "Propuestas Ciudadanas",
        path: "/propuestas",
        icon: faHandPaper,
      },
      {
        name: "Escalamientos",
        path: "/escalamientos",
        icon: faUniversity,
      },
      // {
      //   name: "Contactos con SIAC",
      //   path: "/solicitudes",
      //   icon: faInbox,
      // },
      {
        name: "Conversaciones",
        path: "/conversaciones",
        icon: faCommentAlt,
      },
      {
        name: "Proyectos",
        path: "/proyectos",
        icon: faNewspaper,
      },
      {
        name: "Acciones",
        path: "/acciones",
        icon: faBullhorn,
      },
      {
        name: "Votaciones",
        path: "/votaciones",
        icon: faUserMd,
      },

      // {
      //     name: "Redirecciones",
      //     path: "/redirecciones",
      //     icon:  faExchangeAlt
      // },
    ],
  },
  {
    name: "Usuarios",
    children: [
      {
        name: "Ciudadanos",
        path: "/ciudadanos",
        icon: faUsers,
      },
      {
        name: "Administradores",
        path: "/administradores",
        icon: faKey,
      },
    ],
  },
];

export default routes;
