export const clicksInside = (arrayDeIds, callback) => {
  if ((arrayDeIds, callback)) {
    document.addEventListener("click", (event) => {
      if (arrayDeIds.every((id) => document.getElementById(id))) {
        if (
          arrayDeIds.every(
            (id) =>
              event.target.id !== id &&
              !document.getElementById(id).contains(event.target)
          )
        ) {
          callback();
        }
      }
    });
  }
};
