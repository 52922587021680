import * as types from '../../consts/actionTypes'

export const crearNuevaEncuesta = (payload) => {
    console.log("creando encuesta...")
    return {
        type: types.POST_ENCUESTA,
        payload
    }
}

export const getEncuestas = (payload) => {
    console.log("solicitando encuestas...")
    return {
        type: types.GET_ENCUESTAS,
        payload
    }
}

export const getEncuestasVencidas = (payload) => {
    console.log("solicitando encuestas vencidas...")
    return {
        type: types.GET_ENCUESTAS_VENCIDAS,
        payload
    }
}

export const actualizaEncuestas = (payload) => {
    console.log("actualizando encuestas...")
    return {
        type: types.UPDATE_ENCUESTAS,
        payload
    }
}

export const getUsuariosQueRespondieron = (payload) => {
    console.log("solicitando lista de usuarios que respondieron...")
    return {
        type: types.GET_USUARIO_QUE_RESPONDIERON,
        payload
    }
}

export const getRespuestasDeUsuario = (payload) => {
    console.log("solicitando respuestas de usuario...")
    return {
        type: types.GET_RESPUESTA_DE_USUARIO,
        payload
    }
}

export const deleteEncuesta = (payload) => {
    console.log("Eliminando encuesta...")
    return {
        type: types.DELETE_ENCUESTA,
        payload
    }
}

export const getEstadisticasEncuestas = (payload) => {
    console.log("Solicitando estadisticas de encuestas...")
    return {
        type: types.GET_ESTADISTICAS_ENCUESTAS,
        payload
    }
}

export const putEncuesta = (payload) => {
    console.log("Actualizando encuesta...")
    return {
        type: types.PUT_ENCUESTA,
        payload
    }
}
