import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from "../services/authServices"

export function* postRegistroAdministradorSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/register-admin/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Administrador registrado satisfactoriamente");
    yield put({ type: types.POST_REGISTRO_ADMINISTRADOR_SUCCESS, results });
  } catch (e) {
    console.log("Error al registrar administrador:", e);
    yield put({ type: types.POST_REGISTRO_ADMINISTRADOR_ERROR, payload });
  }
}

export function* inhabilitarAdministradorSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `sso-client/assign-access-site/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    console.log("Administrador inhabilitado satisfactoriamente");
    yield put({ type: types.DELETE_VALIDACION_USUARIO_SUCCESS, results });
  } catch (e) {
    console.log("Error al inhabilitar administrador :", e);
    yield put({ type: types.DELETE_VALIDACION_USUARIO_ERROR, payload });
  }
}

export function* postAsignaRolUsuarioSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/assign-userrol/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Rol asignado a usuario satisfactoriamente");
    yield put({ type: types.POST_ASIGNA_ROL_USUARIO_SUCCESS, results });
  } catch (e) {
    console.log("Error al asignar rol a usuario:", e);
    yield put({ type: types.POST_ASIGNA_ROL_USUARIO_ERROR, payload });
  }
}

export function* putRolUsuarioSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `sso-client/assign-userrol/${payload.id}/`,
      payload.body,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Rol de ususario actualizado satisfactoriamente");
    yield put({ type: types.PUT_ROL_USUARIO_SUCCESS, results });
  } catch (e) {
    console.log("Error al actualizar rol de usuario:", e);
    yield put({ type: types.PUT_ROL_USUARIO_ERROR, payload });
  }
}

export function* postAceptarPoliticasSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/info/",
      {privacy_policy: "True"},
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Politicas de privacidad aceptadas satisfactoriamente");
    yield put({ type: types.POST_ACEPTA_POLITICAS_PRIVACIDAD_SUCCESS, results });
    yield put({type:types.REFRESH_USER});
  } catch (e) {
    console.log("Error al aceptar las politicas de privacidad:", e);
    yield put({ type: types.POST_ACEPTA_POLITICAS_PRIVACIDAD_ERROR, payload });
  }
}

export function* postAceptarVideoTutorialSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/info/",
      {video_tutorial: "True"},
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Video tutorial aceptado satisfactoriamente");
    yield put({ type: types.POST_ACEPTA_VIDEO_TUTORIAL_SUCCESS, results });
    yield put({type:types.REFRESH_USER});
  } catch (e) {
    console.log("Error al aceptar las video tutorial:", e);
    yield put({ type: types.POST_ACEPTA_VIDEO_TUTORIAL_ERROR, payload });
  }
}

export function* postCambiarPasswordSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/password-change/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Contraseña cambiada satisfactoriamente");
    yield put({ type: types.POST_CAMBIAR_PASSWORD_SUCCESS, results });
  } catch (e) {
    console.log("Error al cambiar contraseña: ", e);
    yield put({ type: types.POST_CAMBIAR_PASSWORD_ERROR, payload });
  }
}

export default function* accionesSaga() {
  yield takeLatest(
    types.POST_REGISTRO_ADMINISTRADOR,
    postRegistroAdministradorSaga
  );
  yield takeLatest(
    types.DELETE_VALIDACION_USUARIO,
    inhabilitarAdministradorSaga
  );
  yield takeLatest(
    types.POST_ASIGNA_ROL_USUARIO,
    postAsignaRolUsuarioSaga
  );
  yield takeLatest(
    types.PUT_ROL_USUARIO,
    putRolUsuarioSaga
  );
  yield takeLatest(
    types.POST_ACEPTA_POLITICAS_PRIVACIDAD,
    postAceptarPoliticasSaga
  );
  yield takeLatest(
    types.POST_ACEPTA_VIDEO_TUTORIAL,
    postAceptarVideoTutorialSaga
  );
  yield takeLatest(
    types.POST_CAMBIAR_PASSWORD,
    postCambiarPasswordSaga
  );
}
