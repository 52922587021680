import React from "react";
import Header from "../header/header";
import {
  Drawer,
  List,
  makeStyles,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Divider,
  Grid,
} from "@material-ui/core";
//import logo from "./logo2.png";
import routes from "../../routes";
import { useSelector, useDispatch } from "react-redux";
import { toogleSideBarShow } from "../../redux/actions/sideBarActions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import "./sidebar.css";
import logo from "../../assets/Sidebar/M&R SIGLAS SIN FONDO (1).jpg";
import eslogan from "../../assets/Sidebar/Tarapacá Nuestra.png";
//actions
import { postSolicitudSiac } from "../../redux/actions/solicitudSiacActions";

const drawerWidth = 240;

const styles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    display: "flex",
    background: "#FEFFFF",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)",
    top:0,
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      width: "calc(100% - 241px) !important",
    },
    [theme.breakpoints.up('xs')]: {
      width: "100%",
    },
    zIndex: 10
  },
  appBar: {
    width: "100%",
  },
  // appBar: {
  //   [theme.breakpoints.up("sm")]: {
  //     // width: `calc(100% - ${drawerWidth}px)`,
  //     width: "100%",
  //     // marginLeft: "20px",
  //   },
  // },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      // width: drawerWidth,
      flexShrink: 0,
      // width: "100%",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  logoContainer: {
    height: "64px",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  listToolbar: {
    marginTop: "1em",
  },
  toolbar: theme.mixins.toolbar,
  titleToolbar: {
    color: "#546E7A",
    fontWeight: "bolder",
    marginLeft: "1em",
  },
  icon: {
    minWidth: "2.5em",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const SideBar = (props) => {
  const { window } = props;
  const classes = styles();
  const theme = useTheme();
  const sidebarToogle = useSelector((state) => state.sideBar);
  const dispatch = useDispatch();

  const drawer = (
    <div className={classes.toolbar}>
      <List>
        <div className={classes.logoContainer}>
          {/* <img src={logo} alt="Tarapacá" />
          <div className="linea-gris-logo"></div>
          <img src={eslogan} alt="tarapaca Nuestra" /> */}
          <img src={logo} style={{height:"64px"}} alt="M&R" />
        </div>
        <Divider />
        {routes.map((rt, key) => {
          return (
            <div className={classes.listToolbar} key={key}>
              <ListItemText className={classes.titleToolbar}>
                {rt.name}
              </ListItemText>
              {rt.children.map((ch, idx) => {
                // if (ch.name === "Contacta con SIAC") {
                //   return (
                //     <ListItem
                //       button
                //       onClick={() => {
                //         Swal.fire({
                //           title: "Contacta con SIAC",
                //           icon: "info",
                //           text: "Será redirigido a la pagina de SIAC",
                //           onAfterClose: () => {
                //             dispatch(postSolicitudSiac());
                //             // contactaSiac();
                //           },
                //         });
                //       }}
                //       key={idx}
                //     >
                //       <ListItemIcon className={classes.icon}>
                //         <FontAwesomeIcon icon={ch.icon} color="#0F69B4" />
                //       </ListItemIcon>
                //       <ListItemText primary={ch.name} />
                //     </ListItem>
                //   );
                // }
                return (
                  <ListItem button component={Link} to={ch.path} key={idx}>
                    <ListItemIcon className={classes.icon}>
                      <FontAwesomeIcon icon={ch.icon} color="#0F69B4" />
                    </ListItemIcon>
                    <ListItemText primary={ch.name} />
                  </ListItem>
                );
              })}
            </div>
          );
        })}

        <Divider />
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <div className={classes.appBar}>
        <Header />
      </div>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={sidebarToogle}
            onClose={() => dispatch(toogleSideBarShow())}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default SideBar;

function contactaSiac() {
  window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
}
