import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Form,
  FormControl,
  InputGroup,
  Col,
  Row,
  Button,
  Alert,
} from "react-bootstrap";
import Swal from "sweetalert2";
import CalendarioInline from "../../components/reusable/CalendarioInline/CalendarioInline";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import PreviewProyecto from "./PreviewProyecto";
import { Upload } from "react-feather";
import "./Proyectos.css";
import { shorten } from "../../utils/ComponentUtils"


//Actions
import {
  postProyecto,
  putProyecto,
} from "../../redux/actions/proyectosActions";

//Selectors
import {
  proyectosResult,
  updateProyectosResult,
} from "../../redux/selectors/proyectosSelectors";

const CrearNuevoProyecto = ({ setModal }) => {
  const dispatch = useDispatch();
  const { idProyecto } = useParams();
  const proyectos = useSelector((state) => proyectosResult(state));
  let proyectoAEditar;
  if (idProyecto) {
    proyectos.forEach((proyecto) => {
      if (Number(proyecto.id) === Number(idProyecto)) {
        proyectoAEditar = proyecto;
      }
    });
  }

  const [validation, setValidation] = useState(false);
  const [anclarAInicio, setAnclarAInicio] = useState(
    idProyecto ? proyectoAEditar.anclar_inicio : false
  );
  const [nombre, setNombre] = useState(
    idProyecto ? proyectoAEditar.nombre : ""
  );
  const [descripcion, setDescripcion] = useState(
    idProyecto ? proyectoAEditar.descripcion : ""
  );
  const [imagen, setImagen] = useState(idProyecto ? proyectoAEditar.img : "");
  const [imagenNueva, setImagenNueva] = useState(false);
  const [etiquetas, setEtiquetas] = useState(
    idProyecto ? proyectoAEditar.etiquetas : []
  );
  const [fecha, setFecha] = useState(
    idProyecto ? proyectoAEditar.fecha_terminacion : ""
  );
  const [modalPreview, setModalPreview] = useState(false);

  const handleCrearOGuardar = () => {
    let body = new FormData();
    if (nombre && descripcion && fecha && etiquetas && imagen) {
      body.append("nombre", nombre);
      body.append("anclar_inicio", anclarAInicio);
      body.append("descripcion", descripcion);
      body.append("fecha_terminacion", fecha);
      body.append("etiquetas", JSON.stringify(etiquetas));
      if (imagenNueva) {
        body.append("img", imagen);
      }
      if (idProyecto) {
        dispatch(putProyecto({ id: idProyecto, body: body }));
      } else {
        dispatch(postProyecto(body));
      }
      setModal(false);
    } else {
      setValidation(true);
    }
  };

  const handleTags = (string, key) => {
    if (key === "Enter") {
      let arr = [...etiquetas];
      string.trimEnd();
      arr.push({ nombre: string });
      setEtiquetas(arr);
      document.getElementById("input-etiquetas-crear-proyecto").value = "";
    } else if (string === "" && key === "Backspace" && etiquetas.length > 0) {
      let arr = [...etiquetas];
      arr.pop();
      setEtiquetas(arr);
    }
  };

  return (
    <div id="contenedor-modal-nueva-encuesta">
      <section id="seccion-datos-nueva-encuesta">
        <div className="div-titulo-anclar-proyecto">
          <p id="titulo-nueva-encuesta">
            {idProyecto ? "Editar Proyecto: " + shorten(nombre, 100) : "Crear Proyecto"}
          </p>
          <div className="div-anclar-proyecto"> 
            <input
              type="checkbox"
              checked={anclarAInicio}
              onChange={(event) => setAnclarAInicio(event.target.checked)}
            />
            <label className="labels-crear-nueva-encuesta">
              Anclar a inicio
            </label>
          </div>
        </div>
        <Form>
          <Row>
            <Col>
              <label
                htmlFor="input-nombre-de-encuesta"
                className="labels-crear-nueva-encuesta"
              >
                Nombre de Proyecto
              </label>
              <InputGroup>
                <FormControl
                  type="text"
                  name="input-nombre-de-encuesta"
                  className="input-crear-proyecto"
                  placeholder="Titulo del Proyecto"
                  onChange={(event) => setNombre(event.target.value)}
                  defaultValue={nombre}
                />
              </InputGroup>
              {!nombre && validation ? (
                <Alert
                  variant="danger"
                  style={{ marginTop: "5px", marginBottom: "0px" }}
                >
                  Nombre proyecto requerido
                </Alert>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <label
                htmlFor="input-nombre-de-encuesta"
                className="labels-crear-nueva-encuesta"
              >
                Descripción de Proyecto
              </label>
              <InputGroup>
                <textarea
                  rows="4"
                  name="input-nombre-de-encuesta"
                  className="input-crear-proyecto"
                  placeholder="Descripción del Proyecto"
                  onChange={(event) => setDescripcion(event.target.value)}
                  defaultValue={descripcion}
                />
              </InputGroup>
              {!descripcion && validation ? (
                <Alert
                  variant="danger"
                  style={{ marginTop: "5px", marginBottom: "0px" }}
                >
                  Descripción requerida
                </Alert>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <label
                htmlFor="input-nombre-de-encuesta"
                className="labels-crear-nueva-encuesta"
              >
                Tags
              </label>
              <div
                id="div-etiquetas-crear-proyecto"
                className="input-crear-proyecto"
              >
                <div id="div-solo-etiquetas-crear-proyecto">
                  {etiquetas &&
                    etiquetas.map((etiqueta) => (
                      <p className="etiquetas-proyecto">{etiqueta.nombre}</p>
                    ))}
                </div>
                <InputGroup>
                  <FormControl
                    type="text"
                    name="input-nombre-de-encuesta"
                    id="input-etiquetas-crear-proyecto"
                    onKeyDown={(event) =>
                      handleTags(event.target.value, event.key)
                    }
                  />
                </InputGroup>
              </div>
              {etiquetas === [] && validation ? (
                <Alert
                  variant="danger"
                  style={{ marginTop: "5px", marginBottom: "0px" }}
                >
                  Tags requerido
                </Alert>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <label
                htmlFor="subir-imagen-proyecto"
                className="labels-crear-nueva-encuesta"
              >
                Subir Imagen
              </label>
              <input
                id="subir-imagen-proyecto"
                type="file"
                onChange={(event) => {
                  let file = new FileReader();
                  file.readAsDataURL(event.target.files[0]);
                  file.onload = function (e) {
                    setImagenNueva(this.result);
                  };
                  setImagen(event.target.files[0]);
                }}
              />
              <Button
                id="boton-subir-imagen-proyecto"
                onClick={() => {
                  if (document.getElementById("subir-imagen-proyecto")) {
                    document.getElementById("subir-imagen-proyecto").click();
                  }
                }}
              >
                <p>{imagenNueva && imagen ? imagen.name : "JPEG, JPG o PNG"}</p>
                <Upload />
              </Button>
              {!imagen && validation ? (
                <Alert
                  variant="danger"
                  style={{ marginTop: "5px", marginBottom: "0px" }}
                >
                  Imagen requerida
                </Alert>
              ) : null}
              {idProyecto && imagen && !imagenNueva && (
                <a href={imagen} target="_blank">
                  Ver imagen actual
                </a>
              )}
            </Col>
            <Col>
              <label
                htmlFor="fecha-de-terminacion-de-encuesta"
                className="labels-crear-nueva-encuesta"
              >
                Fecha de Terminación
              </label>
              <InputGroup
                name="fecha-de-terminacion-crear-encuesta"
                id="fecha-de-terminacion-crear-encuesta"
              >
                <CalendarioInline fecha={fecha} setFecha={setFecha} />
              </InputGroup>
              {!fecha && validation ? (
                <Alert
                  variant="danger"
                  style={{ marginTop: "5px", marginBottom: "0px" }}
                >
                  Fecha requerida
                </Alert>
              ) : null}
            </Col>
          </Row>
        </Form>
      </section>
      <section id="seccion-botones-nuevo-proyecto">
        <button onClick={() => setModalPreview(true)}>Previsualizar</button>
        <button
          onClick={() => setModal(false)}
          id="boton-cancelar-crear-proyecto"
        >
          Cancelar
        </button>
        <button onClick={handleCrearOGuardar} id="boton-guardar-crear-proyecto">
          {idProyecto ? "Guardar" : "Crear"}
        </button>
      </section>
      <ModalEstandar visibilidad={modalPreview} setModal={setModalPreview}>
        <PreviewProyecto
          img={imagenNueva ? imagenNueva : imagen}
          title={nombre ? nombre : ""}
          tags={etiquetas ? etiquetas : []}
          date={fecha ? fecha : ""}
          area={""}
          description={descripcion ? descripcion : ""}
          cardHeight={"324px"}
          setModal={setModalPreview}
        />
      </ModalEstandar>
    </div>
  );
};

export default CrearNuevoProyecto;
