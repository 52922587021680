import * as types from "../../consts/actionTypes";

export const setMessages = (payload) => {
  return {
    type: "SET_MESSAGES",
    payload,
  };
};

export const getConversation = (payload) => {
  return {
    type: types.GET_CONVERSATION,
    payload,
  };
};

export const getConversationSuccess = (payload) => {
  return {
    type: types.GET_CONVERSATION_SUCCESS,
    payload,
  };
};

export const getConversationError = (payload) => {
  return {
    type: types.GET_CONVERSATION_ERROR,
    payload,
  };
};

export const getConversations = (payload) => {
  return {
    type: types.GET_CONVERSATIONS,
    payload,
  };
};

export const getConversationsSuccess = (payload) => {
  return {
    type: types.GET_CONVERSATIONS_SUCCESS,
    payload,
  };
};

export const getConversationsError = (payload) => {
  return {
    type: types.GET_CONVERSATIONS_ERROR,
    payload,
  };
};

export const receiveMessage = (payload) => {
  return {
    type: types.RECEIVE_MESSAGE,
    payload,
  };
};

export const sendMessage = (payload) => {
  return {
    type: types.SEND_MESSAGE,
    payload,
  };
};

/* Upload a file */
export const uploadConversationFile = (payload) =>{
    return{
        type: types.UPLOAD_CONVERSATION_FILE,
        payload
    }
}

export const uploadConversationFileSuccess = (payload) =>{
    return{
        type: types.UPLOAD_CONVERSATION_FILE_SUCCESS,
        payload
    }
}

export const uploadConversationFileError = (payload) =>{
    return{
        type: types.UPLOAD_CONVERSATION_FILE_ERROR,
        payload
    }
}

/* Send attachment message */
export const sendAttachmentMessage = (payload) =>{
    return{
        type: types.SEND_ATTACHMENT_MESSAGE,
        payload
    }
}

export const sendAttachmentMessageSuccess = (payload) =>{
    return{
        type: types.SEND_ATTACHMENT_MESSAGE_SUCCESS,
        payload
    }
}

export const sendAttachmentMessageError = (payload) =>{
    return{
        type: types.SEND_ATTACHMENT_MESSAGE_ERROR,
        payload
    }
}

export const createConvesation = (payload) =>{
  return{
      type: types.POST_NEW_CONVERSATION,
      payload
  }
}

export const limpiaChat = (payload) =>{
  return{
      type: types.LIMPIA_CHAT,
      payload
  }
}

/* Delete conversation */
export const deleteConversacion = (payload) =>{
  console.log("Borrando conversación...")
  return{
      type: types.DELETE_CONVERSACION,
      payload
  }
}
