import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import List from "./List.js";
import SearchForm from "./SearchForm";
import Chat from "./Chat";
import InputForm from "./InputForm";
import { makeStyles } from "@material-ui/core";
import DraftsIcon from "@material-ui/icons/Drafts";
import "./variables.css";

//iconos
import userIcon from "../iconos/user.svg";
import tag from "../iconos/tag.svg";
import location from "../iconos/location.svg";
import clock from "../iconos/clock.svg";
import calendar from "../iconos/calendar.svg";
import redirect from "../iconos/redirect.svg";
import poll from "../iconos/poll.svg";
import ChevronD from "../iconos/Chevron-Down.svg";
import ChevronL from "../iconos/Chevron-Left.svg";
import MenuDosLineas from "../iconos/menu-dos-lineas.svg";

// import { messages } from "../../../redux/selectors/messagesSelectors";

import DefaultAvatar from "./AvatarTest.svg";
import { InputGroup, Form, Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

// Actions
import * as Actions from "../../../redux/actions/messages";

// Socket
import { w3cwebsocket as W3CWebSocket } from "websocket";

//Utils
import { clicksInside } from "../../../redux/services/uxServices";

const drawerWidth = "0";

const personInfo = [
  {
    id: 1,
    name: "Becas Tarapacá",
    avatar: DefaultAvatar,
    time: "12:30",
    message: "Buen día, ¿en qué podemos ayudarte?",
    position: "Lorem ipsum dolor",
    online: true,
    archive: false,
  },
  {
    id: 2,
    name: "Robert",
    avatar: DefaultAvatar,
    time: "12:45",
    message: "Buen día, ¿en qué podemos ayudarte?",
    position: "Resolución de problema",
    questionnaire: true,
    questionnaireMultiply: true,
    multilineField: true,
    archive: false,
  },
  {
    id: 3,
    name: "Richard",
    avatar: DefaultAvatar,
    time: "11:15",
    message: "Buen día, ¿en qué podemos ayudarte?",
    position: "Resolución de problema",
    online: true,
    questionnaireMultiply: true,
    archive: false,
  },
  {
    id: 4,
    name: "Leonardo",
    avatar: DefaultAvatar,
    time: "01:15",
    message: "Buen día, ¿en qué podemos ayudarte?",
    position: "Resolución de problema",
    archive: false,
    multilineField: true,
  },
  {
    id: 5,
    name: "Jacob",
    avatar: DefaultAvatar,
    time: "10:55",
    message:
      "Esa es la información relacionada con las becas Taparacá. Espero que te sea de utilidad.",
    position: "Resolución de problema",
    archive: true,
  },
  {
    id: 6,
    name: "Olivia",
    avatar: DefaultAvatar,
    time: "07:13",
    message:
      "Esa es la información relacionada con las becas Taparacá. Espero que te sea de utilidad.",
    position: "Resolución de problema",
    archive: true,
  },
];

const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  wrapper: {
    width: "100%",
    backgroundColor: "#F9FAFC",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  textWrapper: {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  inicio: {
    color: "var(--description-color)",
    fontSize: "14px",
  },
  title: {
    color: "var(--title-color)",
    fontSize: "18px",
    margin: 0,
    cursor: "pointer",
  },
  boxWrapper: {
    backgroundColor: "##F4F8FC",
    height: "calc(100vh - 132px - 91px)",
    width: "calc(100% -32px)",
    margin: "20px",
    display: "flex",
  },
  mainButton: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "10%",
    backgroundColor: "white",
  },
  letterIcon: {
    width: "50px",
    height: "50px",
    color: "#2475B9",
    marginBottom: "20px",
  },
  checkIcon: {},
  titleBoxText: {
    color: "var(--box-text-color)",
    fontSize: "36px",
    margin: 0,
  },
  descriptionBoxText: {
    fontSize: "16px",
    color: "var(--box-text-color)",
  },
  conversationBox: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

const Conversations = () => {
  const { id } = useParams();
  const history = useHistory();

  // Import dispatch from react-redux
  const dispatch = useDispatch();

  let conversation_channel = useRef(null);

  const classes = styles();
  const [estatus, setEstatus] = useState("Seleccione");

  const [isArchive, setIsArchive] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [conversation, setConversation] = useState();

  const handleArchive = () => {
    setIsArchive(!isArchive);
  };
  // PARA DEMO _______________________________
  const handleEstatusDropdownClick = (opcion) => {
    const elemento = (color, texto) => {
      return (
        <div className="item-dropdown-estatus-propuesta">
          <div className={`bolita-estatus bolita-estatus-${color}`}></div>
          {texto}
        </div>
      );
    };
    switch (opcion) {
      case "aprobado":
        setEstatus(elemento("verde", "Aprobado"));
        break;
      case "revision":
        setEstatus(elemento("amarillo", "En Revisión"));
        break;
      case "rechazado":
        setEstatus(elemento("rojo", "Rechazado"));
        break;
      case "terminado":
        setEstatus(elemento("negro", "Terminado"));
        break;
      case "no-publico":
        setEstatus(elemento("gris", "No Público"));
        break;
      default:
    }
  };

  const conversationById = (idNum) => {
    let conversacion;
    conversations.forEach((conv) => {
      if (conv.id === idNum) {
        conversacion = conv;
      }
    });
    return conversacion;
  };

  const horaParser = (stringFecha) => {
    if (stringFecha) {
      let fecha = new Date(stringFecha.slice(0, 19));
      return `${
        fecha.getHours() > 12 ? fecha.getHours() - 11 : fecha.getHours()
      }:${
        fecha.getMinutes() < 10 ? "0" + fecha.getMinutes() : fecha.getMinutes()
      }${fecha.getHours() > 12 ? "PM" : "AM"}`;
    }
  };

  const soloFechaParser = (string) => {
    if (string) {
      let fecha = new Date(string.slice(0, 19));
      return `${fecha.getDate()}/${
        fecha.getMonth() + 1
      }/${fecha.getFullYear()}`;
    }
  };

  // Handle Conversation fetch
  // Call getConversaciones action
  useEffect(() => {
    // Get initial conversations
    dispatch(Actions.getConversation({ conversation_id: id }));
  }, [id]);

  // Get conversations state
  const conversationsState = useSelector((state) => state.MessagesReducer);
  const { messages, conversations } = conversationsState;
  const [filterMessages, setFilterMessages] = useState(false);

  // Get Auth state
  const authState = useSelector((state) => state.AuthReducer);
  const { user, tokenAccess } = authState;

  // Initialize chat
  useEffect(() => {
    conversation_channel.current = new W3CWebSocket(
      `wss://api.participacion.management-r.com/ws/chat/${id}/?token=${tokenAccess}`
    );

    // Handle conversation error connection
    conversation_channel.current.onerror = () => {
      console.log("No se pudo establecer la conexión con el servidor");
    };

    // Handle socket open connection
    conversation_channel.current.onopen = () => {
      console.log("Conexión establecida exítosamente");
      // dispatch(Actions.openConversationChannel())
    };

    conversation_channel.current.onclose = () => {
      console.log("Closing connection");
      // dispatch(Actions.closeConversationChannel())
    };

    conversation_channel.current.onmessage = (event) => {
      const { data } = event;
      console.log(data);
      let message = JSON.parse(data);
      // message.content = JSON.parse(message.content)
      // message.content = JSON.parse(
      //   message.content
      //     .slice(0, message.content.length)
      //     .replace(/'/g, '"')
      //     .toLowerCase()
      // ).texto;
      dispatch(Actions.receiveMessage({ message }));
    };

    return () => {
      conversation_channel.current.close();
    };
  }, [id, tokenAccess]);

  const sendMessage = (message) => {
    conversation_channel.current.send(
      JSON.stringify({
        message,
      })
    );

    // Delete the file from redux
    dispatch(Actions.sendMessage());
  };
  useEffect(() => {
    if (openInfo) {
      document.getElementById(
        "sidebar-info-ciudadano-conversacion"
      ).style.right = "0";
    } else if (!openInfo) {
      document.getElementById(
        "sidebar-info-ciudadano-conversacion"
      ).style.right = "-70%";
    }
  }, [openInfo]);

  useEffect(() => {
    if (messages) {
      dispatch(Actions.getConversations());
    }
  }, [messages]);

  useEffect(() => {
    if (conversations) {
      setConversation(conversationById(id));
    }
  }, [conversations]);

  useEffect(() => {
    dispatch(Actions.limpiaChat());
  }, []);

  console.log(conversation);

  // const mensajes = useSelector((state) => messages(state));
  const indx = personInfo.findIndex((info) => info.id === parseInt(id, 10));
  return (
    <div id="div-conversacion">
      <div className={classes.wrapper}>
        <div className={classes.textWrapper}>
          <div>
            <Link to="/conversaciones" id="link-regresar-conversaciones">
              <img src={ChevronL} />
              <p className={classes.inicio}>Regresar a Conversaciones</p>
            </Link>
            <h6 className={classes.title}>
              Chat:{" "}
              {conversation &&
                conversation.owners &&
                conversation.owners[1].first_name +
                  " " +
                  conversation.owners[1].last_name}
            </h6>
          </div>
          <div>
            {/* <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-estatus-conversacion"
                className="botones-header-propuesta"
              >
                <div>
                  <p>{estatus}</p>
                </div>
                <img src={ChevronD} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleEstatusDropdownClick("aprobado")}
                >
                  <div className="item-dropdown-estatus-propuesta">
                    <div className="bolita-estatus bolita-estatus-verde"></div>
                    Activo
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleEstatusDropdownClick("terminado")}
                >
                  <div className="item-dropdown-estatus-propuesta">
                    <div className="bolita-estatus bolita-estatus-negro"></div>
                    Inactivo
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
          <button
            id="id-boton-info-chat"
            className="boton-info-chat"
            onClick={() => {
              setOpenInfo(true);
            }}
            onLoad={() =>
              clicksInside(
                ["id-boton-info-chat", "sidebar-info-ciudadano-conversacion"],
                () => setOpenInfo(false)
              )
            }
          >
            <img src={MenuDosLineas} alt="menu de chat" />
          </button>
        </div>

        <div className={classes.boxWrapper}>
          <>
            {/* {id && (
            <List
              personInfo={personInfo}
              id={id}
              handleArchive={handleArchive}
              isArchive={isArchive}
            />
          )} */}
            <div className={classes.conversationBox}>
              {/* {id && <SearchForm personInfo={personInfo[indx]} />} */}
              {messages.length > 0 && id ? (
                <Chat
                  messages={filterMessages ? filterMessages : messages}
                  user={user}
                  personInfo={personInfo[3]}
                  isArchive={isArchive}
                />
              ) : (
                <div className={classes.mainButton} type="button">
                  <DraftsIcon className={classes.letterIcon} />
                  <h3 className={classes.titleBoxText}>Aún no hay mensajes</h3>
                  <p className={classes.descriptionBoxText}>
                    Comienza una nueva conversación
                  </p>
                </div>
              )}

              {id && conversation && conversation.active && (
                <InputForm
                  personInfo={personInfo[indx]}
                  sendMessage={sendMessage}
                />
              )}
            </div>
          </>
        </div>
      </div>
      <div id="sidebar-info-ciudadano-conversacion">
        <div id="div-searchbar-coversacion">
          <Form.Control
            id="input-buscar-en-chat"
            placeholder="Buscar en Chat"
            onChange={(event) => {
              if (event.target.value === "") {
                setFilterMessages(false);
              }
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setFilterMessages(
                  messages.filter((message) =>
                    message.content
                      .replace(/<[^>]*>?/gm, "")
                      .toLowerCase()
                      .includes(event.target.value.toLowerCase())
                  )
                );
              }
            }}
          />
        </div>
        <div id="div-info-ciudadano-conversacion">
          <p>Información del Ciudadano</p>
          <ul id="ul-info-ciudadano-conversacion">
            <li>
              <img src={userIcon} />
              {conversation &&
                (conversation.owners[1]
                  ? conversation.owners[1].first_name +
                    " " +
                    conversation.owners[1].last_name
                  : "")}
            </li>
            {/* <li>
              <img src={tag} />
              Asuntos
            </li> */}
            <li>
              <img src={location} />
              {conversation && conversation.owners[1]
                ? conversation.owners[1].region + ", "
                : ""}
              Tarapacá, Chile
            </li>
            <li>
              <img src={clock} />
              {conversation && horaParser(conversation.created_at)}
            </li>
            <li>
              <img src={calendar} />
              {conversation && soloFechaParser(conversation.created_at)}
            </li>
            {/* <li>
              <img src={redirect} />
              <Link>Historial de Ciudadano</Link>
            </li> */}
          </ul>
          {/* <p>Encuestas</p>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-encuestas-ciudadano-conversacion">
              <img src={poll} /> Seleccionar <img src={ChevronD} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>
    </div>
  );
};
export default Conversations;
