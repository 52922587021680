import * as types from "../../consts/actionTypes";

const initialState = {
  loadingGeneral: false,
  notifications: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LOADING_GENERAL":
      return {
        ...state,
        loadingGeneral: action.payload,
      };
    case types.GET_NOTIFICATIONS_SUCCESS:
      console.log("Fetching notifications into state", action.results.data);
      return {
        ...state,
        notifications: action.results.data,
        updateNotifications: false,
      };
    case types.PUT_NOTIFICATIONS_SUCCESS:
      console.log("Fetching notification response into state");
      return {
        ...state,
        notification: action.results.data,
        updateNotifications: false,
      };
    case types.GET_TODAY_NOTIFICATIONS_SUCCESS:
      console.log("Fetching today's notifications into state");
      return {
        ...state,
        data: action.results.data,
        updateNotifications: false,
      };
    case types.SEND_NUMBER_OF_UNREAD_NOTIFICATIONS:
      console.log("Setting unread notifications into state");
      return {
        ...state,
        unread: action.payload,
        updateNotifications: false,
      };
    case types.UPDATE_NOTIFICATIONS:
      console.log("updating notifications");
      return {
        ...state,
        updateNotifications: true,
      };
    case types.RESET_NOTIFICATIONS:
      return {
        ...state,
        notifications: undefined,
      };
    default:
      return { ...state };
  }
}
