import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// assets
import DrawerButton from "./drawerButton";

//Components
import MiniPanelUsuario from "./MiniPanelUsuario";

//Selectors
import { user } from "../../redux/selectors/rootSelectors";


// icons
import {
  Search,

} from "react-feather";

import bellIcon from "../../assets/Iconos especiales/bell-icon.svg";
import cogIcon from "../../assets/Iconos especiales/cog-icon.svg";

// styles
import "./navbar.css";
// import {
//   ColorPalettem,
//   palette,
// } from "../reusable/color-palette/color-palette";

//actions
import { toogleSideBarShow } from "../../redux/actions/sideBarActions";
import {
  updateNotifications
} from "../../redux/actions/notificationsActions";

// selectores
import {
  unreadNotificationsResult
} from "../../redux/selectors/notificationsSelectors";

import * as Selector from "../../redux/selectors/rootSelectors";
import Notifications from "../Notifications/Notification";
import { OverlayTrigger, InputGroup, FormControl } from "react-bootstrap";
import { Button, Badge } from "@material-ui/core";
import Notification from "../Notifications/Notification";

// Websocket
import { w3cwebsocket as W3CWebSocket } from "websocket";


//   {
//     icon: faBan,
//     text: "Tu reporte al comentario “Nunca me ha gustado...” ha sido enviado con éxito.",
//   },
//   {
//     icon: faHandPaper,
//     text: "Tu propuesta Campaña de reciclaje en Alto Hospicio ha sido aceptada",
//   }

// ]

// import { notificacionesArray } from "../../views/Notifications/Notificaciones";

export default (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  // const user = useSelector((state) => Selector.user(state));
  // console.log(user)
  const usuario = useSelector((state) => user(state));
  let notificacionesNoLeidas = useSelector((state) => unreadNotificationsResult(state));
  const [popover, setPopover] = useState(false);
  const [modalPoliticas, setModalPoliticas] = useState(!usuario.privacy_policy);
  const [abrirMiniPanel, setAbrirMiniPanel] = useState(false);
  const [busqueda, setBusqueda] = useState('')
  // Get Auth state
  const authState = useSelector((state) => state.AuthReducer);
  const { usr, tokenAccess } = authState;

  const [anchorEl, setAnchorEl] = useState(null)
  const userInfo = useSelector((state) => Selector.user(state));
  const togglePopover = (e) => {
    setPopover(!popover);
    setAnchorEl(e.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  let [invisible, setInvisible] = useState(true);
  let [totalUnread, setTotalUnread] = useState(0);

  let notification_channel = useRef(null);

  useEffect(() => {
    if (notificacionesNoLeidas !== undefined) {
      setTotalUnread(
        totalUnread = parseInt(notificacionesNoLeidas.unread)
      )
    }
  }, [notificacionesNoLeidas])

  useEffect(() => {
      totalUnread > 0 ? setInvisible(false) : setInvisible(true)
  }, [totalUnread])

  useEffect(() => {
    notification_channel.current = new W3CWebSocket(
      `wss://api.participacion.management-r.com/ws/pwa/notificaciones/${usuario.user['username']}/?token=${tokenAccess}`
    );

    // Handle error connection
    notification_channel.current.onerror = () => {
      console.log("Notificaciones: No se pudo establecer la conexión con el servidor");
    };

    // Handle socket open connection
    notification_channel.current.onopen = () => {
      console.log("Notificaciones: Conexión establecida exítosamente");
    };

    notification_channel.current.onclose = () => {
      console.log("Notificaciones: Closing connection");
    };

    notification_channel.current.onmessage = (event) => {
      const { data } = event;
      let message = JSON.parse(data);
      console.log(message)
      setTotalUnread(
        totalUnread += 1
      )
      dispatch(
        updateNotifications({})
      )
    };
  }, [usuario, tokenAccess])

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      if(busqueda!=='') history.push(`/busqueda/${busqueda}`)
    }
  }

  return (
    <div>
      <nav className="navbar-w" style={{ background: "#fffffff" }}>
        {/* <div className="navbar-logo"> */}
        <div className="btn-menu">
          {/* <DrawerButton click={props.drawerClickHandler}></DrawerButton> */}
          <DrawerButton
            click={() => dispatch(toogleSideBarShow())}
          ></DrawerButton>
        </div>


        <div className="navbar-search">
          <div className="navbar-search-div d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <Search size={18} />
            </div>
            <input
              type="text"
              placeholder="Buscar en todo el sistema"
              id="input-searchbar-header"
              value={busqueda}
              onKeyPress={handleKeyPress}
              onChange={(e)=>setBusqueda(e.target.value)}
            />
            {/* <div className="col-10 navbar-search-select d-flex align-items-center"></div>
            <div className="navbar-search-options d-flex align-items-center">
              <MoreHorizontal size={18} />
            </div> */}
          </div>
        </div>

        <div className="navbar-opciones-perfil">
          <div className="navbar-botton-group">

            <div className="navbar-button">
              <Badge className="navbar-badge" color="secondary" variant="dot" invisible={invisible}>
                <Button className="boton-notificaciones" onClick={togglePopover}>
                    <Notification open={popover} anchorEl={anchorEl} handleClose={handleClose} />
                    {/* <Bell size={25} color="#0F69B4" /> */}
                      <img src={bellIcon} alt="notificaciones-icono" />
                </Button>
              </Badge>
            </div>

            {/* <OverlayTrigger rootClose={true} trigger="click" placement="right-end" container={document.getElementById('popContain')} overlay={<Notifications info={notificacionesArray} />}>
              <div className="navbar-button d-flex justify-content-center align-items-center icon" >
                <Bell size={25} />
                
              </div>
          </OverlayTrigger> */}

            <div className="navbar-button">
              <Link to="/ajustes">
                <div className="d-flex justify-content-center align-items-center icon">
                  {/* <Settings size={25} color="#0F69B4" /> */}
                  <img src={cogIcon} alt="ajustes-icono" />
                </div>
              </Link>
            </div>
          </div>
          <div className="navbar-info">
            <p className="navbar-info-name">
              {usuario &&
                usuario.user.first_name + " " + usuario.user.last_name}
            </p>
          </div>
          <div
            id="div-div-imagen-de-perfil"
            className="navbar-profile"
            onClick={() => setAbrirMiniPanel(!abrirMiniPanel)}
          >
            <img
              className="navbar-profile-picture"
              src={
                "https://api.participacion.management-r.com/display-avatar/" +
                usuario.avatar
              }
              alt={"profile"}
            />
          </div>
        </div>
        {abrirMiniPanel && (
          <MiniPanelUsuario
            id="area-min-panel-usuario"
            usuario={usuario}
            setAbrirMiniPanel={setAbrirMiniPanel}
          />
        )}
      </nav>

      <div id="popContain"></div>
    </div>
  );
};
