import * as types from "../../consts/actionTypes"

export const getSolicitudSiac = (payload) => {
    console.log("Solicitando lista de solicitudes...")
    return {
        type: types.GET_SOLICITUD_SIAC,
        payload
    }
}

export const atenderSolicitudSiac = (payload) => {
    console.log("Atendiendo solicitud...")
    return {
        type: types.ATENDER_SOLICITUD_SIAC,
        payload
    }
}