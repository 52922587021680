import * as types from "../../consts/actionTypes";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_PROYECTOS_SUCCESS:
      console.log("Proyectos guardados en el estado proyectos");
      return {
        ...state,
        proyectos: action.results.data,
        updateProyectos: false,
      };
    case types.POST_PROYECTO_SUCCESS:
      return {
        ...state,
        updateProyectos: true,
      };
    case types.GET_PROYECTO_DETALLE_SUCCESS:
      return {
        ...state,
        proyectoADetalle: action.results.data,
      };
    case types.DELETE_PROYECTO_SUCCESS:
      return {
        ...state,
        updateProyectos: true,
      };
    case types.PUT_PROYECTO_SUCCESS:
      return {
        ...state,
        updateProyectos: true,
      };
    default:
      return { ...state };
  }
}
