export const getAccessToken = () =>{
    return localStorage.getItem("access")
}

export const setAccessToken = (token) =>{
    return localStorage.setItem("access", token)
}

export const setRefreshToken = (token) =>{
    return localStorage.setItem("refresh", token)
}

export const getRefreshToken = () =>{
    return localStorage.getItem("refresh")
}

export const removeLocalStorageInfo = () =>{
    return localStorage.clear()
}

export const setUser = (userInfo) =>{
    return localStorage.setItem('user', JSON.stringify(userInfo))
}

export const getUser = () =>{
    return JSON.parse(localStorage.getItem('user'))
}
