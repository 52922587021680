import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import "./CalendarioInline.css";

export default function CalendarioInline({ fecha, setFecha, readOnly, disabled }) {
  const fechaHoy = new Date(Date.now());
  //Este string parsea un objeto de fecha al formato aaaa-mm-dd
  const stringFechaHoy = `${fechaHoy.getFullYear()}-${
    fechaHoy.getMonth() <= 9
      ? "0" + (fechaHoy.getMonth() + 1)
      : fechaHoy.getMonth() + 1
  }-${fechaHoy.getDate() <= 9 ? "0" + fechaHoy.getDate() : fechaHoy.getDate()}`;
  useEffect(() => {
    if(!fecha && !readOnly){
      setFecha(stringFechaHoy);
    }
  },[])
  return (
    <form id="form-calendario-inline" noValidate>
      <TextField
        id="fecha-calendario-inline"
        type="date"
        disabled={disabled}
        defaultValue={fecha ? fecha : stringFechaHoy}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) => {
          if (!readOnly) {
            setFecha(event.target.value);
          }
        }}
      />
    </form>
  );
}
