import React, { useState } from "react";
import { Table, FormCheck } from "react-bootstrap";
import { STATUSLIST } from "./enums";
import "./actionTable.css";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { Link } from "react-router-dom";
import iconWhenEmpty from "../../assets/Iconos especiales/seo-and-web.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

let statusBall = null;
let arrayImaginario = [];

const handleChange = (checked, id, arrayDeIds, setArrayDeIds) => {
  let array = [...arrayDeIds];
  if (checked == true && !array.includes(id)) {
    array.push(id);
  } else if (checked == false && array.includes(id)) {
    array.splice(arrayDeIds.indexOf(id), 1);
  }
  console.log(array)
  setArrayDeIds(array);
};

/**
 * const selectable = props.selectable ? props.selectable : false
    const userStatus = props.userStatus ? props.userStatus : false
    const actionColumn = props.actionColumn ? props.actionColumn : false
    const statusColumn = props.statusColumn ? props.statusColumn : false
    const firstColumnLink = props.firstColumnLink ? props.firstColumnLink : false
    const linkType = props.linkType ? props.linkType : ""
    const link = props.link ? props.link : "

    Object.values(userStatus ? rowData : rw).map((data, key) => {
    return selectable ?
        isSelectable(data, key, userStatus, statusColumn, firstColumnLink, linkType, link)
        :
        const { status, ...rowData } = rw
                            return (
                                <tr key={idx}>
                                    {
                                        userStatus || statusColumn ?
                                            assignStatusDiv(rw)
                                            :
                                            null
                                    }

                                    {
                                        Object.values(userStatus ? rowData : rw).map((data, key) => {
                                            return selectable ?
                                                isSelectable(data, key, userStatus, statusColumn, firstColumnLink, linkType, link)
                                                :
                                                userStatus ?
                                                    onlyStatus(data, key, firstColumnLink)
                                                    :
                                                    statusColumn ?
                                                        createStatusColumn(data, key)
                                                        :
                                                        firstColumnLink ? 
                                                            firstColumnWithATag(data,key,linkType, link)
                                                            :
                                                            <td key={key}>{data}</td>
                                        })

                                    }
                                    {
                                        actionColumn ?
                                            <td><a href="#">Abrir</a></td>
                                            : null
                                    }
                                </tr>
                            )
                        })
})
 */

const createTableRow = (
  rw = {},
  index,
  selectable,
  idVisualization,
  userStatus,
  statusColumn,
  actionColumn,
  firstColumnLink,
  linkType,
  link,
  arrayDeIds,
  setArrayDeIds,
  dragDrop
) => {
  let rowInformation = rw;
  let id = rw.id ? rw.id : "";
  // const status = "", estatus = ""
  let boolLinkType = null;

  if (userStatus) {
    const { status, estatus, estado, ...rowData } = rw;
    rowInformation = rowData;
  }

  if (!idVisualization) {
    const { id, ...rowData } = rowInformation;
    rowInformation = rowData;
  }

  if (firstColumnLink) boolLinkType = verifyLinkType(linkType);

  if (userStatus || statusColumn) assignStatusDiv(rw);

  const rowInformationLength = Object.values(rowInformation).length;

  if (dragDrop) {
    return (
      <Draggable draggableId={`${rw.id}`} index={index} type={"PREGUNTA"}>
        {(provided) => (
          <tr
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {Object.values(rowInformation).map((dataCell, key) => {
              let divCell = null;
              if (userStatus && key === 0 && !selectable)
                divCell = onlyStatus(dataCell, key, boolLinkType, link, id);
              if (selectable && key === 0)
                divCell = isSelectable(
                  dataCell,
                  key,
                  userStatus,
                  boolLinkType,
                  link,
                  id,
                  arrayDeIds,
                  setArrayDeIds
                );
              if (statusColumn && key === 1)
                divCell = createStatusColumn(dataCell, key);
              if (divCell === null) divCell = <td>{dataCell}</td>;
              // if(actionColumn && key === rowInformationLength-1) divCell =
              return divCell;
            })}
          </tr>
        )}
      </Draggable>
    );
  } else {
    return (
      <tr key={index}>
        {Object.values(rowInformation).map((dataCell, key) => {
          let divCell = null;
          if (userStatus && key === 0 && !selectable)
            divCell = onlyStatus(dataCell, key, boolLinkType, link, id);
          if (selectable && key === 0)
            divCell = isSelectable(
              dataCell,
              key,
              userStatus,
              boolLinkType,
              link,
              id,
              arrayDeIds,
              setArrayDeIds
            );
          if (statusColumn && key === 1)
            divCell = createStatusColumn(dataCell, key);
          if (divCell === null) divCell = <td key={key}>{dataCell}</td>;
          // if(actionColumn && key === rowInformationLength-1) divCell =
          return divCell;
        })}
      </tr>
    );
  }
};

const createActionColumn = (actions = []) => {};

const isSelectable = (
  data = String,
  idx = Number,
  statusUser = false,
  boolLinkType,
  link,
  id,
  arrayDeIds,
  setArrayDeIds
) => {
  const linkWithId = link + "/" + id;
  return (
    <td key={idx}>
      <FormCheck>
        <FormCheckInput
          type="checkbox"
          checked={arrayDeIds.includes(id)}
          onChange={(event) =>
            handleChange(event.target.checked, id, arrayDeIds, setArrayDeIds)
          }
        />
        <FormCheckLabel className="labelCheck">
          {statusUser ? statusBall : null}

          {boolLinkType !== null ? (
            boolLinkType ? (
              <Link to={linkWithId}>{data}</Link>
            ) : (
              <Link to={link}>{data}</Link>
            )
          ) : (
            data
          )}
        </FormCheckLabel>
      </FormCheck>
    </td>
  );
};

const onlyStatus = (data = String, idx = Number, linkType, link, id) => {
  const linkWithId = link + "/" + id;

  return (
    <td>
      {statusBall}

      {linkType !== null || linkType !== undefined ? (
        linkType ? (
          <Link to={linkWithId}>{data}</Link>
        ) : (
          <Link to={link}>{data}</Link>
        )
      ) : (
        data
      )}
    </td>
  );
};

const createStatusColumn = (data = String, idx = Number) => {
  return (
    <td>
      {statusBall}
      {data}
    </td>
  );
};

const verifyLinkType = (linkType = String) => {
  switch (linkType) {
    case "linkWithId":
      return true;
    case "link":
      return false;
    default:
      return false;
  }
};

const assignStatusDiv = (rw) => {
  switch (rw.status) {
    case STATUSLIST.Todos:
      statusBall = <div className="strongBlue"></div>;
      break;
    case STATUSLIST.NoLeido:
      statusBall = <div className="blue"></div>;
      break;
    case STATUSLIST.Activo:
    case STATUSLIST.Activa:
    case STATUSLIST.Aprobado:
    case STATUSLIST.Aceptado:
      statusBall = <div className="green"></div>;
      break;
    case STATUSLIST.Advertencia:
    case STATUSLIST.EnRevision:
      statusBall = <div className="yellow"></div>;
      break;
    case STATUSLIST.Urgente:
    case STATUSLIST.Desactivado:
    case STATUSLIST.Inactivo:
      statusBall = <div className="red"></div>;
      break;
    case STATUSLIST.Cerrado:
    case STATUSLIST.Rechazado:
      statusBall = <div className="darkgray"></div>;
      break;
    case STATUSLIST.NoPublico:
      statusBall = <div className="lightBlue"></div>;
      break;
    case STATUSLIST.Pendiente:
      statusBall = <div className="gray"></div>;
      break;
  }
};

// Drag and Drop

/**
 *
 * @param {Object} props
 */
const ActionTable = (props) => {
  statusBall = "";
  const [filtersList, setFilters] = useState();

  function getGroup(group_id) {
    let result = null;
    let n = filtersList.length;
    for (let i = 0; i < n; i++) {
      let group = filtersList[i];
      if (group.groupName === group_id) {
        result = { group: group, index: i };
        break;
      }
    }
    return result;
  }

  function reorder(group, startInd, endInd) {
    let newList = Array.from(group.filters);
    let [removed] = newList.splice(startInd, 1);
    newList.splice(endInd, 0, removed);
    return newList;
  }

  function reorderfiltersList(
    newList,
    groupInd,
    secondNewList = null,
    secondGroupInd = null
  ) {
    let tempFiltersList = Array.from(filtersList);
    tempFiltersList[groupInd].filters = newList;
    if (secondGroupInd !== null) {
      tempFiltersList[secondGroupInd].filters = secondNewList;
    }
    return tempFiltersList;
  }

  function move(
    groupSource,
    groupDestination,
    droppableSource,
    droppableDestination
  ) {
    let sourceClone = Array.from(groupSource.filters);
    let destClone = Array.from(groupDestination.filters);
    let [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    return { source: sourceClone, destination: destClone };
  }

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      //IF BOTH FILTERS ARE IN SAME GROUP
      let groupInfo = getGroup(source.droppableId);
      let group = groupInfo.group;
      let groupInd = groupInfo.index;
      let newList = reorder(group, source.index, destination.index);
      let newFiltersList = reorderfiltersList(newList, groupInd);
      setFilters(newFiltersList);
      // forceRender();
    } else {
      //IF FILTERS ARE IN SEPARATED GROUPS
      let groupSourceInfo = getGroup(source.droppableId);
      let groupSource = groupSourceInfo.group;
      let groupSourceInd = groupSourceInfo.index;
      let groupDestinationInfo = getGroup(destination.droppableId);
      let groupDestination = groupDestinationInfo.group;
      let groupDestinationInd = groupDestinationInfo.index;
      let newFilters = move(groupSource, groupDestination, source, destination);
      let newFiltersListSource = newFilters.source;
      let newFiltersListDestination = newFilters.destination;
      let newFiltersList = reorderfiltersList(
        newFiltersListSource,
        groupSourceInd,
        newFiltersListDestination,
        groupDestinationInd
      );
      setFilters(newFiltersList);
      // forceRender();
    }
  };
  const columns = props.columns;
  const rows = props.data;
  const selectable = props.selectable ? props.selectable : false;
  const idVisualization = props.idVisualization ? props.idVisualization : false;
  const userStatus = props.userStatus ? props.userStatus : false;
  const actionColumn = props.actionColumn ? props.actionColumn : false;
  const statusColumn = props.statusColumn ? props.statusColumn : false;
  const firstColumnLink = props.firstColumnLink ? props.firstColumnLink : false;
  const linkType = props.linkType ? props.linkType : "";
  const link = props.link ? props.link : "";
  const arrayDeIds = props.arrayDeIds ? props.arrayDeIds : [];
  const setArrayDeIds = props.setArrayDeIds ? props.setArrayDeIds : "";
  const messageWhenEmpty = props.messageWhenEmpty ? props.messageWhenEmpty : "";
  const dragDrop = props.dragDrop ? props.dragDrop : "";

  return (
    <div>
      <Table responsive className="table-action-table">
        <thead className="table-header">
          <tr>
            {columns &&
              columns.length > 0 &&
              columns.map((col, idx) => {
                return <th key={idx}>{col}</th>;
              })}
          </tr>
        </thead>
        {dragDrop ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"1"} type={"PREGUNTA"}>
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {rows &&
                    rows.length > 0 &&
                    rows.map((rw, idx) => {
                      createTableRow(
                        rw,
                        idx,
                        selectable,
                        idVisualization,
                        userStatus,
                        statusColumn,
                        actionColumn,
                        firstColumnLink,
                        linkType,
                        link,
                        arrayDeIds,
                        setArrayDeIds,
                        dragDrop
                      );
                    })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <tbody>
            {rows &&
              rows.length > 0 &&
              rows.map((rw, idx) => {
                return createTableRow(
                  rw,
                  idx,
                  selectable,
                  idVisualization,
                  userStatus,
                  statusColumn,
                  actionColumn,
                  firstColumnLink,
                  linkType,
                  link,
                  arrayDeIds,
                  setArrayDeIds
                );
              })}
          </tbody>
        )}
      </Table>
      {rows && rows.length === 0 && (
        <div className="div-empty-action-table">
          <img src={iconWhenEmpty} />
          <p>{messageWhenEmpty}</p>
        </div>
      )}
    </div>
  );
};

export default ActionTable;
