import * as types from "../../consts/actionTypes";

export const getCatalogoUsuarios = (payload) => {
  console.log("Solicitando catalogo de usuarios...");
  return {
    type: types.GET_CATALOGO_USUARIOS,
    payload,
  };
};

export const validarUsuario = (payload) => {
    console.log("Validando usuario...");
    return {
      type: types.POST_VALIDACION_USUARIO,
      payload,
    };
  };