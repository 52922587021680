import React from "react";
import { makeStyles } from "@material-ui/core";
import "./variables.css";
import { MoreVert } from "@material-ui/icons/";
import SearchIcon from "@material-ui/icons/Search";
import { Paper, IconButton, InputBase } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  wrapperSearch: {
    height: "64px",
    width: "100%",
    backgroundColor: "var(--searchForm-section-color)",
    display: "flex",
    alignItems: "center",
  },
  personalInfo: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
  },
  avatarWrapper: {
    paddingRight: "20px",
  },
  circle: {
    border: "1px solid white",
    borderRadius: "50%",
    width: "40px",
    height: "auto",
    marginRight: "10px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  moreVert: {
    border: "1px solid white",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    margin: "0 10px 0 10px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "&:hover": {
      "& $proposition": {
        display: "block",
        position: "absolute",
        top: "30px",
        right: "5px",
        backgroundColor: "white",
      },
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  circleWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  proposition: {
    display: "none",
    minWidth: "155px",
  },
  form: {
    borderRadius: "10px",
  },
  rounded: {
    borderRadius: theme.spacing(4),
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1,
    padding: 0,
    display: "contents",
  },
  inputWrapper: {
    transition: "width 0.3s ease",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  expanded: {
    width: "280px",
    marginLeft: theme.spacing(2),
  },
  contracted: {
    width: 0,
  },
  root: {
    display: "flex",
    alignItems: "center",
    borderTopRightRadius: theme.spacing(4),
    borderBottomRightRadius: theme.spacing(4),
    transition: "border-radius 0.3s ease",
  },
  propositionIcon: {
    backgroundColor: "white",
  },
  customUl: {
    marginLeft: "10px",
    paddingLeft: 0,
    margin: 0,
    minWidth: "155px",
  },
  customLi: {
    listStyleType: "none",
    fontSize: "12px",
    cursor: "pointer",
    padding: "14px 8px 8px 8px",
    minWidth: "155px",
    "&:hover": {
      color: "var(--time-color)",
    },
  },
}));

const SearchForm = ({ personInfo }) => {
  const classes = useStyles();
  const { name, avatar, time } = personInfo;

  const [expanded, setExpanded] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  return (
    <div className={classes.wrapperSearch}>
      <div style={{ width: "100%" }}>
        <div key={name} className={classes.wrapper}>
          <div className={classes.personalInfo} key={name}>
            <img src={avatar} alt={name} className={classes.avatarWrapper} />
            <div>
              <div>{name}</div>
              <div>{time}</div>
            </div>
          </div>
          <div className={classes.circleWrapper}>
            <Paper
              component="form"
              className={clsx(classes.root, expanded ? "" : classes.rounded)}
            >
              <div
                className={clsx(
                  classes.inputWrapper,
                  expanded ? classes.expanded : classes.contracted
                )}
              >
                <InputBase
                  className={classes.input}
                  placeholder="Buscar en la conversación"
                  inputProps={{ "aria-label": "Buscar en la conversación" }}
                />
              </div>
              <IconButton
                onClick={handleClick}
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <div className={classes.moreVert}>
              <MoreVert />
              <div className={classes.proposition}>
                <div className={classes.propositionIcon}>
                  <ul className={classes.customUl}>
                    <li className={classes.customLi}>Reportar</li>
                    <li className={classes.customLi}>
                      Enviar chat a mi correo
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchForm;
