import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
} from "react-bootstrap";
import { User, Clock } from "react-feather";
import TableWithActions from "../../components/ActionTable/index";
import { useParams, Link } from "react-router-dom";

//Actions

//Selectors
import { respuestasDeUsuarioResult } from "../../redux/selectors/encuestasSelectors";

const columns = ["PREGUNTA", "RESPUESTA"];

const DetalleRespuestas = () => {
  const { nombreEncuesta, idUser, hora } = useParams();

  const respuestasDeUsuario = useSelector((state) =>
    respuestasDeUsuarioResult(state)
  );
  const [respuestas, setRespuestas] = useState([]);

  useEffect(() => {
    if (respuestasDeUsuario) {
      console.log(respuestasDeUsuario)
      setRespuestas(respuestasDeUsuario);
    }
  }, [respuestasDeUsuario]);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link to="/respuestas/">Regresar a Respuestas</Link>
            <h3>Respuestas: {nombreEncuesta}</h3>
          </div>
          <div style={{display: "flex"}}>
            <div className="div-info-usuario-hora-respuestas-pregunta-votacion">
              <User />
              <p>{idUser}</p>
            </div>
            <div className="div-info-usuario-hora-respuestas-pregunta-votacion">
              <Clock />
              <p>{hora}{" CDT"}</p>
            </div>
          </div>

          <Row>
            <Col sm={12}>
              <TableWithActions columns={columns} data={respuestas} />
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default DetalleRespuestas;
