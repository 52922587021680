import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from '../services/authServices'

export function* getCatalogoUsuariosSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/users-catalog/",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Catalogo de usuarios solicitado satisfactoriamente");
    yield put({ type: types.GET_CATALOGO_USUARIOS_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar catalogo de usuarios :", e);
    yield put({ type: types.GET_CATALOGO_USUARIOS_ERROR, payload });
  }
}

export function* validarUsuarioSaga({ payload }) {
    try {
      const results = yield call(
        apiCall,
        "sso-client/assign-access-site/",
        payload,
        {
          "Content-Type": "application/json",
          Host: "localhost:3000",
          Authorization: `Bearer ${Services.getAccessToken()}`,
        },
        "POST"
      );
      console.log("Usuario validado satisfactoriamente");
      yield put({ type: types.POST_VALIDACION_USUARIO_SUCCESS, results });
    } catch (e) {
      console.log("Error al validar usuario :", e);
      yield put({ type: types.POST_VALIDACION_USUARIO_ERROR, payload });
    }
  }

export default function* accionesSaga() {
    yield takeLatest(types.GET_CATALOGO_USUARIOS, getCatalogoUsuariosSaga);
    yield takeLatest(types.POST_VALIDACION_USUARIO, validarUsuarioSaga);
  }