import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
} from "react-bootstrap";
import { Inbox, Archive } from "react-feather";
import { Link } from "react-router-dom";
import "./Propuestas.css";

// Components
import TableWithActions from "../../components/ActionTable/index";
import BorrarPropuesta from "./components/BorrarPropuesta";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";

//Actions
import { getPropuestas } from "../../redux/actions/propuestasActions";

//Selectors
import {
  propuestasResult,
  debeActualizarPropuestasResult,
} from "../../redux/selectors/propuestasSelectors";

// Tomando en cuenta que quiza la información venga en
// JSON hay que parsearla para que añada los links

const Propuestas = () => {
  const dispatch = useDispatch();
  const propuestas = useSelector((state) => propuestasResult(state));
  const actualizarPropuestas = useSelector((state) =>
    debeActualizarPropuestasResult(state)
  );
  const [modalDelete, setModalDelete] = useState(false);
  const [propuestaABorrar, setPropuestaABorrar] = useState();
  const [propuestasActivas, setPropuestasActivas] = useState();
  const [propuestasArchivadas, setPropuestasArchivadas] = useState();

  const columns = [
    "NOMBRE",
    "ESTATUS",
    "FECHA DE CREACIÓN",
    "VOTOS",
    "ACCIONES",
  ];

  const renderEstatus = (estatus) => {
    const elemento = (color, texto) => {
      return (
        <div className="item-dropdown-estatus-propuesta">
          <div className={`bolita-estatus bolita-estatus-${color}`}></div>
          {texto}
        </div>
      );
    };
    switch (estatus) {
      case "B":
        return elemento("azul", "Borrador");
      case "A":
        return elemento("verde", "Aprobado");
      case "ER":
        return elemento("amarillo", "En Revisión");
      case "I":
        return elemento("naranja", "Inhabilitada");
      case "T":
        return elemento("negro", "Terminado");
      case "AR":
        return elemento("gris", "Archivada");
      default:
    }
  };

  const propuestaFake = [
    {
      id: 1,
      titulo: "Hola",
      status: "A",
      area: "Area",
      fecha_publicacion: "2020-03-12",
      apoyos: "1500",
    },
  ];

  const dataParser = (data) => {
    return data
      .map((propuesta) => {
        return {
          id: propuesta.id,
          nombre: propuesta.titulo,
          estatus: renderEstatus(propuesta.status),
          fecha: propuesta["fecha_publicacion"],
          votos: propuesta.apoyos,
          accion: <Link to={`/propuestas/${propuesta.id}`}>Ver Detalle</Link>,
        };
      })
      .filter((element) => element !== undefined);
  };

  const deletePropuestas = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      setModalDelete(true);
      let obj = {};
      propuestas.forEach((propuesta) => {
        if (propuesta.id === arrayDeIds[0]) {
          obj = {
            id: arrayDeIds[0],
            nombre: propuesta.titulo,
          };
        }
      });
      setPropuestaABorrar(obj);
    }
  };

  useEffect(() => {
    if (propuestas) {
      // let activas = propuestas.filter(((propuesta)=>propuesta.disponibilidad === "1"))
      // let archivadas = propuestas.filter(((propuesta)=>propuesta.disponibilidad === "2"))
      let activas = propuestas.filter(
        (propuesta) =>
          propuesta.status === "A" ||
          propuesta.status === "ER" ||
          propuesta.status === "B"
      );
      let archivadas = propuestas.filter(
        (propuesta) =>
          propuesta.status === "I" ||
          propuesta.status === "T" ||
          propuesta.status === "AR"
      );
      setPropuestasActivas(activas);
      setPropuestasArchivadas(archivadas);
    }
  }, [propuestas]);

  useEffect(() => {
    if (actualizarPropuestas) {
      dispatch(getPropuestas());
    }
  }, [actualizarPropuestas]);

  useEffect(() => {
    dispatch(getPropuestas());
  }, []);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <BreadcrumbItem>Propuestas</BreadcrumbItem>
            <h3 className="titulo-table-actions">Tablero de Propuestas</h3>
          </div>

          <Tabs className="tabs-action-table">
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <Inbox /> {"Activos"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      propuestasActivas
                        ? dataParser(
                            propuestasActivas.sort((a, b) => b.id - a.id)
                          )
                        : []
                    }
                    selectable={true}
                    haveStatus={false}
                    delete={false}
                    deleteFunction={() => console.log("The Function")}
                    archive={true}
                    archiveFunction={deletePropuestas}
                    searchBar={true}
                    messageWhenEmpty={"No hay propuestas para mostrar"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <Archive /> {"Archivados"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      propuestasArchivadas
                        ? dataParser(propuestasArchivadas)
                        : []
                    }
                    selectable={false}
                    haveStatus={false}
                    delete={false}
                    deleteFunction={deletePropuestas}
                    archive={false}
                    archiveFunction={() => console.log("The Function")}
                    searchBar={true}
                    messageWhenEmpty={"No hay propuestas para mostrar"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modalDelete} setModal={setModalDelete}>
        <BorrarPropuesta
          setModal={setModalDelete}
          nombreDePropuesta={propuestaABorrar ? propuestaABorrar.nombre : ""}
          idDePropuesta={propuestaABorrar ? propuestaABorrar.id : ""}
        />
      </ModalEstandar>
    </div>
  );
};

export default Propuestas;
