import * as types from "../../consts/actionTypes";

const initialState = {
  encuestaCreada: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ENCUESTAS:
      return {
        ...state,
        encuestaCreada: undefined,
      };
    case types.GET_ENCUESTAS_SUCCESS:
      console.log("Encuestas guardadas en el estado encuestas");
      return {
        ...state,
        encuestas: action.results.data,
        updateEncuestas: false,
      };
    case types.GET_ENCUESTAS_VENCIDAS_SUCCESS:
      console.log(
        "Encuestas vencidas guardadas en el estado encuestasVencidas"
      );
      return {
        ...state,
        encuestasVencidas: action.results.data,
      };
    case types.POST_ENCUESTA_SUCCESS:
      return {
        ...state,
        encuesta: action.results.data,
        updateEncuestas: true,
        encuestaCreada: action.results.data.id,
      };
    case types.POST_ENCUESTA_ERROR:
      return {
        ...state,
        encuesta: action.results.data,
        updateEncuestas: true,
        encuestaCreada: false,
      };
    case types.UPDATE_ENCUESTAS:
      return {
        ...state,
        updateEncuestas: true,
        encuestaCreada: undefined,
      };
    case types.GET_USUARIO_QUE_RESPONDIERON_SUCCESS:
      console.log(
        "Lista de usuarios que respondieron a encuestas guardada en estado listaDeUsuariosQueRespondieron"
      );
      return {
        ...state,
        listaDeUsuariosQueRespondieron: action.results.data,
      };
    case types.GET_RESPUESTA_DE_USUARIO_SUCCESS:
      return {
        ...state,
        respuestasDeUsuario: action.results.data,
      };
    case types.DELETE_ENCUESTA_SUCCESS:
      return {
        ...state,
        updateEncuestas: true,
      };
    case types.GET_ESTADISTICAS_ENCUESTAS_SUCCESS:
      console.log(
        "Estadísticas de encuestas guardadas en estado estadisticasDeEncuestas"
      );
      return {
        ...state,
        estadisticasDeEncuestas: action.results.data,
      };
    case types.PUT_ENCUESTA_SUCCESS:
      return {
        ...state,
        updateEncuestas: true,
      };
    default:
      return { ...state };
  }
}
