import React, { useCallback, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from '../../../redux/actions/messages';
import { useDispatch, useSelector } from "react-redux";
import { AttachFile } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
  },
  buttonAttachFiles: {
    cursor: "pointer",
    outline: "none",
    border: 0,
    backgroundColor: "inherit",
    display: "inline-block",
  },
}));

const UpLoader = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      if (
        ["image/png", "image/svg", "image/jpeg"].includes(acceptedFiles[0].type)
      ) {
        return dispatch(
          Actions.uploadConversationFile({
            file: acceptedFiles[0],
            conversation: id,
            type: "image",
          })
        );
      }
      if (["application/pdf"].includes(acceptedFiles[0].type)) {
        const file = new Blob([acceptedFiles[0]], {
          type: acceptedFiles[0].type,
        });
        return dispatch(
          Actions.uploadConversationFile({
            file: acceptedFiles[0],
            conversation: id,
            type: "pdf",
            name: acceptedFiles[0].name,
          })
        );
      }
      dispatch(Actions.uploadConversationFile({ message: acceptedFiles[0].name, id }));
    },
    [dispatch, id]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className={classes.buttonAttachFiles} {...getRootProps()}>
      <input {...getInputProps()} />

      <AttachFile
        htmlColor="#828282"
        aria-label="toggle visibility"
        edge="end"
      ></AttachFile>
    </div>
  );
};

export default UpLoader;
