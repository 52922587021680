import React, { useState } from 'react'
import { Table, Tabs, Tab, Row, Container, Col, BreadcrumbItem, FormLabel, FormControl, Button, Dropdown } from 'react-bootstrap'
import "./detallesSolicitud.css"
import ReactQuill from 'react-quill'
import { Inbox, MessageSquare, Clock, MessageCircle } from 'react-feather'
import TableWithActions from '../../components/ActionTable/index'
import { Paper, Card } from '@material-ui/core'
import Comment from '../../components/Comment/Comment'
import CalendarioInline from '../../components/reusable/CalendarioInline/CalendarioInline'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'

const columns = [
    "NOMBRE", "ESTATUS", "ÁREA", "FECHA", "ENLACE"
]

const data = [
    {
        actividad: "“Sección sin luz” cambió estátus a 'Activo'",
        status: "Aceptado",
        datetime: "2020-07-21 02:31:13",
    },
    {
        actividad: "Ciudadano ASDF12345678 envió solicitud 'Sección sin luz'",
        status: "No Leido",
        datetime: "2020-07-21 02:31:13",
    },
]

const fakeComments = [
    {
        img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Armando Barreda",
        datetime: "13 de Junio de 2020 a las 16:05",
        comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut nemo reprehenderit ducimus quaerat aspernatur quae iusto aperiam unde neque quibusdam et quos, praesentium, odio consectetur expedita, molestiae veritatis dignissimos quasi."
    },
    {
        img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Armando Barreda",
        datetime: "13 de Junio de 2020 a las 16:05",
        comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut nemo reprehenderit ducimus quaerat aspernatur quae iusto aperiam unde neque quibusdam et quos, praesentium, odio consectetur expedita, molestiae veritatis dignissimos quasi."
    },
    {
        img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        username: "Armando Barreda",
        datetime: "13 de Junio de 2020 a las 16:05",
        comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut nemo reprehenderit ducimus quaerat aspernatur quae iusto aperiam unde neque quibusdam et quos, praesentium, odio consectetur expedita, molestiae veritatis dignissimos quasi."
    },
]

const tags = ["Escuela", "Administración", "COVID-19", "Gobierno"]

const DetallesSolicitud = () => {

    const [value, setValue] = useState('');

    return (
        <div className="section">
            <Row>
                <Container fluid className="container-information">
                    <Row>
                        <Col md={3}>
                            <div className="breadcrumb-section">
                                <BreadcrumbItem>Inicio</BreadcrumbItem>
                                <h3 className="titulo-table-actions">Tablero de Solicitudes</h3>
                            </div>
                        </Col>
                        <Col md={9}>
                            <div>
                                <Button className="button-with-icon" >
                                    <MessageCircle />
                                    <p>Abrir Chat</p>
                                </Button>
                                {/* <Dropdown>
                                    <DropdownToggle>

                                    </DropdownToggle>
                                </Dropdown> */}
                            </div>

                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>

                            <Card style={{ width: "100%", height: "100%" }}>
                                <div className="container-form">
                                    <Row className={"rowForm"}>
                                        <Col md={6}>
                                            <FormLabel>Título de la Propuesta</FormLabel>
                                            <FormControl></FormControl>
                                        </Col>
                                        <Col md={3}>
                                            <FormLabel>Fecha de Publicación</FormLabel>
                                            <CalendarioInline />
                                        </Col>
                                        <Col md={3}>
                                            <FormLabel>Adjuntar una Imagen</FormLabel>
                                            <FormControl></FormControl>
                                        </Col>
                                    </Row>
                                    <Row className={"rowForm"}>
                                        <Col sm={12}>
                                            <FormLabel>Contenido de la Propuesta</FormLabel>
                                            <ReactQuill theme={"snow"} value={value} onChange={setValue} />
                                        </Col>
                                    </Row>
                                    <Row className={"rowForm"}>
                                        <Col sm={6}>
                                            <FormLabel>Etiquetas</FormLabel>
                                            <div className="tagContainer">
                                                {
                                                    tags.map((tag, key) => {
                                                        return (
                                                            <div className="tag" key={key}>{tag}</div>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </Col>
                                        <Col sm={6}>
                                            <FormLabel>Área</FormLabel>
                                            <FormControl></FormControl>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>

                        </Col>
                        <Col sm={12} className="tabsTable">
                            <Tabs>
                                <Tab
                                    eventKey={1}
                                    title={
                                        <span className="tab-span-solo-demo">
                                            <span className="iconTab"><Clock /></span> {"Actividad"}
                                        </span>
                                    }
                                >
                                    <Row>
                                        <Col dm={12}>
                                            <TableWithActions
                                                columns={["ACTIVIDAD", "ESTATUS", "HORA"]}
                                                data={data}
                                                statusColumn={true}

                                            />
                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab
                                    eventKey={2}
                                    title={
                                        <span className="tab-span-solo-demo">
                                            <span className="iconTab"><MessageSquare /></span> {"Comentarios"}
                                        </span>
                                    }
                                >
                                    <Row>
                                        <Col dm={12} className="tabsTable">
                                            <Comment comments={fakeComments} />
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </div>
    )
}

export default DetallesSolicitud