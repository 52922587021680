import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall, token } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from "../services/authServices";

import Swal from "sweetalert2";

export function* getPropuestasSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "propuesta/propuesta/",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Propuestas solicitadas satisfactoriamente");
    yield put({ type: types.GET_PROPUESTAS_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar propuestas: ", e);
    yield put({ type: types.GET_PROPUESTAS_ERROR, payload });
  }
}

export function* getPropuestaADetalleSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `propuesta/propuesta/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Propuesta a detalle solicitada satisfactoriamente");
    yield put({ type: types.GET_PROPUESTA_DETALLES_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar propuesta a detalle: ", e);
    yield put({ type: types.GET_PROPUESTA_DETALLES_ERROR, payload });
  }
}

export function* putEstatusPropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `propuesta/propuesta/${payload.id}/`,
      {
        bandera: payload.bandera,
        status: payload.estatus,
      },
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Estatus de propuesta actualizado satisfactoriamente");
    yield put({ type: types.PUT_PROPUESTA_ESTATUS_SUCCESS, results });
  } catch (e) {
    console.log("Error al actualizar estatus de propuesta: ", e.message);
    yield put({ type: types.PUT_PROPUESTA_ESTATUS_ERROR, e });
  }
}

export function* getComentariosSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "propuesta/comentario/",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Comentarios solicitados satisfactoriamente");
    yield put({ type: types.GET_COMENTARIOS_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar comentarios: ", e.message);
    yield put({ type: types.GET_COMENTARIOS_ERROR, e });
  }
}

export function* postComentarioSaga({ payload }) {
  console.log("postComentarioSaga", payload);
  try {
    const results = yield call(
      apiCall,
      "propuesta/comentarios/",
      {
        propuesta: payload.propuesta,
        comentario: payload.comentario,
        bandera: "C",
      },
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Comentario enviado satisfactoriamente");
    yield put({ type: types.POST_COMENTARIO_SUCCESS, results });
  } catch (e) {
    console.log("Error al enviar comentario: ", e.message);
    yield put({ type: types.POST_COMENTARIO_ERROR, e });
  }
}

export function* deleteComentarioSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `propuesta/comentarios/${payload.idComentario}`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    console.log("Comentario eliminado satisfactoriamente");
    yield put({ type: types.DELETE_COMENTARIO_SUCCESS, results });
  } catch (e) {
    console.log("Error al eliminar comentario: ", e.message);
    yield put({ type: types.DELETE_COMENTARIO_ERROR, e });
  }
}

export function* escalarPropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `propuesta/escalar/${payload.id}/`,
      { escalado: "True", comentario_escalado: payload.comentario_escalado },
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Propuesta escalada satisfactoriamente");
    yield put({ type: types.PUT_ESCALAR_PROPUESTA_SUCCESS, results });
  } catch (e) {
    console.log("Error al escalar propuesta: ", e.message);
    Swal.fire(
      "Hubo un error!",
      "La propuesta no ha podido ser escalada",
      "error"
    );
    yield put({ type: types.PUT_ESCALAR_PROPUESTA_ERROR, e });
  }
}

export function* deletePropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `propuesta/propuesta/${payload}`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    console.log("Propuesta eliminada satisfactoriamente");
    yield put({ type: types.DELETE_PROPUESTA_SUCCESS, results });
  } catch (e) {
    console.log("Error al eliminar propuesta: ", e.message);
    yield put({ type: types.DELETE_PROPUESTA_ERROR, e });
  }
}

export function* postRespuestaPropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "propuesta/comentarios/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Respuesta creada satisfactoriamente");
    yield put({ type: types.POST_RESPUESTA_PROPUESTA_SUCCESS, results });
  } catch (e) {
    console.log("Error al crear respuesta: ", e.message);
    yield put({ type: types.POST_RESPUESTA_PROPUESTA_ERROR, e });
  }
}

export function* putComentarioPropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `propuesta/comentarios/${payload.id}/`,
      payload.body,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Comentario actualizado satisfactoriamente");
    yield put({ type: types.PUT_COMENTARIO_PROPUESTA_SUCCESS, results });
  } catch (e) {
    console.log("Error al actualizar comentario: ", e.message);
    yield put({ type: types.PUT_COMENTARIO_PROPUESTA_ERROR, e });
  }
}

export function* postVotoComentarioPropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "propuesta/votos-comentarios/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Voto añadido satisfactoriamente");
    yield put({ type: types.POST_VOTO_COMENTARIO_PROPUESTA_SUCCESS, results });
  } catch (e) {
    console.log("Error al añadir voto: ", e.message);
    yield put({ type: types.POST_VOTO_COMENTARIO_PROPUESTA_ERROR, e });
  }
}

export function* getEscalamientos({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "propuesta/escalar/",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Escalamientos solicitados satisfactoriamente");
    yield put({ type: types.GET_ESCALAMIENTOS_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar escalamientos: ", e.message);
    yield put({ type: types.GET_ESCALAMIENTOS_ERROR, e });
  }
}

export default function* propuestasSaga() {
  yield takeLatest(types.GET_PROPUESTAS, getPropuestasSaga);
  yield takeLatest(types.GET_PROPUESTA_DETALLES, getPropuestaADetalleSaga);
  yield takeLatest(types.PUT_PROPUESTA_ESTATUS, putEstatusPropuestaSaga);
  yield takeLatest(types.GET_COMENTARIOS, getComentariosSaga);
  yield takeLatest(types.POST_COMENTARIO, postComentarioSaga);
  yield takeLatest(types.DELETE_COMENTARIO, deleteComentarioSaga);
  yield takeLatest(types.DELETE_COMENTARIO, deleteComentarioSaga);
  yield takeLatest(types.PUT_ESCALAR_PROPUESTA, escalarPropuestaSaga);
  yield takeLatest(types.DELETE_PROPUESTA, deletePropuestaSaga);
  yield takeLatest(types.POST_RESPUESTA_PROPUESTA, postRespuestaPropuestaSaga);
  yield takeLatest(types.PUT_COMENTARIO_PROPUESTA, putComentarioPropuestaSaga);
  yield takeLatest(
    types.POST_VOTO_COMENTARIO_PROPUESTA,
    postVotoComentarioPropuestaSaga
  );
  yield takeLatest(types.GET_ESCALAMIENTOS, getEscalamientos);
}
