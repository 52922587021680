import React, { useState, useEffect } from "react";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
} from "react-bootstrap";
import { Inbox, Archive } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import TableWithActions from "../../components/ActionTable/index";
import "./Proyectos.css";
import { shorten } from "../../utils/ComponentUtils"


// Components
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import CrearNuevoProyecto from "./CrearNuevoProyecto";
import BorrarProyecto from "./BorrarProyecto";

//Actions
import { getProyectos } from "../../redux/actions/proyectosActions";

//Selectors
import {
  proyectosResult,
  updateProyectosResult,
} from "../../redux/selectors/proyectosSelectors";

const columns = ["PROYECTO", "DURACIÓN", "ANCLADO A INICIO", "ACCIONES"];

const Proyectos = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const proyectos = useSelector((state) => proyectosResult(state));
  const actualizarProyectos = useSelector((state) =>
    updateProyectosResult(state)
  );
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [proyectoABorrar, setProyectoABorrar] = useState();
  const [proyectosActivos, setProyectosActivos] = useState();
  const [proyectosArchivados, setProyectosArchivados] = useState();

  const dataParser = (data) => {
    return data
      .map((proyecto) => {
        return {
          id: proyecto.id,
          nombre: shorten(proyecto.nombre, 80),
          duracion: `${proyecto.fecha_creacion.slice(0, 10)} - ${
            proyecto.fecha_terminacion
          }`,
          anclar_inicio: proyecto.anclar_inicio ? "SI" : "NO",
          accion: (
            <Link to={`/proyectos/detalles/${proyecto.id}`}>Ver Proyecto</Link>
          ),
        };
      })
      .filter((element) => element !== undefined);
  };

  const editarProyecto = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      history.push(`/proyectos/${arrayDeIds[0]}`);
      setModal(true);
    }
  };

  const borrarProyecto = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      setModalDelete(true);
      let obj = {};
      proyectos.forEach((proyecto) => {
        if (proyecto.id === arrayDeIds[0]) {
          obj = {
            id: arrayDeIds[0],
            nombre: proyecto.nombre,
          };
        }
      });
      setProyectoABorrar(obj);
    }
  };

  useEffect(() => {
    dispatch(getProyectos());
  }, []);

  useEffect(() => {
    if (actualizarProyectos) {
      dispatch(getProyectos());
    }
  }, [actualizarProyectos]);

  useEffect(() => {
    if (!modal) {
      history.push("/proyectos");
    }
  }, [modal]);

  useEffect(() => {
    if (proyectos) {
      setProyectosActivos(
        proyectos.filter((proyecto) => proyecto.disponibilidad === "1")
      );
      setProyectosArchivados(
        proyectos.filter((proyecto) => proyecto.disponibilidad === "2")
      );
    }
  }, [proyectos]);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link>Proyectos</Link>
            <h3 className="titulo-table-actions">Listado de Proyectos</h3>
          </div>

          <Tabs className="tabs-action-table">
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <Inbox /> {"Activos"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      proyectosActivos
                        ? dataParser(
                            proyectosActivos.sort((a, b) => b.id - a.id)
                          )
                        : []
                    }
                    selectable={true}
                    firstColumnLink={false}
                    delete={true}
                    deleteFunction={borrarProyecto}
                    archive={false}
                    archiveFunction={() => console.log("The Function")}
                    edit={true}
                    editFunction={editarProyecto}
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() => setModal(true)}
                    textForCreateNew={"Crear Proyecto"}
                    messageWhenEmpty={"Crea un proyecto para comenzar"}
                    paginate={true}
                    paginateRows={10}
                  >
                    <ModalEstandar visibilidad={modal} setModal={setModal}>
                      <CrearNuevoProyecto setModal={setModal} />
                    </ModalEstandar>
                  </TableWithActions>
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <Archive /> {"Archivados"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      proyectosArchivados
                        ? dataParser(
                            proyectosArchivados.sort((a, b) => b.id - a.id)
                          )
                        : []
                    }
                    selectable={true}
                    firstColumnLink={false}
                    delete={true}
                    deleteFunction={borrarProyecto}
                    archive={false}
                    archiveFunction={() => console.log("The Function")}
                    searchBar={true}
                    messageWhenEmpty={"No hay proyectos archivados"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modalDelete} setModal={setModalDelete}>
        <BorrarProyecto
          setModal={setModalDelete}
          nombreDeProyecto={proyectoABorrar ? proyectoABorrar.nombre : ""}
          idDeProyecto={proyectoABorrar ? proyectoABorrar.id : ""}
        />
      </ModalEstandar>
    </div>
  );
};

export default Proyectos;
