import React from "react";
import {
  Row,
  Container,
  Col,
  Carousel,
  CarouselItem,
  Card,
} from "react-bootstrap";
import * as TendenciasActions from "../../redux/actions/tendenciasActions";
import * as ProyectosActions from "../../redux/actions/proyectosActions";
import { useDispatch, useSelector } from "react-redux";
import { shorten, outerHtmlToReadableString } from "../../utils/ComponentUtils";
import * as TendenciasSelector from "../../redux/selectors/tendenciasSelector";
import * as ProyectosSelector from "../../redux/selectors/proyectosSelectors";
import BadgeComponent from "../../components/BadgeComponent";
import "./tendencias.css";
import { Link, useHistory } from "react-router-dom";
import fotoDefault from "../../assets/imagen-default.jpg";
import IconoExternalLinkBlanco from "../../assets/Iconos especiales/external-link-blanco.svg";
import Button from '@material-ui/core/Button'
import * as Services from "../../redux/services/authServices"
import axios from 'axios'


const Tendencias = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  //const history = useHistory()
  const tendenciasPropuestas = useSelector((state) =>
    TendenciasSelector.tendenciasPropuestas(state)
  );
  const tendenciasProyectos = useSelector((state) =>
    ProyectosSelector.proyectosResult(state)
  );

  React.useEffect(() => {
    dispatch(TendenciasActions.getTendenciasPropuestas());
    dispatch(ProyectosActions.getProyectos());
  }, [dispatch]);

  const getImg = (card) => {
    return card.propuesta.imagen ? card.propuesta.imagen : fotoDefault;
  };

  const [desplegarTexto, setDesplegarTexto] = React.useState(false);

  console.log(tendenciasProyectos);

  return (
    <div className="section">
      <Row>
        <Container
          fluid
          className="container-information"
          style={{ margin: "25px 50px" }}
        >
          <div className="breadcrumb-section">
            <p style={{ fontSize: "14px", color: "#1C1861" }}>Tendencias</p>
            <h4 style={{ color: "#0F69B4" }}>Proyectos de Gobierno</h4>
          </div>
          <Row>
            <Col sm={12}>
              <Carousel className="carousel">
                {tendenciasProyectos
                  ? tendenciasProyectos
                      .filter((proyecto) => proyecto.anclar_inicio && proyecto.disponibilidad=="1")
                      .map((item, key) => {
                        return (
                          <CarouselItem style={{ height: "inherit" }} key={key}>
                            <div
                              style={{
                                backgroundImage: "url(" + item.img + ")",
                              }}
                              className="bg-cover-image d-block w-100 img-carousel "
                            />
                            <Carousel.Caption className="carousel-gradient">
                              <div className="div-titulo-proyecto-tendencia">
                                <h3>{item.nombre}</h3>
                                <div
                                  className="div-icono-link"
                                  onClick={() =>
                                    history.push(`/proyectos/detalles/${item.id}`)
                                  }
                                >
                                  <img
                                    src={IconoExternalLinkBlanco}
                                    alt="link de proyecto"
                                  />{" "}
                                </div>
                              </div>

                              <div>
                                {item.etiquetas.map((tag, idx) => {
                                  return (
                                    <BadgeComponent
                                      className="badge-component-style"
                                      text={tag.nombre}
                                    />
                                  );
                                })}
                              </div>
                              <div
                                style={{ marginBottom: "1.5em" }}
                                // dangerouslySetInnerHTML={{
                                //   __html: shorten(item.proyecto.descripcion, 200),
                                // }}
                              >
                                <p
                                  id={
                                    desplegarTexto &&
                                    "texto-descriptivo-tarjeta-proyecto-tendencias"
                                  }
                                >
                                  {shorten(
                                    outerHtmlToReadableString(item.descripcion),
                                    desplegarTexto ? undefined : 250
                                  )}
                                  {"    "}
                                  {outerHtmlToReadableString(item.descripcion)
                                    .length > 250 && (
                                    <span
                                      className="ver-mas-desplegar-texto"
                                      onClick={() =>
                                        setDesplegarTexto(!desplegarTexto)
                                      }
                                    >
                                      {desplegarTexto ? "Ver menos" : "Ver más"}
                                    </span>
                                  )}
                                </p>
                              </div>
                            </Carousel.Caption>
                          </CarouselItem>
                        );
                      })
                  : null}
              </Carousel>
            </Col>
            <Col sm={12} style={{ marginTop: 41, marginBottom: 20 }}>
              <div className="breadcrumb-section">
                <h4 style={{ color: "#0F69B4" }}>
                  Propuestas Ciudadanas más activas
                </h4>
                <Button onClick={()=>{
                  axios({
                    method: 'post',
                    url: 'https://api.participacion.management-r.com/tendencias/crowdpropuestas/',
                    headers: {
                      "Content-Type": "application/json",
                      Host: "localhost:3000",
                      Authorization: `Bearer ${Services.getAccessToken()}`,
                    },
                  })
                    .then(function (response) {
                      window.location.reload();

                    });
                }} >Actualizar</Button>

              </div>
              <Row>
                {tendenciasPropuestas
                  ? tendenciasPropuestas.map((card, key) => {
                      return (
                        <Col md={4} key={key} style={{ marginTop: 20 }}>
                          <Card className="cardItem">
                            <Card.Body>
                              <div className="imgContainer-card">
                                {/* <Card.Img variant="top" src={card.img} /> */}
                                <div
                                  className="bg-cover-image card-img-top h-100 "
                                  style={{
                                    backgroundImage:
                                      "url(" + getImg(card) + ")",
                                  }}
                                />
                              </div>
                              <Card.Title className="cards-title">
                                <Link to={"/propuestas/" + card.propuesta.id}>
                                  <h3
                                    style={{
                                      color: "#2475B9",
                                      fontSize: "18px",
                                      height: "44px",
                                    }}
                                  >
                                    {card.propuesta.titulo}
                                  </h3>
                                </Link>
                              </Card.Title>
                              <Card.Text
                                className="cards-text"
                                style={{ fontSize: "14px", height: "88px" }}
                                dangerouslySetInnerHTML={{
                                  __html: shorten(
                                    outerHtmlToReadableString(
                                      card.propuesta.contenido
                                    ),
                                    150
                                  ),
                                }}
                              ></Card.Text>
                              {card.propuesta.etiquetas.map((tag, key) => {
                                return (
                                  // <div key={key} className="tag cards-tags" style={{ color: "#2046D3", fontWeight: 700 }}>
                                  //     {tag}
                                  // </div>
                                  <BadgeComponent
                                    className="badge-component-style"
                                    text={tag.nombre}
                                  />
                                );
                              })}
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default Tendencias;
