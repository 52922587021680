import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Row, Container, Col } from "react-bootstrap";
import { Inbox, Archive, ExternalLink, Shield, User } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import "./Administradores.css";

//actions
import { getCatalogoUsuarios } from "../../redux/actions/ciudadanosActions";
import { noActualizaListaAdmins } from "../../redux/actions/administradoresActions";

//Selectors
import {
  catalogoUsuariosResult,
  updateCatalogoUsuariosResult,
} from "../../redux/selectors/ciudadanosSelectors";
import { updateAdminListResult } from "../../redux/selectors/administradoresSelectors";

//Components
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import CrearNuevoAdministrador from "./CrearNuevoAdministrador";
import EditarAdministrador from "./EditarAdministrador";
import TableWithActions from "../../components/ActionTable/index";

const columns = ["CORREO", "DNI", "ROL", "ÁREA", "ESTATUS", "ACCIÓN"];

const columnasCiudadano = ["ID", "DNI", "NOMBRE", "ROL", "ÁREA"];

const Administradores = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const catalogoUsuarios = useSelector((state) =>
    catalogoUsuariosResult(state)
  );
  const actualizaAdmins = useSelector((state) => updateAdminListResult(state));
  const actualizaUsers = useSelector((state) =>
    updateCatalogoUsuariosResult(state)
  );
  const [modal, setModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [administradorAEditar, setAdministradorAEditar] = useState();

  const rolTranslator = (rol) => {
    switch (rol) {
      case "SA_pwa":
        return "SUPER-ADMINISTRADOR";
      case "Adm_pwa":
        return "ADMINISTRADOR GENERAL";
      case "S_pwa":
        return "COORDINADOR";
      case "G_pwa":
        return "OPERADOR INTERNO";
      case "O_pwa":
        return "OPERADOR PERIFÉRICO";
      default:
        return false;
    }
  };


  const permisosParser = (data) => {
    return data.map((permiso) => {
      if (
        permiso.roluserPwa
          ? permiso.roluserPwa.user_rol !== "C_pwa"
            ? true
            : false
          : true
      ) {
        return {
          id: permiso.id,
          correo: permiso.email,
          rut: permiso.username,
          rol: permiso.roluserPwa
            ? rolTranslator(permiso.roluserPwa.user_rol)
            : "Sin asignar",
          // area: permiso.area ? permiso.area.area : "",
          area: "Ayuntamiento",

          estatus: permiso.sites_for_user === null ? "Inactivo" : "Activo",
          accion: (
            <Link
              onClick={() => {
                setModalEdit(true);
                setAdministradorAEditar(permiso);
              }}
            >
              Editar
            </Link>
          ),
        };
      }
    }).filter((element) => element !== undefined);
  };

  const administradorParser = (data) => {
    return data.map((admin) => {
      if (
        admin.roluserPwa
          ? admin.roluserPwa.user_rol !== "C_pwa"
            ? true
            : false
          : true
      ) {
        return {
          id: admin.id,
          rut: admin.username,
          nombre: admin.first_name + " " + admin.last_name,
          rol: admin.roluserPwa
            ? rolTranslator(admin.roluserPwa.user_rol)
            : "Sin asignar",
          // area: admin.area ? admin.area.area : "",
          area:"Ayuntamiento",
          // actividad: (
          //   <Link to={`/administradores/actividad/${admin.id}`}>
          //     Ver Actividad
          //   </Link>
          // ),
        };
      }
    }).filter((element) => element !== undefined);
  };

  useEffect(() => {
    if (actualizaAdmins || actualizaUsers) {
      dispatch(getCatalogoUsuarios());
      if (actualizaAdmins) {
        dispatch(noActualizaListaAdmins());
      }
    }
  }, [actualizaAdmins, actualizaUsers]);


  useEffect(() => {
    dispatch(getCatalogoUsuarios());
  }, []);
  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link>Administradores</Link>
            <h3 className="titulo-table-actions">Listado de Usuarios</h3>
          </div>

          <Tabs className="tabs-action-table">
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <Shield /> {"Permisos"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      catalogoUsuarios ? permisosParser(catalogoUsuarios.sort((a,b)=>b.id-a.id)) : []
                    }
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() => setModal(true)}
                    textForCreateNew={"Agregar Admin"}
                    messageWhenEmpty={"No hay administradores registrados"}
                    paginate={true}
                    paginateRows={10}
                  >
                    <ModalEstandar visibilidad={modal} setModal={setModal}>
                      <CrearNuevoAdministrador setModal={setModal} />
                    </ModalEstandar>
                  </TableWithActions>
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <User /> {"Administradores"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columnasCiudadano}
                    data={
                      catalogoUsuarios
                        ? administradorParser(catalogoUsuarios.sort((a,b)=>b.id-a.id))
                        : []
                    }
                    idTableVisualization={true}
                    textForCreateNew={"Agregar Admin"}
                    messageWhenEmpty={"No hay administradores registrados"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modalEdit} setModal={setModalEdit}>
        <EditarAdministrador
          setModal={setModalEdit}
          administrador={administradorAEditar}
        />
      </ModalEstandar>
    </div>
  );
};

export default Administradores;
