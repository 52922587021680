import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, FormControl, InputGroup, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { HelpCircle, ChevronDown, Plus, Trash } from "react-feather";

// acciones
import {
  getPregunta,
  postNuevaPregunta,
  putRespuestas,
} from "../../../redux/actions/preguntasActions";

// selectores
import { preguntaResult } from "../../../redux/selectors/preguntasSelector";

const CrearNuevaPregunta = ({
  setModal,
  preguntas,
  nombreDeEncuesta,
  idPregunta,
}) => {
  const dispatch = useDispatch();
  const preguntaADetalle = useSelector((state) => preguntaResult(state));
  //Hooks
  const [tipo, setTipo] = useState();
  const [pregunta, setPregunta] = useState();
  const [textoDePregunta, setTextoDePregunta] = useState("");
  const [respuestas, setRespuestas] = useState([
    { id: "", etiqueta: "", index: 0 },
  ]);

  //URL param
  let { idEncuesta } = useParams();

  // Este es el esqueleto para renderizar respuestas multiples
  function esqueletoRespuesta(index, etiqueta) {
    return (
      <div
        className="div-opciones-mutiples-respuestas"
        key={index}
        id={`respuesta-${index}`}
      >
        <InputGroup>
          <FormControl
            type="text"
            placeholder="Escribe un mensaje..."
            className="input-opciones-multiples-respuestas"
            defaultValue={etiqueta ? etiqueta : respuestas[index].etiqueta}
          />
        </InputGroup>
        <Plus onClick={() => addRespuesta(index)} />
        <Trash onClick={() => removeRespuesta(index)} />
      </div>
    );
  }

  // Con esta función se inserta una nueva respuesta en el arreglo "respuestas"
  // justo debajo del boton "+" presionado.
  function addRespuesta(index) {
    let children = document.getElementById("bloque-de-respuestas").children;
    let array = [];
    for (let i = 0; i < children.length; i++) {
      array.push({
        id: respuestas[i].id,
        etiqueta: children[i].firstChild.firstChild.value,
        valor: children[i].firstChild.firstChild.value.toString().toLowerCase(),
      });
    }
    array.splice(index + 1, 0, {
      id: "",
      value: "",
      label: "",
    });
    setRespuestas(array);
  }

  // Esta función remueve una respuesta del arreglo "respuestas"
  // ubicado justo donde se presiona el boton
  function removeRespuesta(index) {
    let children = document.getElementById("bloque-de-respuestas").children;
    let array = [];
    for (let i = 0; i < children.length; i++) {
      array.push({
        id: respuestas[i].id,
        etiqueta: children[i].firstChild.firstChild.value,
        valor: children[i].firstChild.firstChild.value.toString().toLowerCase(),
      });
    }
    array.splice(index, 1);
    setRespuestas(array);
  }

  const PreguntaAbierta = () => {
    return (
      <div>
        <label
          htmlFor="input-pregunta-abierta"
          className="labels-crear-nueva-pregunta"
        >
          Pregunta
        </label>
        <InputGroup>
          <FormControl
            type="text"
            name="input-pregunta-abierta"
            id="input-pregunta-abierta"
            placeholder="Escribe un mensaje..."
            onBlur={(event) => setTextoDePregunta(event.target.value)}
            defaultValue={textoDePregunta}
          />
        </InputGroup>
      </div>
    );
  };

  //Pregunta con respuesta multiple tiene la misma estructura inicial que "PreguntaAbierta"
  //Este componente es util para opciones unicas o multiples
  const PreguntaOpcionMultiple = () => {
    return (
      <div>
        {!idPregunta && <PreguntaAbierta />}
        <label className="labels-crear-nueva-pregunta">Opciones</label>
        <section id="bloque-de-respuestas">
          {respuestas &&
            respuestas.map((respuesta, index) =>
              esqueletoRespuesta(index, respuesta.etiqueta)
            )}
        </section>
      </div>
    );
  };

  const handleClickDropdown = (event) => {
    event.preventDefault();
    let pregunta = document.getElementById("dropdown-crear-pregunta-texto");
    pregunta.innerText = event.target.innerText;
    switch (pregunta.innerText) {
      case "Abierta":
        setTipo(1);
        break;
      case "Opción Única":
        setRespuestas([{ id: 0, etiqueta: "" }]);
        setTipo(2);
        break;
      case "Opción Multiple":
        setRespuestas([{ id: 0, etiqueta: "" }]);
        setTipo(3);
        break;
      default:
    }
  };

  //Solicitud POST con JSON generado con Hooks y con iterador de elementos hijos
  const handleGuardar = async function () {
    try {
      if (!pregunta && !textoDePregunta) {throw "El cuerpo de la pregunta no puede ir vacío"}
      let body;
      if (tipo === 2 || tipo === 3) {
        let children = document.getElementById("bloque-de-respuestas").children;
        let res = [];
        for (let i = 0; i < children.length; i++) {
          if (!children[i].firstChild.firstChild.value) {throw "El cuerpo de las respuestas no puede ir vacío"}
          res.push({
            id: respuestas[i].id,
            etiqueta: children[i].firstChild.firstChild.value,
            valor: children[i].firstChild.firstChild.value
              .toString()
              .toLowerCase(),
          });
        }
        body = {
          encuesta: Number(idEncuesta),
          pregunta: textoDePregunta ? textoDePregunta : pregunta.pregunta,
          id: idPregunta,
          tipo: tipo,
          respuestas: JSON.stringify(res),
          indice: pregunta
            ? pregunta.indice
            : preguntas
            ? preguntas.length + 1
            : 1,
        };
      } else {
        body = {
          encuesta: Number(idEncuesta),
          pregunta: textoDePregunta ? textoDePregunta : pregunta.pregunta,
          id: idPregunta,
          tipo: tipo,
          indice: pregunta
            ? pregunta.indice
            : preguntas
            ? preguntas.length + 1
            : 1,
        };
      }
      setModal(false);
      if (idPregunta) {
        dispatch(putRespuestas(body));
      } else {
        dispatch(postNuevaPregunta(body));
      }
    } catch (error) {
      setModal(false)
      Swal.fire({
        title: "Pregunta invalida!",
        text: error,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    if (idPregunta) {
      dispatch(getPregunta(idPregunta));
    }
    setRespuestas("");
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    if (preguntaADetalle) {
      if (preguntaADetalle.respuestas) {
        setRespuestas(preguntaADetalle.respuestas);
      }
      setPregunta(preguntaADetalle);
      if (idPregunta) {
        setTipo(Number(preguntaADetalle.tipo));
      }
    }
  }, [preguntaADetalle]);

  return (
    <div id="contenedor-modal-nueva-pregunta">
      <section id="seccion-datos-nueva-pregunta">
        <p id="titulo-nueva-pregunta">
          {!idPregunta
            ? `Crear Pregunta: ${nombreDeEncuesta}`
            : pregunta
            ? `Editar Pregunta: ${pregunta.pregunta}`
            : ""}
        </p>
        <Form>
          <InputGroup name="estatus-crear-pregunta" id="estatus-crear-pregunta">
            {!idPregunta && (
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-crear-pregunta">
                  <div>
                    <HelpCircle />
                    <p id="dropdown-crear-pregunta-texto">Tipo de Pregunta</p>
                  </div>
                  <ChevronDown />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleClickDropdown}>
                    Opción Única
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleClickDropdown}>
                    Opción Multiple
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </InputGroup>
          {tipo ? (
            tipo === 1 ? (
              <PreguntaAbierta />
            ) : (
              <PreguntaOpcionMultiple />
            )
          ) : (
            ""
          )}
        </Form>
      </section>
      <section id="seccion-botones-nueva-pregunta">
        <button
          onClick={() => setModal(false)}
          id="boton-cancelar-crear-pregunta"
        >
          Cancelar
        </button>
        <button onClick={handleGuardar} id="boton-guardar-crear-pregunta">
          Guardar
        </button>
      </section>
    </div>
  );
};

export default CrearNuevaPregunta;
