import { all } from "redux-saga/effects";
import AuthSaga from "./authSaga";
import EncuestasSaga from "./encuestasSaga";
import PreguntasSaga from "./preguntasSaga";
import PropuestasSaga from "./propuestasSaga";
import AccionesSaga from "./accionesSaga";
import ProyectosSaga from "./proyectosSaga";
import CiudadanosSaga from "./ciudadanosSaga";
import AdministradoresSaga from "./administradoresSaga";
import ConversacionesSaga from "./messagesSaga";
import SolicitudSiacSaga from "./solicitudSiacSaga";
import PoliticasPrivacidadSaga from "./politicasPrivacidadSaga";
import NotificationsSaga from "./notificationsSaga";
import BusquedaSaga from "./busquedaSaga";
import ConfiguracionSaga from "./configuracionSaga";
import TendenciasSaga from "./tendenciasSaga";
import RecuperacionSaga from "./recuperacionSaga";
import ReportesSaga from "./reportesSaga";

/**
 * Redux Sagas sit between the Actions and Reducers listening for "messages"
 */

export default function* rootSaga(params) {
  console.log(" <---------  Sagas index --------->");
  // console.log(params)
  yield all([
    AuthSaga(),
    BusquedaSaga(),
    EncuestasSaga(),
    PreguntasSaga(),
    PropuestasSaga(),
    AccionesSaga(),
    ProyectosSaga(),
    CiudadanosSaga(),
    AdministradoresSaga(),
    ConversacionesSaga(),
    SolicitudSiacSaga(),
    PoliticasPrivacidadSaga(),
    NotificationsSaga(),
    ConfiguracionSaga(),
    TendenciasSaga(),
    RecuperacionSaga(),
    ReportesSaga(),
  ]);
}
