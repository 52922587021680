import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const drawerButton = props => (
    <FontAwesomeIcon
        icon={faBars}
        style={{ cursor: 'pointer' }}
        onClick={props.click}
        
    />
)

export default drawerButton;