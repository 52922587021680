import * as types from '../../consts/actionTypes'

export const getProyectos = (payload) => {
    console.log("Solicitando proyectos...")
    return {
        type: types.GET_PROYECTOS,
        payload
    }
}

export const postProyecto = (payload) => {
    console.log("Creando proyecto...")
    return {
        type: types.POST_PROYECTO,
        payload
    }
}

export const getProyectoDetalle = (payload) => {
    console.log("Solicitando proyecto a detalle...")
    return {
        type: types.GET_PROYECTO_DETALLE,
        payload
    }
}

export const deleteProyecto = (payload) => {
    console.log("Eliminando proyecto...")
    return {
        type: types.DELETE_PROYECTO,
        payload
    }
}

export const putProyecto = (payload) => {
    console.log("Actualizando proyecto...")
    return {
        type: types.PUT_PROYECTO,
        payload
    }
}