import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setMessages } from "../../../../redux/actions/messages";
import { Transform } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: 0,
    marginRight: 0,
    border: "1px solid  var(--searchForm-section-color)",
    borderRadius: "5px",
    marginBottom: "20px",
    width: "fit-content",
    minWidth: "133px",
    boxShadow: "0px 5px 25px rgba(0, 0, 0, 0.1)",
  },
  button: {
    width: "133px",
    marginBottom: "20px",
  },
}));

const checkboxes = {
  meGusta: "Me gusta",
  esComodo: "Es cómodo",
  esBarato: "Es barato",
};

const QuestionnaireMultiply = ({ personInfo, onAnswer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = personInfo;

  const [state, setState] = useState({
    meGusta: true,
    esComodo: false,
    esBarato: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSendAnswer = useCallback(() => {
    const answers = Object.keys(checkboxes)

      .map((key) => {
        if (state[key]) {
          return checkboxes[key];
        }
        return null;
      })
      .filter((v) => v);
    dispatch(setMessages({ message: answers.join(", "), id: String(id) }));
    if (onAnswer) {
      onAnswer();
    }
  }, [dispatch, id, onAnswer, state]);

  return (
    <FormGroup>
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            checked={state.meGusta}
            onChange={handleChange}
            name="meGusta"
            color="primary"
          />
        }
        label="Me gusta"
      />

      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            checked={state.esComodo}
            onChange={handleChange}
            name="esComodo"
            color="primary"
          />
        }
        label="Es cómodo"
      />

      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            checked={state.esBarato}
            onChange={handleChange}
            name="esBarato"
            color="primary"
          />
        }
        value="Es barato"
        label="Es barato"
      />
      <Button
        style={{ textTransform: "none" }}
        onClick={handleSendAnswer}
        className={classes.button}
        variant="contained"
        color="primary"
      >
        Continuar
      </Button>
    </FormGroup>
  );
};

export default QuestionnaireMultiply;
