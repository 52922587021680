import React, { useEffect } from "react";
import { Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBusqueda } from "../../redux/actions/busquedaActions";
import { busquedaResult } from "../../redux/selectors/busquedaSelectors";

//Components
import ActionTable from "../../components/ActionTable/index";

const Busqueda = (props) => {
  const query = props.match.params.query;
  const history = useHistory();
  const dispatch = useDispatch();
  const result = useSelector((state) => busquedaResult(state));

  React.useEffect(() => {
    if (query !== "") {
      dispatch(getBusqueda(query));
    } else {
      history.push("/");
    }
  }, [query, history, dispatch]);

  const columns = ["NOMBRE", "TIPO", "FECHA", "ENLACE"];

  const dataParser = (data) => {
    let arrayDeResultados = [];
    data.forEach((resultado) => {
      if (Array.isArray(resultado.campaign) && resultado.campaign.length > 0) {
        resultado.campaign.map((proyecto) => {
          if (
            !arrayDeResultados.some(
              (elemento) =>
                elemento.tipo === "Proyecto" && elemento.id === proyecto.id
            )
          ) {
            arrayDeResultados.push({
              id: proyecto.id,
              nombre: proyecto.nombre,
              tipo: "Proyecto",
              fecha: proyecto.fecha_creacion.slice(0, 10),
              ruta: (
                <Link to={`/proyectos/detalles/${proyecto.id}`}>Abrir</Link>
              ),
            });
          }
        });
      }
      if (
        Array.isArray(resultado.comunicado) &&
        resultado.comunicado.length > 0
      ) {
        resultado.comunicado.map((accion) => {
          if (
            !arrayDeResultados.some(
              (elemento) =>
                elemento.tipo === "Accion" && elemento.id === accion.id
            )
          ) {
            arrayDeResultados.push({
              id: accion.id,
              nombre: accion.titulo,
              tipo: "Accion",
              fecha: accion.fecha.slice(0, 10),
              ruta: <Link to={`/acciones/detalles/${accion.id}`}>Abrir</Link>,
            });
          }
        });
      }
      if (
        Array.isArray(resultado.propuesta) &&
        resultado.propuesta.length > 0
      ) {
        resultado.propuesta.map((propuesta) => {
          if (
            !arrayDeResultados.some(
              (elemento) =>
                elemento.tipo === "Propuesta" && elemento.id === propuesta.id
            )
          ) {
            arrayDeResultados.push({
              id: propuesta.id,
              nombre: propuesta.titulo,
              tipo: "Propuesta",
              fecha: propuesta.fecha_publicacion.slice(0, 10),
              ruta: <Link to={`/propuestas/${propuesta.id}`}>Abrir</Link>,
            });
          }
        });
      }
    });
    return arrayDeResultados;
  };

  console.log(result, dataParser(result));

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link>Busqueda</Link>
            <h3 className="titulo-table-actions">Listado de la busqueda</h3>
          </div>
        </Container>
      </Row>
      <Row>
        <Container fluid className="container-information">
          <ActionTable
            columns={columns}
            data={result && dataParser(result)}
            paginate={true}
            paginateRows={10}
          />
        </Container>
      </Row>
    </div>
  );
};

export default Busqueda;
