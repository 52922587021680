import * as types from "../../consts/actionTypes";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as Services from "../services/authServices";

export function* getSolicitudSiacSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "solicitudesSIAC/",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Lista de solicitudes requerida satisfactoriamente", results);
    yield put({ type: types.GET_SOLICITUD_SIAC_SUCCESS, results });
  } catch (error) {
    console.log("Error al requerir lista de solicitudes: ", error);
    yield put({ type: types.GET_SOLICITUD_SIAC_ERROR, payload });
  }
}

export function* atenderSolicitudSiacSaga ({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `solicitudesSIAC/${payload}/`,
      {seguimiento: "True"},
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Solicitud atendida satisfactoriamente", results);
    yield put({ type: types.ATENDER_SOLICITUD_SIAC_SUCCESS, results });
  } catch (error) {
    console.log("Error al atender solicitud: ", error);
    yield put({ type: types.ATENDER_SOLICITUD_SIAC_ERROR, payload });
  }
}

export default function* solicitudSiacSaga() {
  yield takeLatest(types.GET_SOLICITUD_SIAC, getSolicitudSiacSaga);
  yield takeLatest(types.ATENDER_SOLICITUD_SIAC, atenderSolicitudSiacSaga);
}
