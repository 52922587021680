import { get } from 'lodash';



export const propuestasResult = (state) => get(state, "PropuestasReducer.propuestas");
export const propuestaADetalleResult = (state) => get(state, "PropuestasReducer.propuestaADetalle");
export const comentariosPropuestasResult = (state) => get(state, "PropuestasReducer.comentarios");
export const debeActualizarComentarios = (state) => get(state, "PropuestasReducer.actualizarComentarios");
export const debeActualizarPropuestasResult = (state) => get(state, "PropuestasReducer.actualizarPropuestas");
export const escalamientosResult = (state) => get(state, "PropuestasReducer.escalamientos");


