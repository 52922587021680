import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormControl, InputGroup, Dropdown, Row, Col } from "react-bootstrap";
import { ChevronDown } from "react-feather";

//actions
import { postRegistroAdministrador } from "../../redux/actions/administradoresActions";

//Selectors
import { catalogoUsuariosResult } from "../../redux/selectors/ciudadanosSelectors";
import { user } from "../../redux/selectors/rootSelectors";

const CrearNuevoAdministrador = ({ setModal }) => {
    const dispatch = useDispatch();
    const [nombreUsuario, setNombreUsuario] = useState();
    const [correo, setCorreo] = useState();
    const [password1, setPassword1] = useState();
    const [password2, setPassword2] = useState();
    const [nombre, setNombre] = useState();
    const [apellido, setApellido] = useState();
    const [run, setRun] = useState();
    const [rol, setRol] = useState("Seleccione Rol");
    const [area, setArea] = useState("GORE");
    const [rolDeUsuario, setRolDeUsuario] = useState();
    const [roles, setRoles] = useState(["SA_pwa", "Adm_pwa", "S_pwa", "G_pwa", "O_pwa"]);
    const catalogoUsuarios = useSelector((state) => catalogoUsuariosResult(state));
    const usuario = useSelector((state) => user(state));
    const areas = [
        "GORE",
        "SENAME",
        "SERNAC",
        "INDAP",
        "SERNATUR",
        "SUBPESCA",
        "SERNAPESCA",
        "DIREC. PROVINCIAL DE EDUCACIÓN TARAPACÁ",
        "DIREC. DEL TRABAJO",
        "SENCE",
        "IPS",
        "DIREC. DE AGUAS",
        "INE",
        "IND",
        "SERNAMEG",
        "CORFO",
        "SERCOTEC",
        "SENADIS",
        "SENAMA",
        "INJUV",
        "FOSIS",
        "CONADI",
        "JUNAEB",
        "JUNJI",
        "SERVIU",
    ];

    const rolTranslator = (rol) => {
        switch (rol) {
            case "SA_pwa":
                return "SUPER-ADMINISTRADOR";
            case "Adm_pwa":
                return "ADMINISTRADOR GENERAL";
            case "S_pwa":
                return "COORDINADOR";
            case "G_pwa":
                return "OPERADOR INTERNO";
            case "O_pwa":
                return "OPERADOR PERIFÉRICO";
            default:
                return false;
        }
    };
    const translatorRol = (rol) => {
        switch (rol) {
            case "SUPER-ADMINISTRADOR":
                return "SA_pwa";
            case "ADMINISTRADOR GENERAL":
                return "Adm_pwa";
            case "COORDINADOR":
                return "S_pwa";
            case "OPERADOR INTERNO":
                return "G_pwa";
            case "OPERADOR PERIFÉRICO":
                return "O_pwa";
            default:
                return false;
        }
    };

    const handleGuardar = () => {
        if (document.getElementById("form-registrar-admin").checkValidity()) {
            let body = {
                username: nombreUsuario,
                email: correo,
                password1: password1,
                password2: password2,
                first_name: nombre,
                last_name: apellido,
                rol: translatorRol(rol),
                area: area,
            };
            dispatch(postRegistroAdministrador(body));
            setModal(false);
        } else {
            document.getElementById("form-registrar-admin").reportValidity();
        }
    };

    useEffect(() => {
        if (catalogoUsuarios) {
            catalogoUsuarios.forEach((usuarioOAdmin) => {
                if (usuarioOAdmin.id === usuario.user.id) {
                    setRolDeUsuario(usuarioOAdmin.roluserPwa.user_rol);
                }
            });
        }
    }, [catalogoUsuarios]);

    useEffect(() => {
        if (rolDeUsuario) {
            switch (rolDeUsuario) {
                case "SA_pwa":
                    setRoles(["SA_pwa", "Adm_pwa", "S_pwa", "G_pwa", "O_pwa"]);
                    break;
                case "Adm_pwa":
                    setRoles(["S_pwa", "G_pwa", "O_pwa"]);
                    break;
                case "S_pwa":
                    setRoles(["S_pwa", "G_pwa", "O_pwa"]);
                    break;
                case "G_pwa":
                    setRoles(["O_pwa"]);
                    break;
                case "O_pwa":
                    setRoles(["O_pwa"]);
                    break;
                default:
            }
        }
    }, [rolDeUsuario]);

    return (
        <div id="contenedor-modal-nueva-encuesta">
            <section id="seccion-datos-nueva-encuesta">
                <p id="titulo-nueva-encuesta">Agregar Administrador</p>
                <Form id="form-registrar-admin">
                    <Row>
                        <Col>
                            <label htmlFor="input-nombre-de-encuesta" className="labels-crear-nueva-encuesta">
                                Nombre
                            </label>
                            <InputGroup>
                                <FormControl
                                    required
                                    type="text"
                                    name="input-nombre-de-encuesta"
                                    placeholder="Ingresa Nombre"
                                    className="input-agregar-administrador"
                                    onChange={(event) => setNombre(event.target.value)}
                                    defaultValue={nombre}
                                    required={true}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <label htmlFor="input-nombre-de-encuesta" className="labels-crear-nueva-encuesta">
                                Apellido
                            </label>
                            <InputGroup>
                                <FormControl
                                    required
                                    type="text"
                                    name="input-nombre-de-encuesta"
                                    placeholder="Ingresa Apellido"
                                    className="input-agregar-administrador"
                                    onChange={(event) => setApellido(event.target.value)}
                                    defaultValue={apellido}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="input-nombre-de-encuesta" className="labels-crear-nueva-encuesta">
                                RUN
                            </label>
                            <InputGroup>
                                <FormControl
                                    required
                                    type="text"
                                    name="input-nombre-de-encuesta"
                                    placeholder="Ingresa Nombre de Usuario"
                                    className="input-agregar-administrador"
                                    minLength="9"
                                    maxLength="10"
                                    pattern="(\d{7,8}[-]\w)"
                                    onChange={(event) => setNombreUsuario(event.target.value)}
                                    defaultValue={nombreUsuario}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <label htmlFor="input-nombre-de-encuesta" className="labels-crear-nueva-encuesta">
                                Correo Institucional
                            </label>
                            <InputGroup>
                                <FormControl
                                    required
                                    type="email"
                                    name="input-nombre-de-encuesta"
                                    placeholder="Ingresa Correo"
                                    className="input-agregar-administrador"
                                    onChange={(event) => setCorreo(event.target.value)}
                                    defaultValue={correo}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="input-nombre-de-encuesta" className="labels-crear-nueva-encuesta">
                                Contraseña
                            </label>
                            <InputGroup>
                                <FormControl
                                    required
                                    type="password"
                                    name="input-nombre-de-encuesta"
                                    placeholder="Ingresa Contraseña"
                                    className="input-agregar-administrador"
                                    onChange={(event) => setPassword1(event.target.value)}
                                    defaultValue={password1}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <label htmlFor="input-nombre-de-encuesta" className="labels-crear-nueva-encuesta">
                                Repita Contraseña
                            </label>
                            <InputGroup>
                                <FormControl
                                    required
                                    type="password"
                                    name="input-nombre-de-encuesta"
                                    placeholder="Ingresa Contraseña de nuevo"
                                    className="input-agregar-administrador"
                                    onChange={(event) => setPassword2(event.target.value)}
                                    defaultValue={password2}
                                />
                            </InputGroup>
                            {password1 !== password2 && <p className="p-password-no-coincide-crear-administrador">Las contraseñas no coinciden</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="fecha-de-terminacion-de-encuesta" className="labels-crear-nueva-encuesta">
                                Rol
                            </label>
                            <InputGroup>
                                <Dropdown className="dropdown-agregar-administrador">
                                    <Dropdown.Toggle variant="success">
                                        <p>{rol}</p>
                                        <ChevronDown />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {roles.map((nombreRol) => (
                                            <Dropdown.Item onClick={() => setRol(rolTranslator(nombreRol))}>{rolTranslator(nombreRol)}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="estatus-crear-encuesta" className="labels-crear-nueva-encuesta">
                                Área
                            </label>
                            <InputGroup name="estatus-crear-encuesta" id="estatus-crear-encuesta">
                                <Dropdown className="dropdown-agregar-administrador">
                                    <Dropdown.Toggle variant="success">
                                        <p>{area}</p>
                                        <ChevronDown />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-area-agregar-admin">
                                        {areas.map((nombreArea) => (
                                            <Dropdown.Item onClick={() => setArea(nombreArea)}>{nombreArea}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </section>
            <section id="seccion-botones-nueva-encuesta">
                <button onClick={() => setModal(false)} id="boton-cancelar-crear-encuesta">
                    Cancelar
                </button>
                <button onClick={handleGuardar} id="boton-guardar-crear-encuesta">
                    Guardar
                </button>
            </section>
        </div>
    );
};

export default CrearNuevoAdministrador;
