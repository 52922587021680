import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Row,
  Container,
  Col,
} from "react-bootstrap";
import { Inbox, Archive, ExternalLink, CheckCircle, User } from "react-feather";
import XCircleRed from "../../assets/Iconos especiales/x-circle-red.svg";
import CheckCircleGreen from "../../assets/Iconos especiales/check-green.svg";
import { Link, useHistory } from "react-router-dom";
import TableWithActions from "../../components/ActionTable/index";
import "./Ciudadanos.css";

//Actions
import {
  getCatalogoUsuarios,
  validarUsuario,
} from "../../redux/actions/ciudadanosActions";
import { createConvesation } from "../../redux/actions/messages";

//Selectors
import {
  catalogoUsuariosResult,
  updateCatalogoUsuariosResult,
} from "../../redux/selectors/ciudadanosSelectors";

const columns = [
  "DNI",
  "NOMBRE COMPLETO",
  "FECHA DE NACIMIENTO",
  "CORREO",
  "CLAVE ÚNICA",
  "RECHAZAR",
  "ACEPTAR",
];

const columnasCiudadano = [
  "ID",
  "DNI",
  "FDN",
  "NOMBRE COMPLETO",
  "CORREO ELECTRÓNICO",
  "GÉNERO",
  "DISTRITO",
  "Acción",
];

const Ciudadanos = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const usuarios = useSelector((state) => catalogoUsuariosResult(state));
  const actualizaCatalogoUsuarios = useSelector((state) =>
    updateCatalogoUsuariosResult(state)
  );
  const conversationsState = useSelector((state) => state.MessagesReducer);
  const { idConversacion } = conversationsState;
  const [irAConversacion, setIrAConversacion] = useState(false);


  const validacionesParser = (data) => {
    return data.map((ciudadano) => {
      if (
        (ciudadano.roluserPwa
          ? ciudadano.roluserPwa.user_rol === "C_pwa"
          : false) &&
        ciudadano.sites_for_user === null
      ) {
        return {
          id: ciudadano.id,
          rut: ciudadano.username,
          nombre: ciudadano.first_name + " " + ciudadano.last_name,
          fechaNacimiento: ciudadano.fecha_nacimiento,
          correo: ciudadano.email,
          claveUnica: (
            <Link className="opciones-verificar-aceptar-rechazar-validaciones">
              <p>Verificar</p>
              <ExternalLink />
            </Link>
          ),
          rechazar: (
            <div className="opciones-verificar-aceptar-rechazar-validaciones">
              <p>Rechazar</p>
              <img src={XCircleRed} />
            </div>
          ),
          aceptar: (
            <div
              className="opciones-verificar-aceptar-rechazar-validaciones"
              onClick={() => handleAceptar(ciudadano.id)}
            >
              <p>Aceptar</p>
              <img src={CheckCircleGreen} />
            </div>
          ),
        };
      }
    }).filter((element) => element !== undefined);
  };

  const ciudadanosParser = (data) => {
    return data.map((ciudadano) => {
      if (
        (ciudadano.roluserPwa
          ? ciudadano.roluserPwa.user_rol === "C_pwa"
          : false) &&
        ciudadano.sites_for_user !== null
      ) {
        return {
          id: ciudadano.id,
          rut: ciudadano.username,
          fechaNacimeinto: ciudadano.fecha_nacimiento,
          nombre: ciudadano.first_name + " " + ciudadano.last_name,
          correo: ciudadano.email,
          genero: ciudadano.genero,
          comuna: ciudadano.region,
          acción: (
            <p className="texto-abrir-chat-ciudadanos"
              onClick={() => {
                dispatch(
                  createConvesation({
                    conversation_username: ciudadano.username,
                  })
                );
                setIrAConversacion(true);
              }}
            >
              <p>Abrir chat</p>
            </p>
          ),
        };
      }
    }).filter((element) => element !== undefined);
  };


  const handleAceptar = (idUsuario) => {
    console.log(idUsuario);
    dispatch(validarUsuario({ user: idUsuario, sitio: [4] }));
  };

  useEffect(() => {
    if (irAConversacion && idConversacion) {
      history.push(`/conversacion/${idConversacion}`);
    }
  }, [idConversacion]);

  useEffect(() => {
    dispatch(getCatalogoUsuarios());
  }, []);

  useEffect(() => {
    if (actualizaCatalogoUsuarios) {
      dispatch(getCatalogoUsuarios());
    }
  }, [actualizaCatalogoUsuarios]);


  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link>Ciudadanos</Link>
            <h3 className="titulo-table-actions">Listado de Usuarios</h3>
          </div>

          <Tabs className="tabs-action-table">
            {/* <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <CheckCircle /> {"Validaciones"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={usuarios ? validacionesParser(usuarios) : []}
                    messageWhenEmpty={"No hay ciudadanos pendientes de validar"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab> */}
            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <User /> {"Ciudadanos"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columnasCiudadano}
                    data={usuarios ? ciudadanosParser(usuarios) : []}
                    idTableVisualization={true}
                    searchBar={true}
                    messageWhenEmpty={"No hay ciudadanos pendientes de validar"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
    </div>
  );
};

export default Ciudadanos;
