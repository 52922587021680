import { get } from 'lodash';

export const encuestaResult = (state) => get(state, "EncuestasReducer.encuesta");
export const encuestasResult = (state) => get(state, "EncuestasReducer.encuestas");
export const encuestasVencidasResult = (state) => get(state, "EncuestasReducer.encuestasVencidas");
export const updateEncuestasState = (state) => get(state, "EncuestasReducer.updateEncuestas");
export const listaDeUsuariosQueRespondieronResult = (state) => get(state, "EncuestasReducer.listaDeUsuariosQueRespondieron");
export const respuestasDeUsuarioResult = (state) => get(state, "EncuestasReducer.respuestasDeUsuario");
export const estadisticasDeEncuestasResult = (state) => get(state, "EncuestasReducer.estadisticasDeEncuestas");
export const encuestaCreadaResult = (state) => get(state, "EncuestasReducer.encuestaCreada");




