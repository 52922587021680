import * as types from "../../consts/actionTypes";

export const resetResultadosReportes = (payload) => {
  return {
    type: types.RESET_RESULTADOS_REPORTES,
    payload,
  };
};

export const getReportesTemasCalientes = (payload) => {
  return {
    type: types.GET_REPORTES_TEMAS_CALIENTES,
    payload,
  };
};

export const getReportesPropuestas = (payload) => {
  return {
    type: types.GET_REPORTES_PROPUESTAS,
    payload,
  };
};

export const getReportesSolicitudes = (payload) => {
  return {
    type: types.GET_REPORTES_SOLICITUDES,
    payload,
  };
};

export const getReportesComentarios = (payload) => {
  return {
    type: types.GET_REPORTES_COMENTARIOS,
    payload,
  };
};

export const getReportesPropuestasEscalables = (payload) => {
  return {
    type: types.GET_REPORTES_PROPUESTAS_ESCALABLES,
    payload,
  };
};

export const getReportesTiemposRevision = (payload) => {
  return {
    type: types.GET_REPORTES_TIEMPOS_REVISION,
    payload,
  };
};

export const getReportesMapaCalor = (payload) => {
  return {
    type: types.GET_REPORTES_MAPA_CALOR,
    payload,
  };
};

export const getReportesInteracciones = (payload) => {
  return {
    type: types.GET_REPORTES_INTERACCIONES,
    payload,
  };
};

export const getReportesTablero = (payload) => {
  return {
    type: types.GET_REPORTES_TABLERO,
    payload,
  };
};
