import React from "react";
import { makeStyles } from "@material-ui/core";
import PDFIcon from "../../../assets/Iconos especiales/pdf.png";
import generalUrl from "../../../consts/GeneralUrl";

const useStyles = makeStyles(() => ({
  message: {
    color: "white",
    backgroundColor: "var(--chat-main-color)",
    borderRadius: "5px",
    padding: "20px",
    maxWidth: "350px",
    // wordBreak: "break-all",
    // Para DEMO pero no hace sentido el proque lo tiene
  },
  response: {
    color: "black",
    backgroundColor: "rgba(0,0,0,0.2)",
    borderRadius: "5px",
    padding: "20px",
    maxWidth: "350px",
    // wordBreak: "break-all",
  },
  imageWrapper: {
    justifyContent: "center",
    display: "flex",
    margin: "10px 0 10px 0",
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "5px",
    maxHeight: "150px",
    width: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageWrapperReverse: {
    justifyContent: "center",
    display: "flex",
    margin: "10px 0 10px 0",
    backgroundColor: "var(--chat-main-color)",
    padding: "5px",
    borderRadius: "5px",
    maxHeight: "150px",
    width: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageRender: {
    objectFit: "cover",
    width: "100%",
  },
  pdfRender: {
    backgroundColor: "inherit",
    border: "none",
    outline: "none",
    textDecoration: "underline",
    cursor: "pointer",
    color: "var(--chat-main-color)",
    fontSize: "14px",
    wordWrap: "break-word",
  },
  pdfRenderReverse: {
    backgroundColor: "inherit",
    border: "none",
    outline: "none",
    textDecoration: "underline",
    cursor: "pointer",
    color: "white",
    fontSize: "14px",
    wordWrap: "break-word",
  },
  pdfWrapper: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "20px",
    width: "100%",
    margin: "10px 0 10px 0",
  },
  pdfWrapperReverse: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "var(--chat-main-color)",
    borderRadius: "5px",
    padding: "20px",
    width: "100%",
    margin: "10px 0 10px 0",
  },
  pdfIcon: {
    height: "50px",
    width: "auto",
  },
  datosUsuarioActual: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  datosUsuarioOtro: {
    display: "flex",
    flexFlow: "row-reverse",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fecha: {
    marginBottom: "0",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "15px",
    color: "#325897",
    marginRight: "8px",
  },
  nombre: {
    marginBottom: "0",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "15px",
    marginRight: "8px",
  },
  imagenAvatar: {
    padding: "5px",
    width: "40px",
  },
}));

const MessagesRender = ({
  item,
  type,
  first_name,
  last_name,
  avatar,
  user,
  time,
  has_image,
  has_pdf,
}) => {
  const classes = useStyles();

  const handleOpenPdf = () => {
    window.open(item.file.file);
  };

  const getFileName = (file) => {
    const split = file.split("/");
    const name =
      split[split.length - 1].length > 15
        ? `${split[split.length - 1].substring(0, 15)}...`
        : split[split.length - 1];
    return name;
  };

  const parserDeFecha = (string) => {
    if (string) {
      let fecha = new Date(string.slice(0, 19));
      const nombreDeMes = (num) => {
        switch (num) {
          case 0:
            return "enero";
          case 1:
            return "febrero";
          case 2:
            return "marzo";
          case 3:
            return "abril";
          case 4:
            return "mayo";
          case 5:
            return "junio";
          case 6:
            return "julio";
          case 7:
            return "agosto";
          case 8:
            return "septiembre";
          case 9:
            return "octubre";
          case 10:
            return "noviembre";
          case 11:
            return "diciembre";
          default:
        }
      };
      return `${fecha.getDate()} de ${nombreDeMes(
        fecha.getMonth()
      )} de ${fecha.getFullYear()} a las ${
        fecha.getHours() > 9 ? fecha.getHours() : "0" + fecha.getHours()
      }:${
        fecha.getMinutes() > 9 ? fecha.getMinutes() : "0" + fecha.getMinutes()
      }`;
    }
  };

  return (
    <div className={user ? "div-mis-mensajes-chat" : "div-otros-mensajes-chat"}>
      <div className={user ? "mis-mensajes-chat" : "otros-mensajes-chat"}>
        {/* <div className={user ? classes.message : classes.response}> */}
        {item && (
          <div>
            {has_image && (
              <div
                className={
                  user ? classes.imageWrapper : classes.imageWrapperReverse
                }
                onClick={() => window.open(item.file.file)}
              >
                <img
                  className={classes.imageRender}
                  src={item.file.file}
                  alt="img"
                />
              </div>
            )}

            {has_pdf && (
              <div
                className={
                  user ? classes.pdfWrapper : classes.pdfWrapperReverse
                }
              >
                <img className={classes.pdfIcon} src={PDFIcon} />
                <button
                  className={
                    user ? classes.pdfRender : classes.pdfRenderReverse
                  }
                  onClick={() => handleOpenPdf(item.file.file)}
                  src={item.content}
                  alt="pdf"
                >
                  {getFileName(item.file.file)}
                </button>
              </div>
            )}
            <div>
              <p>{item.content}</p>
            </div>
          </div>
        )}
      </div>
      <div
        className={user ? classes.datosUsuarioActual : classes.datosUsuarioOtro}
      >
        <p className={classes.fecha}>{parserDeFecha(time)}</p>
        <div className="div-nombre-chat">
          <p className={classes.nombre}>{user ? null : first_name}</p>
          <p className={classes.nombre}>{user ? null : last_name}</p>
        </div>
        <img
          className={classes.imagenAvatar}
          src={"https://api.participacion.management-r.com/display-avatar/" + avatar}
        />
      </div>
    </div>
  );
};

export default MessagesRender;
