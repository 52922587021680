import { put, call, takeLatest,takeEvery, take, race, delay, fork } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from '../services/authServices'

export function* login({ payload }) {
  try {
    const tokens = yield call(
      apiCall,
      "sso-client/login-admin/",
      {
        username: payload.username,
        password: payload.password,
      },
      {
        "Content-Type": "application/json",
      },
      "POST"
    );
    
    yield call(Services.setAccessToken, tokens.data.access)
    yield call(Services.setRefreshToken, tokens.data.refresh)
    yield call( getUser, tokens.data.access )
    yield put({ type: types.LOGIN_SUCCESS, tokens });
  } catch (e) {
    yield put({ type: types.LOGIN_ERROR, e });
    console.log(e);
  }
}

export function* verifyToken({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/verify-token/",
      {
        token: Services.getAccessToken(),
      },
      {
        "Content-Type": "application/json",
        // MensajeOnError: "La sesión ha expirado",
      },
      "POST"
    );
      console.log("Verficando ...")
    if (results.data.token === "is valid"){
      yield put({ type: types.VALID_TOKEN, results })
    } else{
      let refreshToken = yield call(Services.getRefreshToken)
      yield fork(refreshNewToken, refreshToken);
    }
      
      
  } catch (e) {
    yield put({ type: types.INVALID_TOKEN, e });
    console.log(e.request);
  }
}

export function* refreshNewToken(tokenRefresh) {
  console.log("refrescando...")
  console.log(tokenRefresh)
  try {
    const newToken = yield call(
      apiCall,
      "sso-client/refresh/",
      {
        refresh_token: tokenRefresh,
      },
      {
        Authorization: "",
        "Content-Type": "application/json",
      },
      "POST"
    );

    yield call(Services.setAccessToken, newToken.data.access)
    yield put({ type: types.REFRESH_TOKEN_SUCCESS, newToken });
  } catch (e) {
    yield put({ type: types.LOGOUT, e });
    console.log(e);
  }
}

function* getUser(accessToken) {
  console.log(accessToken)
  try {
    const userInfo = yield call(
      apiCall,
      "sso-client/info/",
      {},
      {
        "Authorization": "Bearer " + accessToken
      },
      "GET"
    )

    console.log(userInfo)
    
    yield call(Services.setUser, userInfo.data[0])
    yield put({type: types.GET_USER_INFO_SUCCESS, userInfo})
  } catch (error) {
    yield put({type: types.GET_REQUEST_ERROR, error})
    console.log(error)
  }

}
function* refreshUserSaga() {
  try {
    const userInfo = yield call(
      apiCall,
      "sso-client/info/",
      {},
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield call(Services.setUser, userInfo.data[0]);
    yield put({ type: types.GET_USER_INFO_SUCCESS, userInfo });
  } catch (error) {
    yield put({ type: types.GET_REQUEST_ERROR, error });
    console.log(error);
  }
}

export default function* authSaga() {
  yield takeLatest(types.LOGIN, login);
  yield takeEvery(types.VERIFY_TOKEN, verifyToken);
  yield takeLatest(types.REFRESH_TOKEN, refreshNewToken);
  yield takeLatest(types.REFRESH_USER, refreshUserSaga);
}