import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from "../services/authServices";

export function* getProyectosSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "comunicados/campaigns/",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Proyectos solicitadas satisfactoriamente");
    yield put({ type: types.GET_PROYECTOS_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar proyectos :", e);
    yield put({ type: types.GET_PROYECTOS_ERROR, payload });
  }
}

export function* postProyectoSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "comunicados/campaigns/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Proyecto creado satisfactoriamente");
    yield put({ type: types.POST_PROYECTO_SUCCESS, results });
  } catch (e) {
    console.log("Error al crear proyecto :", e);
    yield put({ type: types.POST_PROYECTO_ERROR, payload });
  }
}

export function* getProyectoDetalleSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `comunicados/campaigns/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Proyecto a detalles solicitado satisfactoriamente");
    yield put({ type: types.GET_PROYECTO_DETALLE_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar proyecto a detalle :", e);
    yield put({ type: types.GET_PROYECTO_DETALLE_ERROR, payload });
  }
}

export function* deleteProyectoSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `comunicados/campaigns/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    console.log("Proyecto eliminado satisfactoriamente");
    yield put({ type: types.DELETE_PROYECTO_SUCCESS, results });
  } catch (e) {
    console.log("Error al eliminar proyecto :", e);
    yield put({ type: types.DELETE_PROYECTO_ERROR, payload });
  }
}

export function* putProyectoSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `comunicados/campaigns/${payload.id}/`,
      payload.body,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Proyecto actualizado satisfactoriamente");
    yield put({ type: types.PUT_PROYECTO_SUCCESS, results });
  } catch (e) {
    console.log("Error al actualizar proyecto :", e);
    yield put({ type: types.PUT_PROYECTO_ERROR, payload });
  }
}

export default function* accionesSaga() {
  yield takeLatest(types.GET_PROYECTOS, getProyectosSaga);
  yield takeLatest(types.POST_PROYECTO, postProyectoSaga);
  yield takeLatest(types.GET_PROYECTO_DETALLE, getProyectoDetalleSaga);
  yield takeLatest(types.DELETE_PROYECTO, deleteProyectoSaga);
  yield takeLatest(types.PUT_PROYECTO, putProyectoSaga);
}
