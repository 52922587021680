import React, { useState, useEffect } from "react";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import "./ModalPublicaciones.css";

const ModalPublicaciones = ({ visibilidad, setModal, publicaciones, titulo }) => {
  return (
    <ModalEstandar className="modal" visibilidad={visibilidad} setModal={setModal}>
      <div className="modal-publicaciones">
        <h4>{titulo}</h4>
        {/* <Tabs>
          <Tab
            eventKey={1}
            title={
             titulo
            }
          > */}
        <div className="table-modal">
          <Row className={"headers-row"}>
            {publicaciones &&
              publicaciones.headers.map((header) => {
                return <Col className={"columnas header"}><p className="header-text">{header}</p></Col>;
              })}
          </Row>

          {publicaciones &&
            publicaciones.modalData.map((data) => {
              return (
                <Row>
                  {Object.values(data).map((column) => (
                    <Col className={"columnas"}><p className="data-text">{column}</p></Col>
                  ))}
                </Row>
              );
            })}

          {/* </Tab>
        </Tabs> */}
        </div>
      </div>
    </ModalEstandar>
  );
};

export default ModalPublicaciones;
