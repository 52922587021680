import * as types from "../../consts/actionTypes";
const initialState = {
  successFlag: undefined,
  avatares: [],
  porcentajeApoyo: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CAMBIAR_PASSWORD_SUCCESS:
      return {
        ...state,
        successFlag: true,
      };
    case types.CAMBIAR_PASSWORD_ERROR:
      return {
        ...state,
        successFlag: false,
      };
    case types.RESET_SUCCESS_FLAG:
      return {
        ...state,
        successFlag: undefined,
      };
    case types.GET_AVATARES_SUCCESS:
      console.log("Avatares guardados en estado avatares");
      return {
        ...state,
        successFlag: true,
        avatares: action.results.data.img,
      };
    case types.GET_AVATARES_ERROR:
      return {
        ...state,
        successFlag: false,
      };
    case types.ACTUALIZAR_AVATAR_SUCCESS:
      console.log("successFlag a true");
      return {
        ...state,
        successFlag: true,
      };
    case types.ACTUALIZAR_AVATAR_ERROR:
      return {
        ...state,
        successFlag: false,
      };
    case types.CAMBIAR_EMAIL_SUCCESS:
      return {
        ...state,
        successFlag: true,
      };
    case types.CAMBIAR_EMAIL_ERROR:
      return {
        ...state,
        successFlag: false,
      };
    case types.TOGGLE_NOTIFICACIONES_EMAIL_SUCCESS:
      return {
        ...state,
        successFlag: true,
      };
    case types.TOGGLE_NOTIFICACIONES_EMAIL_ERROR:
      return {
        ...state,
        successFlag: false,
      };
    case types.GET_PORCENTAJE_APOYOS_SUCCESS:
      return {
        ...state,
        porcentajeApoyo: action.results.data,
      };
    case types.GET_PORCENTAJE_APOYOS_ERROR:
      return {
        ...state,
      };
    case types.PUT_PORCENTAJE_APOYOS_SUCCESS:
      return {
        ...state,
      };
    case types.PUT_PORCENTAJE_APOYOS_ERROR:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
}
