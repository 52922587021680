import React, { useEffect, useState } from "react";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
  Dropdown,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Inbox, Archive, PieChart } from "react-feather";
import TableWithActions from "../../components/ActionTable/index";
import { Link, useHistory } from "react-router-dom";
import Acordeon from "../../components/reusable/Acordeon/Acordeon";
import Estadisticas from "./Estadisticas";
//Actions
import { getRespuestasDeUsuario } from "../../redux/actions/encuestasActions";

//Styles
import "./Respuestas.css";

//Selector

import {
  encuestasResult,
  listaDeUsuariosQueRespondieronResult,
  estadisticasDeEncuestasResult,
} from "../../redux/selectors/encuestasSelectors";

const columns = ["ID USUARIO", "FECHA RESPUESTA", "ACCION"];

const encuestasFake = ["Encuesta 2", "Encuesta 3", "Encuesta 4", "Encuesta 5"];

const Respuestas = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const listaDeUsuarios = useSelector((state) =>
    listaDeUsuariosQueRespondieronResult(state)
  );
  const datosDeEncuestas = useSelector((state) => encuestasResult(state));
  const estadisticasDeEncuestas = useSelector((state) =>
    estadisticasDeEncuestasResult(state)
  );
  const [lista, setLista] = useState();
  const [estadisticas, setEstadisticas] = useState();

  const parserListaDeUsuarios = (encuesta) => {
    if (encuesta.listado) {
      return encuesta.listado.map((usuario) => {
        return {
          id: usuario.idUsuario,
          fecha: usuario.fecha_creacion,
          accion: (
            <Link
              to={`/respuestas/detalles/${encuesta.nombre}/${
                usuario.idUsuario
              }/${usuario.fecha_creacion.slice(11, 16)}`}
              onClick={() => {
                let body = {
                  encuesta: encuesta.id,
                  usuario: usuario.idUsuario,
                };
                dispatch(getRespuestasDeUsuario(body));
              }}
            >
              Ver respuestas
            </Link>
          ),
        };
      }).filter((element) => element !== undefined);
    }
  };

  const borrarRespuesta = (arrayDeIds) => {
    console.log(arrayDeIds);
  };

  useEffect(() => {
    if (estadisticasDeEncuestas) {
      setEstadisticas(estadisticasDeEncuestas);
    }
  }, [estadisticasDeEncuestas]);

  useEffect(() => {
    if (listaDeUsuarios) {
      let array = [...listaDeUsuarios];
      array.forEach((encuesta) => {
        datosDeEncuestas.forEach((objeto) => {
          if (Number(objeto.id) === Number(encuesta.id)) {
            encuesta.nombre = objeto.nombre;
          }
        });
      });
      setLista(array);
    }
  }, [listaDeUsuarios]);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link to="/votaciones">Regresar a Votaciones</Link>
            <h3>
              Respuestas:{" "}
              {lista
                ? lista.length > 1
                  ? "Multiples Encuestas"
                  : lista[0].nombre
                : ""}
            </h3>
          </div>

          <Tabs className="tabs-action-table">
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table tabs-mobile-3">
                  <PieChart /> {"Estadísticas"}
                </span>
              }
            >
              {estadisticas &&
                (estadisticas.length > 1 ? (
                  estadisticas.map((encuesta, index) => {
                    console.log(encuesta)
                    return (
                      <Acordeon titulo={encuesta.encuesta} key={index}>
                        <Estadisticas encuesta={encuesta} />
                      </Acordeon>
                    );
                  })
                ) : (
                  <Estadisticas encuesta={estadisticas[0]} />
                ))}
            </Tab>
            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table tabs-mobile-3">
                  <Inbox /> {"Activos"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  {lista &&
                    (lista.length > 1 ? (
                      lista.map((encuesta, index) => {
                        return (
                          <Acordeon titulo={encuesta.nombre} key={index}>
                            <TableWithActions
                              columns={columns}
                              data={parserListaDeUsuarios(encuesta)}
                              idTableVisualization={true}
                              messageWhenEmpty={
                                "Encuesta sin respuestas activas"
                              }
                              searchBar={true}
                            />
                          </Acordeon>
                        );
                      })
                    ) : (
                      <TableWithActions
                        columns={columns}
                        data={parserListaDeUsuarios(lista[0])}
                        idTableVisualization={true}
                        messageWhenEmpty={"Encuesta sin respuestas activas"}
                      />
                    ))}
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
    </div>
  );
};

export default Respuestas;
