import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from "../services/authServices"

export function* getPoliticasPrivacidadSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "politicas/1/",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        // Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Politicas de privacidad solicitadas satisfactoriamente");
    yield put({ type: types.GET_LIST_POLITICAS_PRIVACIDAD_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar Politicas de privacidad :", e);
    yield put({ type: types.GET_LIST_POLITICAS_PRIVACIDAD_ERROR, payload });
  }
}

export function* putPoliticasPrivacidadSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `politicas/${payload.id}/`,
      {politicas:payload.body},
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Politicas de privacidad actualizadas satisfactoriamente");
    yield put({ type: types.PUT_POLITICAS_PRIVACIDAD_SUCCESS, results });
    yield put({type:types.REFRESH_USER});
  } catch (e) {
    console.log("Error al actualizar Politicas de privacidad:", e);
    yield put({ type: types.PUT_POLITICAS_PRIVACIDAD_ERROR, payload });
  }
}

export default function* accionesSaga() {
    yield takeLatest(types.GET_LIST_POLITICAS_PRIVACIDAD, getPoliticasPrivacidadSaga);
    yield takeLatest(types.PUT_POLITICAS_PRIVACIDAD, putPoliticasPrivacidadSaga);
  }