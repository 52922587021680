import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
} from "react-bootstrap";
import { Inbox, Archive } from "react-feather";
import TableWithActions from "../../components/ActionTable/index";

//Actions
import { createConvesation } from "../../redux/actions/messages";
import {
  atenderSolicitudSiac,
  getSolicitudSiac,
} from "../../redux/actions/solicitudSiacActions";
import { getCatalogoUsuarios } from "../../redux/actions/ciudadanosActions";

//Selectors
import {
  usuarioSolicitudResult,
  AtenderSolicitudResult,
} from "../../redux/selectors/solicitudSiacSelectors";
import { catalogoUsuariosResult } from "../../redux/selectors/ciudadanosSelectors";

const columns = ["NOMBRE", "FECHA", "SEGUIMIENTO", "CHAT"];

const Solicitudes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [irAConversacion, setIrAConversacion] = useState(false);
  const [idAtencion, setIdAtencion] = useState();
  const conversationsState = useSelector((state) => state.MessagesReducer);
  const usuarios = useSelector((state) => usuarioSolicitudResult(state));
  const respuestaAtender = useSelector((state) =>
    AtenderSolicitudResult(state)
  );
  const catalogoUsuarios = useSelector((state) =>
    catalogoUsuariosResult(state)
  );
  const { idConversacion } = conversationsState;

  const getUsernameById = (id) => {
    let username;
    catalogoUsuarios.forEach((personita) => {
      if (Number(personita.id) === Number(id)) {
        username = personita.username;
      }
    });
    return username;
  };

  const getPersonNameById = (id) => {
    let nombre;
    catalogoUsuarios.forEach((personita) => {
      if (Number(personita.id) === Number(id)) {
        nombre = personita.first_name + " " + personita.last_name;
      }
    });
    return nombre;
  };

  const parserDeFecha = (string) => {
    if (string) {
      let fecha = new Date(string.slice(0, 19));
      const nombreDeMes = (num) => {
        switch (num) {
          case 0:
            return "enero";
          case 1:
            return "febrero";
          case 2:
            return "marzo";
          case 3:
            return "abril";
          case 4:
            return "mayo";
          case 5:
            return "junio";
          case 6:
            return "julio";
          case 7:
            return "agosto";
          case 8:
            return "septiembre";
          case 9:
            return "octubre";
          case 10:
            return "noviembre";
          case 11:
            return "diciembre";
          default:
        }
      };
      return `${fecha.getDate()} de ${nombreDeMes(
        fecha.getMonth()
      )} de ${fecha.getFullYear()} a las ${
        fecha.getHours() > 9 ? fecha.getHours() : "0" + fecha.getHours()
      }:${
        fecha.getMinutes() > 9 ? fecha.getMinutes() : "0" + fecha.getMinutes()
      }`;
    }
  };

  const dataParser = (data) => {
    return data
      .sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime())
      .map((usuario) => {
        return {
          nombre: getPersonNameById(usuario.usuario),
          fecha: parserDeFecha(usuario.fecha),
          seguimiento: usuario.seguimiento ? "Atendido" : "Sin atender",
          acción: (
            <p
              className="texto-abrir-chat-ciudadanos"
              onClick={() => {
                dispatch(
                  createConvesation({
                    conversation_username: getUsernameById(usuario.usuario),
                  })
                );
                setIrAConversacion(true);
                setIdAtencion(usuario.id);
              }}
            >
              <Link>Abrir chat</Link>
            </p>
          ),
        };
      })
      .filter((element) => element !== undefined);
  };

  useEffect(() => {
    if (
      irAConversacion &&
      idConversacion &&
      respuestaAtender &&
      respuestaAtender.status === 200
    ) {
      history.push(`/conversacion/${idConversacion}`);
    } else if (
      idAtencion &&
      irAConversacion &&
      idConversacion &&
      respuestaAtender === undefined
    ) {
      dispatch(atenderSolicitudSiac(idAtencion));
    }
  }, [idConversacion, respuestaAtender]);

  useEffect(() => {
    if (usuarios) {
      dispatch(getCatalogoUsuarios());
    }
  }, [usuarios]);

  useEffect(() => {
    dispatch(getSolicitudSiac());
  }, []);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link to="/">Regresar a Inicio</Link>
            <h3 className="titulo-table-actions">Contactos con SIAC</h3>
          </div>
          <Row>
            <Col sm={12}>
              <TableWithActions
                columns={columns}
                data={usuarios && catalogoUsuarios && dataParser(usuarios)}
                paginate={true}
                paginateRows={10}
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default Solicitudes;
