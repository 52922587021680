import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  FormControl,
  InputGroup,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import { ChevronDown } from "react-feather";

//actions
import {
  putRolUsuario,
  inhabilitarAdministrador,
  postAsignaRolUsuario,
} from "../../redux/actions/administradoresActions";
import { validarUsuario } from "../../redux/actions/ciudadanosActions";

//Selectors
import { catalogoUsuariosResult } from "../../redux/selectors/ciudadanosSelectors";
import { user } from "../../redux/selectors/rootSelectors";

const EditarAdministrador = ({ setModal, administrador }) => {
  const dispatch = useDispatch();
  const catalogoUsuarios = useSelector((state) =>
    catalogoUsuariosResult(state)
  );
  const usuario = useSelector((state) => user(state));

  const [rol, setRol] = useState(
    administrador.roluserPwa ? administrador.roluserPwa.user_rol : null
  );
  const [estatus, setEstatus] = useState(
    administrador.sites_for_user === null ? "Inactivo" : "Activo"
  );
  const [admin, setAdmin] = useState(administrador);
  const [rolDeUsuario, setRolDeUsuario] = useState();
  const [roles, setRoles] = useState([
    "SA_pwa",
    "Adm_pwa",
    "S_pwa",
    "G_pwa",
    "O_pwa",
  ]);

  const rolTranslator = (rol) => {
    switch (rol) {
      case "SA_pwa":
        return "SUPER-ADMINISTRADOR";
      case "Adm_pwa":
        return "ADMINISTRADOR GENERAL";
      case "S_pwa":
        return "COORDINADOR";
      case "G_pwa":
        return "OPERADOR INTERNO";
      case "O_pwa":
        return "OPERADOR PERIFÉRICO";
      default:
        return false;
    }
  };

  useEffect(() => {
    // PUT Rol
    if (
      (admin.roluserPwa ? admin.roluserPwa.user_rol !== rol : false) &&
      roles.includes(rol)
    ) {
      dispatch(
        putRolUsuario({
          id: admin.roluserPwa.id,
          body: { user: admin.id, user_rol: rol },
        })
      );
      // POST Rol
    } else if (admin.roluserPwa === null && roles.includes(rol)) {
      dispatch(postAsignaRolUsuario({ user: administrador.id, user_rol: rol }));
    }
  }, [rol]);

  useEffect(() => {
    if (estatus === "Inactivo" && admin.sites_for_user !== null) {
      dispatch(inhabilitarAdministrador(admin.sites_for_user.id));
    } else if (estatus === "Activo" && admin.sites_for_user === null) {
      dispatch(validarUsuario({ user: admin.id, sitio: [4] }));
    }
  }, [estatus]);

  useEffect(() => {
    if (catalogoUsuarios) {
      catalogoUsuarios.forEach((usuarioOAdmin) => {
        if (usuarioOAdmin.id === admin.id) {
          setAdmin(usuarioOAdmin);
        }
        if (usuarioOAdmin.id === usuario.user.id) {
          setRolDeUsuario(usuarioOAdmin.roluserPwa.user_rol);
        }
      });
    }
  }, [catalogoUsuarios]);

  useEffect(() => {
    if (rolDeUsuario) {
      switch (rolDeUsuario) {
        case "SA_pwa":
          setRoles(["SA_pwa", "Adm_pwa", "S_pwa", "G_pwa", "O_pwa"]);
          break;
        case "Adm_pwa":
          setRoles(["S_pwa", "G_pwa", "O_pwa"]);
          break;
        case "S_pwa":
          setRoles(["S_pwa", "G_pwa", "O_pwa"]);
          break;
        case "G_pwa":
          setRoles(["O_pwa"]);
          break;
        case "O_pwa":
          setRoles(["O_pwa"]);
          break;
        default:
      }
    }
  }, [rolDeUsuario]);


  return (
    <div id="contenedor-modal-nueva-encuesta">
      <section id="seccion-datos-nueva-encuesta">
        <p id="titulo-nueva-encuesta">
          Editar Administrador:
          {admin.first_name + " " + admin.last_name}
        </p>
        <Form id="form-registrar-admin">
          <Row>
            <Col>
              <label
                htmlFor="fecha-de-terminacion-de-encuesta"
                className="labels-crear-nueva-encuesta"
              >
                Rol
              </label>
              <InputGroup>
                <Dropdown className="dropdown-agregar-administrador">
                  <Dropdown.Toggle variant="success">
                    <p>
                      {rolTranslator(rol)
                        ? rolTranslator(rol)
                        : "Rol no asignado"}
                    </p>
                    <ChevronDown />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {roles.map((nombreRol) => (
                      <Dropdown.Item onClick={() => setRol(nombreRol)}>
                        {rolTranslator(nombreRol)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
            </Col>
            <Col>
              <label
                htmlFor="estatus-crear-encuesta"
                className="labels-crear-nueva-encuesta"
              >
                Estatus
              </label>
              <InputGroup
                name="estatus-crear-encuesta"
                id="estatus-crear-encuesta"
              >
                <Dropdown className="dropdown-agregar-administrador">
                  <Dropdown.Toggle variant="success">
                    <p>{estatus}</p>
                    <ChevronDown />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setEstatus("Activo")}>
                      Activo
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setEstatus("Inactivo")}>
                      Inactivo
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </section>
      <section id="seccion-botones-nueva-encuesta">
        {/* <button
          onClick={() => setModal(false)}
          id="boton-cancelar-crear-encuesta"
        >
          Cancelar
        </button> */}
        <button
          onClick={() => setModal(false)}
          id="boton-guardar-crear-encuesta"
        >
          Cerrar
        </button>
      </section>
    </div>
  );
};

export default EditarAdministrador;
