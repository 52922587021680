import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import "./Notification.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFolderPlus, faComment, faBan, faHandPaper, faBell, faTrash, faHourglassEnd, faGrinWink } from '@fortawesome/free-solid-svg-icons';
import { Popover } from '@material-ui/core';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Actions
import {
    getMyNotifications,
    todayNotifications,
    sendNumberOfUnreadNotifications
} from "../../redux/actions/notificationsActions";

// selectores
import {
    todayNotificationsResult,
    updateNotificationsResult
} from "../../redux/selectors/notificationsSelectors";

export const notificacionesArray = {
    notificaciones_admin: [
        {
            id: 1,
            fuente: "admin",
            creado_el: "2020-09-03T13:38:27.881314Z",
            tipo: "nueva_propuesta",
            texto: "userSA creó la propuesta: Mantenimiento parques",
            leido: false,
            enlace: null
        }
    ],
    notificaciones_user: [
        {
            id: 3,
            fuente: "user",
            creado_el: "2020-09-04T13:45:04.932455Z",
            tipo: "terminar_encuesta",
            texto: "Su encuesta: \"Encuesta Ciudadana - userSA\" creada el \"2020-09-03 13:39:33.025593+00:00\" ha terminado.",
            leido: true,
            enlace: null
        },
        {
            id: 1,
            fuente: "user",
            creado_el: "2020-09-03T13:45:04.533378Z",
            tipo: "terminar_encuesta",
            texto: "Su encuesta: \"Becas Tarapacá - userSA\" creada el \"2020-08-29 15:16:37.565826+00:00\" ha terminado.",
            leido: false,
            enlace: null
        }
    ]
}

const notificationsTypes = {
    'propuesta': faHandPaper,
    'nueva_propuesta': faHandPaper,
    'cambiar_estado_propuesta': faBell,
    'apoyo_propuesta': faHandPaper,
    'escalar_propuesta': faHandPaper,
    'editar_comentario_moderado': faComment,
    'intervenir_comentario': faBan,
    'eliminar_propuesta': faTrash,
    'terminar_encuesta': faHourglassEnd,
    'nueva_solicitud_siac': faFolderPlus,
    'nueva_mensaje': faComment
}

const Notification = (props) =>{
    console.log(props)

    const dispatch = useDispatch();
    const notificaciones = useSelector((state) => todayNotificationsResult(state));
    const updateNotificaciones = useSelector((state) => updateNotificationsResult(state));

    let [data, setData] = useState([]);
    let [todaysNotifications, setTodaysNotifications] = useState([]);

    const notifications = props.info    
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    useEffect(() => {
        dispatch(todayNotifications({url: "tipo=dia"}));
    }, []);

    useEffect(() => {
        if (updateNotificaciones !== undefined) {
            if (updateNotificaciones) {
                dispatch(todayNotifications({url: "tipo=dia"}));
            }
        }
    }, [updateNotificaciones]);


    // Change notificacionesArray with notificaciones when the API is updated
    useEffect(() => {
        if (notificaciones !== undefined) {
            if (Object.keys(notificaciones).length !== 0) {
                let mergedNotifications = [];

                if (notificaciones.results.hasOwnProperty('NotificacionAdmin')) {
                    // Merge both notifications arrays (admin and user)
                    mergedNotifications = [...notificaciones.results["NotificacionAdmin"], ...notificaciones.results["NotificacionUser"]]
                } else {
                    mergedNotifications = [...notificaciones.results["NotificacionUser"]]
                }
                // Sort notifications by date
                const sortedNotificationsByDate = mergedNotifications.sort((a, b) => b.creado_el - a.creado_el)

                const unreadTodayNotifications = sortedNotificationsByDate.filter(n => n.leido === false);

                dispatch(sendNumberOfUnreadNotifications({
                    unread: unreadTodayNotifications.length
                }));

                setTodaysNotifications(
                    todaysNotifications = sortedNotificationsByDate
                );
            }
        }
    }, [notificaciones]);

    return(
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <div style={{ margin: "0.5em" }}>
                <div>
                    <h5 style={{ textAlign: "center" }}>Notificaciones <br /> {new Date().getDate() + " de " + meses[new Date().getMonth()] + " del " + new Date().getFullYear()}</h5>
                </div>
                <div className="popover-basic">
                    <Container fluid>
                        {
                            todaysNotifications.length !== 0 ? todaysNotifications.map((noti, key) => {
                                    return (
                                        <Row key={key} className="notificationRow">
                                            <Col xs={3}>
                                                <FontAwesomeIcon icon={notificationsTypes[noti.tipo]} className="iconNotification" />
                                            </Col>
                                            <Col xs={9}>
                                                {noti.texto}
                                            </Col>
                                        </Row>
                                    )
                                })
                            :
                                <Row className="notificationRow">
                                    <Col xs={3}>
                                        <FontAwesomeIcon icon={faGrinWink} className="iconNotification" />
                                    </Col>
                                    <Col xs={9}>
                                        No tienes notificaciones el día de hoy
                                    </Col>
                                </Row>
                        }
                    </Container>
                    <div style={{ textAlign: "center", margin: "1em 0em" }}>
                        <Link to="/notificaciones">Ver todas las notificaciones</Link>
                    </div>
                </div>
            </div>
        </Popover>
    )
}

export default Notification;