import * as types from "../../consts/actionTypes";

const initialState = {
  conversations: [],
  messages: [],
  file: null,
  idConversacion: "",
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONVERSATIONS: {
      return {
        ...state,
      };
    }
    case types.GET_CONVERSATIONS_SUCCESS: {
      return {
        ...state,
        conversations: action.payload.conversations,
      };
    }
    case types.GET_CONVERSATION_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case types.GET_CONVERSATION: {
      return {
        ...state,
      };
    }
    case types.GET_CONVERSATION_SUCCESS: {
      return {
        ...state,
        messages: [...action.payload.messages],
        // Posible solución a apliamiento de mensajes entre chats
        // messages: [...state.messages, ...action.payload.messages],
      };
    }
    case types.GET_CONVERSATION_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case types.SEND_MESSAGE: {
      return {
        ...state,
        file: null,
      };
    }
    case types.RECEIVE_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, action.payload.message],
      };
    }
    case types.UPLOAD_CONVERSATION_FILE: {
      return {
        ...state,
      };
    }
    case types.UPLOAD_CONVERSATION_FILE_SUCCESS: {
      return {
        ...state,
        file: action.payload.attachment,
      };
    }
    case types.UPLOAD_CONVERSATION_FILE_ERROR: {
      return {
        ...state,
      };
    }
    case types.SEND_ATTACHMENT_MESSAGE: {
      return {
        ...state,
      };
    }
    case types.SEND_ATTACHMENT_MESSAGE_SUCCESS: {
      return {
        ...state,
        file: null,
        messages: [...state.messages, action.payload.message]
      };
    }
    case types.SEND_ATTACHMENT_MESSAGE_ERROR: {
      return {
        ...state,
      };
    }
    case types.POST_NEW_CONVERSATION_SUCCESS: {
      return {
        ...state,
        idConversacion: action.results.data.id
      };
    }
    case types.LIMPIA_CHAT: {
      return {
        ...state,
        messages: []
      };
    }
    case types.DELETE_CONVERSACION: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default messagesReducer;
