import React, { useState } from "react";
import { Card, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  djangoParserDeFecha,
  shorten,
  outerHtmlToReadableString,
} from "../../utils/ComponentUtils";

import IconoCalendarioBlanco from "../../assets/Iconos especiales/calendario-blanco.svg";
import IconoUsuarioBlanco from "../../assets/Iconos especiales/usuario-blanco.svg";
import IconoExternalLinkBlanco from "../../assets/Iconos especiales/external-link-blanco.svg";

const PreviewProyecto = (props) => {
  const [desplegarTexto, setDesplegarTexto] = useState(false);
  return (
    <div className="modal-preview-proyecto">
      <Card className="fullCard-styles" style={{ height: "23em" }}>
        <Card.Body
          className="fullImage"
          style={{
            background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 28, 65, 0.5) 50.00%, #001C41 80.00%), url('${props.img}') center center/cover no-repeat`,
          }}
        >
          <div style={{ bottom: 0, position: "absolute", color: "white" }}>
            <div className="title-link">
              <h4>{props.title}</h4>
              <div className="div-icono-link">
                <img src={IconoExternalLinkBlanco} alt="link de proyecto" />{" "}
              </div>
            </div>

            <div className="div-tags-fecha-area-proyecto">
              {props.tags.map((tag) => {
                return (
                  <Badge pill className="badge-style">
                    {tag.nombre}
                  </Badge>
                );
              })}
              <img src={IconoCalendarioBlanco} alt="fecha" />
              <p>{djangoParserDeFecha(props.date)}</p>
              <img src={IconoUsuarioBlanco} alt="area" />
              <p>Ayuntamiento</p>
            </div>
            <Card.Text className="text-container-card">
              <p id={desplegarTexto && "texto-descriptivo-tarjeta-proyecto"}>
                {shorten(
                  outerHtmlToReadableString(props.description),
                  desplegarTexto ? undefined : 250
                )}
                {"    "}
                {outerHtmlToReadableString(props.description).length > 250 && (
                  <span
                    className="ver-mas-desplegar-texto"
                    onClick={() => setDesplegarTexto(!desplegarTexto)}
                  >
                    {desplegarTexto ? "Ver menos" : "Ver más"}
                  </span>
                )}
              </p>
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
      <button
        className="boton-cerrar-preview-proyecto"
        onClick={() => props.setModal(false)}
      >
        Cerrar
      </button>
    </div>
  );
};

export default PreviewProyecto;
