import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tabs, Tab, Row, Container, Col } from "react-bootstrap";
import { Inbox, Archive } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import TableWithActions from "../../components/ActionTable/index";

//Actions
import { getEscalamientos } from "../../redux/actions/propuestasActions";

//Selectors
import { escalamientosResult } from "../../redux/selectors/propuestasSelectors";

//Components
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import BorrarPropuesta from "../Propuestas/components/BorrarPropuesta";

const columns = ["NOMBRE", "FECHA", "ESTATUS", "ACCIÓN"];

const Escalamientos = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [propuestaABorrar, setPropuestaABorrar] = useState();
  const [propuestasActivas, setPropuestasActivas] = useState();
  const [propuestasArchivadas, setPropuestasArchivadas] = useState();
  const escalamientos = useSelector((state) => escalamientosResult(state));

  const deletePropuestas = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      setModal(true);
      let obj = {};
      escalamientos.forEach((propuesta) => {
        if (propuesta.id === arrayDeIds[0]) {
          obj = {
            id: arrayDeIds[0],
            nombre: propuesta.titulo,
          };
        }
      });
      setPropuestaABorrar(obj);
    }
  };

  const renderEstatus = (estatus) => {
    const elemento = (color, texto) => {
      return (
        <div className="item-dropdown-estatus-propuesta">
          <div className={`bolita-estatus bolita-estatus-${color}`}></div>
          {texto}
        </div>
      );
    };
    switch (estatus) {
      case "B":
        return elemento("azul", "Borrador");
      case "A":
        return elemento("verde", "Aprobado");
      case "ER":
        return elemento("amarillo", "En Revisión");
      case "I":
        return elemento("naranja", "Inhabilitada");
      case "T":
        return elemento("negro", "Terminado");
      case "AR":
        return elemento("gris", "Archivada");
      default:
    }
  };

  const dataParser = (data) => {
    return data
      .map((escalamiento) => {
        return {
          id: escalamiento.id,
          nombre: escalamiento.titulo,
          fecha: escalamiento.fecha_publicacion,
          estatus: renderEstatus(escalamiento.status),
          accion: (
            <Link to={`/escalamientos/detalle/${escalamiento.id}`}>Abrir</Link>
          ),
        };
      })
      .filter((element) => element !== undefined);
  };

  useEffect(() => {
    if (escalamientos) {
      // let activas = propuestas.filter(((propuesta)=>propuesta.disponibilidad === "1"))
      // let archivadas = propuestas.filter(((propuesta)=>propuesta.disponibilidad === "2"))
      let activas = escalamientos.filter(
        (propuesta) =>
          propuesta.status !== "ER" &&
          propuesta.status !== "B" &&
          propuesta.status !== "AR" &&
          propuesta.escalado === false
      );
      let archivadas = escalamientos.filter(
        (propuesta) =>
          propuesta.status !== "ER" &&
          propuesta.status !== "B" &&
          propuesta.status !== "AR" &&
          propuesta.escalado === true
      );
      console.log(activas, archivadas);
      setPropuestasActivas(activas);
      setPropuestasArchivadas(archivadas);
    }
  }, [escalamientos]);


  useEffect(() => {
    dispatch(getEscalamientos());
  }, []);
  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link>Escalamientos</Link>
            <h3 className="titulo-table-actions">Listado de Escalamientos</h3>
          </div>

          <Tabs className="tabs-action-table">
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <Inbox /> {"Escalables"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      propuestasActivas ? dataParser(propuestasActivas) : []
                    }
                    firstColumnLink={false}
                    searchBar={true}
                    messageWhenEmpty={
                      "No hay propuestas escalables para mostrar"
                    }
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <Archive /> {"Escaladas"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      propuestasArchivadas
                        ? dataParser(propuestasArchivadas)
                        : []
                    }
                    selectable={true}
                    firstColumnLink={false}
                    delete={true}
                    deleteFunction={deletePropuestas}
                    searchBar={true}
                    messageWhenEmpty={
                      "No hay propuestas escaladas para mostrar"
                    }
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modal} setModal={setModal}>
        <BorrarPropuesta
          setModal={setModal}
          nombreDePropuesta={propuestaABorrar ? propuestaABorrar.nombre : ""}
          idDePropuesta={propuestaABorrar ? propuestaABorrar.id : ""}
        />
      </ModalEstandar>
    </div>
  );
};

export default Escalamientos;
