import * as types from "../../consts/actionTypes";

const initialState = {
    propuestasTendencias: [],
    proyectosTendencias: [],
};

export default function (state = initialState, action) {
    switch (action.type) {

        case types.GET_TENDENCIAS_PROPUESTAS_SUCCESS:
            console.log(action);
            return {
                ...state,
                propuestasTendencias: action.tendencias.data,
            };

        case types.GET_TENDENCIAS_PROYECTOS_SUCCESS:
            return {
                ...state,
                proyectosTendencias: action.tendenciasProyecto.data
            }

        default:
            return {
                ...state,
            };
    }
}