import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//Actions
import { deleteAccion } from "../../redux/actions/accionesActions";

//Selector

//Las clases de este componente se alojan en App.css

const BorrarAccion = ({ setModal, nombreDeAccion, idDeAccion }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idProyecto, idAccion } = useParams();
  const handleEliminar = () => {
    dispatch(deleteAccion(idDeAccion));
    setModal(false);
    if (idAccion)
      history.push(
        idProyecto ? `/proyectos/detalles/${idProyecto}` : "/acciones"
      );
  };

  return (
    <div className="cuerpo-modal-borrado">
      <p className="titulo-modal-borrado">¿Eliminar Acción?</p>
      <p className="detalle-modal-borrado">
        Procede para eliminar permanentemente la acción “{nombreDeAccion}”
      </p>
      <div className="div-botones-modal-borrado">
        <button
          className="boton-cancelar-modal-borrado"
          onClick={() => setModal(false)}
        >
          Cancelar
        </button>
        <button
          className="boton-eliminar-modal-borrado"
          onClick={handleEliminar}
        >
          Eliminar
        </button>
      </div>
    </div>
  );
};

export default BorrarAccion;
