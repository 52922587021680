import { call, put, select, takeLatest, fork, all } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from "../services/authServices";
import {
  getConversationsSuccess,
  getConversationsError,
  getConversation,
  getConversationSuccess,
  getConversationError,
  uploadConversationFileSuccess,
  uploadConversationFileError,
  sendMessage,
} from "../actions/messages";

/* Fetch all the conversations related to user */
export function* getConversationsSaga({ payload }) {
  try {
    const conversations = yield call(
      apiCall,
      "api/chat/conversations",
      {},
      {
        Authorization: `Bearer ${Services.getAccessToken()}`,
        Host: "https://api.participacion.management-r.com/", //"http://localhost:3000"
      },
      "GET"
    );

    yield put(getConversationsSuccess({ conversations: conversations.data }));
  } catch (error) {
    console.log(error);
    yield put(getConversationsError(error));
  }
}

/* Get conversation messages  */
export function* getConversationSaga({ payload }) {
  const { conversation_id } = payload;
  try {
    const messages = yield call(
      apiCall,
      `api/chat/conversation/${conversation_id}`,
      {},
      {
        Authorization: `Bearer ${Services.getAccessToken()}`,
        Host: "https://api.participacion.management-r.com/", //"http://localhost:3000"
      },
      "GET"
    );
    yield put(getConversationSuccess({ messages: messages.data }));
  } catch (error) {
    console.log(error);
    yield put(getConversationError(error));
  }
}

/* Send messages */
export function* sendMessageSaga({ payload }) {
  const { content, file, conversation } = payload;
  try {
    const message = yield call(
      apiCall,
      `api/chat/message/`,
      { content, file, conversation },
      {
        Authorization: `Bearer ${Services.getAccessToken()}`,
        Host: "https://api.participacion.management-r.com/", //"http://localhost:3000"
      },
      "POST"
    );

    yield put(getConversation({ conversation_id: conversation }));
    yield put(sendMessage());
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

/* Upload converstation file saga */
export function* uploadConversationFileSaga({ payload }) {
  try {
    const { file, conversation } = payload;

    // Create a Form Data
    let fileFormData = new FormData();
    fileFormData.append("file", file);
    fileFormData.append("conversation", conversation);

    const attachment = yield call(
      apiCall,
      "api/chat/conversation/upload/",
      fileFormData,
      {
        Authorization: `Bearer ${Services.getAccessToken()}`,
        Host: "https://api.participacion.management-r.com/", //"http://localhost:3000"
        "Content-Type": "multipart/form-data",
      },
      "POST"
    );
    
    yield put({
      type: types.SEND_ATTACHMENT_MESSAGE,
      payload:{
        content: '',
        conversation,
        file:attachment.data.id,
        active: true,
        encuesta: null,
      }
    })

    //yield put(uploadConversationFileSuccess({ attachment: attachment.data }));
  } catch (error) {
    console.log(error);
    yield put(uploadConversationFileError({ error }));
  }
}

export function* createConversationSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "api/chat/conversations/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Conversación creada con exito");
    yield put({ type: types.POST_NEW_CONVERSATION_SUCCESS, results });
  } catch (error) {
    console.log("Error al crear conversación: ", error);
    yield put({ type: types.POST_NEW_CONVERSATION_ERROR, payload });
  }
}

export function* deleteConversacionSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `api/chat/conversations/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    console.log("Conversación borrada con exito");
    yield put({ type: types.DELETE_CONVERSACION_SUCCESS, results });
  } catch (error) {
    console.log("Error al borrar conversación: ", error);
    yield put({ type: types.DELETE_CONVERSACION_ERROR, payload });
  }
}


/* REQUESTS */

// GET_CONVERSATIONS SUCCESS
function* getConversationsRequest() {
  yield takeLatest(types.GET_CONVERSATIONS, getConversationsSaga);
}

// GET_CONVERSATIONS SUCCESS
function* getConversationRequest() {
  yield takeLatest(types.GET_CONVERSATION, getConversationSaga);
}

// Get conversation messages
/* function* getConversationMessagesRequest() {
    yield takeLatest(types.GET_CONVERSATION_MESSAGES, getConversationMessagesSaga)
}*/

// Receive message

// Send message
function* sendMessageRequest() {
  yield takeLatest(types.SEND_ATTACHMENT_MESSAGE, sendMessageSaga);
}

// Upload File
function* uploadConversationFileRequest() {
  yield takeLatest(types.UPLOAD_CONVERSATION_FILE, uploadConversationFileSaga);
}

//Create conversation
function* createConversationRequest() {
  yield takeLatest(types.POST_NEW_CONVERSATION, createConversationSaga);
}

//Delete conversation
function* deleteConversacionRequest() {
  yield takeLatest(types.DELETE_CONVERSACION, deleteConversacionSaga);
}
/* FORK */

// Wrap all request related to Conversaciones
export default function* rootConversacionesSaga() {
  yield all([
    fork(getConversationsRequest),
    fork(getConversationRequest),
    fork(uploadConversationFileRequest),
    fork(sendMessageRequest),
    fork(createConversationRequest),
    fork(deleteConversacionRequest),
  ]);
}
