import * as types from "../../consts/actionTypes"

export const enviarCorreoRecuperacion = (payload) =>{
    return{
        type: types.ENVIAR_CORREO_RECUPERACION,
        payload
    }
}

export const restablecerPassword = (payload) =>{
    return{
        type: types.RESTABLECER_PASSWORD,
        payload
    }
}