import React from 'react'
import { Row, Col } from 'react-bootstrap'
import "./Comment.css"

const Comment = (props) => {

    return (
        <Row>
            <Col sm={12}>
                {
                    props.comments.map((cm, key) => {
                        return (
                            <Row className="container-comment" key={key}>
                                <Col sm={1}>
                                    <div className="profileImg-container-comment">
                                        <img src={cm.img} className="img-comment" />
                                    </div>

                                </Col>
                                <Col sm={11}>
                                    <div className="container-information-comment">
                                        <h6 className="name-comment">{cm.username}</h6>
                                        <p className="date-comment">{cm.datetime}</p>
                                    </div>
                                    <div>
                                        {cm.comment}
                                    </div>

                                </Col>
                            </Row>
                        )
                    })
                }

            </Col>
        </Row>
    )
}

export default Comment