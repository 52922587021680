import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "./login.css";
import { useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import * as userResult from "../../redux/selectors/rootSelectors";
//Components
import LoginBg from "./LoginBg";
import LoginForm from "./LoginForm";

const Login = (props) => {
  let history = useHistory();
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const resUser = useSelector((state) => userResult.user(state));
  const logged = useSelector((state) => userResult.logged(state));
  const token = useSelector((state) => userResult.tokenAccess(state));
  const [isLoggedIn, setLoggedIn] = useState(false);
  // console.log(logged)

  const handleSubmit = (e, datos) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      e.stopPropagation();
      console.log(datos);
      try {
        const body = {
          username: datos.user,
          password: datos.password,
        };

        dispatch(userActions.login(body));
      } catch (e) {
        console.log(e);
      }
      // console.log({ user, password });

      //REFACTOR THIS
      // if(user=="test" && password=="test"){
      //     const user={user:"1234"}
      //     localStorage.setItem("user",user)
      //     dispatch(userActions.loginSuccess(user));
      //     history.push("/");
      // }
      //----------------
      //const userResponse = await userService.login(user, password);
      // if (userResponse) {
      //     dispatch(userActions.loginSuccess(userResponse));
      //     history.push("/");
      // }
      // else{
      //     setError("Usuario o contraseña invalidos")
      // }
    }
    setValidated(true);
  };

  useEffect(() => {}, [logged]);

  if (token) {
    return <Redirect to="/" />;
  }

  return (
    <Row
      style={{
        height: "fit-content",
        minHeight: "100vh",
        background: "#F9FAFC",
        width: "105%",
        justifyContent:"center"
      }}
    >
      {/* <LoginBg /> */}
      <LoginForm validated={validated} handleSubmit={handleSubmit} />
    </Row>
  );
};

export default Login;
