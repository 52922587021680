import React from "react";
import { useDispatch } from "react-redux";

//Actions
import { deletePropuesta } from "../../../redux/actions/propuestasActions";

//Selector

//Las clases de este componente se alojan en App.css

const BorrarPropuesta = ({ setModal, nombreDePropuesta, idDePropuesta }) => {
  const dispatch = useDispatch();
  const handleEliminar = () => {
    dispatch(deletePropuesta(idDePropuesta));
    setModal(false);
  };

  return (
    <div className="cuerpo-modal-borrado">
      <p className="titulo-modal-borrado">¿Archivar Propuesta?</p>
      <p className="detalle-modal-borrado">
        Procede para archivar permanentemente la propuesta “{nombreDePropuesta}”
      </p>
      <div className="div-botones-modal-borrado">
        <button
          className="boton-cancelar-modal-borrado"
          onClick={() => setModal(false)}
        >
          Cancelar
        </button>
        <button
          className="boton-eliminar-modal-borrado"
          onClick={handleEliminar}
        >
          Archivar
        </button>
      </div>
    </div>
  );
};

export default BorrarPropuesta;
