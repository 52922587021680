export default function (mensaje) {
  if (
    mensaje === "Given token not valid for any token type" ||
    mensaje === "Token is invalid or expired"
  ) {
    return "La sesión ha expirado";
  } else {
    return mensaje;
  }
}
