import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "./Reportes.css";
import * as reportesActions from "../../redux/actions/reportesActions";
import * as reportesSelectors from "../../redux/selectors/reportesSelector";
import {
  clicksInside,
  mesNumericoAMesNombre,
  arrayDeMeses,
  arrayDeSemanas,
  arrayDeAnios,
  fechaStringBasicoAFechaJS,
} from "../../utils/ComponentUtils";
import Swal from "sweetalert2";
import comunas from "../../consts/comunas";
import sectoresConst from "../../consts/sectores";
import juntasVecinales from "../../consts/juntasVecinales";
import GeneradorDeGraficos from "./GeneradorDeGraficos";
import ModalPublicaciones from "./ModalPublicaciones";

// Excel
import ReactExport from "react-export-excel";
import { UPLOAD_CONVERSATION_FILE_ERROR } from "../../consts/actionTypes";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DetalleReporte = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { tipo, fechas } = useParams();
  const datos = useSelector((state) =>
    reportesSelectors.respuestaSuccessResult(state)
  );
  const [tipoGraficosDisponibles, setTipoGraficosDisponibles] = useState(null);
  const [configuracion, setConfiguracion] = useState({
    cambiarTipoGrafico: false,
    mostrarTemporalidad: false,
    mostrarFiltros: false,
    filtros: {
      comunas_destino: false,
      comunas_origen: false,
      sectores: false,
      sexo: false,
      edad: false,
      usuario: false,
    },
  });
  const [valores, setValores] = useState(null);
  const [etiquetas, setEtiquetas] = useState(null);
  const [counter, setCounter] = useState(0);
  const [tipoGrafico, setTipoGrafico] = useState("Pastel");
  const [comunasOrigen, setComunasOrigen] = useState([]);
  const [comunasDestino, setComunasDestino] = useState([]);
  const [sectores, setSectores] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [sexo, setSexo] = useState();
  const [edad, setEdad] = useState({ minimo: null, maximo: null });
  const [mostrarComunasOrigen, setMostrarComunasOrigen] = useState(false);
  const [mostrarComunasDestino, setMostrarComunasDestino] = useState(false);
  const [mostrarSectores, setMostrarSectores] = useState(false);
  const [mostrarUsuarios, setMostrarUsuarios] = useState(false);
  const [buscadorComunaOrigen, setBuscadorComunaOrigen] = useState(null);
  const [buscadorComunaDestino, setBuscadorComunaDestino] = useState(null);
  const [buscadorSector, setBuscadorSector] = useState(null);
  const [buscadorUsuario, setBuscadorUsuario] = useState(null);
  const [tituloEjeX, setTituloEjeX] = useState();
  const [tituloEjeY, setTituloEjeY] = useState();
  const [titulo, setTitulo] = useState(null);

  // Estados de fechas como variable
  const [semanas, setSemanas] = useState(null);
  const [meses, setMeses] = useState(null);
  const [anios, setAnios] = useState(null);
  const [variableDeFecha, setVariableDeFecha] = useState(null);

  const [infoExcel, setInfoExcel] = useState(null);
  const [callbackFunction, setCallbackFunction] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [label,setLabel]= useState("Cargando...")

  const filtrado = (arr) => {
    try {
      let array = [...arr];
      if (comunasOrigen.length > 0) {
        array = array.filter((registro) =>
          comunasOrigen.includes(registro.comuna_origen)
        );
      }
      if (comunasDestino.length > 0) {
        array = array.filter((registro) =>
          comunasDestino.includes(registro.comuna_destino)
        );
      }
      if (sectores.length > 0) {
        array = array.filter((registro) => sectores.includes(registro.sector));
      }
      if (sexo) {
        array = array.filter((registro) => registro.sexo === sexo);
      }
      if (edad.minimo && edad.maximo) {
        array = array.filter(
          (registro) =>
            Number(registro.edad) >= edad.minimo &&
            Number(registro.edad) <= edad.maximo
        );
      }
      if (usuarios.length > 0 && array[0].first_name) {
        array = array.filter((registro) => {
          console.log(
            usuarios.includes(
              registro.first_name +
                " " +
                registro.last_name +
                " - " +
                registro.area
            )
          );
          return usuarios.includes(
            registro.first_name +
              " " +
              registro.last_name +
              " - " +
              registro.area
          );
        });
      }
      switch (variableDeFecha) {
        case "semana":
          array = semanas.map((semana) =>
            array.filter(
              (registro) =>
                Date.parse(
                  fechaStringBasicoAFechaJS(registro.fecha_publicacion)
                ) >= semana.fecha_inicio &&
                Date.parse(
                  fechaStringBasicoAFechaJS(registro.fecha_publicacion)
                ) <= semana.fecha_fin
            )
          );
          setEtiquetas(
            semanas.map(
              (semana) =>
                mesNumericoAMesNombre(new Date(semana.fecha_inicio).getMonth())
                  .slice(0, 3)
                  .toUpperCase() +
                new Date(semana.fecha_inicio).getDate() +
                " - " +
                mesNumericoAMesNombre(new Date(semana.fecha_fin).getMonth())
                  .slice(0, 3)
                  .toUpperCase() +
                new Date(semana.fecha_fin).getDate()
            )
          );
          break;
        case "mes":
          array = meses.map((mes) =>
            array.filter(
              (registro) =>
                fechaStringBasicoAFechaJS(
                  registro.fecha_publicacion
                ).getMonth() === mes.mes &&
                fechaStringBasicoAFechaJS(
                  registro.fecha_publicacion
                ).getFullYear() === mes.anio
            )
          );

          setEtiquetas(meses.map((mes) => mesNumericoAMesNombre(mes.mes)));
          break;
        case "año":
          array = anios.map((anio) =>
            array.filter(
              (registro) =>
                fechaStringBasicoAFechaJS(
                  registro.fecha_publicacion
                ).getFullYear() === anio
            )
          );
          setEtiquetas(anios);
          break;
        default:
      }
      return array;
    } catch (error) {
      console.log("Hay un error en los filtros:", error);
      return [];
    }
  };

  const renderizaOpcionCheckbox = (
    valor,
    funcionAsociada,
    estadoAsociado,
    setEstadoAsociado
  ) => {
    return (
      <li>
        <input
          type="checkbox"
          checked={estadoAsociado.includes(valor)}
          onChange={(event) =>
            funcionAsociada(
              event.target.checked,
              valor,
              estadoAsociado,
              setEstadoAsociado
            )
          }
        />
        <label>{valor}</label>
      </li>
    );
  };

  const handleCheckBoxComunas = (
    checked,
    valor,
    estadoComunas,
    setEstadoComunas
  ) => {
    let copiaComunas = [...estadoComunas];
    if (checked === true) {
      copiaComunas.push(valor);
      setEstadoComunas(copiaComunas);
    } else {
      copiaComunas.splice(copiaComunas.indexOf(valor), 1);
      setEstadoComunas(copiaComunas);
    }
  };

  const handleCheckBoxSectores = (
    checked,
    valor,
    estadoSectores,
    setEstadoSectores
  ) => {
    let copiaSectores = [...estadoSectores];
    if (checked === true) {
      copiaSectores.push(valor);
      setEstadoSectores(copiaSectores);
    } else {
      copiaSectores.splice(copiaSectores.indexOf(valor), 1);
      setEstadoSectores(copiaSectores);
    }
  };

  useEffect(() => {
    if (
      fechas &&
      fechas.match(/\d{4}[-]\d{2}[-]\d{2}[-]\d{4}[-]\d{2}[-]\d{2}/)
    ) {
      switch (tipo) {
        case "temas-calientes":
          dispatch(reportesActions.getReportesTemasCalientes(fechas));
          break;
        case "propuestas":
          dispatch(reportesActions.getReportesPropuestas(fechas));
          break;
        case "propuestas-escalables":
          dispatch(reportesActions.getReportesPropuestasEscalables(fechas));
          break;
        case "solicitudes-atendidas":
          dispatch(reportesActions.getReportesSolicitudes(fechas));
          break;
        case "comentarios":
          dispatch(reportesActions.getReportesComentarios(fechas));
          break;
        case "tiempos-revision":
          dispatch(reportesActions.getReportesTiemposRevision(fechas));
          break;
        case "mapa-de-calor":
          dispatch(reportesActions.getReportesMapaCalor(fechas));
          break;
        case "interacciones":
          dispatch(reportesActions.getReportesInteracciones(fechas));
          break;
        default:
          Swal.fire(
            "Hubo un error",
            "El tipo de reporte a generar no pudo ser identificado",
            "error"
          );
          history.push("/reportes");
      }
    } else {
      Swal.fire(
        "Hubo un error",
        "Las fechas especificadas no son validas",
        "error"
      );
      history.push("/reportes");
    }
    clicksInside(
      ["input-multidropdown-comunas-origen", "multidropdown-comunas-origen"],
      () => setMostrarComunasOrigen(false)
    );
    clicksInside(
      ["input-multidropdown-comunas-destino", "multidropdown-comunas-destino"],
      () => setMostrarComunasDestino(false)
    );
    clicksInside(
      ["input-multidropdown-sectores", "multidropdown-sectores"],
      () => setMostrarSectores(false)
    );
    clicksInside(
      ["input-multidropdown-usuarios", "multidropdown-usuarios"],
      () => setMostrarUsuarios(false)
    );
  }, []);

  useEffect(() => {
    try {
      if (datos) {
        let fechaInicio = fechaStringBasicoAFechaJS(fechas.slice(0, 10));
        let fechaFin = fechaStringBasicoAFechaJS(fechas.slice(11));
        switch (tipo) {
          case "temas-calientes":
            setConfiguracion({
              cambiarTipoGrafico: true,
              mostrarTemporalidad: false,
              mostrarFiltros: true,
              filtros: {
                comunas_destino: true,
                comunas_origen: false,
                sectores: true,
                sexo: true,
                edad: true,
                usuario: false,
              },
            });
            setTipoGraficosDisponibles(["Pastel", "Barras", "Lineas"]);
            setValores(
              datos
                .filter((tag) => tag.info.length > 0)
                .map((tema) => {
                    return tema.info.length;
                })
            );
            setEtiquetas(
              datos
                .filter((etiq) => etiq.info.length > 0)
                .map((tema) => tema.nombre)
            );
            if (datos.length > 0) {
              setInfoExcel(
                datos
                  .filter((tag) => tag.info.length > 0)
                  .map((tema) => {
                    return {
                      nombre: tema.nombre,
                      registros: tema.info,
                      columas: Object.keys(tema.info[0]),
                    };
                  })
              );
            }
            break;
          case "propuestas":
            setConfiguracion({
              cambiarTipoGrafico: true,
              mostrarTemporalidad: true,
              mostrarFiltros: true,
              filtros: {
                comunas_destino: true,
                comunas_origen: true,
                sectores: true,
                sexo: true,
                edad: true,
                usuario: false,
              },
            });
            setTipoGraficosDisponibles(["Pastel", "Barras", "Lineas"]);
            setSemanas(arrayDeSemanas(fechaInicio, fechaFin));
            setMeses(arrayDeMeses(fechaInicio, fechaFin));
            setAnios(arrayDeAnios(fechaInicio, fechaFin));
            setVariableDeFecha("mes");
            setInfoExcel([
              {
                nombre: "Propuestas",
                registros: datos,
                columas: Object.keys(datos[0]),
              },
            ]);
            break;
          case "propuestas-escalables":
            setConfiguracion({
              cambiarTipoGrafico: true,
              mostrarTemporalidad: false,
              mostrarFiltros: true,
              filtros: {
                comunas_destino: true,
                comunas_origen: false,
                sectores: true,
                sexo: true,
                edad: true,
                usuario: true,
              },
            });
            setTipoGraficosDisponibles(["Pastel", "Barras"]);
            setValores([
              datos.escalables_sin_escalar.length,
              datos.escaladas.length,
              datos.no_escalables.length,
            ]);
            setEtiquetas([
              "Escalables sin escalar",
              "Escaladas",
              "No escalables",
            ]);
            if (datos) {
              setInfoExcel([
                {
                  nombre: "No escalables",
                  registros: [
                    {
                      cantidad_total_propuestas: datos.no_escalables,
                    },
                  ],
                  columas: ["No escalables"],
                },
                {
                  nombre: "Propuestas escalables no escaladas",
                  registros: datos.escalables_sin_escalar,
                  columas: ["Propuestas escalables no escaladas"],
                },
                {
                  nombre: "Escaladas",
                  registros: datos.escaladas,
                  columas: ["Escaladas"],
                },
              ]);
            }
            break;
          case "solicitudes-atendidas":
            setConfiguracion({
              cambiarTipoGrafico: false,
              mostrarTemporalidad: false,
              mostrarFiltros: false,
              filtros: {
                comunas_destino: false,
                comunas_origen: false,
                sectores: false,
                sexo: false,
                edad: false,
                usuario: true,
              },
            });
            setTipoGraficosDisponibles(["Pastel"]);
            setValores([
              datos.total - datos.atendidas.length,
              datos.atendidas.length,
            ]);
            setEtiquetas(["Solicitudes sin atender", "Solicitudes atendidas"]);
            setInfoExcel([
              {
                nombre: "Solicitudes",
                registros: [
                  {
                    totales: datos.total,
                    atendidas: datos.atendidas,
                  },
                ],
                columas: ["totales", "atendidas"],
              },
            ]);
            break;
          case "comentarios":
            setConfiguracion({
              cambiarTipoGrafico: false,
              mostrarTemporalidad: false,
              mostrarFiltros: false,
              filtros: {
                comunas_destino: false,
                comunas_origen: false,
                sectores: false,
                sexo: false,
                edad: false,
                usuario: true,
              },
            });
            setTipoGraficosDisponibles(["Pastel"]);
            setValores([
              datos.total - datos.intervenidos_y_censurados.length,
              datos.intervenidos_y_censurados.length,
            ]);
            setEtiquetas([
              "Comentarios publicados",
              "Comentarios intervenidos",
            ]);
            setInfoExcel([
              {
                nombre: "Comentarios",
                registros: [
                  {
                    totales: datos.total,
                    intervenidos_y_censurados: datos.intervenidos_y_censurados,
                  },
                ],
                columas: ["totales", "intervenidos"],
              },
            ]);
            break;
          case "tiempos-revision":
            setConfiguracion({
              cambiarTipoGrafico: false,
              mostrarTemporalidad: false,
              mostrarFiltros: false,
              filtros: {
                comunas_destino: false,
                comunas_origen: false,
                sectores: false,
                sexo: false,
                edad: false,
                usuario: true,
              },
            });
            setTipoGrafico("Histograma");
            setTipoGraficosDisponibles(["Histograma"]);
            setValores(datos.map((dato) => dato.tiempo));
            setEtiquetas([]);
            setTituloEjeX("Tiempo de respuesta (Horas)");
            setTituloEjeY("Cantidad de propuestas revisadas");
            setTitulo("Tiempo de revisión de las propuestas");
            setInfoExcel([
              {
                nombre: "Tiempos de revision",
                registros: [
                  {
                    horas: datos,
                  },
                ],
                columas: ["Horas"],
              },
            ]);
            break;
          case "mapa-de-calor":
            setConfiguracion({
              cambiarTipoGrafico: false,
              mostrarTemporalidad: false,
              mostrarFiltros: true,
              filtros: {
                comunas_destino: true,
                comunas_origen: true,
                sectores: true,
                sexo: true,
                edad: true,
                usuario: false,
              },
            });
            setTipoGrafico("Mapa de calor");
            setValores(datos);
            setEtiquetas([]);
            if (datos.length > 0) {
              setInfoExcel([
                {
                  nombre: "Mapa de calor",
                  registros: datos.map((registro) => {
                    let objeto = Object.assign({}, registro);
                    objeto.etiquetas = objeto.etiquetas
                      .map((etiqueta) => etiqueta.nombre)
                      .join(", ");
                    return objeto;
                  }),
                  columas: Object.keys(datos[0]),
                },
              ]);
            }
            break;
          case "interacciones":
            setConfiguracion({
              cambiarTipoGrafico: true,
              mostrarTemporalidad: false,
              mostrarFiltros: true,
              filtros: {
                comunas_destino: false,
                comunas_origen: false,
                sectores: false,
                sexo: false,
                edad: false,
                usuario: true,
              },
            });
            setTipoGrafico("Barras");
            setTipoGraficosDisponibles(["Barras", "Pastel"]);
            setValores([
              datos.acciones,
              datos.comentarios,
              datos.conversaciones,
              datos.intervenciones,
            ]);
            setEtiquetas([
              "Acciones",
              "Comentarios",
              "Conversaciones",
              "Intervenciones",
            ]);
            if (datos.usuarios.length > 0) {
              setInfoExcel([
                {
                  nombre: "General",
                  registros: [
                    {
                      acciones: datos.acciones,
                      comentarios: datos.comentarios,
                      conversaciones: datos.conversaciones,
                      intervenciones: datos.intervenciones,
                    },
                  ],
                  columas: [
                    "acciones",
                    "comentarios",
                    "conversaciones",
                    "intervenciones",
                  ],
                },
                {
                  nombre: "Actividad de funcionarios",
                  registros: datos.usuarios,
                  columas: Object.keys(datos.usuarios[0]),
                },
              ]);
            }
            break;
          default:
            Swal.fire(
              "Hubo un error",
              "El tipo de reporte a generar no pudo ser identificado",
              "error"
            );
            history.push("/reportes");
        }
      }
    } catch (error) {
      Swal.fire(
        "Surgió un error",
        "La informacón solicitada no pudo ser visualizada",
        "error"
      );
      history.push("/reportes");
      console.log(
        "Se presento un error al intentar preprar la informacion para GeneradorDeGraficos.jsx: ",
        error
      );
    }
  }, [datos]);

  useEffect(() => {
    if (datos) {
      switch (tipo) {
        case "temas-calientes":
          setValores(datos.filter((tag) => tag.info.length>0).map((conjunto) => filtrado(conjunto.info).length));
          break;
        case "propuestas":
          setValores(filtrado(datos).map((conjunto) => conjunto.length));
          break;
        case "propuestas-escalables":
          console.log("escaladas");
          console.log(datos.escaladas);
          const escaladas = filtrado(datos.escaladas);
          setValores([
            filtrado(datos.escalables_sin_escalar).length,
            escaladas.length,
            filtrado(datos.no_escalables).length,
          ]);
          setCallbackFunction({
            f: () => {
              setModalData({
                titulo: "Propuestas escaladas (" + escaladas.length + ")",
                modalData: escaladas.map((escalada) => {
                  return { Propuesta: escalada.titulo, Escalo: escalada.actor };
                }),
                headers: ["Propuesta", "Escaló"],
              });
              setModal(true);
            },
          });
          // setUsuarios(escaladas.map(escalada=>{return {name:escalada.actor,last_name:"",area:""}}))
          break;
        case "mapa-de-calor":
          setValores(filtrado(datos));
          break;
        case "interacciones":
          if (usuarios.length > 0) {
            setValores([
              filtrado(datos.usuarios)
                .map((user) => user.acciones)
                .reduce((acc, cur) => acc + cur),
              filtrado(datos.usuarios)
                .map((user) => user.comentarios)
                .reduce((acc, cur) => acc + cur),
              filtrado(datos.usuarios)
                .map((user) => user.conversaciones)
                .reduce((acc, cur) => acc + cur),
              filtrado(datos.usuarios)
                .map((user) => user.intervenciones)
                .reduce((acc, cur) => acc + cur),
            ]);
          } else {
            setValores([
              datos.acciones,
              datos.comentarios,
              datos.conversaciones,
              datos.intervenciones,
            ]);
          }
          break;
        case "solicitudes-atendidas":
          setValores([
            datos.total - filtrado(datos.atendidas).length,
            filtrado(datos.atendidas).length,
          ]);
          break;
        case "tiempos-revision":
          const datosFiltrado = filtrado(datos);
          setValores(datosFiltrado.map((dato) => dato.tiempo));
          setCallbackFunction({
            f: () => {
              setModalData({
                titulo: "Tiempos de revisión (" + datosFiltrado.length + ")",
                modalData: datosFiltrado.map((propuesta) => {
                  return { Reviso: propuesta.autor, Tiempo: propuesta.tiempo };
                }),
                headers: ["Revisó", "Tiempo"],
              });
              setModal(true);
            },
          });
          break;
        case "comentarios":
          setValores([
            datos.total - filtrado(datos.intervenidos_y_censurados).length,
            filtrado(datos.intervenidos_y_censurados).length,
          ]);
          setCallbackFunction({
            f: () => {
              setModalData({
                titulo:
                  "Comentarios Intervenidos (" +
                  datos.intervenidos_y_censurados.length +
                  ")",
                modalData: filtrado(datos.intervenidos_y_censurados).map(
                  (comentario) => {
                    return {
                      Comentario: comentario.comentario,
                      Intervino: comentario.actor,
                    };
                  }
                ),
                headers: ["Comentario", "Intervino"],
              });
              setModal(true);
            },
          });

          break;
        default:
      }
    }
  }, [
    datos,
    comunasOrigen,
    comunasDestino,
    sectores,
    sexo,
    edad,
    variableDeFecha,
    usuarios,
  ]);

  useEffect(() => {
    setCounter(counter + 1);
  }, [valores, etiquetas]);

  console.log(valores, etiquetas, tipo);
  setTimeout(()=>{ setLabel("No hay datos") }, 7000);

  return (
    <div className="contenedor-detalles-reporte">
      <div className="breadcrumb-section">
        <Link to="/reportes">Regresar a Reportes</Link>
        <h3 className="titulo-table-actions">
          REPORTE {tipo.split("-").join(" ").toUpperCase()}
        </h3>
      </div>
      {Array.isArray(datos)|| tipo==="interacciones"?<div>
        <Row className="row-filtros-reporte">
          {configuracion.cambiarTipoGrafico && (
            <Col className="opciones-filtros-reportes">
              <p>Tipo de gráfico</p>
              <select onChange={(event) => setTipoGrafico(event.target.value)}>
                {tipoGraficosDisponibles &&
                  tipoGraficosDisponibles.map((grafico, key) => {
                    return <option key={key}>{grafico}</option>;
                  })}
              </select>
            </Col>
          )}
          {configuracion.filtros.sexo && (
            <Col className="opciones-filtros-reportes">
              <p>Sexo</p>
              <input
                type="radio"
                name="sexo"
                checked={!sexo}
                onChange={(event) => {
                  if (event.target.checked === true) {
                    setSexo(null);
                  }
                }}
              />
              <label>Ambos</label>
              <input
                type="radio"
                name="sexo"
                onChange={(event) => {
                  if (event.target.checked === true) {
                    setSexo("masculino");
                  }
                }}
              />
              <label>Masculino</label>
              <input
                type="radio"
                name="sexo"
                onChange={(event) => {
                  if (event.target.checked === true) {
                    setSexo("femenino");
                  }
                }}
              />
              <label>Femenino</label>
            </Col>
          )}
          {configuracion.filtros.edad && (
            <Col className="opciones-filtros-reportes">
              <p>Edad</p>
              <label>Minimo</label>
              <input
                className="inputs-edad-reporte"
                type="number"
                min="0"
                max={edad.maximo}
                onChange={(event) =>
                  setEdad({ minimo: event.target.value, maximo: edad.maximo })
                }
              />
              <label>Maximo</label>
              <input
                className="inputs-edad-reporte"
                type="number"
                min={edad.minimo ? edad.minimo : "0"}
                onChange={(event) =>
                  setEdad({ minimo: edad.minimo, maximo: event.target.value })
                }
              />
            </Col>
          )}

          {configuracion.filtros.comunas_origen && (
            <Col className="opciones-filtros-reportes">
              <p>Provincias origen</p>
              <div className="div-input-multidropdown-comunas-origen">
                <input
                  type="text"
                  id="input-multidropdown-comunas-origen"
                  placeholder={comunasOrigen.join(", ")}
                  onClick={() => setMostrarComunasOrigen(true)}
                  onChange={(event) =>
                    setBuscadorComunaOrigen(event.target.value)
                  }
                  onBlur={(event) => {
                    event.target.value = "";
                    setBuscadorComunaOrigen();
                  }}
                />
                {mostrarComunasOrigen && (
                  <div id="multidropdown-comunas-origen">
                    <ul>
                      {buscadorComunaOrigen
                        ? comunas
                            .filter((comuna) =>
                              comuna.match(buscadorComunaOrigen)
                            )
                            .map((comuna) =>
                              renderizaOpcionCheckbox(
                                comuna,
                                handleCheckBoxComunas,
                                comunasOrigen,
                                setComunasOrigen
                              )
                            )
                        : comunas.map((comuna) =>
                            renderizaOpcionCheckbox(
                              comuna,
                              handleCheckBoxComunas,
                              comunasOrigen,
                              setComunasOrigen
                            )
                          )}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
          )}
          {configuracion.filtros.comunas_destino && (
            <Col className="opciones-filtros-reportes">
              <p>Provincia destino</p>
              <div className="div-input-multidropdown-comunas-destino">
                <input
                  type="text"
                  id="input-multidropdown-comunas-destino"
                  placeholder={comunasDestino.join(", ")}
                  onClick={() => setMostrarComunasDestino(true)}
                  onChange={(event) =>
                    setBuscadorComunaDestino(event.target.value)
                  }
                  onBlur={(event) => {
                    event.target.value = "";
                    setBuscadorComunaDestino();
                  }}
                />
                {mostrarComunasDestino && (
                  <div id="multidropdown-comunas-destino">
                    <ul>
                      {buscadorComunaDestino
                        ? comunas
                            .filter((comuna) =>
                              comuna.match(buscadorComunaDestino)
                            )
                            .map((comuna) =>
                              renderizaOpcionCheckbox(
                                comuna,
                                handleCheckBoxComunas,
                                comunasDestino,
                                setComunasDestino
                              )
                            )
                        : comunas.map((comuna) =>
                            renderizaOpcionCheckbox(
                              comuna,
                              handleCheckBoxComunas,
                              comunasDestino,
                              setComunasDestino
                            )
                          )}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
          )}
          {configuracion.filtros.sectores && (
            <Col className="opciones-filtros-reportes">
              <p>Sectores</p>
              <div className="div-input-multidropdown-sectores">
                <input
                  type="text"
                  id="input-multidropdown-sectores"
                  placeholder={sectores.join(", ")}
                  onClick={() => setMostrarSectores(true)}
                  onChange={(event) => setBuscadorSector(event.target.value)}
                  onBlur={(event) => {
                    event.target.value = "";
                    setBuscadorSector();
                  }}
                />
                {mostrarSectores && (
                  <div id="multidropdown-sectores">
                    <ul>
                      {buscadorSector
                        ? sectoresConst
                            .filter((sector) => sector.match(buscadorSector))
                            .map((sector) =>
                              renderizaOpcionCheckbox(
                                sector,
                                handleCheckBoxSectores,
                                sectores,
                                setSectores
                              )
                            )
                        : sectoresConst.map((sector) =>
                            renderizaOpcionCheckbox(
                              sector,
                              handleCheckBoxSectores,
                              sectores,
                              setSectores
                            )
                          )}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
          )}

          {configuracion.mostrarTemporalidad && (
            <Col className="opciones-filtros-reportes">
              <p>Segmentación de tiempo</p>
              <input
                type="radio"
                name="variables-tiempo"
                checked={variableDeFecha === "semana"}
                onChange={(event) => {
                  if (event.target.checked === true) {
                    setVariableDeFecha("semana");
                  }
                }}
              />
              <label>Semanas</label>
              <input
                type="radio"
                name="variables-tiempo"
                checked={variableDeFecha === "mes"}
                onChange={(event) => {
                  if (event.target.checked === true) {
                    setVariableDeFecha("mes");
                  }
                }}
              />
              <label>Meses</label>
              <input
                type="radio"
                name="variables-tiempo"
                checked={variableDeFecha === "año"}
                onChange={(event) => {
                  if (event.target.checked === true) {
                    setVariableDeFecha("año");
                  }
                }}
              />
              <label>Años</label>
            </Col>
          )}
          {configuracion.filtros.usuario && datos && datos.usuarios && (
            <Col className="opciones-filtros-reportes">
              <p>Usuarios</p>
              <div className="div-input-multidropdown-usuarios">
                <input
                  type="text"
                  id="input-multidropdown-usuarios"
                  onClick={() => setMostrarUsuarios(true)}
                  placeholder={usuarios.join(", ")}
                  onChange={(event) => setBuscadorUsuario(event.target.value)}
                  onBlur={(event) => {
                    // event.target.value = "";
                    // setBuscadorUsuario();
                  }}
                />
                {mostrarUsuarios && (
                  <div id="multidropdown-usuarios">
                    <ul>
                      {/* {console.log("AQUII")}
                      {console.log(datos.usuarios)}
                      {console.log(buscadorUsuario)} */}
                      {buscadorUsuario
                        ? datos.usuarios
                            .filter(
                              (user) =>
                                user.first_name.match(buscadorUsuario) ||
                                user.last_name.match(buscadorUsuario) ||
                                user.area.match(buscadorUsuario)
                            )
                            .map((user) =>
                              renderizaOpcionCheckbox(
                                user.first_name +
                                  " " +
                                  user.last_name +
                                  " - " +
                                  user.area,
                                handleCheckBoxSectores,
                                usuarios,
                                setUsuarios
                              )
                            )
                        : datos.usuarios.map((user) =>
                            renderizaOpcionCheckbox(
                              user.first_name +
                                " " +
                                user.last_name +
                                " - " +
                                user.area,
                              handleCheckBoxSectores,
                              usuarios,
                              setUsuarios
                            )
                          )}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
          )}
          {infoExcel && (
            <Col
              style={{ justifyContent: "flex-end" }}
              className="opciones-filtros-reportes"
            >
              <ExcelFile
                filename={"Reporte " + tipo + " - " + fechas}
                element={
                  <button className="boton-descragar-excel-reporte">
                    Descargar Excel
                  </button>
                }
              >
                {infoExcel.map((hoja, key) => (
                  <ExcelSheet
                    key={key}
                    data={hoja ? hoja.registros : ""}
                    name={hoja ? hoja.nombre : ""}
                  >
                    {hoja
                      ? hoja.columas.map((columna, key) => (
                          <ExcelColumn
                            key={key}
                            label={columna.toUpperCase()}
                            value={columna}
                          />
                        ))
                      : ""}
                  </ExcelSheet>
                ))}
              </ExcelFile>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <GeneradorDeGraficos
              tipoGrafico={tipoGrafico}
              valores={valores}
              etiquetas={etiquetas}
              titulo={titulo || tipo}
              IndicadorRender={counter}
              jsonComunasJuntas={juntasVecinales}
              alturaGrafico={"80vh"}
              anchoGrafico={"100%"}
              tituloEjeX={tituloEjeX ? tituloEjeX : ""}
              tituloEjeY={tituloEjeY ? tituloEjeY : ""}
              callbackClick={callbackFunction}
            />
          </Col>
        </Row>
      </div>
:(label)}
      <ModalPublicaciones
        visibilidad={modal}
        setModal={setModal}
        publicaciones={modalData}
        titulo={modalData.titulo}
      />
    </div>
  );
};

export default DetalleReporte;
