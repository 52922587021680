import React from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";


import TableWithActions from "../../components/ActionTable/index";

const columns = ["TIMESTAMP", "REGISTRO"];

const data = [
  {
    date: "09:13 CDT 04/08/2020",
    registro: "Cambió el estátus de propuesta “Parques más seguros” a activa",
  },
  {
    date: "09:13 CDT 04/08/2020",
    registro: "Cambió el estátus de propuesta “Parques más seguros” a activa",
  },
  {
    date: "09:13 CDT 04/08/2020",
    registro: "Cambió el estátus de propuesta “Parques más seguros” a activa",
  },
  {
    date: "09:13 CDT 04/08/2020",
    registro: "Cambió el estátus de propuesta “Parques más seguros” a activa",
  },
  {
    date: "09:13 CDT 04/08/2020",
    registro: "Cambió el estátus de propuesta “Parques más seguros” a activa",
  },
  {
    date: "09:13 CDT 04/08/2020",
    registro: "Cambió el estátus de propuesta “Parques más seguros” a activa",
  },
];

const ActividadAdministrador = () => {
  const { id } = useParams();
  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link to="/administradores">Regresar a Administradores</Link>
            <h3 className="titulo-table-actions">
              Actividad Administrador {id}
            </h3>
          </div>

          <Row>
            <Col sm={12}>
              <TableWithActions
                columns={columns}
                data={data ? data : []}
                idTableVisualization={true}
                searchBar={true}
                paginate={true}
                paginateRows={10}
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default ActividadAdministrador;
