import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Form,
  Row,
  Col,
  FormControl,
  InputGroup,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  ChevronLeft,
  ChevronDown,
  Download,
  MessageCircle,
  ArrowUp,
  ArrowUpCircle,
  AlertCircle,
  MessageSquare,
  Clock,
  MoreHorizontal,
  ThumbsUp,
  ThumbsDown,
  CheckCircle,
  X,
} from "react-feather";
import generalUrl from "../../consts/GeneralUrl";

//Componentes
import CalendarioInline from "../../components/reusable/CalendarioInline/CalendarioInline";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import EscalarPropuesta from "./components/EscalarPropuesta";
import TableWithActions from "../../components/ActionTable/index";

//Actions
import {
  getPropuestaADetalle,
  putEstatusPropuesta,
  postComentario,
  deleteComentario,
  postRespuestaPropuesta,
  putComentarioPropuesta,
  postVotoComentarioPropuesta,
} from "../../redux/actions/propuestasActions";
import { createConvesation } from "../../redux/actions/messages";
import { getCatalogoUsuarios } from "../../redux/actions/ciudadanosActions";

//Selectors
import {
  propuestasResult,
  propuestaADetalleResult,
  comentariosPropuestasResult,
  debeActualizarComentarios,
  debeActualizarPropuestasResult,
} from "../../redux/selectors/propuestasSelectors";
import { catalogoUsuariosResult } from "../../redux/selectors/ciudadanosSelectors";
import { user } from "../../redux/selectors/rootSelectors";

import avatar from "./AvatarTest.svg";
import PreviewPropuestas from "./PreviewPropuestas";

const Propuesta = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [propuesta, setPropuesta] = useState();
  const [estatus, setEstatus] = useState();
  const [tab, setTab] = useState("comentarios");
  const [modal, setModal] = useState(false);
  const [esRespuesta, setEsRespuesta] = useState(false);
  const [comentarioAResponder, setComentarioAResponder] = useState({
    id: null,
    comentario: null,
    usuario: "",
  });
  const [imagenParaDescargar, setImagenParaDescargar] = useState();
  let [coments, setComents] = useState();
  const [irAConversacion, setIrAConversacion] = useState(false);
  const [bannerEsclado, setBannerEscalado] = useState(true);
  const propuestaADetalle = useSelector((state) =>
    propuestaADetalleResult(state)
  );
  const actualizarPropuestas = useSelector((state) =>
    debeActualizarPropuestasResult(state)
  );

  const comentarios = useSelector((state) =>
    comentariosPropuestasResult(state)
  );
  const actualizarComentarios = useSelector((state) =>
    debeActualizarComentarios(state)
  );
  const conversationsState = useSelector((state) => state.MessagesReducer);
  const { idConversacion } = conversationsState;

  const listaDeUsuarios = useSelector((state) => catalogoUsuariosResult(state));

  const usuarioActual = useSelector((state) => user(state));
  let { id } = useParams();
  const location = useLocation();
  const [modalPreview, setModalPreview] = useState(false);

  const parserDeFecha = (string) => {
    if (string) {
      let fecha = new Date(string.slice(0, 19));
      const nombreDeMes = (num) => {
        switch (num) {
          case 0:
            return "enero";
          case 1:
            return "febrero";
          case 2:
            return "marzo";
          case 3:
            return "abril";
          case 4:
            return "mayo";
          case 5:
            return "junio";
          case 6:
            return "julio";
          case 7:
            return "agosto";
          case 8:
            return "septiembre";
          case 9:
            return "octubre";
          case 10:
            return "noviembre";
          case 11:
            return "diciembre";
          default:
        }
      };
      return `${fecha.getDate()} de ${nombreDeMes(
        fecha.getMonth()
      )} de ${fecha.getFullYear()} a las ${
        fecha.getHours() > 9 ? fecha.getHours() : "0" + fecha.getHours()
      }:${
        fecha.getMinutes() > 9 ? fecha.getMinutes() : "0" + fecha.getMinutes()
      }`;
    }
  };

  const handleEstatusDropdownClick = (opcion) => {
    let obj = Object.assign({}, propuesta);
    obj.status = opcion;
    setPropuesta(obj);
    dispatch(
      putEstatusPropuesta({
        id: Number(id),
        bandera: "S",
        estatus: opcion,
      })
    );
  };

  const handleComentar = () => {
    if (esRespuesta) {
      dispatch(
        postRespuestaPropuesta({
          propuesta: id,
          comentario: document.getElementById("input-comentario-propuesta")
            .value,
          padre: comentarioAResponder.id,
        })
      );
      setEsRespuesta(false);
    } else {
      dispatch(
        postComentario({
          propuesta: id,
          comentario: document.getElementById("input-comentario-propuesta")
            .value,
        })
      );
    }
    document.getElementById("input-comentario-propuesta").value = "";
  };

  const handleResponder = (idComentario, comentario, usuario) => {
    document.getElementById("input-comentario-propuesta").focus();
    document.getElementById("input-comentario-propuesta").click();
    setEsRespuesta(true);
    setComentarioAResponder({
      id: idComentario,
      comentario: comentario,
      usuario: usuario,
    });
  };

  const handleModerar = (comentario, idComentario, estatusComentario) => {
    let body = {
      comentario: comentario,
      propuesta: id,
      moderated: estatusComentario === "N" ? "M" : "N",
    };
    dispatch(putComentarioPropuesta({ id: idComentario, body: body }));
  };

  const handleBorrarComentario = (idComentario) => {
    dispatch(deleteComentario({ idComentario: idComentario }));
  };

  const handleVotar = (idComentario, tipo) => {
    dispatch(
      postVotoComentarioPropuesta({ voto: tipo, comentario: idComentario })
    );
  };

  const handleAbrirChat = () => {
    let body = {
      conversation_username: getUsernameById(propuestaADetalle.usuario),
    };
    dispatch(createConvesation(body));
    setIrAConversacion(true);
  };

  const getUsernameById = (id) => {
    let username;
    listaDeUsuarios.forEach((personita) => {
      if (Number(personita.id) === Number(id)) {
        username = personita.username;
      }
    });
    return username;
  };

  const renderEstatus = (estatus) => {
    const elemento = (color, texto) => {
      return (
        <div className="item-dropdown-estatus-propuesta">
          <div className={`bolita-estatus bolita-estatus-${color}`}></div>
          {texto}
        </div>
      );
    };
    switch (estatus) {
      case "B":
        return elemento("azul", "Borrador");
      case "A":
        return elemento("verde", "Aprobado");
      case "ER":
        return elemento("amarillo", "En Revisión");
      case "I":
        return elemento("naranja", "Inhabilitada");
      // case "U":
      //   return elemento("rojo", "Urgente");
      case "T":
        return elemento("negro", "Terminado");
      // case "NP":
      //   return elemento("gris", "No Público");
      // case "NL":
      //   return elemento("azul-claro", "No Leido");
      default:
    }
  };

  const parserActividades = (actividades) => {
    return actividades.map((actividad) => {
      return {
        actividad: actividad.actividad,
        hora: parserDeFecha(actividad.fecha),
        estatus: renderEstatus(actividad.estado),
      };
    });
  };

  useEffect(() => {
    if (irAConversacion && idConversacion) {
      history.push(`/conversacion/${idConversacion}`);
    }
  }, [idConversacion]);

  useEffect(() => {
    if (propuestaADetalle) {
      setPropuesta(propuestaADetalle);
      setEstatus(propuestaADetalle.status);
      dispatch(getCatalogoUsuarios());
    }
  }, [propuestaADetalle]);

  useEffect(() => {
    if (comentarios) {
      let arr = comentarios.filter((obj) => obj.propuesta === Number(id));
      setComents(arr);
    }
  }, [comentarios]);

  useEffect(() => {
    if (actualizarComentarios || actualizarPropuestas) {
      dispatch(getPropuestaADetalle(id));
    }
  }, [actualizarComentarios, actualizarPropuestas]);

  useEffect(() => {
    if (estatus && propuesta && propuesta.status !== estatus) {
      dispatch(
        putEstatusPropuesta({
          id: Number(id),
          bandera: "S",
          estatus: estatus,
        })
      );
    }
  }, [estatus]);

  useEffect(() => {
    if (imagenParaDescargar) {
      document.getElementById("imagen-descargar-propuesta").style.display =
        "none";
    }
  }, [imagenParaDescargar]);

  useEffect(() => {
    dispatch(getPropuestaADetalle(id));
  }, []);

  return (
    <div className="section">
      <section id="header-propuesta">
        {location.pathname.split("/").includes("escalamientos") ? (
          <Link id="regresar-propuesta" to="/escalamientos">
            <ChevronLeft />
            <p>Regresar a Escalamientos</p>
          </Link>
        ) : (
          <Link id="regresar-propuesta" to="/propuestas">
            <ChevronLeft />
            <p>Regresar a Propuestas</p>
          </Link>
        )}

        <div id="div-header-titulo-botones-propuesta">
          <p id="titulo-propuesta">
            Propuesta: {propuesta ? propuesta.titulo : ""}
          </p>

          <div id="div-header-botones-propuesta">
            {listaDeUsuarios && propuestaADetalle && (
              <Button
                id="boton-abrir-chat-propuesta"
                className="botones-header-propuesta"
                onClick={handleAbrirChat}
              >
                <MessageCircle />
                <p>Abrir Chat</p>
              </Button>
            )}
            <Button
              variant="outline-secondary"
              size="sm"
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={() => setModalPreview(true)}
            >
              Preview
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-estatus-propuesta"
                className="botones-header-propuesta"
              >
                <div>
                  {estatus
                    ? renderEstatus(estatus)
                    : propuesta
                    ? renderEstatus(propuesta.status)
                    : null}
                </div>
                <ChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {["B", "A", "I", "T"].map((elemento) => (
                  <Dropdown.Item onClick={() => setEstatus(elemento)}>
                    {renderEstatus(elemento)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {propuesta && location.pathname.split("/").includes("escalamientos")
              ? propuesta.escalado === false && (
                  <Button
                    id="boton-escalar-propuesta"
                    className="botones-header-propuesta"
                    onClick={() => {
                      setModal(true);
                    }}
                  >
                    <ArrowUp />
                    <p>Escalar</p>
                  </Button>
                )
              : null}
          </div>
        </div>
        {propuesta
          ? propuesta.escalado === true &&
            bannerEsclado && (
              <div className="banner-azul-mensajes-cambios">
                <ArrowUpCircle />
                <p>
                  Esta propuesta ha sido escalada con el siguiente mensaje:
                  {"\n"}
                  {propuesta.comentario_escalado}
                </p>
                <X
                  className="banner-x-cerrar"
                  onClick={() => setBannerEscalado(false)}
                />
              </div>
            )
          : null}
      </section>
      <section id="datos-propuesta">
        <Form id="form-propuesta">
          <Row className="secciones-propuesta">
            <Col sm={6}>
              <label className="labels-propuesta">Titulo de la Propuesta</label>
              <InputGroup>
                <FormControl
                  type="text"
                  className="inputs-propuesta"
                  readOnly={true}
                  value={propuesta ? propuesta.titulo : ""}
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <label className="labels-propuesta">Sector</label>
              <InputGroup>
                <FormControl
                  type="text"
                  className="inputs-propuesta"
                  readOnly={true}
                  value={propuesta ? propuesta.sector : ""}
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <label className="labels-propuesta">Imagen adjunta</label>
              <InputGroup>
                <Button
                  id="propuesta-boton-descargar"
                  onClick={() => {
                    var link = document.createElement("a");
                    link.target = "_blank";
                    link.href =
                      propuesta && propuesta.imagen
                        ? generalUrl + propuesta.imagen.slice(1)
                        : "";
                    link.click();
                  }}
                >
                  <p>
                    {propuesta && propuesta.imagen
                      ? "Descargar Archivo"
                      : "No se adjuntó imagen"}
                  </p>
                  <Download />
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <Row className="secciones-propuesta">
            <Col sm={12} id="seccion-quill-propuesta">
              <label className="labels-propuesta">
                Contenido de la Propuesta
              </label>
              <ReactQuill
                children={<div id="contenedor-de-quill-propuesta"></div>}
                theme="snow"
                value={propuesta ? propuesta.contenido : ""}
                readOnly={true}
              />
            </Col>
          </Row>
          <Row className="secciones-propuesta">
            <Col sm={6}>
              <label className="labels-propuesta">Etiquetas</label>
              <div id="div-etiquetas">
                {propuesta &&
                propuesta.etiquetas &&
                propuesta.etiquetas.length > 0 ? (
                  propuesta.etiquetas.map((etiqueta, index) => (
                    <p className="etiquetas-propuesta" key={index}>
                      {etiqueta.nombre}
                    </p>
                  ))
                ) : (
                  <p style={{ fontSize: "12px" }}>
                    El creador de está propuesta no incluyó etiquetas
                  </p>
                )}
              </div>
            </Col>
            {/* <Col sm={3}>
              <label className="labels-propuesta">Área</label>
              <InputGroup>
                <FormControl
                  className="inputs-propuesta"
                  type="text"
                  placeholder="Ingrese información"
                  readOnly={true}
                  value={propuesta ? propuesta.area : ""}
                />
              </InputGroup>
            </Col> */}
            <Col sm={3}>
              <label className="labels-propuesta">Apoyos</label>
              <div id="div-votos">{propuesta ? propuesta.apoyos : ""}</div>
            </Col>
            <Col sm={3}>
              <label className="labels-propuesta">Distrito</label>
              <InputGroup>
                <FormControl
                  type="text"
                  className="inputs-propuesta"
                  readOnly={true}
                  value={propuesta ? propuesta.comuna_destino : ""}
                />
              </InputGroup>
              {propuesta &&
                propuesta.junta_vecinal &&
                propuesta.junta_vecinal !== "null" && (
                  <div style={{ marginTop: "10px" }}>
                    <label className="labels-propuesta">Junta Vecinal</label>
                    <InputGroup>
                      <FormControl
                        type="text"
                        className="inputs-propuesta input-junta-vecinal"
                        readOnly={true}
                        value={
                          propuesta ? propuesta.junta_vecinal.toLowerCase() : ""
                        }
                      />
                    </InputGroup>
                  </div>
                )}
            </Col>
          </Row>
        </Form>
      </section>
      <section id="comentarios-actividad-propuesta">
        <div id="div-tabs-propuesta">
          <div
            id="tab-comentarios-propuesta"
            className={
              tab === "comentarios"
                ? "tab-activo-propuesta"
                : "tabs-inactivos-propuesta"
            }
            onClick={() => setTab("comentarios")}
          >
            <MessageSquare />
            <p>Comentarios</p>
          </div>
          <div
            id="tab-actividad-propuestas"
            className={
              tab === "actividad"
                ? "tab-activo-propuesta"
                : "tabs-inactivos-propuesta"
            }
            onClick={() => setTab("actividad")}
          >
            <Clock />
            <p>Actividad</p>
          </div>
        </div>
        {tab === "comentarios" && (
          <div id="contenido-comentarios-actividad-propuesta">
            {estatus
              ? (estatus !== "I" || estatus !== "T") && (
                  <Form id="form-comentarios-propuesta">
                    <InputGroup>
                      <Form.Control
                        id="input-comentario-propuesta"
                        as="textarea"
                        rows="1"
                        placeholder="Ingresa tu comentario"
                      />
                    </InputGroup>
                    <Button
                      id="boton-comentar-propuesta"
                      onClick={handleComentar}
                    >
                      <p>Comentar</p>
                      <MessageSquare />
                    </Button>
                  </Form>
                )
              : null}
            {esRespuesta && (
              <div className="mensaje-respondiendo-comentario">
                <p>Respondiendo a {comentarioAResponder.usuario}</p>
                <X
                  onClick={() => {
                    setEsRespuesta(false);
                    setComentarioAResponder({ id: null, comentario: null });
                  }}
                />
              </div>
            )}
            <div id="div-render-comentarios-propuesta">
              {propuesta ? (
                propuesta.comentarios.length === 0 ? (
                  <div className="div-comentarios-o-actividad-vacio">
                    <p>Sin comentarios</p>
                  </div>
                ) : (
                  propuesta.comentarios.map((comentario, index) => {
                    if (comentario.padre === null) {
                      return (
                        <div
                          className="comentario-contenedor-primario-propuesta"
                          key={index}
                        >
                          <img
                            src={
                              "https://api.participacion.management-r.com/display-avatar/" +
                              comentario.user.avatar
                            }
                            className="img-avatar-comentarios-desktop"
                          />
                          <div className="comentario-contenedor-secundario-propuesta">
                            <div className="comentario-contenedor-terciario-propuesta">
                              <img
                                src={
                                  "static-pc/static/avatar/" +
                                  comentario.user.avatar
                                }
                                className="img-avatar-comentarios-movil"
                              />
                              <div className="comentario-contenedor-cuaternario-propuesta">
                                <p className="nombres-comentarios-propuesta">
                                  {comentario.user.first_name +
                                    " " +
                                    comentario.user.last_name}
                                </p>
                                <p className="fecha-comentarios-propuesta">
                                  {parserDeFecha(comentario.fecha)}
                                </p>
                              </div>
                              {comentario.disponibilidad === "1" && (
                                <Dropdown>
                                  <Dropdown.Toggle className="dropdown-comentario-propuesta">
                                    <MoreHorizontal />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleResponder(
                                          comentario.id,
                                          comentario.comentario,
                                          comentario.user.first_name +
                                            " " +
                                            comentario.user.last_name
                                        )
                                      }
                                    >
                                      Responder
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleModerar(
                                          comentario.comentario,
                                          comentario.id,
                                          comentario.moderated
                                        )
                                      }
                                    >
                                      {comentario.moderated === "N"
                                        ? "Intervenir"
                                        : "Aprobar"}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() =>
                                        handleBorrarComentario(comentario.id)
                                      }
                                    >
                                      Archivar
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </div>
                            {comentario.moderated === "M" && (
                              <div className="div-banner-comentario-rojo">
                                <AlertCircle />
                                <p>
                                  Este comentario infringe las normas de la
                                  plataforma o contiene información sensible.
                                </p>
                              </div>
                            )}
                            {comentario.disponibilidad === "2" && (
                              <div className="div-banner-comentario-rojo">
                                <AlertCircle />
                                <p>Este comentario ha sido archivado.</p>
                              </div>
                            )}
                            <p className="texto-comentarios-propuesta">
                              {comentario.comentario}
                            </p>
                            <div className="div-votos-comentarios-propuestas">
                              <ThumbsUp
                                onClick={() => handleVotar(comentario.id, "U")}
                              />
                              <p>{comentario.votos_up}</p>
                              <ThumbsDown
                                onClick={() => handleVotar(comentario.id, "D")}
                              />
                              <p>{comentario.votos_down}</p>
                              <div className="barra-gris-2X32"></div>
                              <p className="texto-negro-votos-comentarios-propuestas">
                                {comentario.votos_up + comentario.votos_down}{" "}
                                Votos
                              </p>
                            </div>
                            <p className="etiqueta-respuestas">
                              {comentario.respuestas.length > 0
                                ? comentario.respuestas.length > 1
                                  ? "Respuestas"
                                  : "Respuesta"
                                : null}
                            </p>
                            {comentario.respuestas.map((respuesta, index) => {
                              return (
                                <div
                                  className="comentario-contenedor-primario-propuesta"
                                  key={index}
                                >
                                  <img
                                    src={
                                      "https://api.participacion.management-r.com/display-avatar/" +
                                      respuesta.user.avatar
                                    }
                                    className="img-avatar-comentarios-desktop"
                                  />
                                  <div className="comentario-contenedor-secundario-propuesta">
                                    <div className="comentario-contenedor-terciario-propuesta">
                                      <img
                                        src={
                                          "https://api.participacion.management-r.com/display-avatar/" +
                                          respuesta.user.avatar
                                        }
                                        className="img-avatar-comentarios-movil"
                                      />
                                      <div className="comentario-contenedor-cuaternario-propuesta">
                                        <p className="nombres-comentarios-propuesta">
                                          {respuesta.user.first_name +
                                            " " +
                                            respuesta.user.last_name}
                                        </p>
                                        <p className="fecha-comentarios-propuesta">
                                          {parserDeFecha(respuesta.fecha)}
                                        </p>
                                      </div>
                                      <Dropdown>
                                        <Dropdown.Toggle className="dropdown-comentario-propuesta">
                                          <MoreHorizontal />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleModerar(
                                                respuesta.comentario,
                                                respuesta.id,
                                                respuesta.moderated
                                              )
                                            }
                                          >
                                            {respuesta.moderated === "N"
                                              ? "Intervenir"
                                              : "Aprobar"}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleBorrarComentario(
                                                respuesta.id
                                              )
                                            }
                                          >
                                            Archivar
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    {respuesta.moderated === "M" && (
                                      <div className="div-banner-comentario-rojo">
                                        <AlertCircle />
                                        <p>
                                          Este comentario infringe las normas de
                                          la plataforma o contiene información
                                          sensible.
                                        </p>
                                      </div>
                                    )}

                                    {respuesta.disponibilidad === "2" && (
                                      <div className="div-banner-comentario-rojo">
                                        <AlertCircle />
                                        <p>
                                          Este comentario ha sido archivado.
                                        </p>
                                      </div>
                                    )}
                                    <p className="texto-comentarios-propuesta">
                                      {respuesta.comentario}
                                    </p>
                                    <div className="div-votos-comentarios-propuestas">
                                      <ThumbsUp
                                        onClick={() =>
                                          handleVotar(respuesta.id, "U")
                                        }
                                      />
                                      <p>{respuesta.votos_up}</p>
                                      <ThumbsDown
                                        onClick={() =>
                                          handleVotar(respuesta.id, "D")
                                        }
                                      />
                                      <p>{respuesta.votos_down}</p>
                                      <div className="barra-gris-2X32"></div>
                                      <p className="texto-negro-votos-comentarios-propuestas">
                                        {respuesta.votos_up +
                                          respuesta.votos_down}{" "}
                                        Votos
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    }
                  })
                )
              ) : (
                <div className="div-comentarios-o-actividad-vacio">
                  <p>Sin comentarios</p>
                </div>
              )}
            </div>
          </div>
        )}
        {tab === "actividad" && (
          <div
            id="contenido-comentarios-actividad-propuesta"
            style={{ justifyContent: "flex-start" }}
          >
            {propuesta ? (
              propuesta.actividades.length === 0 ? (
                <div className="div-comentarios-o-actividad-vacio">
                  <p>Sin actividad</p>
                </div>
              ) : (
                <TableWithActions
                  columns={["ACTIVIDAD", "HORA", "ESTATUS"]}
                  data={parserActividades(propuesta.actividades).reverse()}
                />
              )
            ) : (
              <div className="div-comentarios-o-actividad-vacio">
                <p>Sin actividad</p>
              </div>
            )}
          </div>
        )}
      </section>

      {propuesta ? (
        <ModalEstandar visibilidad={modalPreview} setModal={setModalPreview}>
          <PreviewPropuestas
            titulo={propuesta.titulo}
            fecha={propuesta.fecha_publicacion}
            contenido={propuesta.contenido}
            etiquetas={propuesta.etiquetas}
            imagen={propuesta.imagen}
            setModal={setModalPreview}
          />
        </ModalEstandar>
      ) : null}

      <ModalEstandar visibilidad={modal} setModal={setModal}>
        <EscalarPropuesta setModal={setModal} />
      </ModalEstandar>
      {/* <img
        id="imagen-descargar-propuesta"
        className="imagenes-invisibles-descarga"
        src={
          propuestaADetalle
            ? "htts://api.pc.tarapacanuestra.cl" + propuestaADetalle.imagen
            : ""
        }
        onLoad={(event) => {
          domtoimage
            .toJpeg(event.target, {
              quality: 0.95,
            })
            .then(function (dataUrl) {
              setImagenParaDescargar(dataUrl);
              event.target.style.display = "none";
            });
        }}
      /> */}
    </div>
  );
};

export default Propuesta;
