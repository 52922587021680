import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Container,
  InputGroup,
  Button,
  Form,
  Dropdown,
} from "react-bootstrap";
import "./Ajustes.css";

// Iconos
import {
  User,
  XCircle,
  ChevronDown,
  Link as LinkIcon,
  ChevronUp,
} from "react-feather";
import Building from "../../assets/Iconos especiales/building.svg";

//Actions
import { postCambiarPassword } from "../../redux/actions/administradoresActions";
import { getCatalogoUsuarios } from "../../redux/actions/ciudadanosActions";
import {
  getPoliticasPrivacidad,
  putPoliticasPrivacidad,
} from "../../redux/actions/politicasPrivacidadActions";
import * as ConfigActions from "../../redux/actions/configuracionActions";
import * as userActions from "../../redux/actions/userActions";
//Selectors
import { user } from "../../redux/selectors/rootSelectors";
import * as ConfigSelectors from "../../redux/selectors/configuracionSelectors";
import { catalogoUsuariosResult } from "../../redux/selectors/ciudadanosSelectors";
import {
  politicasDePrivacidadResult,
  politicasDePrivacidadResult as politicasDePrivacidadResultSelector,
} from "../../redux/selectors/politicasPrivacidadSelectors";

const Ajustes = () => {
  const dispatch = useDispatch();
  const usuario = useSelector((state) => user(state));
  const catalogoUsuarios = useSelector((state) =>
    catalogoUsuariosResult(state)
  );
  const politicasDePrivacidad = useSelector((state) =>
    politicasDePrivacidadResultSelector(state)
  );
  const [pass1, setPass1] = useState();
  const [pass2, setPass2] = useState();
  const [
    politicasDePrivacidadNuevas,
    setPoliticasDePrivacidadNuevas,
  ] = useState();
  const [avatar, setAvatar] = useState(0);
  const [porcentajeNuevoApoyos, setPorcentajeNuevoApoyos] = useState();
  const [idPutPorcentajeApoyos, setIdPutPorcentajeApoyos] = useState();
  const successFlag = useSelector((state) =>
    ConfigSelectors.successFlagResult(state)
  );

  const handleCambiarPassword = () => {
    dispatch(
      postCambiarPassword({
        new_password1: pass1,
        new_password2: pass2,
      })
    );
  };
  useEffect(() => {
    if (successFlag) {
      dispatch(userActions.refreshUser());
      dispatch(ConfigActions.resetSuccessFlag());
    }
  }, [successFlag]);

  const handleGuardarPoliticasPrivacidad = () => {
    dispatch(
      putPoliticasPrivacidad({
        id: politicasDePrivacidad.id,
        body: politicasDePrivacidadNuevas,
      })
    );
  };
  const avatares = useSelector((state) =>
    ConfigSelectors.avataresResult(state)
  );
  const porcentajeApoyos = useSelector((state) =>
    ConfigSelectors.porcentajeApoyoResult(state)
  );
  const handleCambiarAvatar = () => {
    dispatch(ConfigActions.actualizarAvatar({ avatar: avatar.img }));
  };
  const permisoCambiarPoliticas = (userId) => {
    if (Array.isArray(catalogoUsuarios)) {
      if (
        catalogoUsuarios.filter(
          (usuario) => Number(usuario.id) === Number(userId)
        )[0] &&
        catalogoUsuarios.filter(
          (usuario) => Number(usuario.id) === Number(userId)
        )[0].roluserPwa
      ) {
        let rol = catalogoUsuarios.filter(
          (usuario) => Number(usuario.id) === Number(userId)
        )[0].roluserPwa.user_rol;
        if (rol === "SA_pwa" || rol === "Adm_pwa" || rol === "S_pwa") {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    dispatch(getPoliticasPrivacidad());
  }, []);

  useEffect(() => {
    if (politicasDePrivacidad) {
      dispatch(ConfigActions.getAvatares());
    }
  }, [politicasDePrivacidad]);

  useEffect(() => {
    if (avatares) {
      dispatch(getCatalogoUsuarios());
    }
  }, [avatares]);

  useEffect(() => {
    if (catalogoUsuarios) {
      dispatch(ConfigActions.getPorcentajeApoyos());
    }
  }, [catalogoUsuarios]);

  useEffect(() => {
    if (porcentajeApoyos) {
      setPorcentajeNuevoApoyos(porcentajeApoyos.value);
      setIdPutPorcentajeApoyos(porcentajeApoyos.id);
    }
  }, [porcentajeApoyos]);

  console.log(usuario, catalogoUsuarios);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link>Ciudadanos</Link>
            <h3 className="titulo-table-actions">Listado de Usuarios</h3>
          </div>

          <Tabs className="tabs-action-table">
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <User /> {"Perfil"}
                </span>
              }
            >
              <Container id="container-perfil-ajustes">
                <Row>
                  <Col>
                    <p className="titulos-seccion-ajustes">Usuario</p>
                  </Col>
                </Row>
                <Row className="row-ajustes-salto">
                  <Col>
                    <label className="labels-ajustes">Nombre</label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        className="input-oscuro-ajustes"
                        defaultValue={usuario.user.first_name}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label className="labels-ajustes">Apellido</label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        className="input-oscuro-ajustes"
                        defaultValue={usuario.user.last_name}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="titulos-seccion-ajustes">
                      Listado de Usuarios
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="labels-ajustes">Contraseña Nueva</label>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        className="input-ajustes"
                        onChange={(event) => setPass1(event.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label className="labels-ajustes">
                      Confirmar Nueva Contraseña
                    </label>
                    <InputGroup>
                      <Form.Control
                        type="password"
                        className="input-ajustes"
                        onChange={(event) => setPass2(event.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-boton-enviar-ajustes">
                    {pass1 === pass2 ? (
                      <Col>
                        <Button
                          className="botones-ajustes"
                          onClick={handleCambiarPassword}
                        >
                          Enviar
                        </Button>
                      </Col>
                    ) : (
                      <Col>
                        <p className="p-password-no-coincide-ajustes">
                          Las contraseñas no coinciden
                        </p>
                      </Col>
                    )}
                  </Col>
                </Row>
                <Row className="row-ajustes-salto">
                  <Col>
                    <section className="seccion-6-configuracion">
                      {/* <label className="labels-configuracion">Nuevo avatar</label> */}
                      <p className="titulos-seccion-ajustes">Avatar</p>
                      <div className="div-div-avatares-configuracion">
                        {avatares &&
                          avatares.map((avatarImg, index) => (
                            <img
                              className={
                                avatar.index === index
                                  ? "icono-avatar-seleccionado-configuracion"
                                  : "icono-avatar-configuracion"
                              }
                              src={
                                "https://api.participacion.management-r.com/display-avatar/" +
                                avatarImg
                              }
                              alt={`Avatar: ${avatarImg}`}
                              onClick={() =>
                                setAvatar({ img: avatarImg, index: index })
                              }
                            />
                          ))}
                      </div>
                    </section>
                    <section className="seccion-3-configuracion">
                      {/* <button
                                                className="botones-guardar-configuracion boton-guardar-avatar-configuracion"
                                                onClick={handleCambiarAvatar}
                                            >
                                                Guardar
                                            </button> */}
                      <Button
                        className="botones-ajustes"
                        onClick={handleCambiarAvatar}
                      >
                        Guardar
                      </Button>
                    </section>
                    {/* <p className="titulos-seccion-ajustes">Cerrar Todas las Sesiones</p>
                                        <Button className="botones-ajustes">
                                            Cerrar Sesion
                                            <XCircle />
                                        </Button> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="titulos-seccion-ajustes">
                      Grupo Organigrama GoRe y Periféricos
                    </p>
                  </Col>
                </Row>
                <Row className="row-ajustes-salto">
                  <Col>
                    <label className="labels-ajustes">
                      Nombre de la Organizacion
                    </label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        className="input-oscuro-ajustes"
                        defaultValue="Ayuntamiento"
                        readOnly={true}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label className="labels-ajustes">Puesto</label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        className="input-oscuro-ajustes"
                        readOnly={true}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="labels-ajustes">Email</label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        className="input-oscuro-ajustes"
                        defaultValue={usuario.user.email}
                        readOnly={true}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label className="labels-ajustes">DNI</label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        className="input-oscuro-ajustes"
                        defaultValue={usuario.user.username}
                        readOnly={true}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
            </Tab>
            {/* <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <LinkIcon /> {"Integraciones"}
                </span>
              }
            >
              <Container id="container-perfil-ajustes">
                <Row>
                  <Col>
                    <p className="titulos-seccion-ajustes">Usuario</p>
                  </Col>
                </Row>
                <Row className="row-ajustes-salto">
                  <Col>
                    <label className="labels-ajustes">Nombre</label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        className="input-oscuro-ajustes"
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label className="labels-ajustes">Apellido</label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        className="input-oscuro-ajustes"
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="titulos-seccion-ajustes">
                      Listado de Usuarios
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="labels-ajustes">Contraseña Nueva</label>
                    <InputGroup>
                      <Form.Control type="password" className="input-ajustes" />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label className="labels-ajustes">
                      Confirmar Nueva Contraseña
                    </label>
                    <InputGroup>
                      <Form.Control type="password" className="input-ajustes" />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-boton-enviar-ajustes">
                    {true && (
                      <Col>
                        <Button className="botones-ajustes">Enviar</Button>
                      </Col>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="titulos-seccion-ajustes">Servidor</p>
                  </Col>
                </Row>
                <Row className="row-ajustes-salto">
                  <Col>
                    <label className="labels-ajustes">Servidor</label>
                    <InputGroup>
                      <Form.Control type="text" className="input-ajustes" />
                    </InputGroup>
                  </Col>
                  <Col>
                    <label className="labels-ajustes">Puerto</label>
                    <InputGroup>
                      <Form.Control type="text" className="input-ajustes" />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="titulos-seccion-ajustes">TIPO</p>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "25px" }}>
                  <Col>
                    <label className="labels-ajustes">
                      Nombre de la Organización
                    </label>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-organizacion-integraciones-ajustes">
                        <p>Texto</p>
                        <ChevronDown />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <p>Opción 1</p>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <p>Opción 2</p>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col className="col-botones-tipo-integraciones-ajustes">
                    <Button className="botones-ajustes">Añadir</Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="labels-ajustes">
                      Nombre de la Organización
                    </label>
                    <InputGroup>
                      <Form.Control type="text" className="input-ajustes" />
                    </InputGroup>
                  </Col>
                  <Col className="col-botones-tipo-integraciones-ajustes">
                    <Button className="botones-ajustes">Invitar</Button>
                  </Col>
                </Row>
              </Container>
            </Tab> */}
            <Tab
              eventKey={3}
              title={
                <span className="tab-span-action-table">
                  <img src={Building} /> {"Sistema"}
                </span>
              }
            >
              <Container id="container-perfil-ajustes">
                <Row>
                  <Col>
                    <p className="titulos-seccion-ajustes">Sistema</p>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "25px" }}>
                  <Col>
                    <label className="labels-ajustes">
                      Cantidad mínima de apoyos para escalar propuesta
                    </label>
                    <div>
                      <input
                        type="number"
                        defaultValue={
                          porcentajeApoyos ? porcentajeApoyos.value : null
                        }
                        id="input-numero-votos-ajustes"
                        className="input-ajustes"
                        onChange={(event) =>
                          setPorcentajeNuevoApoyos(event.target.value)
                        }
                      />
                    </div>
                  </Col>
                  <Col className="col-botones-tipo-integraciones-ajustes col-boton-guardar-numvotos">
                    {porcentajeApoyos && porcentajeApoyos.id && (
                      <Button
                        className="botones-ajustes "
                        onClick={() =>
                          dispatch(
                            ConfigActions.putPorcentajeApoyos({
                              id: idPutPorcentajeApoyos,
                              body: {
                                name: "votos_mínimos",
                                setting_type: "int",
                                value: porcentajeNuevoApoyos,
                              },
                            })
                          )
                        }
                      >
                        Guardar
                      </Button>
                    )}
                  </Col>
                </Row>
                {/* <Row style={{ marginBottom: "25px" }}>
                  <Col>
                    <label className="labels-ajustes">
                      Enlace para video de bienvenida
                    </label>
                    <div>
                      <input
                        type="number"
                        id="input-numero-votos-ajustes"
                        className="input-ajustes"
                      />
                    </div>
                  </Col>
                  <Col className="col-botones-tipo-integraciones-ajustes col-boton-guardar-numvotos">
                    <Button className="botones-ajustes ">Guardar</Button>
                  </Col>
                </Row> */}
                <Row>
                  <Col>
                    <label className="labels-ajustes">
                      Actualización de Aviso de Privacidad
                    </label>
                    <div>
                      <textarea
                        rows="10"
                        cols="50"
                        className="aviso-privacidad-textarea"
                        onChange={(event) =>
                          setPoliticasDePrivacidadNuevas(event.target.value)
                        }
                        defaultValue={
                          politicasDePrivacidad.politicas
                          // ? politicasDePrivacidad.politicas
                          // : "Las politicas de privacidad no pudieron ser cargadas"
                        }
                        readOnly={!permisoCambiarPoliticas(usuario.id)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-boton-guardar-aviso-privacidad">
                    {permisoCambiarPoliticas(usuario.id) && (
                      <Button
                        className="botones-ajustes"
                        onClick={handleGuardarPoliticasPrivacidad}
                      >
                        Guardar
                      </Button>
                    )}
                  </Col>
                </Row>
              </Container>
            </Tab>
          </Tabs>
        </Container>
      </Row>
    </div>
  );
};

export default Ajustes;
