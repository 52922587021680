import React from "react";
//Redux
import { useSelector } from "react-redux";
import * as userError from "../../redux/selectors/rootSelectors";
//React-Router
import { Link } from "react-router-dom";

//Bostrap
import { Form, Button, Card, Row, Col } from "react-bootstrap";
//Components
import HeaderMobile from "./HeaderMobile";

const LoginForm = (props) => {
  const { handleSubmit, validated } = props;
  const error = useSelector((state) => userError.error(state));
  const [datos, setDatos] = React.useState({
    user: "",
    password: "",
  });

  const handleDatos = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Col sm={6}>
      <Row
        className="d-flex justify-content-center align-content-center"
        style={{ height: "100%" }}
      >
        <Col
          xs={12}
          className="d-sm-none header-registro"
          style={{ position: "absolute", padding: "0.5rem" }}
        >
          <HeaderMobile />
        </Col>
        <Col xs={11} sm={9}>
          <Card className="card-container login">
            <h3 className="login__title">Iniciar sesión</h3>
            <Form
              noValidate
              // validated={validated}
              onSubmit={(e) => handleSubmit(e, datos)}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Label style={{ color: "#1069B4" }}>
                  DNI
                </Form.Label>
                <Form.Control
                  // required
                  type="text"
                  placeholder="Ej. 00523821F"
                  // minLength="9"
                  // maxLength="10"
                  // pattern="(\d{7,8}[-]\w)"
                  name="user"
                  onChange={handleDatos}
                  value={datos.user}
                />
                <Form.Control.Feedback type="invalid">
                  RUN no válido
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label style={{ color: "#1069B4" }}>Contraseña</Form.Label>
                <Form.Control
                  // required
                  type="password"
                  placeholder="**********"
                  name="password"
                  onChange={handleDatos}
                  value={datos.password}
                />
                <Form.Text className="text-muted "></Form.Text>
                <Form.Control.Feedback type="invalid">
                  Contraseña requerida
                </Form.Control.Feedback>
              </Form.Group>
              <Link to="/recuperacion">No recuerdo mi contraseña</Link>
              <Button type="submit" className="submit-login" block>
                Iniciar sesión
              </Button>
              <Form.Label className="error-label">{error}</Form.Label>
            </Form>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default LoginForm;
