import React, { useEffect, useState } from "react";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
} from "react-bootstrap";
import { Inbox, Archive } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// Components
import TableWithActions from "../../components/ActionTable/index";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import BorrarAccion from "./BorrarAccion";

//Actions
import { getAcciones, putAccion } from "../../redux/actions/accionesActions";

// Selectors
import {
  accionesResult,
  updateAccionesResult,
} from "../../redux/selectors/accionesSelectors";

const columns = [
  "NOMBRE",
  "ESTATUS",
  "PROYECTO",
  "FECHA DE CREACIÓN",
  "ACCIONES",
];

const Acciones = () => {
  const dispatch = useDispatch();
  const acciones = useSelector((state) => accionesResult(state));
  const actualizaAcciones = useSelector((state) => updateAccionesResult(state));
  const history = useHistory();
  const [modalDelete, setModalDelete] = useState(false);
  const [accionABorrar, setAcccionABorrar] = useState();
  const [activos, setActivos] = useState();
  const [archivados, setArchivados] = useState();

  const renderEstatus = (estatus) => {
    const elemento = (color, texto) => {
      return (
        <div className="item-dropdown-estatus-propuesta">
          <div className={`bolita-estatus bolita-estatus-${color}`}></div>
          {texto}
        </div>
      );
    };
    switch (estatus) {
      case "B":
        return elemento("azul", "Borrador");
      case "A":
        return elemento("verde", "Aprobado");
      case "T":
        return elemento("negro", "Terminado");
      default:
    }
  };

  const dataParser = (data) => {
    return data
      .map((accion) => {
        return {
          id: accion.id,
          nombre: accion.titulo,
          estatus: renderEstatus(accion.estado),
          proyecto: accion.campaign ? accion.campaign.nombre : "Sin Proyecto",
          duracion: accion.fecha.slice(0, 10),
          accion: (
            <Link to={`/acciones/detalles/${accion.id}`}>Editar Acción</Link>
          ),
        };
      })
      .filter((element) => element !== undefined);
  };

  const borrarAccion = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      setModalDelete(true);
      let obj = {};
      acciones.forEach((accion) => {
        if (accion.id === arrayDeIds[0]) {
          obj = {
            id: arrayDeIds[0],
            nombre: accion.titulo,
          };
        }
      });
      setAcccionABorrar(obj);
    }
  };

  const archivarAccion = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      dispatch(putAccion({ id: arrayDeIds[0], body: { estado: "T" } }));
    }
  };

  useEffect(() => {
    if (actualizaAcciones) {
      dispatch(getAcciones());
    }
  }, [actualizaAcciones]);

  useEffect(() => {
    dispatch(getAcciones());
  }, []);

  useEffect(() => {
    if (acciones) {
      // let activas = acciones.filter(((accion)=>accion.disponibilidad === "1"))
      // let archivadas = acciones.filter(((accion)=>accion.disponibilidad === "2"))
      let activas = acciones.filter(
        (accion) => accion.estado === "A" || accion.estado === "B"
      );
      let archivadas = acciones.filter((accion) => accion.estado === "T");
      setActivos(activas);
      setArchivados(archivadas);
    }
  }, [acciones]);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link>Acciones</Link>
            <h3 className="titulo-table-actions">Listado de Acciones</h3>
          </div>

          <Tabs className="tabs-action-table">
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <Inbox /> {"Activos"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      activos
                        ? dataParser(activos.sort((a, b) => b.id - a.id))
                        : []
                    }
                    selectable={true}
                    firstColumnLink={false}
                    delete={true}
                    deleteFunction={borrarAccion}
                    archive={true}
                    archiveFunction={archivarAccion}
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() => history.push("/acciones/detalles")}
                    textForCreateNew={"Crear Acción"}
                    messageWhenEmpty={"Crea una acción para comenzar"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <Archive /> {"Archivados"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      archivados
                        ? dataParser(archivados.sort((a, b) => b.id - a.id))
                        : []
                    }
                    selectable={true}
                    firstColumnLink={false}
                    delete={true}
                    deleteFunction={borrarAccion}
                    searchBar={true}
                    messageWhenEmpty={"No hay acciones archivadas"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modalDelete} setModal={setModalDelete}>
        <BorrarAccion
          setModal={setModalDelete}
          nombreDeAccion={accionABorrar ? accionABorrar.nombre : ""}
          idDeAccion={accionABorrar ? accionABorrar.id : ""}
        />
      </ModalEstandar>
    </div>
  );
};

export default Acciones;
