import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from '../services/authServices'

export function* crearNuevaEncuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "encuesta/encuestas/",
      payload,
      {
        "Content-Type": "application/json",
        "Host": "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Nueva encuesta creada satisfactoriamente", payload);
    yield put({ type: types.POST_ENCUESTA_SUCCESS, results });
  } catch (e) {
    console.log("Error al crear nueva encuesta :", e);
    yield put({ type: types.POST_ENCUESTA_ERROR, payload });
  }
}


export function* getEncuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "encuesta/encuestas/",
      null,
      {
        "Content-Type": "application/json",
        "Host": "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Encuestas solicitadas satisfactoriamente");
    yield put({ type: types.GET_ENCUESTAS_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar encuestas :", e);
    yield put({ type: types.GET_ENCUESTAS_ERROR, payload });
  }
}

export function* getEncuestasVencidasSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "encuesta/detalle/encuesta/?detalle=vencidas",
      null,
      {
        "Content-Type": "application/json",
        "Host": "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Encuestas vencidas solicitadas satisfactoriamente");
    yield put({ type: types.GET_ENCUESTAS_VENCIDAS_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar encuestas vencidas:", e);
    yield put({ type: types.GET_ENCUESTAS_VENCIDAS_ERROR, payload });
  }
}

export function* actualizaEncuestasSaga({ payload }) {
  try {
    const results = payload;
    yield put({ type: types.UPDATE_ENCUESTAS_SUCCESS, results });
  } catch (e) {
    console.log(e);
    yield put({ type: types.UPDATE_ENCUESTAS_ERROR, payload });
  }
}

export function* getUsuariosQueRespondieronSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `encuesta/detalle/encuesta/?detalle=usuarios&encuestas=${JSON.stringify(
        payload
      )}`,
      null,
      {
        "Content-Type": "application/json",
        "Host": "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    // const results = {data: data};
    console.log(
      "Listas de usuarios que respondieron solicitadas satisfactoriamente"
    );
    yield put({ type: types.GET_USUARIO_QUE_RESPONDIERON_SUCCESS, results });
  } catch (e) {
    console.log(
      "Hubo un error al solicitar la lista de usuarios que respondieron :",
      e
    );
    yield put({ type: types.GET_USUARIO_QUE_RESPONDIERON_ERROR, payload });
  }
}

export function* getRespuestasDeUsuarioSaga({ payload }) {
  console.log(payload);
  try {
    const results = yield call(
      apiCall,
      `encuesta/detalle/encuesta/?detalle=respuestas&encuesta=${payload.encuesta}&usuario=${payload.usuario}`,
      null,
      {
        "Content-Type": "application/json",
        "Host": "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    // const results = {data: data};
    console.log("Respuestas de usuario solicitadas satisfactoriamente");
    yield put({ type: types.GET_RESPUESTA_DE_USUARIO_SUCCESS, results });
  } catch (e) {
    console.log("Hubo un error al solicitar respuestas de usuarios:", e);
    yield put({ type: types.GET_RESPUESTA_DE_USUARIO_ERROR, payload });
  }
}

export function* deleteEncuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `encuesta/encuestas/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        "Host": "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    console.log("Encuesta eliminada satisfactoriamente");
    yield put({ type: types.DELETE_ENCUESTA_SUCCESS, results });
  } catch (e) {
    console.log("Hubo un error al eliminar la encuesta:", e);
    yield put({ type: types.DELETE_ENCUESTA_ERROR, payload });
  }
}

export function* getEstadisticasEncuestasSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `encuesta/detalle/encuesta/?detalle=encuesta&referencias=[${payload}]`,
      null,
      {
        "Content-Type": "application/json",
        "Host": "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Estadísticas de encuestas solicitadas satisfactoriamente");
    yield put({ type: types.GET_ESTADISTICAS_ENCUESTAS_SUCCESS, results });
  } catch (e) {
    console.log("Hubo un error al solicitar estadísticas de enecuestas:", e);
    yield put({ type: types.GET_ESTADISTICAS_ENCUESTAS_ERROR, payload });
  }
}

export function* putEncuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `encuesta/encuestas/${payload.id}/`,
      payload.body,
      {
        "Content-Type": "application/json",
        "Host": "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Encuesta actualizada satisfactoriamente");
    yield put({ type: types.PUT_ENCUESTA_SUCCESS, results });
  } catch (e) {
    console.log("Hubo un error al actualizar encuesta:", e);
    yield put({ type: types.PUT_ENCUESTA_ERROR, payload });
  }
}

export default function* encuestasSaga() {
  yield takeLatest(types.POST_ENCUESTA, crearNuevaEncuestaSaga);
  yield takeLatest(types.GET_ENCUESTAS, getEncuestaSaga);
  yield takeLatest(types.GET_ENCUESTAS_VENCIDAS, getEncuestasVencidasSaga);
  yield takeLatest(types.UPDATE_ENCUESTAS, getEncuestaSaga);
  yield takeLatest(
    types.GET_USUARIO_QUE_RESPONDIERON,
    getUsuariosQueRespondieronSaga
  );
  yield takeLatest(types.GET_RESPUESTA_DE_USUARIO, getRespuestasDeUsuarioSaga);
  yield takeLatest(types.DELETE_ENCUESTA, deleteEncuestaSaga);
  yield takeLatest(types.GET_ESTADISTICAS_ENCUESTAS, getEstadisticasEncuestasSaga);
  yield takeLatest(types.PUT_ENCUESTA, putEncuestaSaga);
}
