import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as Services from "../services/authServices";
import * as types from "../../consts/actionTypes";
import { getData } from "../../consts/fakeData";

export function* getReportesTemasCalientesSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `reportes/reports-hot/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield put({ type: types.GET_REPORTES_TEMAS_CALIENTES_SUCCESS, results });
  } catch (error) {
    yield put({ type: types.GET_REPORTES_TEMAS_CALIENTES_ERROR, error });
  }
}

export function* getReportesPropuestasSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `reportes/reports-proposals/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield put({ type: types.GET_REPORTES_PROPUESTAS_SUCCESS, results });
  } catch (error) {
    yield put({ type: types.GET_REPORTES_PROPUESTAS_ERROR, error });
  }
}

export function* getReportesSolicitudesSaga({ payload }) {
  try {
    let results = yield call(
      apiCall,
      `reportes/reports-siac-request/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    // let results = yield call(getData, "SIAC");
    results.data.atendidas = results.data.atendidas.map((atendida) => {
      return { first_name: atendida, last_name: "", area: "" };
    });
     let arrayUsers=results.data.atendidas.map((atendida) => {
        return { first_name: atendida.first_name, last_name: "", area: "" };
    });
    let uniqueUsers = new Set(arrayUsers.map(e => JSON.stringify(e)));
    uniqueUsers = Array.from(uniqueUsers).map(e => JSON.parse(e));
    results.data.usuarios = uniqueUsers
    yield put({ type: types.GET_REPORTES_SOLICITUDES_SUCCESS, results });
  } catch (error) {
    yield put({ type: types.GET_REPORTES_SOLICITUDES_ERROR, error });
  }
}

export function* getReportesComentariosSaga({ payload }) {
  try {
    let results = yield call(
      apiCall,
      `reportes/reports-comments/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    // let results = yield call(getData, "comentarios_intervenidos");
    results.data.intervenidos_y_censurados = results.data.intervenidos_y_censurados.map((comentario) => {
      return Object.assign(comentario, { first_name: comentario.actor, last_name: "", area: "" });
    });
     let arrayUsers=results.data.intervenidos_y_censurados.map((comentario) => {
        return { first_name: comentario.actor, last_name: "", area: "" };
    });
    let uniqueUsers = new Set(arrayUsers.map(e => JSON.stringify(e)));
    uniqueUsers = Array.from(uniqueUsers).map(e => JSON.parse(e));
    results.data.usuarios = uniqueUsers

    yield put({ type: types.GET_REPORTES_COMENTARIOS_SUCCESS, results });
  } catch (error) {
    alert(error)
    yield put({ type: types.GET_REPORTES_COMENTARIOS_ERROR, error });
  }
}

export function* getReportesPropuestasEscalablesSaga({ payload }) {
  try {
    let results = yield call(
      apiCall,
      `reportes/reports-scalables/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );

    // let results = yield call(getData, "propuestas_escalables");
    results.data.escaladas = results.data.escaladas.map((escalada) => {
      return Object.assign(escalada, { first_name: escalada.actor, last_name: "", area: "" });
    });
     let arrayUsers=results.data.escaladas.map((escalada) => {
        return { first_name: escalada.actor, last_name: "", area: "" };
    });
    let uniqueUsers = new Set(arrayUsers.map(e => JSON.stringify(e)));
    uniqueUsers = Array.from(uniqueUsers).map(e => JSON.parse(e));
    results.data.usuarios = uniqueUsers
    
    yield put({
      type: types.GET_REPORTES_PROPUESTAS_ESCALABLES_SUCCESS,
      results,
    });
  } catch (error) {
    yield put({ type: types.GET_REPORTES_PROPUESTAS_ESCALABLES_ERROR, error });
  }
}

export function* getReportesTiemposRevisionSaga({ payload }) {
  try {
    let results = yield call(
      apiCall,
      `reportes/reports-answer-time/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    // let results = yield call(getData, "tiempos_revision");
    results.data = results.data.map((tiempo) => {
      return Object.assign(tiempo, { first_name: tiempo.autor, last_name: "", area: "" });
    });
    let arrayUsers=results.data.map((tiempo) => {
        return {first_name: tiempo.autor, last_name: "", area: "" };
    });
    let uniqueUsers = new Set(arrayUsers.map(e => JSON.stringify(e)));
    uniqueUsers = Array.from(uniqueUsers).map(e => JSON.parse(e));
    results.data.usuarios = uniqueUsers

    yield put({
      type: types.GET_REPORTES_TIEMPOS_REVISION_SUCCESS,
      results,
    });
  } catch (error) {
    yield put({ type: types.GET_REPORTES_TIEMPOS_REVISION_ERROR, error });
  }
}

export function* getReportesMapaCalorSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `reportes/reports-heatmap/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield put({
      type: types.GET_REPORTES_MAPA_CALOR_SUCCESS,
      results,
    });
  } catch (error) {
    yield put({ type: types.GET_REPORTES_MAPA_CALOR_ERROR, error });
  }
}

export function* getReportesInteraccionesSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `reportes/reports-activity/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield put({
      type: types.GET_REPORTES_INTERACCIONES_SUCCESS,
      results,
    });
  } catch (error) {
    yield put({ type: types.GET_REPORTES_INTERACCIONES_ERROR, error });
  }
}

export function* getReportesTableroSaga({ payload }) {
  try {
    let results = yield call(
      apiCall,
      `reportes/reports-heatmap/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield put({
      type: types.GET_REPORTES_TABLERO_MAPA_SUCCESS,
      results,
    });
    results = yield call(
      apiCall,
      `reportes/reports-hot/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield put({ type: types.GET_REPORTES_TABLERO_TEMAS_SUCCESS, results });
    results = yield call(
      apiCall,
      `reportes/reports-proposals/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield put({ type: types.GET_REPORTES_TABLERO_PROPUESTAS_SUCCESS, results });
  } catch (error) {
    yield put({ type: types.GET_REPORTES_TABLERO_ERROR, error });
  }
}

export default function* accionSagas() {
  yield takeLatest(types.GET_REPORTES_TEMAS_CALIENTES, getReportesTemasCalientesSaga);
  yield takeLatest(types.GET_REPORTES_PROPUESTAS, getReportesPropuestasSaga);
  yield takeLatest(types.GET_REPORTES_SOLICITUDES, getReportesSolicitudesSaga);
  yield takeLatest(types.GET_REPORTES_COMENTARIOS, getReportesComentariosSaga);
  yield takeLatest(types.GET_REPORTES_PROPUESTAS_ESCALABLES, getReportesPropuestasEscalablesSaga);
  yield takeLatest(types.GET_REPORTES_TIEMPOS_REVISION, getReportesTiemposRevisionSaga);
  yield takeLatest(types.GET_REPORTES_MAPA_CALOR, getReportesMapaCalorSaga);
  yield takeLatest(types.GET_REPORTES_INTERACCIONES, getReportesInteraccionesSaga);
  yield takeLatest(types.GET_REPORTES_TABLERO, getReportesTableroSaga);
}
