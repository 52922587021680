import * as Selector from "./redux/selectors/rootSelectors";
import { useDispatch, useSelector } from "react-redux";
import React, { Suspense, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import PrivateRoute from "./components/routes/privateRoute";
import LoadingGeneral from "./components/LoadingGeneral";
import Busqueda from "./views/Busqueda";
// import Chat from "./views/Conversaciones/componentes/Chat"
import Conversations from "./views/Conversaciones/componentes/Conversations";
// import {token} from './redux/api/pwa_api'
import { verifyToken, refreshToken } from "./redux/actions/userActions";

import Conversaciones from "./views/Conversaciones/Conversaciones";
import Reportes from "./views/Reportes/Reportes";
import DetalleReporte from "./views/Reportes/DetalleReporte";
import Agrupacion from "./views/Agrupacion/Agrupacion";
import Acciones from "./views/Acciones/Acciones";
import DetalleAccion from "./views/Acciones/DetalleAccion";
import Proyectos from "./views/Proyectos/Proyectos";
import DetalleProyecto from "./views/Proyectos/DetalleProyecto";
import Escalamientos from "./views/Escalamientos/Escalamientos";
import Ciudadanos from "./views/Ciudadanos/Ciudadanos";
import Administradores from "./views/Administradores/Administradores";
import ActividadAdministrador from "./views/Administradores/ActividadAdministrador";
import Encuestas from "./views/Encuestas/Encuestas";
import Propuestas from "./views/Propuestas/Propuestas";
import Propuesta from "./views/Propuestas/Propuesta";
import Preguntas from "./views/Preguntas/Preguntas";
import PreguntasPredeterminadas from "./views/Preguntas/PreguntasPredeterminadas";
import Respuestas from "./views/Respuestas/Respuestas";
import DetalleRespuestas from "./views/Respuestas/DetalleRespuestas";
import Solicitudes from "./views/Solicitudes/Solicitudes";
import Login from "./views/Login/Login";
import DetallesSolicitud from "./views/Solicitudes/DetallesSolicitud";
import Notificaciones from "./views/Notifications/Notificaciones";
import Ajustes from "./views/Ajustes/Ajustes";
import Tablero from "./views/Dashboard/inicio";
import Recuperacion from "./views/Recuperacion/Recuperacion";
import Restablecer from "./views/Recuperacion/Restablecer";
import Inicio from "./views/Tendencias/index.jsx";
import NotFound from "./views/NotFound";

//const Sidebar = React.lazy(() => import("./components/sidebar/sidebar"));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

function App() {
  //const token = localStorage.getItem("token")
  const user = useSelector((state) => Selector.user(state));
  const dispatch = useDispatch();
  //const logged = useSelector((state) => Selector.logged(state));
  const token = localStorage.getItem("access");
  const tokenRefresh = localStorage.getItem("refresh");
  const isValid = useSelector((state) => Selector.isValid(state));

  const verifyTokenTrue = async () => {
    dispatch(verifyToken(token));
    if (!isValid) {
      dispatch(refreshToken(tokenRefresh));
    }
  };

  useEffect(() => {
    if (token !== "" && token !== null) verifyTokenTrue();
  }, [isValid]);

  return (
    <Router>
      <Suspense fallback={loading}>
        <LoadingGeneral />
        <div>
          <Switch>
            <Route exact path="/login" name="Dashboard" component={Login} />
            <Route
              exact
              path="/recuperacion"
              name="Recuperacion"
              component={Recuperacion}
            />
            <Route
              exact
              path="/restablecer/:noUse/:uid/:token"
              name="Restablecer"
              component={Restablecer}
            />
            <PrivateRoute exact path="/" name="Inicio" component={Inicio} />
            <PrivateRoute
              exact
              path="/reportes"
              name="Reportes"
              component={Reportes}
            />
            <PrivateRoute
              exact
              path="/reportes/:tipo/:fechas"
              name="Reporte"
              component={DetalleReporte}
            />
            <PrivateRoute
              exact
              path="/tablero"
              name="Tablero"
              component={Tablero}
            />
            <PrivateRoute
              exact
              path="/busqueda/:query"
              name="Busqueda"
              component={Busqueda}
            />
            <PrivateRoute
              exact
              path="/agrupacion"
              name="Agrupacion"
              component={Agrupacion}
            />
            <PrivateRoute
              exact
              path="/conversaciones"
              name="Conversaciones"
              component={Conversaciones}
            />
            <PrivateRoute
              exact
              path="/conversacion/:id"
              name="Preguntas"
              component={Conversations}
            />
            <PrivateRoute
              exact
              path="/votaciones"
              name="Votacion"
              component={Encuestas}
            />
            <PrivateRoute
              exact
              path="/votaciones/:idEncuesta"
              name="Encuestas"
              component={Encuestas}
            />
            <PrivateRoute
              exact
              path="/preguntas/predeterminadas"
              name="Preguntas"
              component={PreguntasPredeterminadas}
            />
            <PrivateRoute
              exact
              path="/preguntas/:idEncuesta"
              name="Preguntas"
              component={Preguntas}
            />
            <PrivateRoute
              exact
              path="/preguntas/:id"
              name="Preguntas"
              component={Preguntas}
            />
            <PrivateRoute
              exact
              path="/respuestas"
              name="Respuestas"
              component={Respuestas}
            />
            <PrivateRoute
              exact
              path="/respuestas/detalles/:nombreEncuesta/:idUser/:hora"
              name="Detalle Respuestas"
              component={DetalleRespuestas}
            />
            <PrivateRoute
              exact
              path="/propuestas"
              name="Propuestas"
              component={Propuestas}
            />
            <PrivateRoute
              exact
              path="/propuestas/:id"
              name="PropuestasCrear"
              component={Propuesta}
            />
            <PrivateRoute
              exact
              path="/solicitudes"
              name="Solicitudes"
              component={Solicitudes}
            />
            <PrivateRoute
              exact
              path="/solicitudes/detalle/:idSolicitud"
              name="DetalleSolicitud"
              component={DetallesSolicitud}
            />
            <PrivateRoute
              exact
              path="/proyectos"
              name="Proyectos"
              component={Proyectos}
            />
            <PrivateRoute
              exact
              path="/proyectos/:idProyecto"
              name="Proyectos"
              component={Proyectos}
            />
            <PrivateRoute
              exact
              path={"/proyectos/detalles/:id"}
              name="DetalleProyecto"
              component={DetalleProyecto}
            />
            <PrivateRoute
              exact
              path={"/proyectos/detalles/:idProyecto/:nombreProyecto/crearAccion"}
              name="DetalleProyecto"
              component={DetalleAccion}
            />
            <PrivateRoute
              exact
              path={"/proyectos/detalles/:idProyecto/accion/:idAccion"}
              name="DetalleProyecto"
              component={DetalleAccion}
            />
            <PrivateRoute
              exact
              path="/acciones"
              name="Acciones"
              component={Acciones}
            />
            <PrivateRoute
              exact
              path={"/acciones/detalles"}
              name="DetalleAccion"
              component={DetalleAccion}
            />
            <PrivateRoute
              exact
              path={"/acciones/detalles/:idAccion"}
              name="DetalleAccion"
              component={DetalleAccion}
            />
            <PrivateRoute
              exact
              path={"/escalamientos"}
              name="Escalamientos"
              component={Escalamientos}
            />
            <PrivateRoute
              exact
              path={"/escalamientos/detalle/:id"}
              name="DetalleEscalamiento"
              component={Propuesta}
            />
            <PrivateRoute
              exact
              path="/notificaciones"
              name="notificaciones"
              component={Notificaciones}
            />
            <PrivateRoute
              exact
              path="/ajustes"
              name="Ajustes"
              component={Ajustes}
            />
            <PrivateRoute
              exact
              path="/ciudadanos"
              name="ciudadanos"
              component={Ciudadanos}
            />
            <PrivateRoute
              exact
              path="/administradores"
              name="administradores"
              component={Administradores}
            />
            <PrivateRoute
              exact
              path="/administradores/actividad/:id"
              name="actividadAdministrador"
              component={ActividadAdministrador}
            />
            {user && isValid && (
              <PrivateRoute
                component={NotFound}
                name="404"
                isAuthenticated={true}
              />
            )}
            <Redirect to="/login" />
          </Switch>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
