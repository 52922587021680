import * as types from "../../consts/actionTypes";

export const getAcciones = (payload) => {
  console.log("Solicitando acciones...");
  return {
    type: types.GET_ACCIONES,
    payload,
  };
};

export const getAccionADetalle = (payload) => {
  console.log("Solicitando accion a detalle...");
  return {
    type: types.GET_ACCION_DETALLE,
    payload,
  };
};

export const postAccion = (payload) => {
  console.log("Creando accion...");
  return {
    type: types.POST_ACCION,
    payload,
  };
};

export const putAccion = (payload) => {
  console.log("Actualizando accion...");
  return {
    type: types.PUT_ACCION,
    payload,
  };
};

export const deleteAccion = (payload) => {
  console.log("Eliminando accion...");
  return {
    type: types.DELETE_ACCION,
    payload,
  };
};

export const postComentarioAccion = (payload) => {
  console.log("Creando comentario de accion...");
  return {
    type: types.POST_COMENTARIO_ACCION,
    payload,
  };
};

export const postRespuestaAccion = (payload) => {
  console.log("Creando respuesta de accion...");
  return {
    type: types.POST_RESPUESTA_ACCION,
    payload,
  };
};

export const putComentarioAccion = (payload) => {
  console.log("Actualizando comentario de accion...");
  return {
    type: types.PUT_COMENTARIO_ACCION,
    payload,
  };
};

export const postVotoComentarioAccion = (payload) => {
  console.log("Añadiendo un voto...");
  return {
    type: types.POST_VOTO_COMENTARIO_ACCION,
    payload,
  };
};

export const deleteComentarioAccion = (payload) => {
  console.log("Eliminando comentario...");
  return {
    type: types.DELETE_COMENTARIO_ACCION,
    payload,
  };
};

export const closeAlert = (payload) => {
  return {
    type: "ALERT_CLOSE_ACCION",
    payload,
  };
};
