import axios from "axios";
import Swal from "sweetalert2";
import { store } from "../store";
import { noLoadingUrls, noErrorAlertUrls } from "./exceptionsUrl";
import TraductorDeErrores from "./TraductorDeErrores"

const baseUrl = "https://api.participacion.management-r.com/";
// const baseUrl = "http://localhost:8080/";

axios.interceptors.request.use(
  function (request) {
    for (let eUrl of noLoadingUrls) {
      if (request.url === baseUrl + eUrl) {
        return request;
      }
    }

    store.dispatch({
      type: "LOADING_GENERAL",
      payload: true,
    });
    return request;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    store.dispatch({
      type: "LOADING_GENERAL",
      payload: false,
    });
    return response;
  },
  function (error) {
    if (
      !noErrorAlertUrls.some(
        (eUrl) => error.request.responseURL === baseUrl + eUrl
      )
    ) {
      Swal.fire({
        title: "Error!",
        text: TraductorDeErrores(Object.values(error.response.data)[0]),
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
    store.dispatch({
      type: "LOADING_GENERAL",
      payload: false,
    });
    return Promise.reject(error);
  }
);

export const token = () => {
  return localStorage.getItem("access");
};

export const apiCall = (url, data, headers, method) =>
  axios({ method, url: baseUrl + url, data, headers }).catch((error) =>
    console.log(error)
  );
