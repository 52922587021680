import * as types from "../../consts/actionTypes";

export const postRegistroAdministrador = (payload) => {
  console.log("Registrando administrador...");
  return {
    type: types.POST_REGISTRO_ADMINISTRADOR,
    payload,
  };
};

export const inhabilitarAdministrador = (payload) => {
  console.log("Inhabilitando administrador...");
  return {
    type: types.DELETE_VALIDACION_USUARIO,
    payload,
  };
};

export const noActualizaListaAdmins = (payload) => {
  return {
    type: types.NO_ACTUALIZA_LISTA_ADMINS,
    payload
  }
}

export const postAsignaRolUsuario = (payload) => {
  console.log("Asignando rol a usuario...")
  return {
    type: types.POST_ASIGNA_ROL_USUARIO,
    payload
  }
}

export const putRolUsuario = (payload) => {
  console.log("Actualizando rol de usuario...")
  return {
    type: types.PUT_ROL_USUARIO,
    payload
  }
}

export const postAceptarPoliticas = (payload) => {
  console.log("Aceptando politicas...")
  return {
    type: types.POST_ACEPTA_POLITICAS_PRIVACIDAD,
    payload
  }
}

export const postAceptarVideoTutorial = (payload) => {
  console.log("Aceptando videotutorial...")
  return {
    type: types.POST_ACEPTA_VIDEO_TUTORIAL,
    payload
  }
}

export const postCambiarPassword = (payload) => {
  console.log("Cambiando contraseña...")
  return {
    type: types.POST_CAMBIAR_PASSWORD,
    payload
  }
}