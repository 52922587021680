
// Encuestas
export const GET_ENCUESTAS = "GET_ENCUESTAS";
export const GET_ENCUESTAS_SUCCESS = "GET_ENCUESTAS_SUCCESS";
export const GET_ENCUESTAS_ERROR = "GET_ENCUESTAS_ERROR";

export const GET_ENCUESTAS_VENCIDAS = "GET_ENCUESTAS_VENCIDAS";
export const GET_ENCUESTAS_VENCIDAS_SUCCESS = "GET_ENCUESTAS_VENCIDAS_SUCCESS";
export const GET_ENCUESTAS_VENCIDAS_ERROR = "GET_ENCUESTAS_VENCIDAS_ERROR";


export const POST_ENCUESTA = "POST_ENCUESTA";
export const POST_ENCUESTA_SUCCESS = "POST_ENCUESTA_SUCCESS";
export const POST_ENCUESTA_ERROR = "POST_ENCUESTA_ERROR";

export const UPDATE_ENCUESTAS = "UPDATE_ENCUESTAS";
export const UPDATE_ENCUESTAS_SUCCESS = "UPDATE_ENCUESTAS_SUCCCESS";
export const UPDATE_ENCUESTAS_ERROR = "UPDATE_ENCUESTAS_ERROR";

export const GET_USUARIO_QUE_RESPONDIERON = "GET_USUARIO_QUE_RESPONDIERON";
export const GET_USUARIO_QUE_RESPONDIERON_SUCCESS = "GET_USUARIO_QUE_RESPONDIERON_SUCCESS";
export const GET_USUARIO_QUE_RESPONDIERON_ERROR = "GET_USUARIO_QUE_RESPONDIERON_ERROR";

export const GET_RESPUESTA_DE_USUARIO = "GET_RESPUESTA_DE_USUARIO"
export const GET_RESPUESTA_DE_USUARIO_SUCCESS = "GET_RESPUESTA_DE_USUARIO_SUCCESS"
export const GET_RESPUESTA_DE_USUARIO_ERROR = "GET_RESPUESTA_DE_USUARIO_ERROR"

export const DELETE_ENCUESTA = "DELETE_ENCUESTA"
export const DELETE_ENCUESTA_SUCCESS = "DELETE_ENCUESTA_SUCCESS"
export const DELETE_ENCUESTA_ERROR = "DELETE_ENCUESTA_ERROR"

export const GET_ESTADISTICAS_ENCUESTAS = "GET_ESTADISTICAS_RESPUESTAS"
export const GET_ESTADISTICAS_ENCUESTAS_SUCCESS = "GET_ESTADISTICAS_ENCUESTAS_SUCCESS"
export const GET_ESTADISTICAS_ENCUESTAS_ERROR = "GET_ESTADISTICAS_ENCUESTAS_ERROR"

export const PUT_ENCUESTA = "PUT_ENCUESTA"
export const PUT_ENCUESTA_SUCCESS = "PUT_ENCUESTA_SUCCESS"
export const PUT_ENCUESTA_ERROR = "PUT_ENCUESTA_ERROR"

//Preguntas
export const GET_PREGUNTA = "GET_PREGUNTA";
export const GET_PREGUNTA_SUCCESS = "GET_PREGUNTA_SUCCESS";
export const GET_PREGUNTA_ERROR = "GET_PREGUNTA_ERROR";

export const POST_PREGUNTA = "POST_PREGUNTA";
export const POST_PREGUNTA_SUCCESS = "POST_PREGUNTA_SUCCESS";
export const POST_PREGUNTA_ERROR = "POST_PREGUNTA_ERROR";

export const DELETE_PREGUNTA = "DELETE_PREGUNTA"
export const DELETE_PREGUNTA_SUCCESS = "DELETE_PREGUNTA_SUCCESS"
export const DELETE_PREGUNTA_ERROR = "DELETE_PREGUNTA_ERROR"

export const PUT_RESPUESTAS = "PUT_PREGUNTA"
export const PUT_RESPUESTAS_SUCCESS = "PUT_PREGUNTA_SUCCESS"
export const PUT_RESPUESTAS_ERROR = "PUT_PREGUNTA_ERROR"

export const ACTUALIZA_ENCUESTAS = "ACTUALIZA_ENCUESTAS"
export const NO_ACTUALIZA_ENCUESTAS = "NO_ACTUALIZA_ENCUESTAS"


//USER
export const LOGIN="LOGIN"
export const LOGIN_SUCCESS="LOGIN_SUCCESS"
export const LOGIN_ERROR ="LOGIN_ERROR"
export const VERIFY_TOKEN ="VERIFY_TOKEN"
export const VALID_TOKEN ="VALID_TOKEN"
export const INVALID_TOKEN ="INVALID_TOKEN"
export const REFRESH_TOKEN ="REFRESH_TOKEN"
export const REFRESH_TOKEN_SUCCESS ="REFRESH_TOKEN_SUCCESS"
export const REFRESH_TOKEN_ERROR ="REFRESH_TOKEN_ERROR"
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS"
export const LOGOUT="LOGOUT"

export const GET_POLITICAS_PRIVACIDAD = "GET_POLITICAS_PRIVACIDAD"
export const GET_POLITICAS_PRIVACIDAD_SUCCESS = "GET_POLITICAS_PRIVACIDAD_SUCCESS"
export const GET_POLITICAS_PRIVACIDAD_ERROR = "GET_POLITICAS_PRIVACIDAD_ERROR"


//RECUPERACION 
export const ENVIAR_CORREO_RECUPERACION = "ENVIAR_CORREO_RECUPERACION"
export const ENVIAR_CORREO_RECUPERACION_SUCCESS = "ENVIAR_CORREO_RECUPERACION_SUCCESS"
export const ENVIAR_CORREO_RECUPERACION_ERROR = "ENVIAR_CORREO_RECUPERACION_ERROR"

export const RESTABLECER_PASSWORD = "RESTABLECER_PASSWORD"
export const RESTABLECER_PASSWORD_SUCCESS = "RESTABLECER_PASSWORD_SUCCESS"
export const RESTABLECER_PASSWORD_ERROR = "RESTABLECER_PASSWORD_ERROR"


//Popuestas
export const GET_PROPUESTAS = "GET_PROPUESTAS";
export const GET_PROPUESTAS_SUCCESS = "GET_PROPUESTAS_SUCCESS";
export const GET_PROPUESTAS_ERROR = "GET_PROPUESTAS_ERROR";

export const GET_PROPUESTA_DETALLES = "GET_PROPUESTA_DETALLES"
export const GET_PROPUESTA_DETALLES_SUCCESS = "GET_PROPUESTA_DETALLES_SUCCESS"
export const GET_PROPUESTA_DETALLES_ERROR = "GET_PROPUESTA_DETALLES_ERROR"

export const PUT_PROPUESTA_ESTATUS = "PUT_PROPUESTA_ESTATUS";
export const PUT_PROPUESTA_ESTATUS_SUCCESS = "PUT_PROPUESTA_ESTATUS_SUCCESS";
export const PUT_PROPUESTA_ESTATUS_ERROR = "PUT_PROPUESTA_ESTATUS_ERROR";

export const GET_COMENTARIOS = "GET_COMENTARIOS"
export const GET_COMENTARIOS_SUCCESS = "GET_COMENTARIOS_SUCCESS"
export const GET_COMENTARIOS_ERROR = "GET_COMENTARIOS_ERROR"

export const POST_COMENTARIO = "POST_COMENTARIO"
export const POST_COMENTARIO_SUCCESS = "POST_COMENTARIO_SUCCESS"
export const POST_COMENTARIO_ERROR = "POST_COMENTARIO_ERROR"

export const UPDATE_COMENTARIOS = "UPDATE_COMENTARIOS"

export const DELETE_COMENTARIO = "DELETE_COMENTARIO"
export const DELETE_COMENTARIO_SUCCESS = "DELETE_COMENTARIO_SUCCESS"
export const DELETE_COMENTARIO_ERROR = "DELETE_COMENTARIO_ERROR"

export const PUT_ESCALAR_PROPUESTA = "PUT_ESCALAR_PROPUESTA"
export const PUT_ESCALAR_PROPUESTA_SUCCESS = "PUT_ESCALAR_PROPUESTA_SUCCESS"
export const PUT_ESCALAR_PROPUESTA_ERROR = "PUT_ESCALAR_PROPUESTA_ERROR"

export const DELETE_PROPUESTA = "DELETE_PROPUESTA"
export const DELETE_PROPUESTA_SUCCESS = "DELETE_PROPUESTA_SUCCESS"
export const DELETE_PROPUESTA_ERROR = "DELETE_PROPUESTA_ERROR"

export const POST_RESPUESTA_PROPUESTA = "POST_RESPUESTA_PROPUESTA"
export const POST_RESPUESTA_PROPUESTA_SUCCESS = "POST_RESPUESTA_PROPUESTA_SUCCESS"
export const POST_RESPUESTA_PROPUESTA_ERROR = "POST_RESPUESTA_PROPUESTA_ERROR"

export const PUT_COMENTARIO_PROPUESTA = "PUT_COMENTARIO_PROPUESTA"
export const PUT_COMENTARIO_PROPUESTA_SUCCESS = "PUT_COMENTARIO_PROPUESTA_SUCCESS"
export const PUT_COMENTARIO_PROPUESTA_ERROR = "PUT_COMENTARIO_PROPUESTA_ERROR"

export const POST_VOTO_COMENTARIO_PROPUESTA = "POST_VOTO_COMENTARIO_PROPUESTA"
export const POST_VOTO_COMENTARIO_PROPUESTA_SUCCESS = "POST_VOTO_COMENTARIO_PROPUESTA_SUCCESS"
export const POST_VOTO_COMENTARIO_PROPUESTA_ERROR = "POST_VOTO_COMENTARIO_PROPUESTA_ERROR"

export const GET_ESCALAMIENTOS = "GET_ESCALAMIENTOS"
export const GET_ESCALAMIENTOS_SUCCESS = "GET_ESCALAMIENTOS_SUCCESS"
export const GET_ESCALAMIENTOS_ERROR = "GET_ESCALAMIENTOS_ERROR"


//Messages
export const SET_MESSAGES = "SET_MESSAGES";

export const GET_CONVERSATION = "GET_CONVERSATION";
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS";
export const GET_CONVERSATION_ERROR = "GET_CONVERSATION_ERROR";

export const GET_CONVERSATIONS = "GET_CONVERSATIONS";
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS";
export const GET_CONVERSATIONS_ERROR = "GET_CONVERSATIONS_ERROR";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";

export const UPLOAD_CONVERSATION_FILE = "UPLOAD_CONVERSATION_FILE";
export const UPLOAD_CONVERSATION_FILE_SUCCESS = "UPLOAD_CONVERSATION_FILE_SUCCESS";
export const UPLOAD_CONVERSATION_FILE_ERROR = "UPLOAD_CONVERSATION_FILE_ERROR";

export const SEND_ATTACHMENT_MESSAGE = "SEND_ATTACHMENT_MESSAGE";
export const SEND_ATTACHMENT_MESSAGE_SUCCESS = "SEND_ATTACHMENT_MESSAGE_SUCCESS";
export const SEND_ATTACHMENT_MESSAGE_ERROR = "SEND_ATTACHMENT_MESSAGE_ERROR";

export const POST_NEW_CONVERSATION = "POST_NEW_CONVERSATION"
export const POST_NEW_CONVERSATION_SUCCESS = "POST_NEW_CONVERSATION_SUCCESS"
export const POST_NEW_CONVERSATION_ERROR = "POST_NEW_CONVERSATION_ERROR"

export const LIMPIA_CHAT = "LIMPIA_CHAT"

export const DELETE_CONVERSACION = "DELETE_CONVERSACION"
export const DELETE_CONVERSACION_SUCCESS = "DELETE_CONVERSACION_SUCCESS"
export const DELETE_CONVERSACION_ERROR = "DELETE_CONVERSACION_ERROR"




//Acciones
export const GET_ACCIONES = "GET_ACCIONES"
export const GET_ACCIONES_SUCCESS = "GET_ACCIONES_SUCCESS"
export const GET_ACCIONES_ERROR = "GET_ACCIONES_ERROR"

export const GET_ACCION_DETALLE = "GET_ACCION"
export const GET_ACCION_DETALLE_SUCCESS = "GET_ACCION_SUCCESS"
export const GET_ACCION_DETALLE_ERROR = "GET_ACCION_ERROR"

export const POST_ACCION = "POST_ACCION"
export const POST_ACCION_SUCCESS = "POST_ACCION_SUCCESS"
export const POST_ACCION_ERROR = "POST_ACCION_ERROR"

export const PUT_ACCION = "PUT_ACCION"
export const PUT_ACCION_SUCCESS = "PUT_ACCION_SUCCESS"
export const PUT_ACCION_ERROR = "PUT_ACCION_ERROR"

export const DELETE_ACCION = "DELETE_ACCION"
export const DELETE_ACCION_SUCCESS = "DELETE_ACCION_SUCCESS"
export const DELETE_ACCION_ERROR = "DELETE_ACCION_ERROR"

export const POST_COMENTARIO_ACCION = "POST_COMENTARIO_ACCION"
export const POST_COMENTARIO_ACCION_SUCCESS = "POST_COMENTARIO_ACCION_SUCCESS"
export const POST_COMENTARIO_ACCION_ERROR = "POST_COMENTARIO_ACCION_ERROR"

export const POST_RESPUESTA_ACCION = "POST_RESPUESTA_ACCION"
export const POST_RESPUESTA_ACCION_SUCCESS = "POST_RESPUESTA_ACCION_SUCCESS"
export const POST_RESPUESTA_ACCION_ERROR = "POST_RESPUESTA_ACCION_ERROR"

export const PUT_COMENTARIO_ACCION = "PUT_COMENTARIO_ACCION"
export const PUT_COMENTARIO_ACCION_SUCCESS = "PUT_COMENTARIO_ACCION_SUCCESS"
export const PUT_COMENTARIO_ACCION_ERROR = "PUT_COMENTARIO_ACCION_ERROR"

export const POST_VOTO_COMENTARIO_ACCION = "POST_VOTO_COMENTARIO_ACCION"
export const POST_VOTO_COMENTARIO_ACCION_SUCCESS = "POST_VOTO_COMENTARIO_ACCION_SUCCESS"
export const POST_VOTO_COMENTARIO_ACCION_ERROR = "POST_VOTO_COMENTARIO_ACCION_ERROR"

export const DELETE_COMENTARIO_ACCION = "DELETE_COMENTARIO_ACCION"
export const DELETE_COMENTARIO_ACCION_SUCCESS = "DELETE_COMENTARIO_ACCION_SUCCESS"
export const DELETE_COMENTARIO_ACCION_ERROR = "DELETE_COMENTARIO_ACCION_ERROR"

//Proyectos
export const GET_PROYECTOS = "GET_PROYECTOS"
export const GET_PROYECTOS_SUCCESS = "GET_PROYECTOS_SUCCESS"
export const GET_PROYECTOS_ERROR = "GET_PROYECTOS_ERROR"

export const POST_PROYECTO = "POST_PROYECTO"
export const POST_PROYECTO_SUCCESS = "POST_PROYECTO_SUCCESS"
export const POST_PROYECTO_ERROR = "POST_PROYECTO_ERROR"

export const GET_PROYECTO_DETALLE = "GET_PROYECTO_DETALLE"
export const GET_PROYECTO_DETALLE_SUCCESS = "GET_PROYECTO_DETALLE_SUCCESS"
export const GET_PROYECTO_DETALLE_ERROR = "GET_PROYECTO_DETALLE_ERROR"

export const DELETE_PROYECTO = "DELETE_PROYECTO"
export const DELETE_PROYECTO_SUCCESS = "DELETE_PROYECTO_SUCCESS"
export const DELETE_PROYECTO_ERROR = "DELETE_PROYECTO_ERROR"

export const PUT_PROYECTO = "PUT_PROYECTO"
export const PUT_PROYECTO_SUCCESS = "PUT_PROYECTO_SUCCESS"
export const PUT_PROYECTO_ERROR = "PUT_PROYECTO_ERROR"


//Ciudadanos
export const GET_CATALOGO_USUARIOS = "GET_CATALOGO_USUARIOS"
export const GET_CATALOGO_USUARIOS_SUCCESS = "GET_CATALOGO_USUARIOS_SUCCESS"
export const GET_CATALOGO_USUARIOS_ERROR = "GET_CATALOGO_USUARIOS_ERROR"

export const POST_VALIDACION_USUARIO = "POST_VALIDACION_USUARIO"
export const POST_VALIDACION_USUARIO_SUCCESS = "POST_VALIDACION_USUARIO_SUCCESS"
export const POST_VALIDACION_USUARIO_ERROR = "POST_VALIDACION_USUARIO_ERROR"

export const DELETE_RECHAZAR_USUARIO = "DELETE_RECHAZAR_USUARIO"
export const DELETE_RECHAZAR_USUARIO_SUCCESS = "DELETE_RECHAZAR_USUARIO_SUCCESS"
export const DELETE_RECHAZAR_USUARIO_ERROR = "DELETE_RECHAZAR_USUARIO_ERROR"

//Administradores
export const POST_REGISTRO_ADMINISTRADOR = "POST_REGISTRO_ADMINISTRADOR"
export const POST_REGISTRO_ADMINISTRADOR_SUCCESS = "POST_REGISTRO_ADMINISTRADOR_SUCCESS"
export const POST_REGISTRO_ADMINISTRADOR_ERROR = "POST_REGISTRO_ADMINISTRADOR_ERROR"

export const DELETE_VALIDACION_USUARIO = "DELETE_VALIDACION_USUARIO"
export const DELETE_VALIDACION_USUARIO_SUCCESS = "DELETE_VALIDACION_USUARIO_SUCCESS"
export const DELETE_VALIDACION_USUARIO_ERROR = "DELETE_VALIDACION_USUARIO_ERROR"

export const NO_ACTUALIZA_LISTA_ADMINS = "NO_ACTUALIZA_LISTA_ADMINS"

export const POST_ASIGNA_ROL_USUARIO = "POST_ASIGNA_ROL_USUARIO"
export const POST_ASIGNA_ROL_USUARIO_SUCCESS = "POST_ASIGNA_ROL_USUARIO_SUCCESS"
export const POST_ASIGNA_ROL_USUARIO_ERROR = "POST_ASIGNA_ROL_USUARIO_ERROR"

export const PUT_ROL_USUARIO = "PUT_ROL_USUARIO"
export const PUT_ROL_USUARIO_SUCCESS = "PUT_ROL_USUARIO_SUCCESS"
export const PUT_ROL_USUARIO_ERROR = "PUT_ROL_USUARIO_ERROR"

export const POST_ACEPTA_POLITICAS_PRIVACIDAD = "POST_ACEPTA_POLITICAS_PRIVACIDAD"
export const POST_ACEPTA_POLITICAS_PRIVACIDAD_SUCCESS = "POST_ACEPTA_POLITICAS_PRIVACIDAD_SUCCESS"
export const POST_ACEPTA_POLITICAS_PRIVACIDAD_ERROR = "POST_ACEPTA_POLITICAS_PRIVACIDAD_ERROR"

export const POST_ACEPTA_VIDEO_TUTORIAL = "POST_ACEPTA_VIDEO_TUTORIAL"
export const POST_ACEPTA_VIDEO_TUTORIAL_SUCCESS = "POST_ACEPTA_VIDEO_TUTORIAL_SUCCESS"
export const POST_ACEPTA_VIDEO_TUTORIAL_ERROR = "POST_ACEPTA_VIDEO_TUTORIAL_ERROR"

export const POST_CAMBIAR_PASSWORD = "POST_CAMBIAR_PASSWORD"
export const POST_CAMBIAR_PASSWORD_SUCCESS = "POST_CAMBIAR_PASSWORD_SUCCESS"
export const POST_CAMBIAR_PASSWORD_ERROR = "POST_CAMBIAR_PASSWORD_ERROR"

//Politicas de Privacidad
export const GET_LIST_POLITICAS_PRIVACIDAD = "GET_LIST_POLITICAS_PRIVACIDAD"
export const GET_LIST_POLITICAS_PRIVACIDAD_SUCCESS = "GET_LIST_POLITICAS_PRIVACIDAD_SUCCESS"
export const GET_LIST_POLITICAS_PRIVACIDAD_ERROR = "GET_LIST_POLITICAS_PRIVACIDAD_ERROR"

export const POST_POLITICAS_PRIVACIDAD = "POST_POLITICAS_PRIVACIDAD"
export const POST_POLITICAS_PRIVACIDAD_SUCCESS = "POST_POLITICAS_PRIVACIDAD_SUCCESS"
export const POST_POLITICAS_PRIVACIDAD_ERROR = "POST_POLITICAS_PRIVACIDAD_ERROR"

export const GET_RETRIEVE_POLITICAS_PRIVACIDAD = "GET_RETRIEVE_POLITICAS_PRIVACIDAD"
export const GET_RETRIEVE_POLITICAS_PRIVACIDAD_SUCCESS = "GET_RETRIEVE_POLITICAS_PRIVACIDAD_SUCCESS"
export const GET_RETRIEVE_POLITICAS_PRIVACIDAD_ERROR = "GET_RETRIEVE_POLITICAS_PRIVACIDAD_ERROR"

export const DELETE_POLITICAS_PRIVACIDAD = "DELETE_POLITICAS_PRIVACIDAD"
export const DELETE_POLITICAS_PRIVACIDAD_SUCCESS = "DELETE_POLITICAS_PRIVACIDAD_SUCCESS"
export const DELETE_POLITICAS_PRIVACIDAD_ERROR = "DELETE_POLITICAS_PRIVACIDAD_ERROR"

export const PUT_POLITICAS_PRIVACIDAD = "PUT_POLITICAS_PRIVACIDAD"
export const PUT_POLITICAS_PRIVACIDAD_SUCCESS = "PUT_POLITICAS_PRIVACIDAD_SUCCESS"
export const PUT_POLITICAS_PRIVACIDAD_ERROR = "PUT_POLITICAS_PRIVACIDAD_ERROR"

//Cotacta SIAC

export const GET_SOLICITUD_SIAC = "GET_SOLICITUD_SIAC"
export const GET_SOLICITUD_SIAC_SUCCESS = "GET_SOLICITUD_SIAC_SUCCESS"
export const GET_SOLICITUD_SIAC_ERROR = "GET_SOLICITUD_SIAC_ERROR"

export const ATENDER_SOLICITUD_SIAC = "ATENDER_SOLICITUD_SIAC"
export const ATENDER_SOLICITUD_SIAC_SUCCESS = "ATENDER_SOLICITUD_SIAC_SUCCESS"
export const ATENDER_SOLICITUD_SIAC_ERROR = "ATENDER_SOLICITUD_SIAC_ERROR"

//Auth
export const GET_REQUEST_ERROR = "GET_REQUEST_ERROR"
export const SET_API_ERROR = "SET_API_ERROR"
export const REFRESH_USER ="REFRESH_USER"

// Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR"

export const GET_TODAY_NOTIFICATIONS = "GET_TODAY_NOTIFICATIONS"
export const GET_TODAY_NOTIFICATIONS_SUCCESS = "GET_TODAY_NOTIFICATIONS_SUCCESS"
export const GET_TODAY_NOTIFICATIONS_ERROR = "GET_TODAY_NOTIFICATIONS_ERROR"

export const PUT_NOTIFICATIONS = "PUT_NOTIFICATIONS"
export const PUT_NOTIFICATIONS_SUCCESS = "PUT_NOTIFICATIONS_SUCCESS"
export const PUT_NOTIFICATIONS_ERROR = "PUT_NOTIFICATIONS_ERROR"

export const SEND_NUMBER_OF_UNREAD_NOTIFICATIONS = "SEND_NUMBER_OF_UNREAD_NOTIFICATIONS"

export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"

export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS"

//Busqueda
export const GET_BUSQUEDA = "GET_BUSQUEDA"
export const SET_RESULT = "SET_RESULT"

//TENDENCIAS
export const GET_TENDENCIAS_PROPUESTAS = "GET_TENDENCIAS_PROPUESTAS"
export const GET_TENDENCIAS_PROYECTOS = "GET_TENDENCIAS_PROYECTOS"
export const GET_TENDENCIAS_PROYECTOS_SUCCESS = "GET_TENDENCIAS_PROYECTOS_SUCCESS"
export const GET_TENDENCIAS_PROPUESTAS_SUCCESS = "GET_TENDENCIAS_PROPUESTAS_SUCCESS"

//CONFIGURACION
export const CAMBIAR_PASSWORD = "CAMBIAR_PASSWORD"
export const CAMBIAR_PASSWORD_SUCCESS = "CAMBIAR_PASSWORD_SUCCESS"
export const CAMBIAR_PASSWORD_ERROR = "CAMBIAR_PASSWORD_ERROR"

export const RESET_SUCCESS_FLAG = "RESET_SUCCESS_FLAG"

export const GET_AVATARES = "GET_AVATARES"
export const GET_AVATARES_SUCCESS = "GET_AVATARES_SUCCESS"
export const GET_AVATARES_ERROR = "GET_AVATARES_ERROR"

export const ACTUALIZAR_AVATAR = "ACTUALIZAR_AVATAR"
export const ACTUALIZAR_AVATAR_SUCCESS = "ACTUALIZAR_AVATAR_SUCCESS"
export const ACTUALIZAR_AVATAR_ERROR = "ACTUALIZAR_AVATAR_ERROR"

export const CAMBIAR_EMAIL = "CAMBIAR_EMAIL"
export const CAMBIAR_EMAIL_SUCCESS = "CAMBIAR_EMAIL_SUCCESS"
export const CAMBIAR_EMAIL_ERROR = "CAMBIAR_EMAIL_ERROR"

export const TOGGLE_NOTIFICACIONES_EMAIL = "TOGGLE_NOTIFICACIONES_EMAIL"
export const TOGGLE_NOTIFICACIONES_EMAIL_SUCCESS = "TOGGLE_NOTIFICACIONES_EMAIL_SUCCESS"
export const TOGGLE_NOTIFICACIONES_EMAIL_ERROR = "TOGGLE_NOTIFICACIONES_EMAIL_ERROR"

export const GET_PORCENTAJE_APOYOS = "GET_PORCENTAJE_APOYOS" 
export const GET_PORCENTAJE_APOYOS_SUCCESS = "GET_PORCENTAJE_APOYOS_SUCCESS" 
export const GET_PORCENTAJE_APOYOS_ERROR = "GET_PORCENTAJE_APOYOS_ERROR" 

export const PUT_PORCENTAJE_APOYOS = "PUT_PORCENTAJE_APOYOS"
export const PUT_PORCENTAJE_APOYOS_SUCCESS = "PUT_PORCENTAJE_APOYOS_SUCCESS"
export const PUT_PORCENTAJE_APOYOS_ERROR = "PUT_PORCENTAJE_APOYOS_ERROR"


//Reportes
export const RESET_RESULTADOS_REPORTES = "RESET_RESULTADOS_REPORTES"

export const GET_REPORTES_TEMAS_CALIENTES = "GET_REPORTES_TEMAS_CALIENTES"
export const GET_REPORTES_TEMAS_CALIENTES_SUCCESS = "GET_REPORTES_TEMAS_CALIENTES_SUCCESS"
export const GET_REPORTES_TEMAS_CALIENTES_ERROR = "GET_REPORTES_TEMAS_CALIENTES_ERROR"

export const GET_REPORTES_PROPUESTAS = "GET_REPORTES_PROPUESTAS"
export const GET_REPORTES_PROPUESTAS_SUCCESS = "GET_REPORTES_PROPUESTAS_SUCCESS"
export const GET_REPORTES_PROPUESTAS_ERROR = "GET_REPORTES_PROPUESTAS_ERROR"

export const GET_REPORTES_SOLICITUDES = "GET_REPORTES_SOLICITUDES"
export const GET_REPORTES_SOLICITUDES_SUCCESS = "GET_REPORTES_SOLICITUDES_SUCCESS"
export const GET_REPORTES_SOLICITUDES_ERROR = "GET_REPORTES_SOLICITUDES_ERROR"

export const GET_REPORTES_COMENTARIOS = "GET_REPORTES_COMENTARIOS"
export const GET_REPORTES_COMENTARIOS_SUCCESS = "GET_REPORTES_COMENTARIOS_SUCCESS"
export const GET_REPORTES_COMENTARIOS_ERROR = "GET_REPORTES_COMENTARIOS_ERROR"

export const GET_REPORTES_PROPUESTAS_ESCALABLES = "GET_REPORTES_PROPUESTAS_ESCALABLES"
export const GET_REPORTES_PROPUESTAS_ESCALABLES_SUCCESS = "GET_REPORTES_PROPUESTAS_ESCALABLES_SUCCESS"
export const GET_REPORTES_PROPUESTAS_ESCALABLES_ERROR = "GET_REPORTES_PROPUESTAS_ESCALABLES_ERROR"

export const GET_REPORTES_TIEMPOS_REVISION = "GET_REPORTES_TIEMPOS_REVISION"
export const GET_REPORTES_TIEMPOS_REVISION_SUCCESS = "GET_REPORTES_TIEMPOS_REVISION_SUCCESS"
export const GET_REPORTES_TIEMPOS_REVISION_ERROR = "GET_REPORTES_TIEMPOS_REVISION_ERROR"

export const GET_REPORTES_MAPA_CALOR = "GET_REPORTES_MAPA_CALOR"
export const GET_REPORTES_MAPA_CALOR_SUCCESS = "GET_REPORTES_MAPA_CALOR_SUCCESS"
export const GET_REPORTES_MAPA_CALOR_ERROR = "GET_REPORTES_MAPA_CALOR_ERROR"

export const GET_REPORTES_INTERACCIONES = "GET_REPORTES_INTERACCIONES"
export const GET_REPORTES_INTERACCIONES_SUCCESS = "GET_REPORTES_INTERACCIONES_SUCCESS"
export const GET_REPORTES_INTERACCIONES_ERROR = "GET_REPORTES_INTERACCIONES_ERROR"

//Tablero
export const GET_REPORTES_TABLERO = "GET_REPORTES_TABLERO"
export const GET_REPORTES_TABLERO_MAPA_SUCCESS = "GET_REPORTES_TABLERO_MAPA_SUCCESS"
export const GET_REPORTES_TABLERO_TEMAS_SUCCESS = "GET_REPORTES_TABLERO_TEMAS_SUCCESS"
export const GET_REPORTES_TABLERO_PROPUESTAS_SUCCESS = "GET_REPORTES_TABLERO_PROPUESTAS_SUCCESS"
export const GET_REPORTES_TABLERO_ERROR = "GET_REPORTES_TABLERO_ERROR"



export const REDUCER_EXCEPTIONS = [
    'LOADING_GENERAL',
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    SET_API_ERROR,
    VERIFY_TOKEN,
    REFRESH_TOKEN,
    INVALID_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ERROR,
    LOGOUT,
    NO_ACTUALIZA_LISTA_ADMINS,
    UPDATE_COMENTARIOS,
    NO_ACTUALIZA_ENCUESTAS,
    NO_ACTUALIZA_ENCUESTAS,
    "toggle",
    LIMPIA_CHAT,
    GET_LIST_POLITICAS_PRIVACIDAD,
    ENVIAR_CORREO_RECUPERACION,
    ENVIAR_CORREO_RECUPERACION_SUCCESS,
    ENVIAR_CORREO_RECUPERACION_ERROR,
    RESTABLECER_PASSWORD,
    RESTABLECER_PASSWORD_SUCCESS,
    RESTABLECER_PASSWORD_ERROR,
]
