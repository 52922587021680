import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  InputGroup,
} from "react-bootstrap";
import { Inbox, Archive, ExternalLink } from "react-feather";
import { useHistory, Link, useParams } from "react-router-dom";
import TableWithActions from "../../components/ActionTable/index";
import CrearNuevaEncuesta from "./componentsEncuestas/CrearNuevaEncuesta";
import BorrarEncuesta from "./componentsEncuestas/BorrarEncuesta";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import "./Encuestas.css";
import moment from 'moment'

// acciones
import {
  getEncuestas,
  getEncuestasVencidas,
  getEstadisticasEncuestas,
  getUsuariosQueRespondieron,
} from "../../redux/actions/encuestasActions";

// selectores
import {
  encuestasResult,
  encuestasVencidasResult,
  updateEncuestasState,
} from "../../redux/selectors/encuestasSelectors";
import Swal from "sweetalert2";

const Encuestas = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idEncuesta } = useParams();
  const encuestas = useSelector((state) => encuestasResult(state));
  const encuestasVencidas = useSelector((state) =>
    encuestasVencidasResult(state)
  );
  const updateEncuestas = useSelector((state) => updateEncuestasState(state));
  const [activos, setActivos] = useState([]);
  const [archivados, setArchivados] = useState([]);
  const [borradores, setBorradores] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [encuestaABorrar, setEncuestaABorrar] = useState();

  const columns = [
    "VOTACIÓN",
    "ESTATUS",
    "RESPUESTAS",
    "DURACIÓN",
    "ACCIÓN VINCULADA",
    "ESTADÍSTICAS",
  ];

  const visualizarDetalles = (arrayDeIds) => {
    if (arrayDeIds.length > 0) {
      dispatch(getEstadisticasEncuestas(arrayDeIds));
      // Se time out temporal, hasta definir mejor metodo de implementación que evite una acumulación de requeest en la api
      setTimeout(() => {
        dispatch(getUsuariosQueRespondieron(arrayDeIds));
      }, 1000);
      history.push("/respuestas");
    }
  };

  console.log(encuestas)

  const dataParser = (data) => {
    return data
      .map((encuesta) => {
        return {
          id: encuesta.id,
          votacion: encuesta.nombre,
          estatus: moment(encuesta.fecha_finalizacion,"YYYY-MM-DD").isBefore(moment())? "Finalizada":encuesta.estado,
          respuestas: encuesta.numero_respuestas
            ? encuesta.numero_respuestas
            : 0,
          duracion:
            encuesta.creado_el.slice(0, 11) +
            " - " +
            encuesta.fecha_finalizacion,
          accion: encuesta.comunicado ? (
            <div>
              <Link
                to={`/acciones/detalles/${encuesta.comunicado}`}
                className="accion-vinculada-tablero-votaciones"
              >
                <p>Abrir Enlace</p>
                <ExternalLink />
              </Link>
            </div>
          ) : (
            <p>N/A</p>
          ),
          estadisticas: (
            <Link
              to="/respuestas"
              onClick={() => visualizarDetalles([encuesta.id])}
            >
              Ver
            </Link>
          ),
        };
      })
      .filter((element) => element !== undefined);
  };

  const deleteEncuestas = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      setModalDelete(true);
      let obj = {};
      encuestas.forEach((encuesta) => {
        if (encuesta.id === arrayDeIds[0]) {
          obj = {
            id: arrayDeIds[0],
            nombre: encuesta.nombre,
          };
        }
      });
      setEncuestaABorrar(obj);
    }
  };

  const editEncuestas = (arrayDeIds) => {
    encuestas.forEach((encuesta) => {
      if (encuesta.id === arrayDeIds[0]) {
        if (encuesta.estado === "Activa") {
          Swal.fire(
            "Error",
            "No es posible modificar una encuesta activa",
            "error"
          );
        } else {
          if (arrayDeIds.length === 1) {
            history.push(`/votaciones/${arrayDeIds[0]}`);
            setModal(true);
          }
        }
      }
    });
  };

  useEffect(() => {
    dispatch(getEncuestas());
  }, []);

  useEffect(() => {
    if (encuestas !== undefined) {
      setActivos(
        dataParser(encuestas).filter(
          (encuesta) => encuesta.estatus === "Activa"
        )
      );
      setArchivados(
        dataParser(encuestas).filter(
          (encuesta) => encuesta.estatus === "Archivada"||encuesta.estatus === "Finalizada"
        )
      );
      setBorradores(
        dataParser(encuestas).filter(
          (encuesta) => encuesta.estatus === "Borrador"
        )
      );
      //Con esta solución la app se detiene cuando la base de datos esta vacia
      // let columnas = Object.keys(dataParser(encuestas)[0]);
      dispatch(getEncuestasVencidas());
    }
  }, [encuestas]);

  useEffect(() => {
    if (encuestasVencidas) {
      // let arr = [...activos];
      // arr.push(...dataParser(encuestasVencidas));
      // setArchivados(arr);
      setArchivados(archivados.concat(dataParser(encuestasVencidas)))
    }
  }, [encuestasVencidas]);

  //Update encuestas cambia su valor dentro de actions
  useEffect(() => {
    if (updateEncuestas) {
      dispatch(getEncuestas());
    }
  }, [updateEncuestas]);

  //Cada vez que cerremos modal hay que asegurar que la ruta borra el parametro de id
  useEffect(() => {
    if (!modal && idEncuesta) {
      history.push("/votaciones");
    }
  }, [modal]);

  console.log(encuestas);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link to="/">Regresar a Inicio</Link>
            <h3 className="titulo-table-actions">Tablero de Votaciones</h3>
          </div>

          <Tabs className="tabs-action-table" defaultActiveKey={1}>
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <Inbox /> {"Activos"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={activos ? activos : []}
                    selectable={true}
                    firstColumnLink={true}
                    linkType={"linkWithId"}
                    link={"/preguntas"}
                    statusColumn={false}
                    settings={false}
                    settingsFunction={() => console.log("settings function")}
                    info={false}
                    // infoFunction={() =>
                    //   history.push("/preguntas/predeterminadas")
                    // }
                    archive={true}
                    idVisualization={false}
                    archiveFunction={deleteEncuestas}
                    visualize={true}
                    visualizeFunction={visualizarDetalles}
                    edit={false}
                    editFunction={editEncuestas}
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() => setModal(true)}
                    messageWhenEmpty={"Crea una votación para comenzar"}
                    textForCreateNew={"Crear Votación"}
                    paginate={true}
                    paginateRows={10}
                  >
                    <ModalEstandar visibilidad={modal} setModal={setModal}>
                      <CrearNuevaEncuesta setModal={setModal} />
                    </ModalEstandar>
                  </TableWithActions>
                </Col>
              </Row>
            </Tab>

            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <Inbox /> {"Borradores"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={borradores ? borradores : []}
                    selectable={true}
                    firstColumnLink={true}
                    linkType={"linkWithId"}
                    link={"/preguntas"}
                    statusColumn={false}
                    settings={false}
                    settingsFunction={() => console.log("settings function")}
                    info={false}
                    // infoFunction={() =>
                    //   history.push("/preguntas/predeterminadas")
                    // }
                    archive={true}
                    idVisualization={false}
                    archiveFunction={deleteEncuestas}
                    visualize={true}
                    visualizeFunction={visualizarDetalles}
                    edit={true}
                    editFunction={editEncuestas}
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() => setModal(true)}
                    messageWhenEmpty={"Crea una votación para comenzar"}
                    textForCreateNew={"Crear Votación"}
                    paginate={true}
                    paginateRows={10}
                  >
                    <ModalEstandar visibilidad={modal} setModal={setModal}>
                      <CrearNuevaEncuesta setModal={setModal} />
                    </ModalEstandar>
                  </TableWithActions>
                </Col>
              </Row>
            </Tab>

            <Tab
              eventKey={3}
              title={
                <span className="tab-span-action-table">
                  <Archive /> {"Archivados"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={archivados ? archivados : []}
                    selectable={true}
                    firstColumnLink={true}
                    linkType={"linkWithId"}
                    link={"/preguntas"}
                    statusColumn={false}
                    delete={false}
                    idVisualization={false}
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() => setModal(true)}
                    messageWhenEmpty={"Crea una votación para comenzar"}
                    textForCreateNew={"Crear Votación"}
                  >
                    <ModalEstandar visibilidad={modal} setModal={setModal} forzado={true}>
                      <CrearNuevaEncuesta setModal={setModal} />
                    </ModalEstandar>
                  </TableWithActions>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modalDelete} setModal={setModalDelete}>
        <BorrarEncuesta
          setModal={setModalDelete}
          nombreDeEncuesta={encuestaABorrar ? encuestaABorrar.nombre : ""}
          idDeEncuesta={encuestaABorrar ? encuestaABorrar.id : ""}
        />
      </ModalEstandar>
    </div>
  );
};

export default Encuestas;
