import * as types from "../../consts/actionTypes";
import { getUser } from "../services/authServices";

// let user = JSON.parse(localStorage.getItem("user"));
const initialState = {
  user: getUser() ,
  logged: false, 
  isValid: false,
  error: '',
  tokenAccess: localStorage.getItem('access') !== null ? localStorage.getItem('access') : "" ,
  tokenRefresh: localStorage.getItem('refresh') !== null ? localStorage.getItem('refresh') : "" 
};

export default function (state = initialState, action) {

  switch (action.type) {

    case types.LOGIN:
      return {
        ...state,
        logged:true,
        isValid: true
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        tokenAccess: action.tokens.data.access,
        tokenRefresh: action.tokens.data.refresh,
        logged: true,
        isValid: true
      };
    
    case types.VERIFY_TOKEN:
      return {
        ...state,
        // isValid: true
      };
    
      case types.VALID_TOKEN:
        Object.assign(state.isValid, true)
      return {
        ...state,
        isValid: true,
        logged: true,
      };
      case types.INVALID_TOKEN:
        Object.assign(state.isValid, false)
      return {
        ...state,
        isValid: false,
        logged: true,
      };

      case types.REFRESH_TOKEN:
        return{
          ...state
        }

      case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        tokenAccess: action.newToken.data.access,
      };

      case types.SET_API_ERROR:
        return {
          ...state,
          tokenAccess: action.payload,
        };

      case types.GET_USER_INFO_SUCCESS:
        return{
          ...state,
          user: action.userInfo.data[0]
        }

    case types.LOGIN_ERROR: 
      return {
        ...state,
        logged: false,
        error: 'RUN o Contraseña incorrectas'
      };

      case types.LOGOUT:
        localStorage.clear()
      return {
        ...state,
        user: false,
        logged: false,
        tokenAccess: null,
        tokenRefresh: null,
      };

    default:
      return { ...state };
  }
}
