import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
} from "react-bootstrap";
import { Inbox, Archive } from "react-feather";
import TableWithActions from "../../components/ActionTable/index";
import { useParams, Link, useHistory } from "react-router-dom";
import BorrarPregunta from "./Componentes/BorrarPregunta";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import CrearNuevaPregunta from "./Componentes/CrearNuevaPregunta";
import "./Preguntas.css";
import Swal from "sweetalert2";

// acciones
import { getEncuestas } from "../../redux/actions/encuestasActions";
import {
  noActualizaEncuestas,
  deletePregunta,
} from "../../redux/actions/preguntasActions";

// selectores
import {
  updateEncuestasState,
  encuestasResult,
} from "../../redux/selectors/encuestasSelectors";

import { updatePreguntasState } from "../../redux/selectors/preguntasSelector";

const columns = ["PREGUNTA", "TIPO", "ACCIÓN"];

const Preguntas = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const encuestas = useSelector((state) => encuestasResult(state));
  const updateEncuestas = useSelector((state) => updateEncuestasState(state));
  const updatePreguntas = useSelector((state) => updatePreguntasState(state));
  const [data, setData] = useState([]);
  const [idPreguntaEdit, setIdPreguntaEdit] = useState(null);
  const [modal, setModal] = useState(false);
  const [nombre, setNombre] = useState();
  const [modalDelete, setModalDelete] = useState(false);
  const [preguntaABorrar, setPreguntaABorrar] = useState();
  const [agregarPregunta, setAgregarPregunta] = useState(false);

  //URL param
  let { idEncuesta } = useParams();

  const modalEditar = (id) => {
    setIdPreguntaEdit(id);
    setModal(true);
  };

  // const preguntas = [
  //   {
  //     pregunta: "hola",
  //     tipo: 2
  //   },
  //   {
  //     pregunta: "hola",
  //     tipo: 2
  //   },
  //   {
  //     pregunta: "hola",
  //     tipo: 2
  //   },
  //   {
  //     pregunta: "hola",
  //     tipo: 2
  //   }
  // ]

  const parserPreguntas = (preguntas) => {
    if (preguntas) {
      return preguntas
        .map((pregunta) => {
          return {
            id: pregunta.id,
            preguntas: pregunta.pregunta,
            tipo: pregunta.tipo === "2" ? "Opción Única" : "Opción Multiple",
            accion: (
              <Link onClick={() => modalEditar(pregunta.id)}>Editar</Link>
            ),
          };
        })
        .filter((element) => element !== undefined);
    }
  };

  const borrarPregunta = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      setModalDelete(true);
      let obj = {};
      console.log(data);
      data.forEach((pregunta) => {
        if (pregunta.id === arrayDeIds[0]) {
          obj = {
            id: arrayDeIds[0],
            nombre: pregunta.pregunta,
          };
        }
      });
      setPreguntaABorrar(obj);
    }
  };

  useEffect(() => {
    dispatch(getEncuestas());
  }, []);

  useEffect(() => {
    if (encuestas) {
      encuestas.forEach((encuesta) => {
        if (encuesta.id === Number(idEncuesta)) {
          setData(encuesta.preguntas);
          setNombre(encuesta.nombre);
          if (encuesta.numero_respuestas > 0) {
            setAgregarPregunta(false);
          } else {
            setAgregarPregunta(true);
          }
        }
      });
    }
  }, [encuestas]);

  // dispatch(actualizaEncuestas());

  useEffect(() => {
    if (updatePreguntas) {
      dispatch(getEncuestas());
      dispatch(noActualizaEncuestas());
    }
  }, [updatePreguntas]);

  useEffect(() => {
    if (updateEncuestas) {
      dispatch(getEncuestas());
    }
  }, [updateEncuestas]);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <BreadcrumbItem>
              <Link to="/votaciones">Regresar a Votaciones</Link>
            </BreadcrumbItem>
            <h3 className="titulo-table-actions">Preguntas: {nombre}</h3>
          </div>

          <Tabs className="tabs-action-table">
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <Inbox /> {"Activos"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={parserPreguntas(data)}
                    selectable={true}
                    idTableVisualization={false}
                    delete={true}
                    deleteFunction={borrarPregunta}
                    archive={false}
                    archiveFunction={() => console.log("The Function")}
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() => {
                      if (agregarPregunta) {
                        setIdPreguntaEdit(null);
                        setModal(true);
                      } else {
                        Swal.fire(
                          "Acción no permitida",
                          "No es posible agregar preguntas a una votación que ya tiene respuestas",
                          "warning"
                        );
                      }
                    }}
                    dragDrop={false}
                    textForCreateNew={"Crear Pregunta"}
                    messageWhenEmpty={
                      'La votación no tiene preguntas en su contenido, haga click en "Crear pregunta". Una votación sin preguntas no sera visible para los ciudadanos.'
                    }
                  >
                    <ModalEstandar visibilidad={modal} setModal={setModal}>
                      <CrearNuevaPregunta
                        setModal={setModal}
                        preguntas={data}
                        nombreDeEncuesta={nombre}
                        idPregunta={idPreguntaEdit}
                      />
                    </ModalEstandar>
                  </TableWithActions>
                </Col>
              </Row>
            </Tab>
            {/* <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <Archive /> {"Archivados"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={parserPreguntas(data)}
                    selectable={true}
                    delete={true}
                    deleteFunction={borrarPregunta}
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() => {
                      setIdPreguntaEdit(null);
                      setModal(true);
                    }}
                    textForCreateNew={"Crear Pregunta"}
                  />
                </Col>
              </Row>
            </Tab> */}
          </Tabs>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modalDelete} setModal={setModalDelete}>
        <BorrarPregunta
          setModal={setModalDelete}
          nombreDePregunta={preguntaABorrar ? preguntaABorrar.nombre : ""}
          idDePregunta={preguntaABorrar ? preguntaABorrar.id : ""}
        />
      </ModalEstandar>
    </div>
  );
};

export default Preguntas;
