import { combineReducers } from "redux";
import toogleSidebar from "./sidebar";
import EncuestasReducer from "./encuestasReducers";
import PreguntasReducer from "./preguntasReducer";
import AuthReducer from "./authReducers";
import PropuestasReducer from "./propuestasReducers";
import MessagesReducer from "./messagesReducers";
import AccionesReducer from "./accionesReducers";
import ProyectosReducer from "./proyectosReducers";
import CiudadanosReducer from "./ciudadanosReducer";
import AdministradoresReducer from "./administradoresReducer";
import SolicitudSiacReducer from "./solicitudSiacReducers";
import PoliticasPrivacidadReducer from "./politicasPrivacidadReducers";
import NotificationsReducer from "./notificationsReducer";
import BusquedaReducer from "./busquedaReducer";
import ConfiguracionReducer from "./configuracionReducer";
import TendenciasReducer from "./tendenciasReducer";
import RecuperacionReducer from "./recuperacionReducer";
import ReportesReducer from "./reportesReducer";

const rootReducer = combineReducers({
  sideBar: toogleSidebar,
  BusquedaReducer,
  EncuestasReducer,
  PreguntasReducer,
  AuthReducer,
  PropuestasReducer,
  MessagesReducer,
  AccionesReducer,
  ProyectosReducer,
  CiudadanosReducer,
  AdministradoresReducer,
  SolicitudSiacReducer,
  PoliticasPrivacidadReducer,
  NotificationsReducer,
  ConfiguracionReducer,
  TendenciasReducer,
  RecuperacionReducer,
  ReportesReducer,
});

export default rootReducer;
