import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { restablecerPassword } from "../../redux/actions/recuperacionActions";
//Selector
import { restablecerRespuestaResult } from "../../redux/selectors/recuperacionSelector";
//Bostrap
import { Form, Button, Card, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";


const Restablecer = () => {
  const { uid, token } = useParams();
  const history = useHistory()
  const dispatch = useDispatch();
  const respuesta = useSelector((state) => restablecerRespuestaResult(state));
  const [validated, setValidated] = useState(false);
  const [pass1, setPass1] = useState();
  const [pass2, setPass2] = useState();

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      e.stopPropagation();
      try {
        dispatch(restablecerPassword({
          new_password1: pass1,
          new_password2: pass2,
          uid: uid,
          token: token,
        }))
      } catch (e) {
        console.log(e);
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    if (respuesta && respuesta.status === 200) {
      Swal.fire(
        "Contraseña restablecida",
        "Se ha restablecido su contraseña con exito",
        "success"
      );
      history.push("/login")
    }
  }, [respuesta]);

  return (
    <div style={{ width: "100%", height: "calc(100vh - 144px)" }}>
      <Row
        className="d-flex justify-content-center align-content-center"
        style={{ height: "100%" }}
      >
        <Col
          xs={12}
          className="d-sm-none header-registro"
          style={{ position: "absolute", padding: "0.5rem" }}
        ></Col>
        <Col xs={11} sm={9}>
          <Card className="card-container login">
            <h3 className="login__title">Restablezca su contraseña</h3>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Form.Group controlId="formPass1">
                <Form.Label style={{ color: "#1069B4" }}>
                  Nueva contraseña
                </Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="password1"
                  onChange={(event) => setPass1(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Contraseña inválida
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formPass2">
                <Form.Label style={{ color: "#1069B4" }}>
                  Repita contraseña
                </Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="password2"
                  onChange={(event) => {
                    if(event.target.value !== pass1){
                      event.target.setCustomValidity("Las contraseñas no coinciden")
                    } else {
                      event.target.setCustomValidity("")
                    }
                    setPass2(event.target.value)}}
                />
                <Form.Control.Feedback type="invalid">
                  {pass2 ? "Las contraseñas no coinciden" : ""}
                </Form.Control.Feedback>
              </Form.Group>

              <Button type="submit" className="submit-login" block>
                Restablecer
              </Button>
              <Form.Label className="error-label"></Form.Label>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Restablecer;
