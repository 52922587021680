import React, { useState, useEffect } from "react";
import ActionTable from "./ActionTable";
import { STATUSLIST } from "./enums";
import {
  InputGroup,
  FormControl,
  Row,
  Col,
  Dropdown,
  Button,
} from "react-bootstrap";
import {
  Flag,
  Trash,
  Eye,
  Edit2,
  Archive,
  Download,
  Search,
  Info,
  Settings,
  ChevronRight,
  ChevronLeft,
} from "react-feather";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import filePlus from "../../assets/Iconos especiales/file-plus.svg";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 *
 * @param {{ data: Array, buttonCreate: Boolean,  userStatus: Boolean,  statusColumn: Boolean, idTableVisualization: Boolean, download: Boolean, delete: Boolean, archive: Boolean, edit: Boolean, visualize: Boolean, columns: Array, selectable: Boolean, statusColumn: Boolean, actionColumn: Boolean, firstColumnLink: Boolean, linkType: ["link", "linkWithId"], link: String, children: import('react').ReactElement, downloadFunction: Function, deleteFunction: Function, archiveFunction: Function, editFunction: Function, visualizeFunction: Function, createNewFunction: Function, messageWhenEmpty: String, searchBar: Boolean, dragDrop: Boolean, textForCreateNew: String, info: Boolean, infoFunction: Function, settings: Boolean, settingsFunction: Function, paginate: Boolean, paginateRows: Number  }} props
 */
const TableWithActions = (props) => {
  const handleDrop = (evt) => {
    const divFilter = evt.currentTarget.innerText;
    let newData =
      divFilter !== "Todos"
        ? originalData.filter((obj) =>
            Object.keys(obj).some((key) =>
              obj["status" || "estado" || "estatus"].includes(divFilter)
            )
          )
        : originalData;
    setFilterDropdown(divFilter);
    setData(newData);
  };

  const searchAction = () => {
    let newData = "";
    searchKey !== ""
      ? (newData = originalData.filter((obj) =>
          Object.keys(obj).some((key) => obj[key].includes(searchKey))
        ))
      : (newData = originalData);

    setData(newData);
  };

  const handleSearchInput = (e) => {
    if (e.target.value === "") {
      setData(originalData);
      setSearchKey(e.target.value);
    } else {
      setSearchKey(e.target.value);
    }
  };

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [filterDropdown, setFilterDropdown] = useState("");
  const [arrayDeIds, setArrayDeIds] = useState([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setData(props.data);
    setOriginalData(props.data);
  }, [props]);

  useEffect(() => {
    if (data && pages !== Math.ceil(data.length / props.paginateRows)) {
      setPages(Math.ceil(data.length / props.paginateRows));
    }
  }, [data]);

  const filter = () => {
    if (searchKey !== "") {
      const resultados = data.filter((req) =>
        searchKey
          .toLowerCase()
          .split(" ")
          .every((v) => conditionalFilter(v, req))
      );
      if (currentPage !== 1) {
        setCurrentPage(1);
      }
      if (pages !== Math.ceil(resultados.length / props.paginateRows)) {
        setPages(Math.ceil(resultados.length / props.paginateRows));
      }
      return resultados;
    }
    return data;
  };

  const paginar = (array) => {
    if (Array.isArray(array)) {
      return array.slice(
        (currentPage - 1) * props.paginateRows,
        currentPage * props.paginateRows
      );
    }
  };

  const conditionalFilter = (v, req) => {
    for (const prop in req) {
      if (req[prop] !== undefined) {
        if (req[prop].toString().toLowerCase().includes(v)) {
          return true;
        }
      }
    }
  };

  console.log(pages);

  return (
    <div id="div-index-action-table">
      <Row className="row-herramientas-action-table">
        <Col>
          <Row className="row-action-table-demo">
            <Col className="action-icon-group">
              {props.settings ? (
                <div
                  className="settings-icon-action-table"
                  onClick={() => props.settingsFunction()}
                >
                  <Settings />
                </div>
              ) : null}
              {props.download ? (
                <div onClick={props.downloadFunction}>
                  <Download />
                </div>
              ) : null}
              {props.info ? (
                <div onClick={() => props.infoFunction()}>
                  <Info />
                </div>
              ) : null}
              {props.delete && arrayDeIds.length < 2 ? (
                <div
                  onClick={() => {
                    props.deleteFunction(arrayDeIds);
                    setArrayDeIds([]);
                  }}
                >
                  <Trash />
                </div>
              ) : null}

              {props.archive && arrayDeIds.length < 2 ? (
                <div
                  onClick={() => {
                    props.archiveFunction(arrayDeIds);
                    setArrayDeIds([]);
                  }}
                >
                  <Archive />
                </div>
              ) : null}

              {props.edit && arrayDeIds.length < 2 ? (
                <div
                  onClick={() => {
                    props.editFunction(arrayDeIds);
                    setArrayDeIds([]);
                  }}
                >
                  <Edit2 />
                </div>
              ) : null}
              {props.visualize ? (
                <div
                  onClick={() => {
                    props.visualizeFunction(arrayDeIds);
                    setArrayDeIds([]);
                  }}
                >
                  <Eye />
                </div>
              ) : null}
            </Col>
            {props.userStatus || props.statusColumn ? (
              <Col>
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-filter">
                    {filterDropdown ? (
                      filterDropdown
                    ) : (
                      <span>
                        <Flag /> Filter{" "}
                      </span>
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-filter-menu">
                    <Dropdown.Item eventKey={"Todos"} onClick={handleDrop}>
                      <div className="strongBlue"></div>Todos
                    </Dropdown.Item>
                    {originalData.map((dt, key) => {
                      switch (dt.status) {
                        case STATUSLIST.NoLeido:
                          return (
                            <Dropdown.Item
                              eventKey={dt.status}
                              onClick={handleDrop}
                              key={key}
                            >
                              <div className="blue"></div>
                              {dt.status}
                            </Dropdown.Item>
                          );

                        case STATUSLIST.Activo:
                        case STATUSLIST.Activa:
                        case STATUSLIST.Aprobado:
                        case STATUSLIST.Aceptado:
                          return (
                            <Dropdown.Item
                              eventKey={dt.status}
                              onClick={handleDrop}
                              key={key}
                            >
                              <div className="green"></div>
                              {dt.status}
                            </Dropdown.Item>
                          );

                        case STATUSLIST.Advertencia:
                        case STATUSLIST.EnRevision:
                          return (
                            <Dropdown.Item
                              eventKey={dt.status}
                              onClick={handleDrop}
                              key={key}
                            >
                              <div className="yellow"></div>
                              {dt.status}
                            </Dropdown.Item>
                          );

                        case STATUSLIST.Urgente:
                        case STATUSLIST.Desactivado:
                        case STATUSLIST.Inactivo:
                          return (
                            <Dropdown.Item
                              eventKey={dt.status}
                              onClick={handleDrop}
                              key={key}
                            >
                              <div className="red"></div>
                              {dt.status}
                            </Dropdown.Item>
                          );

                        case STATUSLIST.Cerrado:
                        case STATUSLIST.Rechazado:
                          return (
                            <Dropdown.Item
                              eventKey={dt.status}
                              onClick={handleDrop}
                              key={key}
                            >
                              <div className="darkgray"></div>
                              {dt.status}
                            </Dropdown.Item>
                          );

                        case STATUSLIST.NoPublico:
                          return (
                            <Dropdown.Item
                              eventKey={dt.status}
                              onClick={handleDrop}
                              key={key}
                            >
                              <div className="lightBlue"></div>
                              {dt.status}
                            </Dropdown.Item>
                          );

                        case STATUSLIST.Pendiente:
                          return (
                            <Dropdown.Item
                              eventKey={dt.status}
                              onClick={handleDrop}
                              key={key}
                            >
                              <div className="gray"></div>
                              {dt.status}
                            </Dropdown.Item>
                          );

                        default:
                          break;
                      }
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            ) : null}
            {/* md de este Col es solo para DEMO, original es 4 */}
            {props.searchBar ? (
              <Col className="search-action-table">
                <InputGroup>
                  <InputGroup.Prepend>
                    <Search onClick={searchAction} />
                  </InputGroup.Prepend>
                  <FormControl
                    id="input-search-action-table"
                    onChange={(e) => handleSearchInput(e)}
                    placeholder="Buscar en tabla"
                  />
                </InputGroup>
              </Col>
            ) : null}

            {props.settings ? (
              <div
                className="settings-icon-mobile-action-table"
                onClick={() => props.settingsFunction()}
              >
                <Settings />
              </div>
            ) : null}

            {props.buttonCreate ? (
              <Col className="col-create-button-action-table">
                <Button
                  className="button-new"
                  onClick={props.createNewFunction}
                >
                  <img src={filePlus} />
                  <p>
                    {props.textForCreateNew
                      ? props.textForCreateNew
                      : "Crear Nuevo"}
                  </p>
                </Button>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <ActionTable
        columns={props.columns}
        data={props.paginate ? paginar(filter()) : filter()}
        arrayDeIds={arrayDeIds}
        setArrayDeIds={setArrayDeIds}
        selectable={props.selectable}
        userStatus={props.userStatus}
        idVisualization={props.idTableVisualization}
        statusColumn={props.statusColumn}
        actionColumn={props.actionColumn}
        firstColumnLink={props.firstColumnLink}
        linkType={props.linkType}
        link={props.link}
        messageWhenEmpty={props.messageWhenEmpty}
        dragDrop={props.dragDrop}
      />
      <div>{props.children ? props.children : null}</div>
      {data && props.paginate && data.length > 0 && (
        <div className="set-pagination-action-table">
          <button
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
              }
            }}
            disabled={!(currentPage > 1)}
          >
            <ChevronLeft />
          </button>
          <div>{currentPage}</div>
          <button
            onClick={() => {
              if (currentPage < pages) {
                setCurrentPage(currentPage + 1);
              }
            }}
            disabled={!(currentPage < pages)}
          >
            <ChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default TableWithActions;
