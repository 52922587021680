import * as types from "../../consts/actionTypes";

const initialState = {
  recuperacionRespuesta: undefined,
  restablecerRespuesta: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ENVIAR_CORREO_RECUPERACION_SUCCESS:
      return {
        ...state,
        recuperacionRespuesta: action.result,
      };
    case types.ENVIAR_CORREO_RECUPERACION_ERROR:
      return {
        ...state,
        recuperacionRespuesta: action.result,
      };
    case types.RESTABLECER_PASSWORD_SUCCESS:
      return {
        ...state,
        restablecerRespuesta: action.result,
      };
    case types.RESTABLECER_PASSWORD_ERROR:
      return {
        ...state,
        restablecerRespuesta: action.result,
      };
    default:
      return {
        ...state,
      };
  }
}
