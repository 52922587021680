import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Tabs,
  Tab,
  Row,
  Container,
  Col,
  BreadcrumbItem,
} from "react-bootstrap";
import { Inbox, Archive } from "react-feather";
import { Link, useHistory, useParams } from "react-router-dom";

// Components
import TableWithActions from "../../components/ActionTable/index";
import ModalEstandar from "../../components/reusable/ModalEstandar/ModalEstandar";
import BorrarAccion from "../Acciones/BorrarAccion";

//Actions
import { getProyectoDetalle } from "../../redux/actions/proyectosActions";
import { putAccion } from "../../redux/actions/accionesActions";

//Selectors
import { proyectoADetalleResult } from "../../redux/selectors/proyectosSelectors";
import { updateAccionesResult } from "../../redux/selectors/accionesSelectors";

const columns = ["NOMBRE", "ESTATUS", "FECHA DE CREACIÓN", "ACCIONES"];

const data = [
  {
    id: "1",
    nombre: "Acción 1",
    status: "Activo",
    datetime: "2020-07-21 02:31:13",
  },
  {
    id: "2",
    nombre: "Acción 2",
    status: "Activo",
    datetime: "2020-07-21 02:31:13",
  },
  {
    id: "3",
    nombre: "Acción 3",
    status: "Activo",
    datetime: "2020-07-21 02:31:13",
  },
  {
    id: "4",
    nombre: "Acción 4",
    status: "Activo",
    datetime: "2020-07-21 02:31:13",
  },
  {
    id: "5",
    nombre: "Acción 5",
    status: "Activo",
    datetime: "2020-07-21 02:31:13",
  },
];

const DetalleProyecto = () => {
  const dispatch = useDispatch();
  const proyecto = useSelector((state) => proyectoADetalleResult(state));
  const actualizaAcciones = useSelector((state) => updateAccionesResult(state));
  const history = useHistory();
  const { id } = useParams();
  const [modalDelete, setModalDelete] = useState(false);
  const [accionABorrar, setAcccionABorrar] = useState();

  const renderEstatus = (estatus) => {
    const elemento = (color, texto) => {
      return (
        <div className="item-dropdown-estatus-propuesta">
          <div className={`bolita-estatus bolita-estatus-${color}`}></div>
          {texto}
        </div>
      );
    };
    switch (estatus) {
      case "B":
        return elemento("azul", "Borrador");
      case "A":
        return elemento("verde", "Aprobado");
      case "T":
        return elemento("negro", "Terminado");
      default:
    }
  };

  const dataParser = (data) => {
    return data
      // .filter((accion) => accion.disponibilidad === "1")
      .map((accion) => {
        return {
          id: accion.id,
          nombre: accion.titulo,
          estatus: renderEstatus(accion.estado),
          duracion: accion.fecha.slice(0, 10),
          accion: (
            <Link to={`/proyectos/detalles/${id}/accion/${accion.id}`}>
              Editar Acción
            </Link>
          ),
        };
      });
  };

  const archivarAccion = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      dispatch(putAccion({ id: arrayDeIds[0], body: { estado: "T" } }));
    }
  };

  const borrarAccion = (arrayDeIds) => {
    if (arrayDeIds.length === 1) {
      setModalDelete(true);
      let obj = {};
      proyecto.comunicados.forEach((accion) => {
        if (accion.id === arrayDeIds[0]) {
          obj = {
            id: arrayDeIds[0],
            nombre: accion.titulo,
          };
        }
      });
      setAcccionABorrar(obj);
    }
  };

  useEffect(() => {
    if (actualizaAcciones) {
      dispatch(getProyectoDetalle(id));
    }
  }, [actualizaAcciones]);

  useEffect(() => {
    dispatch(getProyectoDetalle(id));
  }, []);

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <Link to="/proyectos">Regresar a Proyectos</Link>
            <h3 className="titulo-table-actions">
              Proyecto: {proyecto ? proyecto.nombre : ""}
            </h3>
          </div>

          <Tabs className="tabs-action-table">
            <Tab
              eventKey={1}
              title={
                <span className="tab-span-action-table">
                  <Inbox /> {"Activos"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      proyecto
                        ? dataParser(
                            proyecto.comunicados.filter(
                              (accion) =>
                                ((accion.estado === "A" || accion.estado === "B")&& accion.disponibilidad==="1")
                            )
                          )
                        : []
                    }
                    selectable={true}
                    firstColumnLink={false}
                    delete={true}
                    deleteFunction={borrarAccion}
                    archive={true}
                    archiveFunction={archivarAccion}
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() =>
                      history.push(
                        `/proyectos/detalles/${id}/${proyecto.nombre}/crearAccion`
                      )
                    }
                    textForCreateNew={"Crear Acción"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={2}
              title={
                <span className="tab-span-action-table">
                  <Archive /> {"Archivados"}
                </span>
              }
            >
              <Row>
                <Col sm={12}>
                  <TableWithActions
                    columns={columns}
                    data={
                      proyecto
                        ? dataParser(
                            proyecto.comunicados.filter(
                              (accion) => {
                                console.log({"ACCION":accion, "RESULT": accion.estado === "T" || accion.disponibilidad === "2", "DISPONIBILIDAD":accion.disponibilidad})
                                return (accion.estado === "T" || accion.disponibilidad === "2")
                              }

                            )
                          )
                        : []
                    }
                    selectable={true}
                    firstColumnLink={false}
                    delete={true}
                    deleteFunction={borrarAccion}
                    archive={false}
                    archiveFunction={() => console.log("The Function")}
                    searchBar={true}
                    buttonCreate={true}
                    createNewFunction={() => history.push("/acciones/detalles")}
                    textForCreateNew={"Crear Acción"}
                    paginate={true}
                    paginateRows={10}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </Row>
      <ModalEstandar visibilidad={modalDelete} setModal={setModalDelete}>
        <BorrarAccion
          setModal={setModalDelete}
          nombreDeAccion={accionABorrar ? accionABorrar.nombre : ""}
          idDeAccion={accionABorrar ? accionABorrar.id : ""}
        />
      </ModalEstandar>
    </div>
  );
};

export default DetalleProyecto;
