import * as types from '../../consts/actionTypes'

export const getMyNotifications = (payload) => {
    console.log("getting notifications...")
    return {
        type: types.GET_NOTIFICATIONS,
        payload
    }
}

export const todayNotifications = (payload) => {
    console.log("sending notifications...")
    return {
        type: types.GET_TODAY_NOTIFICATIONS,
        payload
    }
}

export const readNotification = (payload) => {
    console.log("putting notification...")
    return {
        type: types.PUT_NOTIFICATIONS,
        payload
    }
}

export const sendNumberOfUnreadNotifications = (payload) => {
    console.log("sending number of unread notifications...")
    return {
        type: types.SEND_NUMBER_OF_UNREAD_NOTIFICATIONS,
        payload
    }
}

export const updateNotifications = (payload) => {
    console.log("sending update notifications...")
    return {
        type: types.UPDATE_NOTIFICATIONS,
        payload
    }
}

export const resetNotifications = (payload) => {
    return {
        type: types.RESET_NOTIFICATIONS,
        payload
    }
}
