import * as types from "../../consts/actionTypes";

const initialState = {
  respuestaAtenderSolicitud: undefined
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_SOLICITUD_SIAC_SUCCESS:
      console.log(
        "Lista de solicitudes guardada en el estado usuarioSolicitud"
      );
      return {
        ...state,
        usuarioSolicitud: action.results.data,
      };
    case types.ATENDER_SOLICITUD_SIAC_SUCCESS:
      return {
        ...state,
        respuestaAtenderSolicitud: action.results,
      };
    default:
      return {
        ...state,
      };
  }
}
