import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from "../services/authServices"

export function* getAccionesSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "comunicados/",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Acciones solicitadas satisfactoriamente");
    yield put({ type: types.GET_ACCIONES_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar acciones :", e);
    yield put({ type: types.GET_ACCIONES_ERROR, payload });
  }
}

export function* getAccionADetalleSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `comunicados/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Accion a detalle solicitada satisfactoriamente");
    yield put({ type: types.GET_ACCION_DETALLE_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar accion a detalle :", e);
    yield put({ type: types.GET_ACCION_DETALLE_ERROR, payload });
  }
}

export function* postAccionSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "comunicados/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Accion creada satisfactoriamente");
    yield put({ type: types.POST_ACCION_SUCCESS, results });
  } catch (e) {
    console.log("Error al crear accion :", e);
    yield put({ type: types.POST_ACCION_ERROR, payload });
  }
}

export function* putAccionSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `comunicados/${payload.id}/`,
      payload.body,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Accion actualizada satisfactoriamente");
    yield put({ type: types.PUT_ACCION_SUCCESS, results });
  } catch (e) {
    console.log("Error al actualizar accion :", e);
    yield put({ type: types.PUT_ACCION_ERROR, payload });
  }
}

export function* deleteAccionSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `comunicados/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    console.log("Accion eliminada satisfactoriamente");
    yield put({ type: types.DELETE_ACCION_SUCCESS, results });
  } catch (e) {
    console.log("Error al eliminar accion :", e);
    yield put({ type: types.DELETE_ACCION_ERROR, payload });
  }
}

export function* postComentarioAccionSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "comunicados/comentario/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Comentario creado satisfactoriamente");
    yield put({ type: types.POST_COMENTARIO_ACCION_SUCCESS, results });
  } catch (e) {
    console.log("Error al crear comentario :", e);
    yield put({ type: types.POST_COMENTARIO_ACCION_ERROR, payload });
  }
}

export function* postRespuestaAccionSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "comunicados/comentario/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Respuesta creada satisfactoriamente");
    yield put({ type: types.POST_RESPUESTA_ACCION_SUCCESS, results });
  } catch (e) {
    console.log("Error al crear respuesta :", e);
    yield put({ type: types.POST_RESPUESTA_ACCION_ERROR, payload });
  }
}

export function* putComentarioAccionSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `comunicados/comentario/${payload.id}/`,
      payload.body,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Comentario actualizado satisfactoriamente");
    yield put({ type: types.PUT_COMENTARIO_ACCION_SUCCESS, results });
  } catch (e) {
    console.log("Error al actualizar comentario :", e);
    yield put({ type: types.PUT_COMENTARIO_ACCION_ERROR, payload });
  }
}

export function* postVotoComentarioAccionSaga({ payload }) {
  console.log(payload)
  try {
    const results = yield call(
      apiCall,
      "comunicados/votos-comentarios/",  
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Voto añadido satisfactoriamente");
    yield put({ type: types.POST_VOTO_COMENTARIO_ACCION_SUCCESS, results });
  } catch (e) {
    console.log("Error al añadir voto: ", e.message);
    yield put({ type: types.POST_VOTO_COMENTARIO_ACCION_ERROR, e });
  }
}

export function* deleteComentarioAccionSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `comunicados/comentario/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    console.log("Comentario eliminado satisfactoriamente");
    yield put({ type: types.DELETE_COMENTARIO_ACCION_SUCCESS, results });
  } catch (e) {
    console.log("Error al eliminar comentario: ", e.message);
    yield put({ type: types.DELETE_COMENTARIO_ACCION_ERROR, e });
  }
}



export default function* accionesSaga() {
  yield takeLatest(types.GET_ACCIONES, getAccionesSaga);
  yield takeLatest(types.GET_ACCION_DETALLE, getAccionADetalleSaga);
  yield takeLatest(types.POST_ACCION, postAccionSaga);
  yield takeLatest(types.PUT_ACCION, putAccionSaga);
  yield takeLatest(types.DELETE_ACCION, deleteAccionSaga);
  yield takeLatest(types.POST_COMENTARIO_ACCION, postComentarioAccionSaga);
  yield takeLatest(types.POST_RESPUESTA_ACCION, postRespuestaAccionSaga);
  yield takeLatest(types.PUT_COMENTARIO_ACCION, putComentarioAccionSaga);
  yield takeLatest(types.POST_VOTO_COMENTARIO_ACCION, postVotoComentarioAccionSaga);
  yield takeLatest(types.DELETE_COMENTARIO_ACCION, deleteComentarioAccionSaga);
}
