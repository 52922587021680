import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Selector from "../../redux/selectors/rootSelectors";
import { useEffect } from "react";
import { verifyToken, refreshToken } from "../../redux/actions/userActions";
import SideBar from "../sidebar/sidebar";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const logged = useSelector((state) => Selector.logged(state));
  const access = useSelector((state) => Selector.tokenAccess(state));
  const isValid = useSelector((state) => Selector.isValid(state));
  const dispatch = useDispatch();
  const token = localStorage.getItem("access");
  const tokenRefresh = localStorage.getItem("refresh");

  const verifyTokenTrue = async () => {
    dispatch(verifyToken(token));
    if (!isValid) {
      dispatch(refreshToken(tokenRefresh));
    }
  };

  useEffect(() => {
    if (token !== "" && token !== null) verifyTokenTrue();
  }, [isValid]);

console.log(logged)

  return (
    <Route
      {...rest}
      render={(props) =>
        logged ? (
          <div className="drawer-content">
            <SideBar />
            <Component {...props}  />
          </div>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
