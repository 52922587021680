import * as types from "../../consts/actionTypes";

const initialState = {
  updateAdminList: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.POST_REGISTRO_ADMINISTRADOR_SUCCESS:
      return {
        ...state,
        updateAdminList: true,
      };
    case types.DELETE_VALIDACION_USUARIO_SUCCESS:
      return {
        ...state,
        updateAdminList: true,
      };
    case types.NO_ACTUALIZA_LISTA_ADMINS:
      return {
        ...state,
        updateAdminList: false,
      };
    case types.POST_ASIGNA_ROL_USUARIO_SUCCESS:
      return {
        ...state,
        updateAdminList: true,
      };
    case types.PUT_ROL_USUARIO_SUCCESS:
      return {
        ...state,
        updateAdminList: true,
      };
    case types.PUT_ROL_USUARIO_SUCCESS:
      return {
        ...state,
        updateAdminList: true,
      };
    case types.POST_ACEPTA_POLITICAS_PRIVACIDAD_SUCCESS:
      return {
        ...state,
      };
    case types.POST_ACEPTA_VIDEO_TUTORIAL_SUCCESS:
      return {
        ...state,
      };
    case types.POST_CAMBIAR_PASSWORD_SUCCESS:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
}
