import React from "react";
import { useDispatch } from "react-redux";

//Actions
import { deleteConversacion } from "../../../redux/actions/messages";

//Selector

//Las clases de este componente se alojan en App.css

const BorrarConversacion = ({ setModal, idDeConversacion }) => {
  const dispatch = useDispatch();
  const handleEliminar = () => {
    dispatch(deleteConversacion(idDeConversacion));
    setModal(false);
  };

  return (
    <div className="cuerpo-modal-borrado">
      <p className="titulo-modal-borrado">¿Eliminar Conversación?</p>
      <p className="detalle-modal-borrado">
        Procede para eliminar permanentemente la conversacion con id “{idDeConversacion}”
      </p>
      <div className="div-botones-modal-borrado">
        <button
          className="boton-cancelar-modal-borrado"
          onClick={() => setModal(false)}
        >
          Cancelar
        </button>
        <button
          className="boton-eliminar-modal-borrado"
          onClick={handleEliminar}
        >
          Eliminar
        </button>
      </div>
    </div>
  );
};

export default BorrarConversacion;
